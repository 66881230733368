import React from "react";
import { useParams } from "react-router-dom";

import { Container } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import AdminTrilha from "../../components/formularios/AdminTrilha";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import adminService from "../../services/admin.service";

const PageVisualizarTrilha = () => {
  let { id } = useParams();
  // TODO: remover esse default
  id = id || "1";

  // Conexão com o back-end
  const {
    isPending,
    isError,
    data: initialData,
    error,
  } = useQuery({
    queryKey: ["trilhaCursoVisualizar", id],
    queryFn: ({ queryKey }) => {
      const [, id] = queryKey;
      return adminService.getTrilhaCurso(id);
    },
    enabled: !!id,
  });

  if (isError) {
    console.log("Erro de carregamento:", error);
  }

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "", text: "Trilhas" },
          { href: "/trilhas/listar", text: "Listar" },
        ]}
        currentPage="Visualizar"
      />

      <TitlePage title={"Visualizar"} />

      {isPending ? (
        <LoadingComponent
          isLoading={isPending}
          fullScreen={false}
        />
      ) : (
        <AdminTrilha
          initialValues={initialData}
          visualizar={true}
        />
      )}
      {isError && <ErroCarregamentoModal isShow={true} />}
    </Container>
  );
};

export default PageVisualizarTrilha;
