import React, { useEffect, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, Card, Grid, Stack, TextField } from "@mui/material";

import { tabelaPerfil } from "../formularios/dadosMockados";
import DefaultTable from "../table/DefaultTable";

// Retornar a pesquisa vazia
const initialFilterState = {
  nome: "",
  situacao: "",
};

const tabelaColunas = [
  { field: "dataModificacao", headerName: "Modificação" },
  { field: "usuario", headerName: "Usuário" },
  { field: "situacao", headerName: "Situação Anterior" },
  { field: "motivo", headerName: "Motivo" },
];

const AdminHistorioSituacaoUsuario = () => {
  const [isLoading, setIsLoading] = useState(true);
  // Dados fictícios para teste da tabela
  const [initialData, setInitialData] = useState(tabelaPerfil);
  const [filter, setFilter] = useState(initialFilterState);
  const [filteredData, setFilteredData] = useState(initialData);
  const perfilID = JSON.parse(localStorage.getItem("perfilID"));

  useEffect(() => {
    if (perfilID) {
      const perfilEncontrado = tabelaPerfil.find(perfil => perfil.id === perfilID);

      if (perfilEncontrado) {
        setInitialData(perfilEncontrado.historico);
        setFilteredData(perfilEncontrado.historico);
      }
    }
    setIsLoading(false);
  }, []);

  const handleFilterChange = event => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
  };

  if (isLoading) {
    return <div>Carregando...</div>;
  }
  // const handleFilterSelectChange = event => {
  //   const { name, value } = event.target;
  //   setFilter({ ...filter, [name]: typeof value === "string" ? value.split(",") : value });
  // };

  const applyFilter = () => {
    const filtered = initialData.filter(usuario => {
      const matchesTextFilter =
        usuario.usuario.toLowerCase().includes(filter.nome.toLowerCase()) &&
        usuario.situacao.toLowerCase().includes(filter.situacao.toLowerCase());

      return matchesTextFilter;
    });
    setFilteredData(filtered);
  };

  const resetFilter = () => {
    setFilter(initialFilterState);
    applyFilter();
  };

  return (
    <>
      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          {/* Campos para filtrar */}
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              spacing={2}
              container
            >
              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  fullWidth
                  id="nome"
                  name="nome"
                  value={filter.nome}
                  label="Nome"
                  placeholder="Nome"
                  type="text"
                  onChange={handleFilterChange}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  fullWidth
                  id="situacao"
                  name="situacao"
                  value={filter.situacao}
                  label="Situação"
                  placeholder="Situação"
                  type="text"
                  onChange={handleFilterChange}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Botões de pesquisa/cancelar */}
          <Box sx={{ flexGrow: 1, mt: "16px" }}>
            <Grid
              container
              spacing={1}
              justifyContent="flex-end"
            >
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={resetFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={applyFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                >
                  <SearchIcon />
                  Pesquisar
                </Button>
              </Grid>
            </Grid>
          </Box>

          {/* Tabela */}
          <Box sx={{ flexGrow: 1, mt: "40px" }}>
            <DefaultTable
              rows={filteredData}
              notFoundText={"Não foi localizado Hitórico do Perfil!"}
              columns={tabelaColunas}
            ></DefaultTable>
          </Box>

          <Grid
            container
            mt={2}
            spacing={1}
            justifyContent="flex-end"
          >
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => window.history.back()}
                sx={{ gap: "8px" }}
                style={{ fontFamily: "Rawline Medium" }}
              >
                Voltar
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Stack>
    </>
  );
};

export default AdminHistorioSituacaoUsuario;
