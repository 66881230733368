import React, { useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Box, FormGroup, Grid, TextField } from "@mui/material";
import PropTypes from "prop-types";

import { useResponsive } from "../../hooks/use-responsive";
import ButtonSecondary from "../buttons/ButtonSecondary";

const FormMultipleInsert = ({ label, placeHolder, onFieldsChange, initialValues = [""], disabled, errors, touched }) => {
  const defaultFields =
    (initialValues ?? []).length > 0
      ? initialValues.map((value, index) => ({ id: Date.now() + index, value }))
      : [{ id: Date.now(), value: "" }];

  const [fields, setFields] = useState(defaultFields);
  const smDown = useResponsive("down", "sm");

  const handleAddField = () => {
    const newFields = [...fields, { id: Date.now(), value: "" }];
    setFields(newFields);
    onFieldsChange(newFields.map(field => field.value));
  };

  const handleRemoveField = id => {
    const newFields = fields.filter(field => field.id !== id);
    setFields(newFields);
    onFieldsChange(newFields.map(field => field.value));
  };

  const handleChange = (id, newValue) => {
    const newFields = fields.map(field => (field.id === id ? { ...field, value: newValue } : field));
    setFields(newFields);
    onFieldsChange(newFields.map(field => field.value));
  };

  const allFieldsFilled = () => {
    return fields.every(field => field.value?.trim() !== "");
  };

  // Os errors só são atualizados ao chamar um validateField, por enquanto eu só removo eles da verificação do componente
  if (errors?.length > initialValues?.length) {
    errors = errors.slice(0, initialValues.length);
  }

  // Usando os erros para permitir adicionar mais campos
  const hasErrorInFields = errors?.some(error => error) || false;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}>
      <Grid
        container
        spacing={2}
        px={2}
      >
        {fields.map((field, index) => (
          <Grid
            container
            spacing={2}
            key={field.id}
          >
            <Grid
              item
              xs={disabled ? 12 : 10}
              sx={{ mt: 1 }}
            >
              <FormGroup>
                <TextField
                  label={label}
                  id={"id_" + label + "_" + index}
                  value={field.value}
                  placeholder={placeHolder}
                  type="text"
                  onChange={e => handleChange(field.id, e.target.value)}
                  disabled={disabled}
                  error={Boolean(touched?.[index] && errors?.[index])}
                  helperText={touched?.[index] && errors?.[index] ? errors[index] : ""}
                />
              </FormGroup>
            </Grid>

            {index === 0 && allFieldsFilled() && !disabled && (
              <Grid
                item
                xs={2}
                sx={{ mt: 1 }}
                style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-end" }}
              >
                <ButtonSecondary
                  title={smDown ? "" : "adicionar"}
                  icon={<AddCircleIcon />}
                  onClick={handleAddField}
                  disabled={disabled || hasErrorInFields}
                />
              </Grid>
            )}

            {index > 0 && !disabled && (
              <Grid
                item
                xs={2}
                sx={{ mt: 1 }}
                style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-end" }}
              >
                <ButtonSecondary
                  title={smDown ? "" : "remover"}
                  icon={<RemoveCircleIcon />}
                  onClick={() => handleRemoveField(field.id)}
                  disabled={disabled}
                />
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

FormMultipleInsert.propTypes = {
  initialValues: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  placeHolder: PropTypes.string,
  onFieldsChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  touched: PropTypes.arrayOf(PropTypes.bool),
};

export default FormMultipleInsert;
