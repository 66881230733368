import React from "react";

import { Checkbox } from "@mui/material";
import PropTypes from "prop-types";

import constantsService from "../../services/constants.service";
import CustomModal from "./CustomModal";

const termos = constantsService.getAllServicosTermos();

const ServicoOfertadoModal = ({ showModal, handleClose, tipoDeServico }) => {
  const modalTitle = "Serviços Ofertados";

  if (!tipoDeServico || Object.keys(tipoDeServico).length === 0) {
    return null;
  }

  const modalContent = (
    <>
      <center>{"Os serviços ofertados são: "}</center>
      <p>
        {tipoDeServico &&
          Object.keys(tipoDeServico).map(nome => {
            if (nome in termos) {
              return (
                <React.Fragment key={nome}>
                  <Checkbox
                    disabled
                    id={nome}
                    name={nome}
                    checked={tipoDeServico[nome]}
                  />{" "}
                  {termos[nome]} <br />
                </React.Fragment>
              );
            }
            return null;
          })}
      </p>
    </>
  );

  const modalButtons = [{ label: "Fechar", onClick: handleClose }];

  return (
    <CustomModal
      showModal={showModal}
      handleClose={handleClose}
      title={modalTitle}
      content={modalContent}
      buttons={modalButtons}
      maxWidth="xs"
    />
  );
};

ServicoOfertadoModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  tipoDeServico: PropTypes.object,
};

export default ServicoOfertadoModal;
