import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminHistoricoSituacaoUsuario from "../../components/filtros/AdminHistoricoSituacaoUsuario";
import TitlePage from "../../components/title-page/TitlePage";

const PageHistoricoSituacaoUsuario = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "", text: "Usuários" },
          { href: "/visualizar-usuario", text: "Visualizar" },
        ]}
        currentPage="Histórico Ativação"
      />

      <TitlePage title={"Histórico de Ativação do Usuário"} />

      <AdminHistoricoSituacaoUsuario />
    </Container>
  );
};

export default PageHistoricoSituacaoUsuario;
