import React from "react";

const AvisoDePrivacidade = () => {
  return (
    <>
      <h2>
        <strong>1. DEFINIÇÕES</strong>
      </h2>
      <p>Para melhor compreensão deste documento, nesta Política de Privacidade, consideram-se:</p>
      <table>
        <thead>
          <tr>
            <th>Termo</th>
            <th>Definição</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Agentes de tratamento:</td>
            <td>O controlador e o operador.</td>
          </tr>
          <tr>
            <td>Anonimização:</td>
            <td>
              Utilização de meios técnicos razoáveis e disponíveis no momento do tratamento, por meio dos quais um dado perde a
              possibilidade de associação, direta ou indireta, a um indivíduo.
            </td>
          </tr>
          <tr>
            <td>Autoridade Nacional:</td>
            <td>
              Órgão da administração pública responsável por zelar, implementar e fiscalizar o cumprimento desta Lei em todo o território
              nacional.
            </td>
          </tr>
          <tr>
            <td>Banco de Dados:</td>
            <td>Conjunto estruturado de dados pessoais, estabelecido em um ou em vários locais, em suporte eletrônico ou físico.</td>
          </tr>
          <tr>
            <td>Consentimento:</td>
            <td>
              Manifestação livre, informada e inequívoca pela qual o titular concorda com o tratamento de seus dados pessoais para uma
              finalidade determinada.
            </td>
          </tr>
          <tr>
            <td>Controlador:</td>
            <td>
              Pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados
              pessoais.
            </td>
          </tr>
          <tr>
            <td>Dado Anonimizado:</td>
            <td>
              Dado relativo a um titular que não possa ser identificado, considerando a utilização de meios técnicos razoáveis e disponíveis
              na ocasião de seu tratamento.
            </td>
          </tr>
          <tr>
            <td>Dado Pessoal:</td>
            <td>Informação relacionada a uma pessoa natural identificada ou identificável.</td>
          </tr>
          <tr>
            <td>Dado Pessoal Sensível:</td>
            <td>
              Dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de
              caráter religioso filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando
              vinculado a uma pessoa natural.
            </td>
          </tr>
          <tr>
            <td>Encarregado:</td>
            <td>
              Pessoa indicada pelo controlador e operador para atuar como canal de comunicação entre o controlador, os titulares dos dados e
              a Autoridade Nacional de Proteção de Dados (ANPD).
            </td>
          </tr>
          <tr>
            <td>Operador:</td>
            <td>
              Pessoa natural ou jurídica, de direito público ou privado, que realiza o tratamento de dados pessoais em nome do controlador.
            </td>
          </tr>
          <tr>
            <td>Órgão de Pesquisa:</td>
            <td>
              Órgão ou entidade da administração pública direta ou indireta ou pessoa jurídica de direito privado sem fins lucrativos,
              legalmente constituída sob as leis brasileiras e com sede e foro no País, que inclua em sua missão institucional ou em seu
              objetivo social ou estatutário a pesquisa básica ou aplicada de caráter histórico, científico, tecnológico ou estatístico.
            </td>
          </tr>
          <tr>
            <td>Titular:</td>
            <td>Pessoa natural a quem se referem os dados pessoais que são objeto de tratamento.</td>
          </tr>
          <tr>
            <td>Transferência Internacional de Dados:</td>
            <td>Transferência de dados pessoais para país estrangeiro ou organismo internacional do qual o país seja membro.</td>
          </tr>
          <tr>
            <td>Tratamento:</td>
            <td>
              Toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização,
              acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle
              da informação, modificação, comunicação, transferência, difusão ou extração.
            </td>
          </tr>
          <tr>
            <td>Uso Compartilhado de Dados:</td>
            <td>
              Comunicação, difusão, transferência internacional, interconexão de dados pessoais ou tratamento compartilhado de bancos de
              dados pessoais por órgãos e entidades públicos no cumprimento de suas competências legais, ou entre esses e entes privados,
              reciprocamente, com autorização específica, para uma ou mais modalidades de tratamento permitidas por esses entes públicos, ou
              entre entes privados.
            </td>
          </tr>
        </tbody>
      </table>

      <h2>
        <strong>2. BASE LEGAL PARA TRATAMENTO DE DADOS PESSOAIS</strong>
      </h2>
      <p>
        Esta Política de Privacidade foi elaborada em conformidade com a Lei Federal nº 12.965 de 23 de abril de 2014 (Marco Civil da
        Internet) e com a Lei Federal nº 13.709, de 14 de agosto de 2018 (Lei Geral de Proteção de Dados Pessoais).
      </p>
      <p>
        O site se compromete a cumprir as normas previstas na Lei Geral de Proteção de Dados Pessoais (LGPD) e a respeitar os princípios
        dispostos no Art. 6º de tal norma:
      </p>
      <ol style={{ listStyleType: "upper-roman" }}>
        <li>
          <strong>Finalidade:</strong> realização do tratamento para propósitos legítimos, específicos, explícitos e informados ao titular,
          sem possibilidade de tratamento posterior de forma incompatível com essas finalidades;
        </li>
        <li>
          <strong>Adequação:</strong> compatibilidade do tratamento com as finalidades informadas ao titular, de acordo com o contexto do
          tratamento;
        </li>
        <li>
          <strong>Necessidade:</strong> limitação do tratamento ao mínimo necessário para a realização de suas finalidades, com abrangência
          dos dados pertinentes, proporcionais e não excessivos em relação às finalidades do tratamento de dados;
        </li>
        <li>
          <strong>Livre Acesso:</strong> garantia, aos titulares, de consulta facilitada e gratuita sobre a forma e a duração do tratamento,
          bem como sobre a integralidade de seus dados pessoais;
        </li>
        <li>
          <strong>Qualidade dos dados:</strong> garantia, aos titulares, de exatidão, clareza, relevância e atualização dos dados, de acordo
          com a necessidade e para o cumprimento da finalidade de seu tratamento;
        </li>
        <li>
          <strong>Transparência:</strong> garantia, aos titulares, de informações claras, precisas e facilmente acessíveis sobre a
          realização do tratamento e os respectivos agentes de tratamento, observados os segredos comercial e industrial;
        </li>
        <li>
          <strong>Segurança:</strong> utilização de medidas técnicas e administrativas aptas a proteger os dados pessoais de acessos não
          autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou difusão;
        </li>
        <li>
          <strong>Prevenção:</strong> adoção de medidas para prevenir a ocorrência de danos em virtude do tratamento de dados pessoais;
        </li>
        <li>
          <strong>Não discriminação</strong> impossibilidade de realização do tratamento para fins discriminatórios ilícitos ou abusivos;
        </li>
        <li>
          <strong>Responsabilização e prestação de contas:</strong> demonstração, pelo agente, da adoção de medidas eficazes e capazes de
          comprovar a observância e o cumprimento das normas de proteção de dados pessoais e, inclusive, da eficácia dessas medidas.
        </li>
      </ol>

      <h2>
        <strong>3. CONTROLADOR</strong>
      </h2>
      <p>
        A quem competem as decisões referentes ao tratamento de dados pessoais realizado no serviço do Aplicativo PROGRAMA ACREDITA NO
        PRIMEIRO PASSO (Controlador)?
      </p>
      <p>
        A Lei Geral de Proteção de Dados Pessoais define como controlador, em seu Art. 5º, inciso VI, a pessoa natural ou jurídica, de
        direito público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais.
      </p>
      <p>
        Para o serviço do Programa Acredita no Primeiro Passo, as decisões referentes ao tratamento de dados pessoais são de
        responsabilidade do Ministério do Desenvolvimento e Assistência Social, Família e Combate à Fome.
      </p>
      <p>Controlador - Ministério do Desenvolvimento e Assistência Social, Família e Combate à Fome,</p>
      <p>Endereço: Esplanada dos Ministérios, Bloco A, 8º andar, Brasília/DF, CEP 70.054-906,</p>
      <p>
        E-mail: <a href="mailto:gabineteministro@cidadania.gov.br">gabineteministro@cidadania.gov.br</a>
      </p>
      <p>Telefone: (61) 2030-2606.</p>

      <h2>
        <strong>4. OPERADOR</strong>
      </h2>
      <p>Quem realiza o tratamento de dados (Operador)?</p>
      <p>
        A Lei Geral de Proteção de Dados Pessoais define como operador, em seu Art. 5º, inciso VII, a pessoa natural ou jurídica, de direito
        público ou privado, que realiza o tratamento de dados pessoais em nome do controlador.
      </p>
      <p>
        Para o serviço do Aplicativo Programa Acredita no Primeiro Passo, quem realiza o tratamento de dados pessoais em nome do controlador
        é o operador:
      </p>
      <p>Operador – Secretário de Inclusão Socioeconômica (SISEC),</p>
      <p>Nome: Luiz Carlos Everton de Farias,</p>
      <p>
        Endereço: Esplanada dos Ministérios, Bloco A, 7º andar, Brasília/DF, E-mail:{" "}
        <a href="mailto:gabinete.sisec@cidadania.gov.br">gabinete.sisec@cidadania.gov.br</a>
      </p>
      <p>Telefone: (61) 2030-1223.</p>

      <h2>
        <strong>5. ENCARREGADO</strong>
      </h2>
      <p>
        Quem é o responsável por atuar como canal de comunicação entre o controlador, os titulares dos dados e a Autoridade Nacional de
        Proteção de Dados (Encarregado)?
      </p>
      <p>
        A Lei Geral de Proteção de Dados Pessoais define como encarregado, em seu Art. 5º, inciso VIII, a pessoa indicada pelo controlador e
        operador para atuar como canal de comunicação entre o controlador, os titulares dos dados e a Autoridade Nacional de Proteção de
        Dados (ANPD).
      </p>
      <p>
        Para o serviço do Aplicativo Programa Acredita no Primeiro Passo, o responsável por atuar como canal de comunicação entre o
        controlador, os titulares dos dados e a Autoridade Nacional de Proteção de Dados é a encarregada Eliana Pinto, representante da
        Ouvidoria Geral do Ministério do Desenvolvimento e Assistência Social, Família e Combate à Fome.
      </p>
      <p>
        O usuário poderá entrar em contato por meio do e-mail:{" "}
        <a href="mailto:ouvidoria.gabinete@cidadania.gov.br">ouvidoria.gabinete@cidadania.gov.br</a>, Endereço: Esplanada dos Ministérios,
        Bloco A, 2º andar, sala 205, Brasília/DF, CEP 70.054-906, Telefone: (61) 3429-6828, para sanar quaisquer dúvidas sobre esta Política
        de Privacidade ou para obter mais informações sobre o tratamento dos dados realizado com fundamento na LGPD.
      </p>

      <h2>
        <strong>6. DIREITOS DO TITULAR DE DADOS PESSOAIS</strong>
      </h2>
      <p>Quais são os direitos do titular de dados pessoais?</p>
      <p>O titular de dados pessoais possui os seguintes direitos, conferidos pela Lei Geral de Proteção de Dados Pessoais (LGPD):</p>
      <ul>
        <li>
          Direito de confirmação e acesso (Art. 18, incisos I e II): é o direito do titular de dados de obter do serviço a confirmação de
          que os dados pessoais que lhe digam respeito são ou não objeto de tratamento e, se for esse o caso, o direito de acessar os seus
          dados pessoais.
        </li>
        <li>
          Direito de retificação (Art. 18, inciso III): é o direito de solicitar a correção de dados incompletos, inexatos ou
          desatualizados.
        </li>
        <li>
          Direito à limitação do tratamento dos dados (Art. 18, inciso IV): é o direito do titular de dados de limitar o tratamento de seus
          dados pessoais, podendo exigir a eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na
          Lei Geral de Proteção de Dados Pessoais.
        </li>
        <li>
          Direito de oposição (Art. 18, § 2º): é o direito do titular de dados de, a qualquer momento, opor-se ao tratamento de dados por
          motivos relacionados com a sua situação particular, com fundamento em uma das hipóteses de dispensa de consentimento ou em caso de
          descumprimento ao disposto na Lei Geral de Proteção de Dados Pessoais.
        </li>
        <li>
          Direito de portabilidade dos dados (Art. 18, inciso V): é o direito do titular de dados de realizar a portabilidade dos dados a
          outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional,
          observados os segredos comercial e industrial.
        </li>
        <li>
          Direito de não ser submetido a decisões automatizadas (Art. 20): o titular dos dados tem direito a solicitar a revisão de decisões
          tomadas unicamente com base em tratamento automatizado de dados pessoais que afetem seus interesses, incluídas as decisões
          destinadas a definir o seu perfil pessoal, profissional, de consumo e de crédito ou os aspectos de sua personalidade.
        </li>
      </ul>

      <h2>
        <strong>7. QUAIS DADOS SÃO TRATADOS</strong>
      </h2>
      <p>
        A utilização de determinadas funcionalidades do Serviço pelo titular de dados pessoais dependerá do tratamento dos seguintes dados
        pessoais:
      </p>
      <ul>
        <li>Login único;</li>
        <li>Nome completo;</li>
        <li>Número de inscrição no CPF;</li>
        <li>Dados do dispositivo (modelo de hardware, sistema operacional);</li>
      </ul>

      <h2>
        <strong>8. COMO OS DADOS SÃO COLETADOS</strong>
      </h2>
      <p>A forma como os seus dados pessoais são coletados é indicada abaixo:</p>
      <table>
        <thead>
          <tr>
            <th>
              <strong>DADOS TRATADOS</strong>
            </th>
            <th>
              <strong>FORMA DE COLETA DOS DADOS</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Login único</td>
            <td>Obtido de terceiros</td>
          </tr>
          <tr>
            <td>Nome completo</td>
            <td>Obtido de base de dados pré-cadastrado</td>
          </tr>
          <tr>
            <td>Número de inscrição no CPF</td>
            <td>Obtido de base de dados pré-cadastrado</td>
          </tr>
          <tr>
            <td>Dados do dispositivo (modelo de hardware, sistema operacional)</td>
            <td>Obtido ao utilizar o serviço após a aceite desta Política de Privacidade</td>
          </tr>
        </tbody>
      </table>

      <h2>
        <strong>9. QUAL O TRATAMENTO REALIZADO E PARA QUAL FINALIDADE</strong>
      </h2>
      <table>
        <thead>
          <tr>
            <th>
              <strong>DADOS</strong>
            </th>
            <th>
              <strong>TRATAMENTO</strong>
            </th>
            <th>
              <strong>FINALIDADE</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Login único</td>
            <td>Acesso</td>
            <td>O dado é necessário para autenticar o usuário dentro do aplicativo</td>
          </tr>
          <tr>
            <td>Nome completo</td>
            <td>Acesso / Armazenamento</td>
            <td>Identificação do usuário dentro do aplicativo</td>
          </tr>
          <tr>
            <td>Número de inscrição no CPF</td>
            <td>Acesso / Armazenamento</td>
            <td>Identificação do usuário e registro de operações realizadas no aplicativo</td>
          </tr>
          <tr>
            <td>Dados do dispositivo (modelo de hardware, sistema operacional)</td>
            <td>Coleta</td>
            <td>Informação capturada pela loja Google Play</td>
          </tr>
        </tbody>
      </table>

      <h2>
        <strong>10. COMPARTILHAMENTO DE DADOS</strong>
      </h2>
      <p>
        O Ministério do Ministério do Desenvolvimento e Assistência Social, Família e Combate à Fome poderá, a qualquer tempo, fornecer
        dados ou informações relativas aos usuários do Aplicativo Programa Acredita no Primeiro Passo a outros serviços públicos digitais
        cuja finalidade seja a efetiva prestação de serviço público pelo compartilhamento de dados ou informações ou atender demanda
        judicial ou policial ou por requisição do Ministério Público, conforme a LGPD.
      </p>

      <h2>
        <strong>11. TRANSFERÊNCIA INTERNACIONAL DE DADOS</strong>
      </h2>
      <p>
        O serviço do Aplicativo Programa Acredita no Primeiro Passo poderá realizar transferência de dados internacionalmente ou tratamento
        compartilhado de bancos de dados pessoais por órgãos e entidades públicos no cumprimento de suas competência legais, ou entre esses
        e entes privados, reciprocamente, com autorização específica, para uma ou mais modalidades de tratamento permitidas por esses entes
        públicos, ou entre entes privados.
      </p>

      <h2>
        <strong>12. SEGURANÇA DOS DADOS</strong>
      </h2>
      <p>
        O serviço do Aplicativo Programa Acredita no Primeiro Passo se compromete a aplicar as medidas técnicas e organizativas aptas a
        proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, alteração, comunicação ou difusão de tais
        dados.
      </p>
      <p>
        Para a garantia da segurança, serão adotadas soluções que levem em consideração: as técnicas adequadas; os custos de aplicação; a
        natureza, o âmbito, o contexto e as finalidades do tratamento; e os riscos para os direitos e liberdades do usuário.
      </p>
      <p>
        O serviço utiliza criptografia para que os dados sejam transmitidos de forma segura e confidencial, de maneira que a transmissão dos
        dados entre o servidor e o usuário, e em retroalimentação, ocorra de maneira totalmente cifrada ou encriptada.
      </p>
      <p>
        No entanto, o serviço se exime de responsabilidade por culpa exclusiva de terceiros, como em caso de ataque de hackers ou crackers,
        ou culpa exclusiva do usuário, como no caso em que ele mesmo transfere seus dados a terceiro. O serviço de aplicativo Programa
        Acredita no Primeiro Passo se compromete, ainda, a comunicar o usuário em prazo adequado caso ocorra algum tipo de violação da
        segurança de seus dados pessoais que possa lhe causar um alto risco para seus direitos e liberdades pessoais.
      </p>
      <p>
        A violação de dados pessoais é uma violação de segurança que provoque, de modo acidental ou ilícito, a destruição, a perda, a
        alteração, a divulgação ou o acesso não autorizado a dados pessoais transmitidos, conservados ou sujeitos a qualquer outro tipo de
        tratamento.
      </p>
      <p>Por fim, o serviço se compromete a tratar os dados pessoais do usuário com confidencialidade, dentro dos limites legais.</p>

      <h2>
        <strong>13. COOKIES</strong>
      </h2>
      <p>
        Cookies são pequenos arquivos de texto enviados pelo site ao computador do usuário e que nele ficam armazenados, com informações
        relacionadas à navegação do site.
      </p>
      <p>
        Por meio dos cookies, pequenas quantidades de informação são armazenadas pelo navegador do usuário para que o servidor do serviço
        possa lê-las posteriormente. Podem ser armazenados, por exemplo, dados sobre o dispositivo utilizado pelo usuário, bem como seu
        local e horário de acesso ao site. É importante ressaltar que nem todo cookie contém dados pessoais do usuário, já que determinados
        tipos de cookies podem ser utilizados somente para que o serviço funcione corretamente.
      </p>
      <p>
        As informações eventualmente armazenadas em cookies também são consideradas dados pessoais. Todas as regras previstas nesta Política
        de Privacidade também são aplicáveis aos referidos cookies.
      </p>
      <p>
        Diante destas informações, é importante destacar que o serviço do Aplicativo Programa Acredita no Primeiro Passo não utiliza
        cookies.
      </p>

      <h2>
        <strong>14. TRATAMENTO POSTERIOR DOS DADOS PARA OUTRAS FINALIDADES</strong>
      </h2>
      <p>
        Informações sobre registro de interesses em cursos, em vagas de trabalho, os currículos elaborados, e os registros de interessados
        em empreender, dentre outros, podem ser utilizados para melhoria contínua dos serviços e aprimoramento da experiência do usuário no
        âmbito do Aplicativo Programa Acredita no Primeiro Passo.
      </p>

      <h2>
        <strong>15. MUDANÇAS NA POLÍTICA DE PRIVACIDADE</strong>
      </h2>
      <p>A presente versão desta Política de Privacidade foi atualizada pela última vez em: 04/05/2023.</p>
      <p>
        O editor se reserva o direito de modificar, a qualquer momento o site as presentes normas, especialmente para adaptá-las às
        evoluções do serviço Aplicativo Acredita, seja pela disponibilização de novas funcionalidades, seja pela supressão ou modificação
        daquelas já existentes.
      </p>
      <p>
        Esta Política de Privacidade poderá ser atualizada em decorrência de eventual atualização normativa, razão pela qual se convida o
        usuário a consultar periodicamente esta seção.
      </p>
    </>
  );
};
export default AvisoDePrivacidade;
