import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminVisualizarVagaTrabalho from "../../components/formularios/AdminVisualizarVagaTrabalho";
import TitlePage from "../../components/title-page/TitlePage";

const PageVisualizarVagaTrabalho = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "", text: "Vagas" },
          { href: "/listar-vagas", text: "Listar" },
        ]}
        currentPage="Visualizar"
      />

      <TitlePage title={"Visualizar"} />

      <AdminVisualizarVagaTrabalho visualizacao={true} />
    </Container>
  );
};

export default PageVisualizarVagaTrabalho;
