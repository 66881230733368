import React, { useEffect, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, Card, FormControl, FormGroup, Grid, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

import optionsService from "../../services/options.service";
import { tabelaInteressadosMPO } from "../formularios/dadosMockados";
import { SelectCidade } from "../formularios/fields/SelectCidade";
import { SelectUF } from "../formularios/fields/SelectUF";
import TipoDeArquivoModal from "../modals/TipoDeArquivoModal";
import DefaultTable from "../table/DefaultTable";

const termos = {
  id: "ID",
  habilitacao: "Habilitação",
  email: "E-mail",
  uf: "UF",
  municipio: "Município",
  tipo: "Tipo de questionário respondido",
  dataResposta: "Data da resposta",
};

// Dados fictícios para teste da tabela
const initialData = tabelaInteressadosMPO;

// Retornar a pesquisa vazia
const initialFilterState = {
  parceiro: "",
  cpf: "",
  nome: "",
  uf: "",
  municipio: "",
  tipo: "",
  dataResposta: null,
};

const tabelaColunas = [
  { field: "nome", headerName: "Nome" },
  { field: "cpf", headerName: "CPF" },
  { field: "telefone", headerName: "Telefone" },
];

const keysHidden = ["email", "uf", "municipio", "tipo", "dataResposta"];

const ParceiroConsultarMPO = () => {
  const [filter, setFilter] = useState(initialFilterState);
  const [filteredData, setFilteredData] = useState(initialData);
  const [modal, setModal] = useState(false);

  const [ufs, setUfs] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [selectedUf, setSelectedUf] = useState("");
  const [selectedMunicipio, setSelectedMunicipio] = useState("");

  useEffect(() => {
    const fetchUfs = async () => {
      await optionsService
        .getAllUFs()
        .then(ufsData => {
          setUfs(ufsData);
        })
        .catch(error => {
          console.error("Erro ao obter UFs:", error);
        });
    };
    fetchUfs();
  }, []);

  const fetchCidadesByUf = async (ufSigla, setCidadesFunction) => {
    try {
      const cidadesData = await optionsService.getCidadesFromUF(ufSigla);
      setCidadesFunction(cidadesData);
    } catch (error) {
      console.error("Erro ao obter cidades:", error);
    }
  };

  const handleSelectUf = event => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });

    setSelectedUf(value);
    setSelectedMunicipio("");
    setFilter({ ...filter, ["municipio"]: "" });
    fetchCidadesByUf(event.target.value, setMunicipios);
  };

  const handleSelectMunicipio = event => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
    setSelectedMunicipio(event.target.value);
  };

  const tabelaHiddenLinhas = filteredData.map(row =>
    keysHidden.reduce((acc, key) => {
      acc[key] = row[key];
      return acc;
    }, {}),
  );

  const getTabelaActions = () => [
    {
      title: "Visualizar",
      href: "",
      icon: "visibility",
    },
    {
      title: "Adicionar",
      href: "",
      icon: "add",
      // onClick: rowId => localStorage.setItem("perfilID", JSON.stringify(rowId)),
    },
  ];

  const handleFilterChange = event => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
  };

  const applyFilter = () => {
    const filtered = initialData.filter(parceiro => {
      return (
        (!filter.dataDaRespostaInicio ||
          !filter.dataDaRespostaFim ||
          dayjs(parceiro.dataResposta).isBetween(filter.dataDaRespostaInicio, filter.dataDaRespostaFim, "day", "[]")) &&
        (!filter.dataDaUltimaModificacaoInicio ||
          !filter.dataDaUltimaModificacaoFim ||
          dayjs(parceiro.dataUltimaModificacao).isBetween(
            filter.dataDaUltimaModificacaoInicio,
            filter.dataDaUltimaModificacaoFim,
            "day",
            "[]",
          ))
      );
    });
    setFilteredData(filtered);
  };

  const resetFilter = () => {
    setFilter(initialFilterState);
    setSelectedUf("");
    setSelectedMunicipio("");
    applyFilter();
  };
  return (
    <>
      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          {/* Campos para filtrar */}
          <Grid
            spacing={3}
            container
          >
            {/* CPF e Parceiro */}
            <Grid
              item
              xs={12}
              sm={6}
            >
              <TextField
                fullWidth
                id="cpf"
                name="cpf"
                label="CPF"
                placeholder="CPF"
                value={filter.cpf}
                onChange={handleFilterChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <TextField
                fullWidth
                id="parceiro"
                name="parceiro"
                label="Parceiro"
                placeholder="Parceiro"
                value={filter.parceiro}
                onChange={handleFilterChange}
              />
            </Grid>

            {/* UF e Município */}
            <Grid
              item
              xs={12}
              sm={6}
            >
              <SelectUF
                idSelect="uf"
                nameSelect="uf"
                value={filter.uf}
                ufs={ufs}
                uf={selectedUf}
                label="* UF"
                handleSelectUf={event => handleSelectUf(event)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <SelectCidade
                idSelect="municipio"
                nameSelect="municipio"
                value={filter.municipio}
                cidades={municipios}
                cidade={selectedMunicipio}
                label="* Município"
                handleSelectCidade={event => handleSelectMunicipio(event)}
              />
            </Grid>

            {/* Data */}
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormGroup>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Data de Resposta"
                    id="dataResposta"
                    name="dataResposta"
                    value={filter.dataResposta}
                    format="DD/MM/YYYY"
                    onChange={valor => setFilter({ ...filter, ["dataResposta"]: valor })}
                  />
                </LocalizationProvider>
              </FormGroup>
            </Grid>

            {/* Informação complementar */}
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="label-infoComplementar">Informação complementar</InputLabel>
                <Select
                  id="infoComplementar"
                  name="infoComplementar"
                  value={filter.infoComplementar || ""}
                  type="text"
                  onChange={handleFilterChange}
                  labelId="label-infoComplementar"
                >
                  <MenuItem
                    value=""
                    disabled
                  >
                    Selecione uma opção
                  </MenuItem>
                  {initialData.map(usuario => (
                    <MenuItem
                      key={usuario.id + usuario.infoComplementar}
                      value={usuario.infoComplementar}
                    >
                      {usuario.infoComplementar}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              container
              xs={7}
            ></Grid>
          </Grid>

          {/* Botões de pesquisa/cancelar */}
          <Box sx={{ flexGrow: 1, mt: "16px" }}>
            <Grid
              container
              spacing={1}
              justifyContent="flex-end"
            >
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={resetFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={applyFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                >
                  <SearchIcon />
                  Pesquisar
                </Button>
              </Grid>
            </Grid>
          </Box>

          {/* Tabela */}
          <Box sx={{ flexGrow: 1, mt: "40px" }}>
            <DefaultTable
              rows={filteredData}
              notFoundText={"Não foi localizado interessados!"}
              columns={tabelaColunas}
              hiddenRows={tabelaHiddenLinhas}
              termos={termos}
              actionButtons={getTabelaActions}
            ></DefaultTable>
          </Box>
        </Card>
      </Stack>
      <div>
        <TipoDeArquivoModal
          showModal={modal}
          handleClose={() => setModal(false)}
          dados={filteredData}
          tipo={"Consulta"}
        />
      </div>
    </>
  );
};

export default ParceiroConsultarMPO;
