import React, { useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import { Button, Card, FormControl, Grid, InputLabel, Select, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";

import { tabelaUsuarios } from "../formularios/dadosMockados";
import ModalTituloSubTitulo from "../modals/ModalTituloSubTitulo";
import DefaultTable from "../table/DefaultTable";

// Dados fictícios para teste da tabela
const initialData = tabelaUsuarios;

// Retornar a pesquisa vazia
const initialFilterState = {
  nome: "",
  cpf: "",
  tipo: "",
  situacao: "",
};

const tabelaColunas = [
  { field: "nome", headerName: "Nome" },
  { field: "cpf", headerName: "CPF" },
  { field: "situacao", headerName: "Situação" },
  { field: "tipo", headerName: "Tipo de Usuário" },
  { field: "dataUltimaModificacao", headerName: "Última Modificação" },
];

const AdminListarUsuarios = () => {
  const [id, setId] = useState(1);
  const [modal, setModal] = useState(false);
  const [titulo, setTitulo] = useState("");
  const [subTitulo, setSubTitulo] = useState("");
  const [filter, setFilter] = useState(initialFilterState);
  const [filteredData, setFilteredData] = useState(initialData);

  const handleFilterChange = event => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
  };

  // const handleFilterSelectChange = event => {
  //   const { name, value } = event.target;
  //   setFilter({ ...filter, [name]: typeof value === "string" ? value.split(",") : value });
  // };

  const applyFilter = () => {
    const filtered = initialData.filter(parceiro => {
      const matchesTextFilter =
        parceiro.nome.toLowerCase().includes(filter.nome.toLowerCase()) &&
        parceiro.cpf.includes(filter.cpf) &&
        parceiro.tipo.toLowerCase().includes(filter.tipo.toLowerCase()) &&
        parceiro.situacao.toLowerCase().includes(filter.situacao.toLowerCase());

      return matchesTextFilter;
    });
    setFilteredData(filtered);
  };

  const resetFilter = () => {
    setFilter(initialFilterState);
    applyFilter();
  };

  const handleModalClose = () => {
    setModal(false);
  };

  const getTabelaActions = () => [
    {
      title: "Visualizar",
      href: "visualizar-usuario",
      icon: "visibility",
      onClick: rowId => localStorage.setItem("usuarioID", JSON.stringify(rowId)),
    },
    {
      title: "Editar",
      href: "editar-usuario",
      icon: "edit",
      onClick: rowId => localStorage.setItem("usuarioID", JSON.stringify(rowId)),
    },
    {
      title: "Ativar",
      icon: "thumb_up",
      onClick: rowId => {
        setModal(true);
        setTitulo("Ativar usuário");
        setSubTitulo("Situação: ");
        setId(rowId);
      },
    },
    {
      title: "Inativar",
      icon: "thumb_down",
      onClick: rowId => {
        setModal(true);
        setTitulo("Inativar usuário");
        setSubTitulo("Situação: ");
        setId(rowId);
      },
    },
    {
      title: "Excluir",
      href: "excluir-usuario",
      icon: "delete",
      onClick: rowId => localStorage.setItem("usuarioID", JSON.stringify(rowId)),
    },
  ];

  return (
    <>
      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          {/* Campos para filtrar */}
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              spacing={2}
              container
            >
              <Grid
                item
                xs={12}
                sm={8}
              >
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id="label-nome">Nome</InputLabel>
                  <Select
                    id="nome"
                    name="nome"
                    value={filter.nome || ""}
                    type="text"
                    onChange={handleFilterChange}
                    labelId="label-nome"
                  >
                    <MenuItem
                      value=""
                      disabled
                    >
                      Selecione uma opção
                    </MenuItem>
                    {initialData.map(usuario => (
                      <MenuItem
                        key={usuario.id + usuario.nome}
                        value={usuario.nome}
                      >
                        {usuario.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
              >
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id="label-cpf">CPF</InputLabel>
                  <Select
                    id="cpf"
                    name="cpf"
                    value={filter.cpf || ""}
                    type="text"
                    onChange={handleFilterChange}
                    labelId="label-cpf"
                  >
                    <MenuItem
                      value=""
                      disabled
                    >
                      Selecione uma opção
                    </MenuItem>
                    {initialData.map(usuario => (
                      <MenuItem
                        key={usuario.id + usuario.cpf}
                        value={usuario.cpf}
                      >
                        {usuario.cpf}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              item
              spacing={2}
              container
            >
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id="label-tipo">Tipo de usuário</InputLabel>
                  <Select
                    id="tipo"
                    name="tipo"
                    value={filter.tipo || ""}
                    type="text"
                    onChange={handleFilterChange}
                    labelId="label-tipo"
                  >
                    <MenuItem
                      value=""
                      disabled
                    >
                      Selecione uma opção
                    </MenuItem>
                    {initialData.map(usuario => (
                      <MenuItem
                        key={usuario.id + usuario.tipo}
                        value={usuario.tipo}
                      >
                        {usuario.tipo}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id="label-situacao">Situação</InputLabel>
                  <Select
                    id="situacao"
                    name="situacao"
                    value={filter.situacao || ""}
                    type="text"
                    onChange={handleFilterChange}
                    labelId="label-situacao"
                  >
                    <MenuItem
                      value=""
                      disabled
                    >
                      Selecione uma opção
                    </MenuItem>
                    {initialData.map(usuario => (
                      <MenuItem
                        key={usuario.id + usuario.situacao}
                        value={usuario.situacao}
                      >
                        {usuario.situacao}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {/* Botões de pesquisa/cancelar */}
          <Box sx={{ flexGrow: 1, mt: "16px" }}>
            <Grid
              container
              spacing={1}
              justifyContent="flex-end"
            >
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={resetFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={applyFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                >
                  <SearchIcon />
                  Pesquisar
                </Button>
              </Grid>
            </Grid>
          </Box>

          {/* Tabela */}
          <Box sx={{ flexGrow: 1, mt: "40px" }}>
            <DefaultTable
              rows={filteredData}
              notFoundText={"Não foi localizado Usuário na situação pendente de aprovação!"}
              columns={tabelaColunas}
              actionButtons={getTabelaActions}
            ></DefaultTable>
          </Box>
        </Card>
      </Stack>
      <div>
        {/* Modal para listar Serviços*/}
        <ModalTituloSubTitulo
          texto={tabelaUsuarios.find(usuario => usuario.id == id).situacao}
          titulo={titulo}
          subTitulo={subTitulo}
          showModal={modal}
          handleClose={handleModalClose}
        />
      </div>
    </>
  );
};

AdminListarUsuarios.propTypes = {
  setModal: PropTypes.func,
  modal: PropTypes.bool,
};

export default AdminListarUsuarios;
