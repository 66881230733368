import React from "react";

import { useTheme } from "@mui/material/styles";

import { useResponsive } from "../../hooks/use-responsive";
import COMMIT_HASH from "../../services/commit_hash";

const Footer = () => {
  const theme = useTheme();
  const smUp = useResponsive("up", "sm");

  const footerStyle = {
    width: "100%",
    minHeight: "40px",
    padding: "8px",
    fontSize: "16px",
    fontWeight: 100,
    color: "white",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 3,
    position: "relative",
    flexDirection: smUp ? "row" : "column",
    backgroundColor: theme.palette.background.dark,
    borderTop: `1px solid ${theme.palette.border.layout}`,
    transition: "1s",
  };

  const linkStyle = {
    color: "white",
    textDecoration: "none",
  };

  const spanStyle = {
    marginLeft: "10px",
    color: "grey",
    fontSize: "10px",
  };

  return (
    <div style={footerStyle}>
      <a
        href="#"
        style={linkStyle}
      >
        Ministério do Desenvolvimento e Assistência Social, Família e Combate à Fome
      </a>
      <span style={spanStyle}>Versão: {COMMIT_HASH}</span>
    </div>
  );
};

export default Footer;
