import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ParceiroCidadaoQuestionarioMpo from "../../components/formularios/ParceiroCidadaoQuestionarioMpo";
import TitlePage from "../../components/title-page/TitlePage";

const PageMicrocreditoMPOQuestionario = () => {
  const linksBreadCrumb = [
    { href: "/", text: "Cidadão" },
    { href: "/empreendedorismo", text: "Empreendedorismo" },
    { href: "/empreendedorismo", text: "Microcrédito" },
  ];

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        currentPage="Questionário Acesso a Microcrédito Produtivo Orientado (MPO)"
        links={linksBreadCrumb}
      />

      <TitlePage title={"QUESTIONÁRIO – Acesso a Microcrédito Produtivo Orientado (MPO)"} />

      <ParceiroCidadaoQuestionarioMpo></ParceiroCidadaoQuestionarioMpo>
    </Container>
  );
};

export default PageMicrocreditoMPOQuestionario;
