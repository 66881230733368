import React from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";

import constantsService from "../../../services/constants.service";
import { capitalizarPrimeiraLetra } from "../utils";

function formatId(id) {
  return id.slice(0, 3) + "-" + id.slice(3);
}

export const SelectNaturezaJuridica = ({ idSelect, nameSelect, handleSelect, item, label, placeholder, errors, touched }) => {
  const list = constantsService.getAllNaturezaJuridica();

  // Validar item antes de selecionar
  const validatedItem = Object.keys(list).includes(item) ? item : "";
  const itemSelecionado = validatedItem || "none";

  const options = Object.entries(list).map(([id, descricao]) => (
    <MenuItem
      key={id}
      id={id}
      name={descricao}
      value={id}
    >
      {formatId(id.toString())} - {descricao.toLowerCase().split(" ").map(capitalizarPrimeiraLetra).join(" ")}
    </MenuItem>
  ));

  return (
    <FormControl
      fullWidth
      label={label}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        id={idSelect}
        name={nameSelect}
        defaultValue=""
        value={itemSelecionado}
        onChange={handleSelect}
        disabled
        error={errors && touched}
      >
        <MenuItem
          key="none"
          value="none"
          disabled
        >
          <a style={{ color: "grey" }}>{placeholder}</a>
        </MenuItem>
        {options}
      </Select>
    </FormControl>
  );
};

SelectNaturezaJuridica.propTypes = {
  idSelect: PropTypes.string.isRequired,
  nameSelect: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  item: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errors: PropTypes.string,
  touched: PropTypes.bool,
};
