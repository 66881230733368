import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminVagasPessoasInteressadas from "../../components/filtros/AdminVagasPessoasInteressadas";
import TitlePage from "../../components/title-page/TitlePage";

const VagasPessoasInteressadas = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/parceiros/listar/pendentes", text: "Listar Parceiros Pendentes" },
          { href: "/parceiros/visualizar/", text: "Vizualizar" },
        ]}
        currentPage="Listar pessoas interessadas"
      />

      <TitlePage title={"Listar pessoas interessadas"} />

      <AdminVagasPessoasInteressadas />
    </Container>
  );
};

export default VagasPessoasInteressadas;
