import React, { useState } from "react";

import { Button, Card, Grid, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";

import InteresseVagaModal from "../modals/InteresseVagaModal";

const ParceiroMobilizacaoVisualizarInformacoesCurso = ({ values }) => {
  const [showInteresseVagaModal, setShowInteresseVagaModal] = useState(false);
  const isInteressado = false;

  const fecharInteresseVaga = () => {
    setShowInteresseVagaModal(false);
  };

  return (
    <Card
      color="#ffffff"
      sx={{
        borderRadius: "8px",
        padding: "16px",
        mt: "24px",
      }}
    >
      <Typography variant="h6">{"<Nome do curso>"}</Typography>
      <Grid
        container
        spacing={2}
        justifyContent="flex-end"
        mt={1}
      >
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
        >
          <TextField
            id="ofertante"
            name="ofertante"
            label="Ofertante"
            value={values.ofertante}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
        >
          <TextField
            id={"curso"}
            name={"curso"}
            label="Curso"
            value={values.curso}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
        >
          <TextField
            id={"vigencia"}
            name={"vigencia"}
            label="Vigência"
            value={values.vigencia}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
        >
          <TextField
            id={"modalidade"}
            name={"modalidade"}
            label="Modalidade"
            value={values.modalidade}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
        >
          <TextField
            id={"tipo"}
            name={"tipo"}
            label="Tipo"
            value={values.tipo}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
        >
          <TextField
            id={"pronatec"}
            name={"pronatec"}
            label="Pronatec"
            value={values.pronatec}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
        >
          <TextField
            id={"abrangencia"}
            name={"abrangencia"}
            label="Abrangência"
            value={values.abrangencia}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
        >
          <TextField
            id={"uf"}
            name={"uf"}
            label="UF"
            value={values.uf}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
        >
          <TextField
            id={"municipio"}
            name={"municipio"}
            label="Município"
            value={values.municipio}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
        >
          <TextField
            id={"turno"}
            name={"turno"}
            label="Turno"
            value={values.turno}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
        >
          <TextField
            id={"cargaHoraria"}
            name={"cargaHoraria"}
            label="Carga Horária"
            value={values.cargaHoraria}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
        >
          <TextField
            id={"vagas"}
            name={"vagas"}
            label="Vagas"
            value={values.vagas}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          md={3}
        >
          <TextField
            id={"idadeMin"}
            name={"idadeMin"}
            label="Idade Mínima Exigida"
            value={values.idadeMin}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          md={3}
        >
          <TextField
            id={"idadeMax"}
            name={"idadeMax"}
            label="Idade Máxima Exigida"
            value={values.idadeMax}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          md={3}
        >
          <TextField
            id={"inicioCurso"}
            name={"inicioCurso"}
            label="Início do Curso"
            value={values.inicioCurso}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          md={3}
        >
          <TextField
            id={"fimCurso"}
            name={"fimCurso"}
            label="Fim do Curso"
            value={values.fimCurso}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
        >
          <TextField
            id={"turno"}
            name={"turno"}
            label="Turno"
            value={values.turno}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
        >
          <TextField
            id={"cargaHoraria"}
            name={"cargaHoraria"}
            label="Carga Horária"
            value={values.cargaHoraria}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
        >
          <TextField
            id={"vagas"}
            name={"vagas"}
            label="Vagas"
            value={values.vagas}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
        >
          <TextField
            id="requisitos"
            name="requisitos"
            label="Requisitos"
            value={values.requisitos}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
        >
          <TextField
            id="linkExterno"
            name="linkExterno"
            label="Link Externo do Curso"
            value={values.linkExterno}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
        >
          <TextField
            id="ementa"
            name="ementa"
            label="Ementa"
            value={values.ementa}
            rows={4}
            disabled
            multiline
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
        >
          <TextField
            id="descricao"
            name="descricao"
            label="Breve Descrição"
            value={values.descricao}
            rows={4}
            disabled
            multiline
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        justifyContent={"flex-end"}
        mt={2}
      >
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          display={"flex"}
          justifyContent={"center"}
        >
          <Button
            variant="outlined"
            fullWidth
          >
            CANCELAR
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          md={3}
          display={"flex"}
          justifyContent={"center"}
        >
          <Button
            variant="contained"
            onClick={() => setShowInteresseVagaModal(true)}
            fullWidth
          >
            <span
              className={"material-icons"}
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "left",
                marginRight: 5,
              }}
            >
              thumb_up
            </span>
            TENHO INTERESSE
          </Button>
        </Grid>
      </Grid>
      <div>
        <InteresseVagaModal
          showModal={showInteresseVagaModal}
          handleClose={fecharInteresseVaga}
          isConfirmado={isInteressado}
        />
      </div>
    </Card>
  );
};

ParceiroMobilizacaoVisualizarInformacoesCurso.propTypes = {
  values: PropTypes.object.isRequired,
};

export default ParceiroMobilizacaoVisualizarInformacoesCurso;
