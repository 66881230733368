import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import FormInscricaoParceiro from "../../components/formularios/empresaParceira/FormInscricaoParceiro";
import TitlePage from "../../components/title-page/TitlePage";
import { useAuth } from "../../contexts/AuthContext";

const PageVisualizacaoParceiro = () => {
  const { user } = useAuth();

  const values = {
    email: user.ds_email,
    cnpj: "04.082.993/0001-49",
    razaoSocial: "teste",
    nomeFantasia: "teste",
    cep: "70070-912",
    endereco: "teste teste",
    numero: "55",
    complemento: "teste teste",
    bairro: "teste",
    uf: "DF",
    cidade: "Brasília",
    telefone: "(00) 0000-0000",
    site: "https://www.gov.br/ibict/pt-br",
    redesSociais: ["rede1", "rede2", "rede3"],
    nomeRepresentante: "teste",
    cpfRepresentante: "55267041149",
    telefoneRepresentante: "(00) 0000-0000",
    ufRepresentante: "AC",
    cidadeRepresentante: "Acrelândia",
    nomePontoFocal: "teste",
    emailPontoFocal: "teste@email.com",
    telefonePontoFocal: "(00) 0000-0000",
    areaAtuacao: "02", //"PRODUÇÃO FLORESTAL",
    naturezaJuridica: "Autarquia Federal",
    naturezaJuridicaCodigo: "1104", // "Autarquia Federal"
    tipo: "ESTABELECIMENTO",
    porte: "PEQUENO",
    checkVagaEmprego: false,
    checkVagaEstagio: true,
    checkVagaJovem: true,
    checkCursos: true,
    checkFinanceiro: false,
    checkMobilidadePublico: false,
    checkMobilidadeParceiro: false,
    toggleCienteNormas: false,
    toggleCienteGratuito: false,
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        homeLink="/parceiro/visualizar"
        homeText="Parceiro"
        currentPage="Visualizar Formulário"
      />

      <TitlePage title={"Visualizar inscrição"} />

      <FormInscricaoParceiro
        initialValues={values}
        visualizacao={true}
        confirmacaoModal={false}
        erroModal={false}
        erroDoUsuarioModal={false}
      />
    </Container>
  );
};

export default PageVisualizacaoParceiro;
