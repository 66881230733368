import React, { useEffect, useState } from "react";

import Save from "@mui/icons-material/Save";
import { Box, Button, Card, FormGroup, Grid, Stack, TextField, Typography } from "@mui/material";
import { Form, Formik } from "formik";

import { tabelaTrilhas } from "../formularios/dadosMockados";
import RelacaoDeCursoModal from "../modals/RelacaoDeCursosModal";
import TransferList from "../transferList/TransferList";

const initialValues = {
  nome: "",
  cursos: [],
  situacao: "",
};

const AdminIncluirCursosTrilha = () => {
  const [valores, setValores] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [itemsTransferfList, setItemsTransferList] = useState({
    left: ["eng da computação", "moda", "fotografia"],
    right: ["culinária", "musica", "artes"],
  });

  const handleItemsTransferListChange = updatedItems => {
    setItemsTransferList(updatedItems);
  };

  const trilhaID = JSON.parse(localStorage.getItem("trilhaID"));

  const handleChange = event => {
    const { name, value } = event.target;
    setValores({ ...valores, [name]: value });
  };

  const fecharModal = () => {
    setModal(false);
  };

  useEffect(() => {
    if (trilhaID) {
      const trilhaEncontrada = tabelaTrilhas.find(trilha => trilha.id === trilhaID);

      if (trilhaEncontrada) {
        setValores({
          ...trilhaEncontrada,
          ["cursos"]: trilhaEncontrada.cursos.split(", "),
        });
      }
    }
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <div>Carregando...</div>;
  }

  if (!valores) {
    return <div>Parceiro não encontrado</div>;
  }

  return (
    <>
      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          <Formik initialValues={valores}>
            <Form>
              {/* Campos */}
              <Grid
                spacing={2}
                container
              >
                <Grid
                  item
                  xs={12}
                >
                  <Typography variant="subtitle1">Incluir cursos na trilha</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  mb={2}
                >
                  <FormGroup>
                    <TextField
                      id="nome"
                      name="nome"
                      value={valores.nome}
                      label="Nome da Trilha"
                      placeholder="Nome da Trilha"
                      onChange={handleChange}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Form>
          </Formik>

          <TransferList
            items={itemsTransferfList}
            titleLeft={"Cursos cadastrados"}
            titleRight={"Cursos selecionados para trilha"}
            onItemsChange={handleItemsTransferListChange}
          />
        </Card>

        {/* Botões de salvar/cancelar */}
        <Box sx={{ flexGrow: 1, mt: "16px" }}>
          <Grid
            container
            spacing={1}
            justifyContent="flex-end"
          >
            <Grid item>
              <Button
                variant="outlined"
                sx={{ gap: "8px" }}
                style={{ fontFamily: "Rawline Medium" }}
                onClick={() => window.history.back()}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                sx={{ gap: "8px" }}
                style={{ fontFamily: "Rawline Medium" }}
                onClick={() => setModal(true)}
              >
                <Save />
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Stack>
      <div>
        <RelacaoDeCursoModal
          showModal={modal}
          handleClose={fecharModal}
          situacao={valores.situacao}
          cursos={valores.cursos}
        />
      </div>
    </>
  );
};

export default AdminIncluirCursosTrilha;
