import React from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Button, Container, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material";

import imagemPrimaria from "../../assets/images/ImageConsultaPainelIndicadores.png";
import imagemMapaBrasil from "../../assets/images/ImageMapaBrasil.png";
import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import CardPrimario from "../../components/cards/CardPrimario";
import TitlePage from "../../components/title-page/TitlePage";

const cardData = [
  { qntd: "00", title: "Cidadãos inscritos nas plataformas do Programa Acredita no Primeiro Passo" },
  { qntd: "00", title: "Total de PCDs inscritos nas plataformas do Programa Acredita no Primeiro Passo" },
  { qntd: "00", title: "Ano" },
  { qntd: "00", title: "Mês" },
  { qntd: "00", title: "Região do Brasil" },
  { qntd: "00", title: "Acessos de cidadãos às plataformas do Programa Acredita no Primeiro Passo" },
  { qntd: "00", title: "UF" },
  { qntd: "00", title: "Município" },
  { qntd: "00", title: "Faixa Etária" },
  { qntd: "00", title: "Escolaridade" },
  { qntd: "00", title: "Sexo" },
  { qntd: "00", title: "Beneficiário do Programa Bolsa Família" },
  { qntd: "00", title: "Nota do aplicativo na Apple Store e no Google Play" },
  { qntd: "00", title: "Número de parcerias vigentes" },
  { qntd: "00", title: "Parceiros cadastrados na Rede" },
  { qntd: "00", title: "Quantidade de parceiros por Tipo de Serviço Ofertado*" },
  { qntd: "00", title: "Nível de satisfação dos parceiros com o Programa Redução da Pobreza" },
  { qntd: "00", title: "Número de cursos vigentes" },
  { qntd: "00", title: "Número de vagas em cursos presenciais" },
  { qntd: "00", title: "Cursos novos inseridos" },
  { qntd: "00", title: "Principais parceiros ofertantes de cursos dentre os ativos" },
  { qntd: "00", title: "Cursos que despertam maior interesse dentre os ativos (listar os melhor classificados" },
  { qntd: "00", title: "Número de pessoas inscritas no Cadastro Único capacitadas pelo sistema S - total e por entidade" },
  { qntd: "00", title: "Número de vagas incluídas" },
  { qntd: "00", title: "Número de pessoas que responderam aos questionários de interesse em acessar o Microcrédito (MPO) por mês" },
  { qntd: "00", title: "Vagas mais buscadas pelos cidadãos no programa Redução da Pobreza" },
  { qntd: "00", title: "Número e lista de cidadãos que concluíram o curso matriculado (certificado de cursos)" },
  { qntd: "00", title: "Lista das principais instituições financeiras parceiras" },
  { qntd: "00", title: "Nível de satisfação dos parceiros com o Aprender a Empreender" },
  { qntd: "00", title: "Tipo de parceiro (Público ou Privado)" },
  { qntd: "00", title: "Qualificação por parceiro" },
  { qntd: "00", title: "Tipo de Serviço por qualificação" },
  { qntd: "00", title: "Data de Cadastro do curso" },
  { qntd: "00", title: "Empregabilidade por parceiro" },
  { qntd: "00", title: "Tipo de serviço por empregabilidade" },
  { qntd: "00", title: "Tipo de vaga" },
  { qntd: "00", title: "Data de cadastro da vaga" },
];

const infoCard = (qntd, title) => {
  const theme = useTheme();

  return (
    <div
      style={{
        backgroundColor: theme.palette.background.paper,
        border: "1px solid " + theme.palette.text.primary,
        padding: "10px 16px 10px 16px",
        minHeight: "100%",
      }}
    >
      <Typography
        variant="h5"
        mt={2}
        sx={{ fontFamily: "Rawline Bold", color: "primary.main", mb: 1 }}
        style={{ transition: "1s" }}
      >
        {qntd}
      </Typography>
      <p>{title} </p>
      <div>
        <span style={{ borderBottom: "1px solid " + theme.palette.primary.main, paddingBottom: "8px" }}>
          <Button
            fullWidth
            startIcon={<AddCircleIcon sx={{ color: "primary.main" }} />}
          >
            <span>Mais detalhes</span>
          </Button>
        </span>
      </div>
    </div>
  );
};

const PageConsultarPainelIndicadores = () => {
  const theme = useTheme();
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        homeLink="/"
        homeText="Página Inicial"
      />

      <TitlePage title={"Seja bem-vindo(a)"} />

      <CardPrimario
        title="Programa Acredita no Primeiro Passo"
        imageUrl={imagemPrimaria}
        imageWidth={"184px"}
        imageHeight={"184px"}
      />

      {/* Relatório de Indicadores */}
      {/* Filtrar resultados */}
      <Grid
        container
        style={{ justifyContent: "space-between", margin: "40px 0px 40px 0px" }}
      >
        <Grid item>
          <Typography
            variant="h6"
            mt={2}
            sx={{ fontFamily: "Rawline Bold" }}
            style={{ transition: "1s" }}
          >
            Relatório de indicadores
          </Typography>
        </Grid>
        <Grid
          item
          style={{ display: "flex", alignItems: "baseline", gap: "8px" }}
        >
          <Grid item>
            <Typography
              variant="h6"
              mt={2}
              sx={{ fontFamily: "Rawline Bold", color: "primary.main" }}
              style={{ transition: "1s" }}
            >
              Filtrar resultados
            </Typography>
          </Grid>
          <Grid item>
            <div
              style={{
                backgroundColor: theme.palette.background.paper,
                border: "1px solid " + theme.palette.text.primary,
                borderRadius: "5px",
                cursor: "pointer",
                padding: "3px",
              }}
            >
              <FilterAltIcon sx={{ color: "primary.main" }} />
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3}
      >
        {/* Cards */}
        <Grid
          container
          spacing={3}
          item
          xs={12}
          md={6}
        >
          {cardData.map((card, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              key={index}
            >
              {infoCard(card.qntd, card.title)}
            </Grid>
          ))}
        </Grid>
        {/* Mapa */}
        {/* Botão Ver Todos Indicadores */}
        <Grid
          item
          container
          spacing={1}
          md={6}
          xs={12}
          flexDirection="column"
          alignContent="flex-end"
          alignItems="flex-end"
          justifyContent="flex-start"
        >
          <Grid item>
            <div
              style={{
                backgroundColor: theme.palette.background.paper,
                border: "1px solid " + theme.palette.text.primary,
                padding: "20px",
              }}
            >
              <Typography
                sx={{ fontFamily: "Rawline Bold", fontSize: "12px", mb: 2 }}
                style={{ transition: "1s" }}
              >
                Número de pessoas cadastradas por estado
              </Typography>
              <img
                alt="Mapa do brasil"
                src={imagemMapaBrasil}
              />
            </div>
          </Grid>

          <Grid item>
            <Button variant="contained">VER TODOS INDICADORES</Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PageConsultarPainelIndicadores;
