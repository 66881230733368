import React, { useEffect, useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useTheme } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";

import optionsService from "../../services/options.service";
import { tabelaVagasTrabalho } from "./dadosMockados";
import { SelectCidade } from "./fields/SelectCidade";
import { SelectUF } from "./fields/SelectUF";

const AdminVisualizarVagaTrabalho = ({ visualizacao }) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [ufs, setUfs] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [selectedUf, setSelectedUf] = useState("");
  const [selectedMunicipio, setSelectedMunicipio] = useState("");
  const [values, setValues] = useState({
    tipo: "",
    cnpj: "",
    nomeParceiro: "",
    validade: "",
    cargaHoraria: "",
    turno: "",
    nomeDoCurso: "",
    escolaridade: "",
    tipoDeCurso: "",
    pronatec: "",
    pre_requisitos: [],
    inicio_matricula: null,
    fim_matricula: null,
    idade_min: "",
    idade_max: "",
    modalidade: "",
    trilhas: "",
    inicio_curso: null,
    fim_curso: null,
    link: "",
    cep: "",
    uf: "",
    endereco: "",
    numero: "",
    complemento: "",
    bairro: "",
    municipio: "",
    telefone: "",
    vagas: "",
    abrangencia: "",
    uf_abrangencia: "",
    municipio_abrangencia: "",
    ementa: "",
    descricao: "",
    vigencia: "",
    ultima_modificacao: "",
    situacao: "",
    motivo_situacao: "",
    dataResposta: null,
  });
  const vagaID = JSON.parse(localStorage.getItem("vagaID"));

  useEffect(() => {
    if (vagaID) {
      const vagaEncontrada = tabelaVagasTrabalho.find(trilha => trilha.id === vagaID);

      if (vagaEncontrada) {
        setValues(vagaEncontrada);
      }
      setMunicipios(optionsService.cidadesJSON.filter(cidade => cidade.estado === vagaEncontrada.uf));
      setSelectedUf(vagaEncontrada.uf);
      setSelectedMunicipio(vagaEncontrada.cidade);
    }

    const fetchUfs = async () => {
      await optionsService
        .getAllUFs()
        .then(ufsData => {
          setUfs(ufsData);
        })
        .catch(error => {
          console.error("Erro ao obter UFs:", error);
        });
    };
    fetchUfs();
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <div>Carregando...</div>;
  }

  const fetchCidadesByUf = async (ufSigla, setCidadesFunction) => {
    try {
      const cidadesData = await optionsService.getCidadesFromUF(ufSigla);
      setCidadesFunction(cidadesData);
    } catch (error) {
      console.error("Erro ao obter cidades:", error);
    }
  };

  const handleSelectUf = (event, setFieldValue) => {
    const { name, value } = event.target;
    setFieldValue(name, value);
    console.log(value);

    setSelectedUf(value);
    setSelectedMunicipio("");
    setFieldValue("municipio", "");
    fetchCidadesByUf(event.target.value, setMunicipios);
  };

  const handleSelectMunicipio = (event, setFieldValue) => {
    const { name, value } = event.target;
    setFieldValue(name, value);
    setSelectedMunicipio(event.target.value);
  };

  return (
    <Formik
      initialValues={values}
      onSubmit={values => {
        console.log(values);
      }}
    >
      {({ values, handleChange, setFieldValue }) => (
        <Form>
          <Stack spacing={2}>
            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
              }}
            >
              {/* Tipo de vaga, CNPJ, Nome do ofertante */}
              {/* Nome da Função, Quantidade de Vagas, Validade do Anúncio */}
              {/* Escolaridade Requerida, Salário/Bolsa Auxílio, Turno  */}
              <Grid
                container
                spacing={2}
                justifyContent="flex-end"
              >
                <Grid
                  item
                  xs={12}
                  md={3}
                >
                  <FormControl fullWidth>
                    <InputLabel>Tipo de vaga</InputLabel>
                    <Select
                      id="tipo"
                      name="tipo"
                      value={values.tipo}
                      onChange={handleChange}
                      disabled={visualizacao}
                    >
                      <MenuItem value={"Presencial"}>Presencial</MenuItem>
                      <MenuItem value={"Remota"}>Remota</MenuItem>
                      <MenuItem value={"Híbrida"}>Híbrida</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                >
                  <TextField
                    id="cnpj"
                    name="cnpj"
                    label="CNPJ"
                    value={values.cnpj}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <TextField
                    id="nomeParceiro"
                    name="nomeParceiro"
                    label="Nome do ofertante"
                    value={values.nomeParceiro}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <TextField
                    id="funcao"
                    name="funcao"
                    label="Nome da Função"
                    value={values.funcao}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <TextField
                    id="vagas"
                    name="vagas"
                    label="Quantidade de Vagas"
                    value={values.vagas}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <FormControl fullWidth>
                    <InputLabel>Validade do Anúncio</InputLabel>
                    <Select
                      id="validade"
                      name="validade"
                      value={values.validade}
                      onChange={handleChange}
                      disabled={visualizacao}
                    >
                      <MenuItem value={"30 dias"}>30 dias</MenuItem>
                      <MenuItem value={"60 dias"}>60 dias</MenuItem>
                      <MenuItem value={"90 dias"}>90 dias</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <TextField
                    id="escolaridadeRequerida"
                    name="escolaridadeRequerida"
                    label="Escolaridade Requerida"
                    value={values.escolaridadeRequerida}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <TextField
                    id="salario"
                    name="salario"
                    label="Salário/Bolsa Auxílio"
                    value={values.salario}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <FormControl fullWidth>
                    <InputLabel>Turno</InputLabel>
                    <Select
                      id="turno"
                      name="turno"
                      value={values.turno}
                      onChange={handleChange}
                      disabled={visualizacao}
                    >
                      <MenuItem value={0}>Manhã</MenuItem>
                      <MenuItem value={1}>Tarde</MenuItem>
                      <MenuItem value={2}>Noite</MenuItem>
                      <MenuItem value={3}>Integral</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <TextField
                    id="situacao"
                    name="situacao"
                    label="Situação"
                    value={values.situacao}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <FormGroup>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        fullWidth
                        label="Data de Resposta"
                        id="dataResposta"
                        name="dataResposta"
                        value={values.dataResposta}
                        format="DD/MM/YYYY"
                        onChange={valor => setValues({ ...values, ["dataResposta"]: valor })}
                        disabled={visualizacao}
                      />
                    </LocalizationProvider>
                  </FormGroup>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <TextField
                    id="interessados"
                    name="interessados"
                    label="Interessados"
                    value={values.interessados}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>
              </Grid>
              {/* Escolaridade mínima exigida, descrição */}
              {/* Essa vaga é direcionada para pessoas com deficiência? */}
              {/* O local de trabalho será o mesmo cadastrado no endereço do parceiro? */}
              <Grid
                container
                spacing={1}
                justifyContent="flex-start"
                mt={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={<Checkbox style={{ color: theme.palette.primary.main }} />}
                    label="Escolaridade mínima exigida?"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    id="descricao"
                    name="descricao"
                    label="* Descrição/Informações Adicionais"
                    value={values.descricao}
                    onChange={handleChange}
                    disabled={visualizacao}
                    rows={4}
                    fullWidth
                    multiline
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={<Checkbox style={{ color: theme.palette.primary.main }} />}
                    label="Essa vaga é direcionada para pessoas com deficiência?"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={<Checkbox style={{ color: theme.palette.primary.main }} />}
                    label="O local de trabalho será o mesmo cadastrado no logradouro do parceiro?"
                  />
                </Grid>
              </Grid>
            </Card>
            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
              }}
            >
              {/* Outro logradouro */}
              <Grid
                container
                spacing={2}
                justifyContent="flex-end"
              >
                <Grid
                  item
                  xs={12}
                >
                  <Typography variant="h6">Outro logradouro</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <SelectUF
                    idSelect="uf"
                    nameSelect="uf"
                    value={values.uf}
                    ufs={ufs}
                    uf={selectedUf}
                    label="* UF"
                    visualizacao={visualizacao}
                    handleSelectUf={event => handleSelectUf(event, setFieldValue)}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <SelectCidade
                    idSelect="municipio"
                    nameSelect="municipio"
                    value={values.municipio}
                    cidades={municipios}
                    cidade={selectedMunicipio}
                    label="* Município"
                    visualizacao={visualizacao}
                    handleSelectCidade={event => handleSelectMunicipio(event, setFieldValue)}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <TextField
                    id="cep"
                    name="cep"
                    label="* CEP"
                    value={values.cep}
                    disabled={visualizacao}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <TextField
                    id="endereco"
                    name="endereco"
                    label="* Logradouro"
                    value={values.endereco}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <TextField
                    id="numero"
                    name="numero"
                    label="* Número"
                    value={values.numero}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <TextField
                    id="bairro"
                    name="bairro"
                    label="Bairro"
                    value={values.bairro}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <TextField
                    id="complemento"
                    name="complemento"
                    label="Complemento"
                    value={values.complemento}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <TextField
                    id="referencia"
                    name="referencia"
                    label="Referência"
                    value={values.referencia}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Card>

            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
              }}
            >
              {/* Benefícios */}
              <Grid
                container
                spacing={2}
                justifyContent="flex-end"
              >
                <Grid
                  item
                  xs={12}
                >
                  <Typography variant="h6">Benefícios</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                >
                  <FormControlLabel
                    control={<Checkbox style={{ color: theme.palette.primary.main }} />}
                    label="Auxílio Alimentação"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={9}
                  md={9}
                >
                  <TextField
                    id="detalheAuxilioAlimentacao"
                    name="detalheAuxilioAlimentacao"
                    label="Detalhes do Auxílio Alimentação"
                    value={values.detalheAuxilioAlimentacao}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                >
                  <FormControlLabel
                    control={<Checkbox style={{ color: theme.palette.primary.main }} />}
                    label="Auxílio Transporte"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={9}
                  md={9}
                >
                  <TextField
                    id="detalheAuxilioTransporte"
                    name="detalheAuxilioTransporte"
                    label="Detalhes do Auxílio Transporte"
                    value={values.detalheAuxilioTransporte}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                >
                  <FormControlLabel
                    control={<Checkbox style={{ color: theme.palette.primary.main }} />}
                    label="Plano de Saúde"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={9}
                  md={9}
                >
                  <TextField
                    id="detalhePlanoSaude"
                    name="detalhePlanoSaude"
                    label="Detalhes do Plano de Saúde"
                    value={values.detalhePlanoSaude}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                >
                  <FormControlLabel
                    control={<Checkbox style={{ color: theme.palette.primary.main }} />}
                    label="Outros"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={9}
                  md={9}
                >
                  <TextField
                    id="detalheOutros"
                    name="detalheOutros"
                    label="Outros Detalhes"
                    value={values.detalheOutros}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Card>

            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
              }}
            >
              {/* EXPERIÊNCIA DO PROFISSIONAL */}
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Typography variant="h6">*Informe a(s) Área(s) de Atuação do Profissional</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={8}
                >
                  <FormControl fullWidth>
                    <InputLabel>Selecione uma Área de Atuação</InputLabel>
                    <Select
                      id="areaAtuacao"
                      name="areaAtuacao"
                      value={values.areaAtuacao}
                      onChange={handleChange}
                      disabled={visualizacao}
                    >
                      <MenuItem value={0}>Administração</MenuItem>
                      <MenuItem value={1}>Agricultura</MenuItem>
                      <MenuItem value={2}>Artes</MenuItem>
                      <MenuItem value={3}>Biologia</MenuItem>
                      <MenuItem value={4}>Ciências</MenuItem>
                      <MenuItem value={5}>Comunicação</MenuItem>
                      <MenuItem value={6}>Direito</MenuItem>
                      <MenuItem value={7}>Educação</MenuItem>
                      <MenuItem value={8}>Engenharia</MenuItem>
                      <MenuItem value={9}>Informática</MenuItem>
                      <MenuItem value={10}>Saúde</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <TextField
                    id="experienciaProfissional"
                    name="experienciaProfissional"
                    label="Expriência Anterior"
                    value={values.experienciaProfissional}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4.5}
                >
                  <RadioGroup
                    id="isExigidoProfissional"
                    name="isExigidoProfissional"
                    value={values.isExigidoProfissional}
                    onChange={handleChange}
                    disabled={visualizacao}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          value={"desejado"}
                          style={{ color: theme.palette.primary.main }}
                        />
                      }
                      label="Desejado"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          value={"exigido"}
                          style={{ color: theme.palette.primary.main }}
                        />
                      }
                      label="Exigido"
                    />
                  </RadioGroup>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sm={6}
                  spacing={1}
                  justifyContent={"flex-end"}
                >
                  <Grid
                    item
                    md={2}
                    mt={2}
                  >
                    <FormGroup>
                      <Button
                        variant="outlined"
                        onClick={() => console.log("Deletar")}
                      >
                        <DeleteOutline />
                      </Button>
                    </FormGroup>
                  </Grid>
                  <Grid
                    item
                    md={2}
                    mt={2}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => console.log("Adicionar")}
                      startIcon={<AddCircleIcon />}
                    >
                      ADICIONAR ÁREA
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
              }}
            >
              {/* EXPERIÊNCIA DO ESTUDANTE */}
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Typography variant="h6">*Informe a(s) Área(s) de Atuação do Estudante</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={8}
                >
                  <FormControl fullWidth>
                    <InputLabel>Selecione uma Área de Atuação</InputLabel>
                    <Select
                      id="areaAtuacao"
                      name="areaAtuacao"
                      value={values.areaAtuacao}
                      onChange={handleChange}
                      disabled={visualizacao}
                    >
                      <MenuItem value={0}>Administração</MenuItem>
                      <MenuItem value={1}>Agricultura</MenuItem>
                      <MenuItem value={2}>Artes</MenuItem>
                      <MenuItem value={3}>Biologia</MenuItem>
                      <MenuItem value={4}>Ciências</MenuItem>
                      <MenuItem value={5}>Comunicação</MenuItem>
                      <MenuItem value={6}>Direito</MenuItem>
                      <MenuItem value={7}>Educação</MenuItem>
                      <MenuItem value={8}>Engenharia</MenuItem>
                      <MenuItem value={9}>Informática</MenuItem>
                      <MenuItem value={10}>Saúde</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <TextField
                    id="experienciaProfissional"
                    name="experienciaProfissional"
                    label="Expriência Anterior"
                    value={values.experienciaProfissional}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4.5}
                >
                  <RadioGroup
                    id="isExigidoProfissional"
                    name="isExigidoProfissional"
                    value={values.isExigidoProfissional}
                    onChange={handleChange}
                    disabled={visualizacao}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          value={"desejado"}
                          style={{ color: theme.palette.primary.main }}
                        />
                      }
                      label="Desejado"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          value={"exigido"}
                          style={{ color: theme.palette.primary.main }}
                        />
                      }
                      label="Exigido"
                    />
                  </RadioGroup>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sm={6}
                  spacing={1}
                  justifyContent={"flex-end"}
                >
                  <Grid
                    item
                    md={2}
                    mt={2}
                  >
                    <FormGroup>
                      <Button
                        variant="outlined"
                        onClick={() => console.log("Deletar")}
                      >
                        <DeleteOutline />
                      </Button>
                    </FormGroup>
                  </Grid>
                  <Grid
                    item
                    md={2}
                    mt={2}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => console.log("Adicionar")}
                      startIcon={<AddCircleIcon />}
                    >
                      ADICIONAR ÁREA
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
              }}
            >
              {/* CURSO EXIGIDO OU DESEJÁVEL */}
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Typography variant="h6">
                    *Algum Curso Complementar é Exigido ou Desejável? (Caso sim adicione um ou mais itens)
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControl fullWidth>
                    <InputLabel>Curso</InputLabel>
                    <Select
                      id="areaAtuacao"
                      name="areaAtuacao"
                      value={values.areaAtuacao}
                      onChange={handleChange}
                      disabled={visualizacao}
                    >
                      <MenuItem value={0}>Administração</MenuItem>
                      <MenuItem value={1}>Agricultura</MenuItem>
                      <MenuItem value={2}>Artes</MenuItem>
                      <MenuItem value={3}>Biologia</MenuItem>
                      <MenuItem value={4}>Ciências</MenuItem>
                      <MenuItem value={5}>Comunicação</MenuItem>
                      <MenuItem value={6}>Direito</MenuItem>
                      <MenuItem value={7}>Educação</MenuItem>
                      <MenuItem value={8}>Engenharia</MenuItem>
                      <MenuItem value={9}>Informática</MenuItem>
                      <MenuItem value={10}>Saúde</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4.5}
                >
                  <RadioGroup
                    id="isExigidoProfissional"
                    name="isExigidoProfissional"
                    value={values.isExigidoProfissional}
                    onChange={handleChange}
                    disabled={visualizacao}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          value={"desejado"}
                          style={{ color: theme.palette.primary.main }}
                        />
                      }
                      label="Desejado"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          value={"exigido"}
                          style={{ color: theme.palette.primary.main }}
                        />
                      }
                      label="Exigido"
                    />
                  </RadioGroup>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sm={6}
                  spacing={1}
                  justifyContent={"flex-end"}
                >
                  <Grid
                    item
                    md={2}
                    mt={2}
                  >
                    <FormGroup>
                      <Button
                        variant="outlined"
                        onClick={() => console.log("Deletar")}
                      >
                        <DeleteOutline />
                      </Button>
                    </FormGroup>
                  </Grid>
                  <Grid
                    item
                    md={2}
                    mt={2}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => console.log("Adicionar")}
                      startIcon={<AddCircleIcon />}
                    >
                      ADICIONAR ÁREA
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Card>

            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
              }}
            >
              {/* CURSO TÉCNICO EXIGIDO OU DESEJÁVEL */}
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Typography variant="h6">*Algum Curso Técnico é Exigido ou Desejável? (Caso sim adicione um ou mais itens)</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControl fullWidth>
                    <InputLabel>Curso</InputLabel>
                    <Select
                      id="areaAtuacao"
                      name="areaAtuacao"
                      value={values.areaAtuacao}
                      onChange={handleChange}
                      disabled={visualizacao}
                    >
                      <MenuItem value={0}>Administração</MenuItem>
                      <MenuItem value={1}>Agricultura</MenuItem>
                      <MenuItem value={2}>Artes</MenuItem>
                      <MenuItem value={3}>Biologia</MenuItem>
                      <MenuItem value={4}>Ciências</MenuItem>
                      <MenuItem value={5}>Comunicação</MenuItem>
                      <MenuItem value={6}>Direito</MenuItem>
                      <MenuItem value={7}>Educação</MenuItem>
                      <MenuItem value={8}>Engenharia</MenuItem>
                      <MenuItem value={9}>Informática</MenuItem>
                      <MenuItem value={10}>Saúde</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4.5}
                >
                  <RadioGroup
                    id="isExigidoProfissional"
                    name="isExigidoProfissional"
                    value={values.isExigidoProfissional}
                    onChange={handleChange}
                    disabled={visualizacao}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          value={"desejado"}
                          style={{ color: theme.palette.primary.main }}
                        />
                      }
                      label="Desejado"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          value={"exigido"}
                          style={{ color: theme.palette.primary.main }}
                        />
                      }
                      label="Exigido"
                    />
                  </RadioGroup>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sm={6}
                  spacing={1}
                  justifyContent={"flex-end"}
                >
                  <Grid
                    item
                    md={2}
                    mt={2}
                  >
                    <FormGroup>
                      <Button
                        variant="outlined"
                        onClick={() => console.log("Deletar")}
                      >
                        <DeleteOutline />
                      </Button>
                    </FormGroup>
                  </Grid>
                  <Grid
                    item
                    md={2}
                    mt={2}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => console.log("Adicionar")}
                      startIcon={<AddCircleIcon />}
                    >
                      ADICIONAR ÁREA
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Card>

            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
              }}
            >
              {/* CURSO SUPERIOR EXIGIDO OU DESEJÁVEL */}
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Typography variant="h6">
                    *Algum Curso de Nível Superior é Exigido ou Desejável? (Caso sim adicione um ou mais itens)
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControl fullWidth>
                    <InputLabel>Curso</InputLabel>
                    <Select
                      id="areaAtuacao"
                      name="areaAtuacao"
                      value={values.areaAtuacao}
                      onChange={handleChange}
                      disabled={visualizacao}
                    >
                      <MenuItem value={0}>Administração</MenuItem>
                      <MenuItem value={1}>Agricultura</MenuItem>
                      <MenuItem value={2}>Artes</MenuItem>
                      <MenuItem value={3}>Biologia</MenuItem>
                      <MenuItem value={4}>Ciências</MenuItem>
                      <MenuItem value={5}>Comunicação</MenuItem>
                      <MenuItem value={6}>Direito</MenuItem>
                      <MenuItem value={7}>Educação</MenuItem>
                      <MenuItem value={8}>Engenharia</MenuItem>
                      <MenuItem value={9}>Informática</MenuItem>
                      <MenuItem value={10}>Saúde</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4.5}
                >
                  <RadioGroup
                    id="isExigidoProfissional"
                    name="isExigidoProfissional"
                    value={values.isExigidoProfissional}
                    onChange={handleChange}
                    disabled={visualizacao}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          value={"desejado"}
                          style={{ color: theme.palette.primary.main }}
                        />
                      }
                      label="Desejado"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          value={"exigido"}
                          style={{ color: theme.palette.primary.main }}
                        />
                      }
                      label="Exigido"
                    />
                  </RadioGroup>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sm={6}
                  spacing={1}
                  justifyContent={"flex-end"}
                >
                  <Grid
                    item
                    md={2}
                    mt={2}
                  >
                    <FormGroup>
                      <Button
                        variant="outlined"
                        onClick={() => console.log("Deletar")}
                      >
                        <DeleteOutline />
                      </Button>
                    </FormGroup>
                  </Grid>
                  <Grid
                    item
                    md={2}
                    mt={2}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => console.log("Adicionar")}
                      startIcon={<AddCircleIcon />}
                    >
                      ADICIONAR ÁREA
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Stack>
          {/* Botões */}
          {!visualizacao && (
            <Grid
              container
              spacing={1}
              mt={2}
              justifyContent="flex-end"
            >
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => window.history.back()}
                  fullWidth
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  sx={{ gap: "8px" }}
                  onSubmit={values => {
                    console.log(values);
                  }}
                >
                  <SaveIcon fontSize="small" />
                  <Typography variant={"BUTTON TEXT"}>SALVAR</Typography>
                </LoadingButton>
              </Grid>
            </Grid>
          )}
        </Form>
      )}
    </Formik>
  );
};

AdminVisualizarVagaTrabalho.propTypes = {
  value: PropTypes.object,
  visualizacao: PropTypes.bool,
};

export default AdminVisualizarVagaTrabalho;
