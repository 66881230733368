import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import FormAcessoMicrocreditoMPO from "../../components/formularios/ParceiroCidadaoAcessoMicrocreditoMPO";
import TitlePage from "../../components/title-page/TitlePage";

const PageAcessoMicrocreditoMPO = () => {
  const linksBreadCrumb = [
    { href: "/", text: "Cidadão" },
    { href: "/empreendedorismo", text: "Empreendedorismo" },
    { href: "/empreendedorismo", text: "Microcrédito" },
  ];

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        currentPage="Acesso a Microcrédito Produtivo Orientado (MPO)"
        links={linksBreadCrumb}
      />

      <TitlePage title={"EMPREENDEDORISMO – Acesso a Microcrédito Produtivo Orientado (MPO)"} />

      <p>(DEV - clique no botao PERGUNTAS E RESPOSTAS para alterar o texto)</p>

      <FormAcessoMicrocreditoMPO />
    </Container>
  );
};

export default PageAcessoMicrocreditoMPO;
