import React, { useEffect, useState } from "react";

import InfoIcon from "@mui/icons-material/Info";
import { Box, Container, Grid, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";

import imageCidadaoHome from "../../assets/images/cidadaoHome.png";
import imagemServicos1 from "../../assets/images/servico1.png";
import imagemServicos2 from "../../assets/images/servico2.png";
import imagemServicos3 from "../../assets/images/servico3.png";
import imagemServicos4 from "../../assets/images/servico4.png";
import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import CardHome from "../../components/cards/CardHome";
import CardPrimario from "../../components/cards/CardPrimario";
import CardServicos from "../../components/cards/CardServicos";
import TitlePage from "../../components/title-page/TitlePage";
import { useAuth } from "../../contexts/AuthContext";
import cidadaoService from "../../services/cidadao.service";

const PageHomeCidadao = () => {
  const { user } = useAuth();
  const theme = useTheme();

  const [cardVisaoGeralData, setCardVisaoGeralData] = useState(null);
  const dataUltimaModificacao = dayjs(user?.dh_atualizacao).format("YYYY-MM-DD");

  const fetchCardVisaoGeralData = async () => {
    cidadaoService
      .getCidadaoData()
      .then(data => {
        setCardVisaoGeralData(data);
      })
      .catch(error => {
        console.error("Erro ao obter dados da visão geral:", error);
      });
  };

  useEffect(() => {
    fetchCardVisaoGeralData();
  }, []);

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb currentPage="Página inicial" />

      <TitlePage
        title={"Seja bem-vindo(a)"}
        mostrarVoltar={false}
      />

      <CardHome
        title="Programa Acredita no Primeiro Passo"
        content={
          <Typography variant="body1">
            A situacao do seu cadastro é <b>{cardVisaoGeralData?.situacao || "..."}</b> desde <b>{dataUltimaModificacao || "..."}</b>
          </Typography>
        }
        imageUrl={imageCidadaoHome || ""}
      />

      <Typography
        variant="h4"
        mt={3}
        mb={1}
        sx={{ fontFamily: "Rawline Bold", marginTop: "40px" }}
      >
        Serviços ofertados
      </Typography>

      <CardServicos />

      <Typography
        variant="h4"
        mt={3}
        mb={1}
        sx={{ fontFamily: "Rawline Bold", marginTop: "40px" }}
      >
        Serviços disponíveis
      </Typography>

      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          md={6}
          xs={12}
        >
          <CardPrimario
            title={cardVisaoGeralData?.["Vagas de Emprego"]?.toString() || ""}
            content="Vagas de emprego"
            subContent="Disponíveis em diversas áreas"
            imageUrl={imagemServicos1}
            imageWidth={"150px"}
            imageHeight={"150px"}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <CardPrimario
            title={cardVisaoGeralData?.["Vagas de Cursos"]?.toString() || ""}
            content="Vagas de curso"
            subContent="Disponíveis em diversas áreas"
            imageUrl={imagemServicos2}
            imageWidth={"150px"}
            imageHeight={"150px"}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <CardPrimario
            title={cardVisaoGeralData?.["Empresas Ofertantes de Serviços Financeiros e de Pagamentos"]?.toString() || ""}
            content="Empresas ofertantes de serviços financeiros e de pagamentos"
            subContent=""
            imageUrl={imagemServicos3}
            imageWidth={"150px"}
            imageHeight={"150px"}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <CardPrimario
            title={cardVisaoGeralData?.["Cursos para Empreender em sua Região"]?.toString() || ""}
            content="Cursos para empreender em sua região"
            subContent=""
            imageUrl={imagemServicos4}
            imageWidth={"230px"}
            imageHeight={"150px"}
          />
        </Grid>
      </Grid>

      <Box
        mt={5}
        sx={{
          display: "flex",
          alignItems: "center",
          background: theme.palette.background.paper,
          color: theme.palette.text.primary,
          border: theme.palette.border.layout,
          padding: "16px",
          paddingRight: "8px",
        }}
      >
        <InfoIcon />
        <Typography
          variant="body1"
          sx={{ ml: 1, fontFamily: "Rawline Regular" }}
          style={{ transition: "1s" }}
        >
          No caso de dúvidas e/ou problemas de acesso, clique em
          <Link
            component="a"
            href="https://www.gov.br/mds/pt-br/acesso-a-informacao/perguntas_frequentes"
            color="primary.main"
            underline="always"
            sx={{ ml: 1 }}
            target="_blank"
          >
            Perguntas Frequentes
          </Link>
          . Caso o problema persista, envie uma mensagem para o
          <Link
            component="a"
            href="https://www.gov.br/mds/pt-br/acesso-a-informacao/participacao-social/fale-conosco"
            color="primary.main"
            underline="always"
            sx={{ ml: 1 }}
            target="_blank"
          >
            Fale com MDS
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default PageHomeCidadao;
