import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminListarCursosPendentes from "../../components/filtros/AdminListarCursosPendentes";
import TitlePage from "../../components/title-page/TitlePage";

const PageListarCursosPendentes = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb currentPage="Listar Cursos Pendentes" />

      <TitlePage title={"Listar Cursos Pendentes"} />

      <AdminListarCursosPendentes />
    </Container>
  );
};

export default PageListarCursosPendentes;
