import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import imagem404 from "../assets/images/page-404.png";
import imagem404DarkMode from "../assets/images/page-404-darkmode.png";

const NotFound = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [imageSrc, setImageSrc] = useState(imagem404);

  useEffect(() => {
    if (theme.palette.mode === "dark") {
      setImageSrc(imagem404DarkMode);
    } else {
      setImageSrc(imagem404);
    }
  }, [theme.palette.mode]);

  return (
    <Grid
      container
      sx={{ mt: 0 }}
    >
      <Grid
        item
        xs={12}
        style={{ textAlign: "center" }}
      >
        <p style={{ fontSize: "32px", fontFamily: "Rawline Bold", color: theme.palette.primary.main }}>Página não encontrada</p>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ textAlign: "center" }}
      >
        <p style={{ fontSize: "16px", fontFamily: "Rawline Regular", color: theme.palette.text.primary }}>
          A página que você procura não foi localizada.
          <br />
          Explore os menus para encontrar uma página válida ou retorne ao início.
        </p>
      </Grid>
      <Box sx={{ mx: "auto", width: 450, mb: 2 }}>
        <img
          src={imageSrc}
          alt="404 Not Found"
        />
      </Box>
      <Grid
        item
        xs={12}
        style={{ textAlign: "center" }}
      >
        <Button
          variant="contained"
          onClick={() => navigate("/")}
        >
          Voltar para página principal
        </Button>
      </Grid>
    </Grid>
  );
};

export default NotFound;
