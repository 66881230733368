import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminConsultarJornadaCidadao from "../../components/filtros/AdminConsultarJornadaCidadao.js";
import TitlePage from "../../components/title-page/TitlePage";

const PageConsultarJornadaCidadao = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb currentPage="Jornada do Cidadão" />

      <TitlePage title={"Jornada do Cidadão"} />

      <AdminConsultarJornadaCidadao />
    </Container>
  );
};

export default PageConsultarJornadaCidadao;
