import React, { useState } from "react";

import SaveIcon from "@mui/icons-material/Save";
import { Button, FormGroup, Grid, Stack, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";

import CustomModal from "./CustomModal";

const TrilhaModal = ({ showModal, handleClose, handleSubmit, modalTitle }) => {
  const [valores, setValores] = useState({
    motivo: "",
  });

  const handleChanges = event => {
    const { name, value } = event.target;
    setValores({ ...valores, [name]: value });
  };

  const modalContent = (
    <Stack spacing={1}>
      {/* Motivo */}
      <Grid
        container
        mt={1}
      >
        <Typography variant="subtitle2">Situação</Typography>
        <Grid
          item
          xs={12}
        >
          <FormGroup>
            <TextField
              multiline
              rows={4}
              id="motivo"
              name="motivo"
              label="Motivo"
              placeholder="Motivo"
              value={valores.motivo}
              type="text"
              onChange={handleChanges}
            />
          </FormGroup>
        </Grid>
      </Grid>

      {/* Botões */}
      <Grid
        container
        spacing={1}
        justifyContent="flex-end"
      >
        <Grid item>
          <Button
            variant="outlined"
            onClick={handleClose}
          >
            <Typography variant={"BUTTON TEXT"}>CANCELAR</Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ gap: "8px" }}
          >
            <SaveIcon fontSize="small" />
            <Typography variant={"BUTTON TEXT"}>SALVAR</Typography>
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );

  return (
    <CustomModal
      showModal={showModal}
      handleClose={handleClose}
      title={modalTitle}
      content={modalContent}
      buttons={[]}
      maxWidth="sm"
    />
  );
};

TrilhaModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  modalTitle: PropTypes.string.isRequired,
};

export default TrilhaModal;
