import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import CardCurriculo from "../../components/cards/CardCurriculo";
import CardCurriculoInexistente from "../../components/cards/CardCurriculoInexistente";
import MobilizacaoCurriculo from "../../components/formularios/ParceiroCidadaoMobilizacaoCurriculo";
import TitlePage from "../../components/title-page/TitlePage";

const PageCurriculoCidadao = () => {
  const linksBreadCrumb = [
    { href: "/", text: "Parceiro" },
    { href: "/parceiro/mobilizacao", text: "Mobilização" },
    { href: "/parceiro/mobilizacao/mobilizacao-individual-cidadao", text: "Individual" },
  ];

  const infoItems = [
    { label: "Endereço:", value: "[endereço]" },
    { label: "Telefone:", value: "[telefone]" },
    { label: "Data de Nascimento:", value: "[data de nascimento]" },
    { label: "Estado Civil:", value: "[estado civil]" },
    { label: "E-mail:", value: "[e-mail]" },
    { label: "Possui CNH:", value: "[possui CNH]" },
    { label: "Tipo CNH:", value: "[tipo CNH]" },
  ];

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        currentPage="Visualizar currículo"
        links={linksBreadCrumb}
      />

      <TitlePage title={"Currículo"} />

      {infoItems && <MobilizacaoCurriculo infoItems={infoItems} />}

      {infoItems && <CardCurriculo infoItems={infoItems} />}

      {!infoItems && <CardCurriculoInexistente />}
    </Container>
  );
};

export default PageCurriculoCidadao;
