import React from "react";

import { Card, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";

const CardCurriculo = function ({ infoItems }) {
  return (
    <Card
      color="#ffffff"
      sx={{ borderRadius: "8px", padding: "16px", mt: "32px" }}
      style={{ transition: "1s" }}
    >
      <Typography
        variant="h5"
        sx={{ fontSize: "20", mb: "16px", fontFamily: "Rawline Regular" }}
      >
        Mobilização de currículo
      </Typography>

      <Grid
        container
        spacing={0.1}
      >
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          mt={3}
        >
          <Typography
            variant="h5"
            sx={{ fontSize: "16px", mb: "16px", fontFamily: "Rawline Thin" }}
          >
            [nome pessoa]
          </Typography>
        </Grid>

        {infoItems.map((item, index) => (
          <Grid
            item
            container
            md={12}
            sm={12}
            xs={12}
            key={index}
          >
            <Typography
              variant="h5"
              sx={{ fontSize: "16px", mb: "10px", fontFamily: "Rawline Bold", mr: "5px" }}
            >
              {item.label}
            </Typography>
            <Typography
              variant="h5"
              sx={{ fontSize: "16px", mb: "10px", fontFamily: "Rawline Thin" }}
            >
              {item.value}
            </Typography>
          </Grid>
        ))}

        <div style={{ borderBottom: "1px solid grey", width: "100%", marginTop: "6px" }}></div>
        <Grid
          item
          container
          md={12}
          sm={12}
          xs={12}
        >
          <Grid
            item
            container
            md={2}
            sm={2}
            xs={4}
          >
            <Typography
              variant="h5"
              sx={{ fontSize: "16px", mt: "16px", fontFamily: "Rawline Bold", mr: "5px" }}
            >
              Escolaridade:
            </Typography>
          </Grid>
          <Grid
            item
            container
            md={10}
            sm={10}
            xs={8}
          >
            <Typography
              variant="h5"
              sx={{ fontSize: "16px", mt: "16px", fontFamily: "Rawline Thin" }}
            >
              Escolaridade - [Situação Escolaridade]
            </Typography>
          </Grid>
        </Grid>

        <div style={{ borderBottom: "1px solid grey", width: "100%", marginTop: "16px" }}></div>
        <Grid
          item
          container
          md={12}
          sm={12}
          xs={12}
        >
          <Grid
            item
            container
            md={2}
            sm={2}
            xs={4}
          >
            <Typography
              variant="h5"
              sx={{ fontSize: "16px", mt: "16px", fontFamily: "Rawline Bold", mr: "5px" }}
            >
              Cursos Técnicos:
            </Typography>
          </Grid>
          <Grid
            item
            container
            md={10}
            sm={10}
            xs={8}
          >
            <Typography
              variant="h5"
              sx={{ fontSize: "16px", mt: "16px", fontFamily: "Rawline Thin" }}
            >
              Tipo do curso - [Situação do Curso] <br />
              Instituição - [Nome instituição] <br />
              Ano de conclusão - [Ano de conclusão] <br />
            </Typography>
          </Grid>
        </Grid>

        <div style={{ borderBottom: "1px solid grey", width: "100%", marginTop: "16px" }}></div>
        <Grid
          item
          container
          md={12}
          sm={12}
          xs={12}
        >
          <Grid
            item
            container
            md={2}
            sm={2}
            xs={4}
          >
            <Typography
              variant="h5"
              sx={{ fontSize: "16px", mt: "16px", fontFamily: "Rawline Bold", mr: "5px" }}
            >
              Experiências:
            </Typography>
          </Grid>
          <Grid
            item
            container
            md={10}
            sm={10}
            xs={8}
          >
            <Typography
              variant="h5"
              sx={{ fontSize: "16px", mt: "16px", fontFamily: "Rawline Thin" }}
            >
              Tipo da experiência - [Situação da experiência] <br />
              Instituição - [Nome instituição] <br />
              Ano de conclusão - [Ano de conclusão] <br />
            </Typography>
          </Grid>
        </Grid>
        <div style={{ borderBottom: "1px solid grey", width: "100%", marginTop: "16px" }}></div>
        <Grid
          item
          container
          md={12}
          sm={12}
          xs={12}
        >
          <Grid
            item
            container
            md={2}
            sm={2}
            xs={4}
          >
            <Typography
              variant="h5"
              sx={{ fontSize: "16px", mt: "16px", fontFamily: "Rawline Bold", mr: "5px" }}
            >
              Qualificação e informações adicionais:
            </Typography>
          </Grid>
          <Grid
            item
            container
            md={10}
            sm={10}
            xs={8}
          >
            <Typography
              variant="h5"
              sx={{ fontSize: "16px", mt: "16px", fontFamily: "Rawline Thin" }}
            >
              Tipo - [Descrição do Tipo] <br />
              Tipo - [Descrição do Tipo] <br />
              Tipo - [Descrição do Tipo] <br />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

CardCurriculo.propTypes = {
  infoItems: PropTypes.array,
};

export default CardCurriculo;
