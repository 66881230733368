import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { Button, Container, FormGroup, Grid, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import FormInscricaoParceiro from "../../components/formularios/empresaParceira/FormInscricaoParceiro";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import parceiroService from "../../services/parceiro.service";

const ExcluirParceiro = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [motivoExclusao, setMotivoExclusao] = useState("");
  const [erroMotivo, setErroMotivo] = useState(false);
  // TODO: remover esse default
  id = id || "1";

  const {
    isPending,
    isError,
    data: initialData,
    error,
  } = useQuery({
    queryKey: ["empresaParceira", id],
    queryFn: ({ queryKey }) => {
      const [, id] = queryKey;
      return parceiroService.getEmpresaData(id);
    },
    enabled: !!id,
  });

  const handleSubmit = (id, motivo) => {
    if (motivo) {
      parceiroService
        .excluiEmpresaParceira(id, motivo)
        .then(() => {
          alert("Operação realizada com sucesso");
          //Depois voltar para a página de listagem
          navigate("/parceiro/listar-parceiros");
        })
        .catch(error => {
          console.error("Erro ao excluir empresa parceira:", error);
          alert("Ocorreu um erro ao realizar a operação.");
        });
    } else {
      alert("Por favor, informe o motivo da exclusão");
    }
  };

  const handleMotivoChange = e => {
    setMotivoExclusao(e.target.value);
    setErroMotivo(e.target.value === "");
  };

  if (isError) {
    console.log("Erro de carregamento:", error);
  }

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "", text: "Rede" },
          { href: "/parceiro/listar-parceiros", text: "Listar Parceiros" },
        ]}
        currentPage="Excluir Parceiro"
      />

      <TitlePage title={"Excluir Parceiro"} />

      {isPending ? (
        <LoadingComponent
          isLoading={isPending}
          fullScreen={false}
        />
      ) : (
        <>
          {/* Formulario de visualização */}
          <FormInscricaoParceiro
            initialValues={initialData}
            visualizacao={true}
            confirmacaoModal={false}
            erroModal={false}
            erroDoUsuarioModal={false}
          />
          {/* Formulario de exclusão */}
          <FormGroup>
            <Grid
              container
              spacing={2}
              mt={1}
            >
              <Grid
                item
                xs={12}
              >
                <TextField
                  multiline
                  rows={3}
                  id="motivoExclusao"
                  name="motivoExclusao"
                  label="Motivo da exclusão"
                  onChange={handleMotivoChange}
                  value={motivoExclusao}
                  fullWidth
                  error={erroMotivo}
                  helperText={erroMotivo ? "Motivo é obrigatório" : ""}
                />
              </Grid>

              {/* Botoes de ação */}
              <Grid
                container
                spacing={1}
                mt={3}
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => window.history.back()}
                  >
                    <Typography variant={"BUTTON TEXT"}>CANCELAR</Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    onClick={() => handleSubmit(id, motivoExclusao)}
                    sx={{ gap: "8px" }}
                    disabled={!motivoExclusao}
                  >
                    <SaveIcon fontSize="small" />
                    <Typography variant={"BUTTON TEXT"}> Confirmar Exclusão</Typography>
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </FormGroup>
        </>
      )}

      {isError && <ErroCarregamentoModal isShow={true} />}
    </Container>
  );
};

export default ExcluirParceiro;
