import React, { useState } from "react";

import Save from "@mui/icons-material/Save";
import { Box, Button, Card, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";

import { tabelaAcoes } from "../formularios/dadosMockados";
import RelacaoDeCursoModal from "../modals/RelacaoDeCursosModal";
import TransferList from "../transferList/TransferList";

const AdminPerfil = ({ editar, visualizar, valores, setValores }) => {
  const theme = useTheme();

  const [modal, setModal] = useState(false);
  const [itemsTransferfList, setItemsTransferList] = useState({
    left: tabelaAcoes.map(curso => curso.nome),
    right: valores.acoes,
  });

  const handleItemsTransferListChange = updatedItems => {
    setItemsTransferList(updatedItems);
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setValores({ ...valores, [name]: value });
  };

  const fecharModal = () => {
    setModal(false);
  };

  return (
    <>
      <Formik initialValues={valores}>
        <Form>
          <Stack spacing={1}>
            <Card
              color="#ffffff"
              sx={{
                borderRadius: "8px",
                padding: "16px",
                mt: "24px",
              }}
            >
              {/* Campos */}
              <Grid
                spacing={2}
                container
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <TextField
                    fullWidth
                    id="nome"
                    name="nome"
                    value={valores.nome}
                    label="Nome"
                    placeholder="Nome"
                    onChange={handleChange}
                    disabled={visualizar}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <TextField
                    fullWidth
                    id="descricao"
                    name="descricao"
                    value={valores.descricao}
                    label="Descrição"
                    placeholder="Descrição"
                    onChange={handleChange}
                    disabled={visualizar}
                  />
                </Grid>

                {editar && !visualizar && (
                  <Grid
                    item
                    xs={12}
                  >
                    <Stack
                      spacing={1}
                      mt={1}
                    >
                      <Typography variant="subtitle2">Situação</Typography>
                      <FormGroup>
                        <RadioGroup
                          row
                          name="situacao"
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="Ativo"
                            control={
                              <Radio
                                style={{ color: theme.palette.primary.main }}
                                checked={valores.situacao === "Ativo"}
                              />
                            }
                            label="Ativo"
                          />
                          <FormControlLabel
                            value="Inativo"
                            control={
                              <Radio
                                style={{ color: theme.palette.primary.main }}
                                checked={valores.situacao === "Inativo"}
                              />
                            }
                            label="Inativo"
                          />
                        </RadioGroup>
                      </FormGroup>
                    </Stack>
                  </Grid>
                )}
              </Grid>

              {/* Tabela de transferência */}
              {!visualizar ? (
                <Grid mt={2}>
                  <TransferList
                    items={itemsTransferfList}
                    titleLeft={"Ações disponíveis"}
                    titleRight={"Ações selecionadas para o perfil"}
                    onItemsChange={handleItemsTransferListChange}
                  />
                </Grid>
              ) : (
                <Grid
                  mt={0.5}
                  spacing={2}
                  container
                >
                  {/* Situação e Data do Cadastro*/}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <TextField
                      fullWidth
                      disabled
                      id="situacao"
                      name="situacao"
                      value={valores.situacao}
                      label="Situação"
                      placeholder="Situação"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormGroup>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          disabled
                          label="Data do Cadastro"
                          id="dataCadastro"
                          name="dataCadastro"
                          value={dayjs(valores.dataCadastro)}
                          format="DD/MM/YYYY"
                          onChange={valor => setValores({ ...valores, ["dataCadastro"]: valor })}
                        />
                      </LocalizationProvider>
                    </FormGroup>
                  </Grid>

                  {/* Ações */}
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      disabled
                      multiline
                      rows={3}
                      id="acoes"
                      name="acoes"
                      value={valores.acoes}
                      label="Ações associadas ao perfil"
                      placeholder="Ações associadas ao perfil"
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      disabled={!editar}
                      multiline
                      rows={3}
                      id="motivo"
                      name="motivo"
                      value={valores.motivo}
                      label="Motivo"
                      placeholder="Motivo"
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              )}
            </Card>

            {/* Botões de salvar/cancelar */}
            <Box sx={{ flexGrow: 1, mt: "16px" }}>
              <Grid
                container
                spacing={1}
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    sx={{ gap: "8px" }}
                    style={{ fontFamily: "Rawline Medium" }}
                    onClick={() => window.history.back()}
                  >
                    Cancelar
                  </Button>
                </Grid>

                {visualizar ? (
                  <>
                    {!editar && (
                      <>
                        <Grid item>
                          <Button
                            variant="outlined"
                            sx={{ gap: "8px" }}
                            style={{ fontFamily: "Rawline Medium" }}
                            href={"/perfil/visualizar/historico-situacao/"}
                          >
                            Histórico de ativação
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="outlined"
                            href="/perfil/editar/"
                            sx={{ gap: "8px" }}
                            style={{ fontFamily: "Rawline Medium" }}
                          >
                            Editar
                          </Button>
                        </Grid>
                      </>
                    )}
                    <Grid item>
                      <Button
                        variant="contained"
                        sx={{ gap: "8px" }}
                        style={{ fontFamily: "Rawline Medium" }}
                      >
                        Excluir
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <Grid item>
                    <Button
                      variant="contained"
                      sx={{ gap: "8px" }}
                      style={{ fontFamily: "Rawline Medium" }}
                      onClick={() => setModal(true)}
                    >
                      <Save />
                      Salvar
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Stack>
        </Form>
      </Formik>
      <div>
        <RelacaoDeCursoModal
          showModal={modal}
          handleClose={fecharModal}
          situacao={valores.situacao}
          cursos={valores.acoes}
        />
      </div>
    </>
  );
};

AdminPerfil.propTypes = {
  editar: PropTypes.bool,
  visualizar: PropTypes.bool,
  valores: PropTypes.object,
  setValores: PropTypes.func,
};

export default AdminPerfil;
