import React, { useEffect, useState } from "react";

import InfoIcon from "@mui/icons-material/Info";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";

import optionsService from "../../../services/options.service.js";
import ReusableSwitch from "../fields/ReusableSwitch.js";
import { SelectCidade } from "../fields/SelectCidade.js";
import { SelectUF } from "../fields/SelectUF.js";
import { formatCEP, formatTelefone } from "../utils.js";

const FormDadosPessoais = ({ values }) => {
  //========================================>  <================================================
  //VARIAVEIS UTILIZADAS NO FORM  - Primeiramente deve setar os valores iniciais
  //========================================>  <================================================
  const initialValues = values
    ? values
    : {
        email: "",
        nome: "",
        nomePai: "",
        nomeMae: "",
        emailAdicional: "",
        telefone: "",
        estadoCivil: "",
        escolaridade: "",
        cnpj: "",
        uf: "",
        municipio: "",
        corRaca: "",
        sexo: "",
        faixaRenda: "",
        acessoInternet: "",
        possuiCnh: false,
        interesseTrabalharOutraLocalidade: false,
        pcd: false,
        toggleCienteGratuito: false,
      };

  const [ufs, setUfs] = useState([]);
  const [cidades, setCidades] = values ? useState(optionsService.cidadesJSON.filter(cidade => cidade.estado === values.uf)) : useState([]);
  const [selectedUf, setSelectedUf] = values ? useState(values.uf) : useState("");
  const [selectedCidade, setSelectedCidade] = values ? useState(values.cidade) : useState("");

  //========================================>  <================================================
  //HANDLES (SET VALUES) DIFERENTES UTILIZADOS NO FORM  - Caso necessite fazer um novo handle utilize o setFielValue, seguir o exemplo do handleSwitch
  //========================================>  <================================================
  const handleSwitchChange = (event, setFieldValue) => {
    const { name, checked } = event.target;
    setFieldValue(name, checked);
  };

  const handleDataNascimetoChange = (event, setFieldValue) => {
    const date = new Date(event.$d);
    const formattedDate = date.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    setFieldValue("dataNascimento", formattedDate);
  };

  const handleSelectUf = (event, setFieldValue) => {
    const { name, value } = event.target;
    setFieldValue(name, value);

    if (name === "uf") {
      setSelectedUf(value);
      setSelectedCidade("");
      setFieldValue("cidade", "");
      fetchCidadesByUf(event.target.value, setCidades);
    }
  };

  const handleSelectCidade = (event, setFieldValue) => {
    const { name, value } = event.target;
    setFieldValue(name, value);

    if (name === "cidade") {
      setSelectedCidade(event.target.value);
    }
  };

  //========================================>  <================================================
  //FETCH PARA PREENCHER OPCOES NOS INPUTS - Caso necessite fazer um fetch para mostrar options
  //========================================>  <================================================
  useEffect(() => {
    const fetchUfs = async () => {
      await optionsService
        .getAllUFs()
        .then(ufsData => {
          setUfs(ufsData);
        })
        .catch(error => {
          console.error("Erro ao obter UFs:", error);
        });
    };
    fetchUfs();
  }, []);

  const fetchCidadesByUf = async (ufSigla, setCidadesFunction) => {
    try {
      const cidadesData = await optionsService.getCidadesFromUF(ufSigla);
      setCidadesFunction(cidadesData);
    } catch (error) {
      console.error("Erro ao obter cidades:", error);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
    >
      {({ values, setFieldValue, handleChange, handleBlur, errors, touched }) => (
        <Form>
          <Stack>
            <Grid
              spacing={2}
              container
            >
              <Grid
                item
                xs={12}
                sm={4}
              >
                <FormGroup>
                  <TextField
                    label="Nome para currículo"
                    id="nome"
                    name="nome"
                    value={values.nome}
                    placeholder="Insira o seu Nome completo"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
              >
                <FormGroup>
                  <TextField
                    label="* E-mail"
                    id="email"
                    name="email"
                    value={values.email}
                    placeholder="Insira seu E-mail aqui"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email && touched.email}
                    helperText={errors.email && touched.email && errors.email}
                  ></TextField>
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  fullWidth
                >
                  <DatePicker
                    label="* Data de nascimento"
                    id="DataNascimento"
                    name="DataNascimento"
                    placeholder="Insira o Telefone"
                    onChange={event => handleDataNascimetoChange(event, setFieldValue)}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: errors.dataNascimento && touched.dataNascimento,
                        helperText: errors.dataNascimento && touched.dataNascimento && errors.dataNascimento,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormControl fullWidth>
                  <InputLabel id="estadoCivilLabel">* Estado civil</InputLabel>
                  <Select
                    labelId="estadoCivilLabel"
                    id="estadoCivil"
                    value={values.estadoCivil}
                    label="Age"
                    onChange={handleChange}
                  >
                    <MenuItem value={"solteiro"}>Solteiro</MenuItem>
                    <MenuItem value={"viuvo"}>Viuvo</MenuItem>
                    <MenuItem value={"casado"}>Casado</MenuItem>
                    <MenuItem value={"divorciado"}>Divorciado</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormControl fullWidth>
                  <InputLabel id="generoLabel">Sexo</InputLabel>
                  <Select
                    labelId="generoLabel"
                    id="sexo"
                    value={values.sexo}
                    label="sexo"
                    onChange={handleChange}
                  >
                    <MenuItem value={"masculino"}>Masculino</MenuItem>
                    <MenuItem value={"feminino"}>Feminino</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormGroup>
                  <TextField
                    label="* Telefone"
                    id="telefone"
                    name="telefone"
                    value={formatTelefone(values.telefone)}
                    placeholder="Insira o Telefone"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputProps={{
                      maxLength: 15,
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormGroup>
                  <TextField
                    label="Telefone adicional"
                    id="telefone_adicional"
                    name="telefone_adicional"
                    value={formatTelefone(values.telefone_adicional)}
                    placeholder="Insira o Telefone"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputProps={{
                      maxLength: 15,
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={12}
              >
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={2}
                >
                  <a style={{ fontFamily: "rawline Bold", marginBottom: "5px" }}>Possui CNH?</a>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    <ReusableSwitch
                      id={"possuiCnh"}
                      name={"possuiCnh"}
                      value={values.possuiCnh}
                      onChange={event => handleSwitchChange(event, setFieldValue)}
                      disabled={false}
                    />
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={7}
                  md={10}
                >
                  <FormGroup>
                    <TextField
                      label="Tipo de CNH"
                      id="tipo_cnh"
                      name="tipo_cnh"
                      value={values.tipo_cnh}
                      placeholder="Insira o tipo da sua CNH"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        maxLength: 15,
                      }}
                    />
                  </FormGroup>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sm={3}
              >
                <FormGroup>
                  <TextField
                    label="* CEP"
                    id="cep"
                    name="cep"
                    value={formatCEP(values.cep)}
                    placeholder="Insira o CEP"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputProps={{
                      maxLength: 10,
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormGroup>
                  <TextField
                    label="* Logradouro"
                    id="endereco"
                    name="endereco"
                    value={values.endereco}
                    placeholder="Insira o Logradouro"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
              >
                <FormGroup>
                  <TextField
                    label="Número"
                    id="numero"
                    name="numero"
                    value={values.numero}
                    placeholder="Insira o Número"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormGroup>
                  <TextField
                    label="Complemento"
                    id="complemento"
                    name="complemento"
                    value={values.complemento}
                    placeholder="Insira o complemento de sua casa"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></TextField>
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormGroup>
                  <TextField
                    label="Bairro"
                    id="bairro"
                    name="bairro"
                    value={values.bairro}
                    placeholder="Insira o seu bairro"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormGroup>
                  <SelectUF
                    idSelect={"uf"}
                    nameSelect={"uf"}
                    value={values.uf}
                    ufs={ufs}
                    uf={selectedUf}
                    handleSelectUf={event => handleSelectUf(event, setFieldValue)}
                    onBlur={handleBlur}
                    errors={errors.uf}
                    touched={touched.uf}
                  />
                  {errors.uf && touched.uf && errors.uf && (
                    <Typography
                      sx={{
                        color: "#FF5630",
                        fontSize: "12px",
                        ml: "12px",
                      }}
                    >
                      {errors.uf}
                    </Typography>
                  )}
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormGroup>
                  <SelectCidade
                    idSelect={"cidade"}
                    nameSelect={"cidade"}
                    value={values.cidade}
                    cidades={cidades}
                    cidade={selectedCidade}
                    handleSelectCidade={event => handleSelectCidade(event, setFieldValue)}
                    onBlur={handleBlur}
                    errors={errors.cidade}
                    touched={touched.cidade}
                  />
                  {errors.cidade && touched.cidade && errors.cidade && (
                    <Typography
                      sx={{
                        color: "#FF5630",
                        fontSize: "12px",
                        ml: "12px",
                      }}
                    >
                      {errors.cidade}
                    </Typography>
                  )}
                </FormGroup>
              </Grid>

              <Grid
                item
                xs={12}
              >
                <FormGroup>
                  <TextField
                    label="Referência"
                    id="referencia"
                    name="referencia"
                    value={values.referencia}
                    placeholder="Insira uma referencia para que seja mais fácil localizar sua casa"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></TextField>
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
              >
                <a style={{ fontFamily: "rawline Bold", marginBottom: "5px", gap: "5px 5px", display: "flex", alignItems: "center" }}>
                  PCD
                  <Tooltip
                    title="Responsável pelo cadastramento da instituição na rede de parceiros no portal Acredita"
                    placement="right"
                  >
                    <InfoIcon
                      color="primary"
                      sx={{ width: "15px", height: "15px" }}
                    />
                  </Tooltip>
                </a>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <ReusableSwitch
                    id={"pcd"}
                    name={"pcd"}
                    value={values.pcd}
                    onChange={event => handleSwitchChange(event, setFieldValue)}
                    disabled={false}
                  />
                </Stack>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
              >
                <div style={{ borderBottom: "1px solid #e1e1e1" }}></div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
              >
                <p style={{ fontFamily: "rawline Bold", marginBottom: "5px", gap: "5px 5px", display: "flex", alignItems: "center" }}>
                  Confirma interesse de trabalhar em outra localidade ou municipio da sua região
                </p>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <ReusableSwitch
                    id={"interesseTrabalharOutraLocalidade"}
                    name={"interesseTrabalharOutraLocalidade"}
                    value={values.interesseTrabalharOutraLocalidade}
                    onChange={event => handleSwitchChange(event, setFieldValue)}
                    disabled={false}
                  />
                </Stack>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      id="toggleCienteGratuito"
                      name="toggleCienteGratuito"
                      checked={values.toggleCienteGratuito}
                      onChange={handleChange}
                      style={{ color: "grey" }}
                    />
                  }
                  label={
                    <Typography
                      variant="body1"
                      style={{ fontFamily: "Rawline Light" }}
                    >
                      Aceito receber, por telefone e/ou e-mail, informações sobre vagas de emprego, cursos de qualificação profissional, e
                      autorizo que os dados do currículo sejam divulgados para empresar cadastradas com forma de buscar uma oportunidade no
                      mercado de trabalho. E estou ciente que o Plano Acredita no Primeiro Passo. não se responsabiliza pela contratação de
                      emprego, estágio ou jovem aprendiz em nenhuma fase.
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

FormDadosPessoais.propTypes = {
  values: PropTypes.object,
};

export default FormDadosPessoais;
