import React from "react";
import { forwardRef, memo } from "react";

import Box from "@mui/material/Box";
import PropTypes from "prop-types";

import { StyledRootScrollbar, StyledScrollbar } from "./styles";

// ----------------------------------------------------------------------

const Scrollbar = forwardRef(({ children, sx, ...other }, ref) => {
  const userAgent = typeof navigator === "undefined" ? "SSR" : navigator.userAgent;

  const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  if (mobile) {
    return (
      <Box
        ref={ref}
        sx={{ overflow: "auto", ...sx }}
        {...other}
      >
        {children}
      </Box>
    );
  }

  return (
    <StyledRootScrollbar>
      <StyledScrollbar
        scrollableNodeProps={{
          ref,
        }}
        clickOnTrack={false}
        sx={sx}
        {...other}
      >
        {children}
      </StyledScrollbar>
    </StyledRootScrollbar>
  );
});

Scrollbar.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
};

Scrollbar.displayName = "Scrollbar";

export default memo(Scrollbar);
