import React from "react";

import { FormGroup, Grid, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";

import CustomModal from "./CustomModal";

const ModalTituloSubTitulo = ({
  titulo,
  subTitulo,
  texto,
  motivo,
  handleMotivoChange,
  showModal,
  handleClose,
  handleSubmit,
  erroMotivo,
}) => {
  const modalContent = (
    <Grid
      container
      spacing={1}
    >
      <Grid
        item
        xs={12}
      >
        <Typography variant="subtitle2">{subTitulo} </Typography>
        <Typography variant="subtitle2">{texto} </Typography>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <FormGroup>
          <TextField
            multiline
            id="motivo"
            name="motivo"
            rows={3}
            value={motivo}
            onChange={handleMotivoChange}
            label="Motivo"
            placeholder="Motivo"
            error={erroMotivo}
            helperText={erroMotivo ? "Motivo é obrigatório" : ""}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );

  const modalButtons = [
    { label: "CANCELAR", onClick: handleClose, variant: "outlined" },
    { label: "SALVAR", onClick: handleSubmit, variant: "contained", icon: "save", disabled: !motivo },
  ];

  return (
    <CustomModal
      showModal={showModal}
      handleClose={handleClose}
      title={titulo}
      content={modalContent}
      buttons={modalButtons}
      maxWidth="md"
    />
  );
};

ModalTituloSubTitulo.propTypes = {
  titulo: PropTypes.string.isRequired,
  subTitulo: PropTypes.string,
  motivo: PropTypes.string,
  handleMotivoChange: PropTypes.func,
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  texto: PropTypes.string,
  erroMotivo: PropTypes.bool,
};

export default ModalTituloSubTitulo;
