import React from "react";

import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";

import CustomModal from "./CustomModal";

const MensagemEmail = ({ showModal, handleClose, info }) => {
  if (!info.mensagem) {
    return false;
  }
  const modalTitle = "Mensagem E-mail";
  const modalContent = (
    <Grid container>
      <Grid
        item
        xs={12}
      >
        <Typography variant="subtitle2">Remetente: {info.remetente}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography variant="subtitle2">Destinatário: {info.destinatario}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        mb={1}
      >
        <Typography variant="subtitle2">Assunto: {info.assunto}</Typography>
      </Grid>
      <div style={{ borderBottom: "1px solid grey", width: "100%", marginTop: "6px" }}></div>
      <Grid
        item
        xs={12}
        mt={1}
      >
        <Typography variant="subtitle2"> Olá, {info.nome} </Typography>
        {info.mensagem.map(text => {
          return (
            <React.Fragment key={text[0] + text[2]}>
              <Typography
                variant="body2"
                textAlign={"justify"}
              >
                {text}
              </Typography>
              <br></br>
            </React.Fragment>
          );
        })}
      </Grid>
    </Grid>
  );

  const modalButtons = [{ label: "CANCELAR", onClick: handleClose, variant: "outlined" }];

  return (
    <CustomModal
      showModal={showModal}
      handleClose={handleClose}
      title={modalTitle}
      content={modalContent}
      buttons={modalButtons}
      maxWidth="md"
    />
  );
};

MensagemEmail.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  info: PropTypes.object.isRequired,
};

export default MensagemEmail;
