import React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ReplyIcon from "@mui/icons-material/Reply";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { Button, Card, FormGroup, Grid, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";

const FormCidadaoVisualizarVaga = ({ handleSubmit, values, readOnly = false }) => {
  // eslint-disable-next-line no-unused-vars
  const theme = useTheme();

  //========================================>  <================================================
  //VARIAVEIS UTILIZADAS NO FORM  - Primeiramente deve setar os valores iniciais
  //========================================>  <================================================
  const initialValues = values
    ? values
    : {
        ofertante: "Empresas Alfas",
        salario: "",
        vagas: "",
        funcao: "",
        areaAtuacao: "",
        areaEstudante: "",
        local: "",
        descricao: "",
        necessario: "",
        beneficios: "",
      };

  //========================================>  <================================================
  //HANDLES (SET VALUES) DIFERENTES UTILIZADOS NO FORM  - Caso necessite fazer um novo handle utilize o setFielValue, seguir o exemplo do handleSwitch
  //========================================>  <================================================

  //========================================>  <================================================
  //DEFINICAO DE INPUTS OBRIGATORIOS  - Necessita utilizar a tag error & helpText, como no exemplo de UF
  //========================================>  <================================================

  //========================================>  <================================================
  //FETCH PARA PREENCHER OPCOES NOS INPUTS - Caso necessite fazer um fetch para mostrar options
  //========================================>  <================================================

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange={false}
    >
      {({ values, handleChange, handleBlur }) => (
        <Form>
          <Stack>
            <Card
              color="#ffffff"
              sx={{
                borderRadius: "8px",
                padding: "16px",
              }}
              style={{ transition: "1s" }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontSize: "20",
                  mb: "16px",
                  fontFamily: "Rawline Regular",
                }}
              >
                Informações da vaga
              </Typography>
              <Grid
                spacing={1}
                container
              >
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormGroup>
                    <TextField
                      label="Ofertante"
                      id="ofertante"
                      name="ofertante"
                      value={values.ofertante}
                      placeholder="Empresa responsável pela vaga"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormGroup>
                    <TextField
                      label="Salário"
                      id="salario"
                      name="salario"
                      value={values.salario}
                      placeholder="Remuneração da vaga"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormGroup>
                    <TextField
                      label="Vagas"
                      id="vagas"
                      name="vagas"
                      value={values.vagas}
                      placeholder="Quantidade de vagas disponíveis"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormGroup>
                    <TextField
                      label="Função"
                      id="funcao"
                      name="funcao"
                      value={values.funcao}
                      placeholder=""
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormGroup>
                    <TextField
                      label="Área de atuação"
                      id="areaAtuacao"
                      name="areaAtuacao"
                      value={values.areaAtuacao}
                      placeholder=""
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormGroup>
                    <TextField
                      label="Área do estudante"
                      id="areaEstudante"
                      name="areaEstudante"
                      value={values.areaEstudante}
                      placeholder=""
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormGroup>
                    <TextField
                      label="Local"
                      id="local"
                      name="local"
                      value={values.local}
                      placeholder=""
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormGroup>
                    <TextField
                      label="Descrição"
                      id="descricao"
                      name="descricao"
                      value={values.descricao}
                      placeholder=""
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormGroup>
                    <TextField
                      label="É necessário"
                      id="necessario"
                      name="necessario"
                      value={values.necessario}
                      placeholder=""
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                      multiline
                      maxRows={4}
                      minRows={4}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormGroup>
                    <TextField
                      label="Benefícios"
                      id="beneficios"
                      name="beneficios"
                      value={values.beneficios}
                      placeholder=""
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                      multiline
                      maxRows={4}
                      minRows={4}
                    />
                  </FormGroup>
                </Grid>

                <Grid
                  item
                  xs={6}
                  md={4}
                >
                  <Button
                    size="large"
                    type="submit"
                    fullWidth
                    startIcon={<ThumbUpIcon />}
                    variant="outlined"
                  >
                    TENHO INTERESSE
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={4}
                >
                  <Button
                    size="large"
                    type="submit"
                    fullWidth
                    startIcon={<CancelIcon />}
                    variant="outlined"
                  >
                    RETIRAR INTERESSE
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={4}
                >
                  <Button
                    size="large"
                    type="submit"
                    fullWidth
                    variant="outlined"
                  >
                    CANCELAR
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                >
                  <Button
                    size="large"
                    type="submit"
                    fullWidth
                    startIcon={<ReplyIcon />}
                    variant="outlined"
                  >
                    ENCAMINHAR CURRICULO
                  </Button>
                </Grid>

                <Grid
                  item
                  xs={6}
                  md={6}
                >
                  <Button
                    size="large"
                    type="submit"
                    fullWidth
                    startIcon={<OpenInNewIcon />}
                    variant="outlined"
                  >
                    ACESSO EXTERNO
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

FormCidadaoVisualizarVaga.propTypes = {
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  confirmacaoModal: PropTypes.bool,
  setConfirmacaoModal: PropTypes.func,
  setErroModal: PropTypes.func,
  erroModal: PropTypes.bool,
  erroDoUsuarioModal: PropTypes.bool,
  values: PropTypes.object,
  readOnly: PropTypes.bool,
};

export default FormCidadaoVisualizarVaga;
