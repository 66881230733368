import { GerarExcelAutomatizado } from "./GerarExcelAutomatizado";

const termos = {
  nome: "Nome",
  dataNascimento: "Data de Nascimento",
  rg: "RG",
  cpf: "CPF",
  cep: "CEP",
  genero: "Gênero",
  escolaridade: "Escolaridade",
  uf: "UF",
  codigoIbge: "Código IBGE",
  municipio: "Município",
  bairro: "Bairro/Distrito",
  endereco: "Endereço",
  numero: "Número",
  complemento: "Complemento",
  telefone: "Telefone de Contato",
  email: "E-mail",
  tipoQuestionarioRespondido: "Tipo de Questionário Respondido",
  tipo: "Tipo de Questionário",
  dataResposta: "Data da Resposta",
  // variaveisQuestionarioQueroSerEmpreendedor
  qse_proprio_negocio: "Você deseja abrir seu próprio negócio?",
  qse_primeira_vez: "É a primeira vez que você vai abrir o seu próprio negócio?",
  qse_motivo_abertura: "Qual(is) o(s) motivo(s) que faz você desejar abrir seu próprio negócio?",
  qse_setor_negocio: "O seu futuro negócio está mais próximo de qual dos setores abaixo?",
  qse_situacao_negocio: "O seu futuro negócio será em qual situação?",
  qse_participou_curso: "Você já participou de algum curso sobre empreendedorismo, microcrédito, educação financeira?",
  qse_curso: "Curso",
  qse_instituicao: "Instituição",
  qse_participar_curso:
    "Você gostaria de participar de algum curso sobre empreendedorismo, microcrédito, educação financeira, de outros assuntos para melhor gerir o seu negócio?",
  qse_motivo_participar: "Por que motivo? 1",
  qse_receber_orientacao: "Você gostaria de receber orientação sobre como Administrar seu negócio?",
  qse_motivo_orientacao: "Por que motivo? 2",
  qse_necessidade_financiamento: "Você tem necessidade de um financiamento/empréstimo de microcrédito?",
  qse_motivo_financiamento: "Por que motivo? 3",
  qse_conhece_aval_solidario:
    "Você conhece os empréstimos para grupos de microempreendedores que utilizam o chamado aval solidário como garantia de pagamento do empréstimo?",
  // variaveisQuestionarioJaSouEmpreendedor
  jse_atividade_negocio: "Qual a atividade do seu negócio?",
  jse_variar_linha_atuacao: "Você tem interesse em variar a linha de atuação do seu negócio?",
  jse_motivo_abertura: "Qual(is) o(s) motivo(s) que faz você desejar abrir seu próprio negócio?",
  jse_fontes_recursos: "Qual(is) a(s) fonte(s) de recursos para manutenção do seu negócio?",
  jse_separa_compras: "Você separa as compras do seu negócio das que você faz para sua família?",
  jse_tempo_negocio: "Há quanto tempo você tem esse negócio?",
  jse_compra_produtos_servicos: "Como você faz a compra de produtos e serviços para seu negócio?",
  jse_propaganda_negocio: "Como você faz a propaganda do seu negócio?",
  jse_razao_consumidor_comprar: "Por que o consumidor deveria comprar seu produto ou serviço?",
  jse_necessidades_crescimento: "O que o seu negócio precisa para crescer?",
  jse_pensou_desistir: "Já pensou em desistir do negócio?",
  jse_financiamento_necessario: "Você já precisou de financiamento/empréstimo para melhorar seu negócio?",
  jse_funcionarios_negocio: "Você tem algum funcionário trabalhando no seu negócio?",
  jse_visao_futura: "Como você vê seu negócio daqui a 3 anos?",
  jse_calculo_custo_produto: "Você calcula o custo fixo e variável por unidade do produto/serviço prestado?",
  jse_reducao_custos: "Você faz algo para reduzir custos/despesas?",
  jse_producao_mensal: "Você sabe dizer quanto você produz por mês?",
  jse_situacao_negocio: "Qual a situação do seu negócio?",
  jse_participou_curso: "Você já participou de algum curso sobre empreendedorismo, microcrédito, educação financeira?",
  jse_curso: "Curso",
  jse_instituicao: "Instituição",
  jse_participar_curso:
    "Você gostaria de participar de algum curso sobre empreendedorismo, microcrédito, educação financeira, de outros assuntos para melhor gerir o seu negócio?",
  jse_motivo_participar: "Por que motivo? 1",
  jse_necessidade_financiamento: "Você tem necessidade de um financiamento/empréstimo de microcrédito?",
  jse_motivo_financiamento: "Por que motivo? 2",
  jse_receber_orientacao: "Você gostaria de receber orientação sobre como Administrar seu negócio?",
  jse_motivo_orientacao: "Por que motivo? 3",
  jse_conhece_aval_solidario:
    "Você conhece os empréstimos para grupos de microempreendedores que utilizam o chamado aval solidário como garantia de pagamento do empréstimo?",
  jse_motivo_proprio_negocio: "Por que você tem o seu próprio negócio?",
  jse_local_funcionamento: "Em que local funciona o seu negócio?",
  // variaveisSituacaoContato
  stc_retorno_contato_ativo: "Retorno de Contato Ativo",
  stc_dataInicioNegocio: "Data de Início de Negócio",
  stc_contato_data: "Data",
  stc_contato_parceiro: "Parceiro",
  stc_emprestimo_data: "Data 2",
  stc_emprestimo_valor: "Valor",
  stc_emprestimo_parceiro: "Parceiro 2",
};

// 135 – Admin – Gerar Excel 1º tipo – planilha padrão
const headerConfigTipo1 = {
  Nome: [],
  "Data de Nascimento": [],
  RG: [],
  CPF: [],
  Gênero: [],
  Escolaridade: [],
  CEP: [],
  UF: [],
  "Localidade/Município": ["Código IBGE", "Município"],
  "Bairro/Distrito": [],
  Endereço: [],
  Número: [],
  Complemento: [],
  "Telefone de Contato": [],
  "E-mail": [],
  "Tipo de Questionário": [],
  "Data da Resposta": [],
};

// 136 – Admin – Gerar Excel 2º tipo – planilha padrão + Questionário Quero Ser Empreendedor
const variaveisQuestionarioQueroSerEmpreendedor = {
  "Variáveis do Questionário Quero Ser Empreendedor": {
    "Você deseja abrir seu próprio negócio?": [],
    "É a primeira vez que você vai abrir o seu próprio negócio?": [],
    "Qual(is) o(s) motivo(s) que faz você desejar abrir seu próprio negócio?": [],
    "O seu futuro negócio está mais próximo de qual dos setores abaixo?": [],
    "O seu futuro negócio será em qual situação?": [],
    "Você já participou de algum curso sobre empreendedorismo, microcrédito, educação financeira?": [],
    "Qual(is) curso(s) e instituição realizadora?": ["Curso", "Instituição"],
    "Você gostaria de participar de algum curso sobre empreendedorismo, microcrédito, educação financeira, de outros assuntos para melhor gerir o seu negócio?":
      [],
    "Por que motivo? 1": [],
    "Você gostaria de receber orientação sobre como Administrar seu negócio?": [],
    "Por que motivo? 2": [],
    "Você tem necessidade de um financiamento/empréstimo de microcrédito?": [],
    "Por que motivo? 3": [],
    "Você conhece os empréstimos para grupos de microempreendedores que utilizam o chamado aval solidário como garantia de pagamento do empréstimo?":
      [],
  },
};

const headerConfigTipo2 = {
  ...headerConfigTipo1,
  ...variaveisQuestionarioQueroSerEmpreendedor,
};

// 137 – Admin – Gerar Excel 3º tipo – planilha padrão + Questionário Já Sou Empreendedor
const variaveisQuestionarioJaSouEmpreendedor = {
  "Variáveis do Questionário Já Sou Empreendedor": {
    "Qual a atividade do seu negócio?": [],
    "Você tem interesse em variar a linha de atuação do seu negócio?": [],
    "Qual(is) o(s) motivo(s) que faz você desejar abrir seu próprio negócio?": [],
    "Qual(is) a(s) fonte(s) de recursos para manutenção do seu negócio?": [],
    "Você separa as compras do seu negócio das que você faz para sua família?": [],
    "Há quanto tempo você tem esse negócio?": [],
    "Como você faz a compra de produtos e serviços para seu negócio?": [],
    "Como você faz a propaganda do seu negócio?": [],
    "Por que o consumidor deveria comprar seu produto ou serviço?": [],
    "O que o seu negócio precisa para crescer?": [],
    "Já pensou em desistir do negócio?": [],
    "Você já precisou de financiamento/empréstimo para melhorar seu negócio?": [],
    "Você tem algum funcionário trabalhando no seu negócio?": [],
    "Como você vê seu negócio daqui a 3 anos?": [],
    "Você calcula o custo fixo e variável por unidade do produto/serviço prestado?": [],
    "Você faz algo para reduzir custos/despesas?": [],
    "Você sabe dizer quanto você produz por mês?": [],
    "Qual a situação do seu negócio?": [],
    "Você já participou de algum curso sobre empreendedorismo, microcrédito, educação financeira?": [],
    "Qual(is) curso(s) e instituição realizadora?": ["Curso", "Instituição"],
    "Você gostaria de participar de algum curso sobre empreendedorismo, microcrédito, educação financeira, de outros assuntos para melhor gerir o seu negócio?":
      [],
    "Por que motivo? 1": [],
    "Você tem necessidade de um financiamento/empréstimo de microcrédito?": [],
    "Por que motivo? 2": [],
    "Você gostaria de receber orientação sobre como Administrar seu negócio?": [],
    "Por que motivo? 3": [],
    "Você conhece os empréstimos para grupos de microempreendedores que utilizam o chamado aval solidário como garantia de pagamento do empréstimo?":
      [],
    "Por que você tem o seu próprio negócio?": [],
    "Em que local funciona o seu negócio?": [],
  },
};

const headerConfigTipo3 = {
  ...headerConfigTipo1,
  ...variaveisQuestionarioJaSouEmpreendedor,
};

// 138 – Admin – Gerar Excel 4º tipo – planilha padrão + Situação do contato
const variaveisSituacaoContato = {
  "Situação do Contato": {
    "Retorno de Contato Ativo": [],
    "Data de Início de Negócio": [],
    "Contato Recebido": ["Data", "Parceiro"],
    "Empréstimo Acessado": ["Data 2", "Valor", "Parceiro 2"],
  },
};
const headerConfigTipo4 = {
  ...headerConfigTipo1,
  ...variaveisSituacaoContato,
};

// 139 – Admin – Gerar Excel 5º tipo – planilha padrão + situação de contato + Questionário Quero Ser Empreendedor
const headerConfigTipo5 = {
  ...headerConfigTipo1,
  ...variaveisSituacaoContato,
  ...variaveisQuestionarioQueroSerEmpreendedor,
};

// 140 – Admin – Gerar Excel 6º tipo – planilha padrão + situação de contato + Questionário Já Sou Empreendedor
const headerConfigTipo6 = {
  ...headerConfigTipo1,
  ...variaveisSituacaoContato,
  ...variaveisQuestionarioJaSouEmpreendedor,
};

export const ExcelTipo1 = dados => {
  // console.log("Chamando ExcelTipo1");
  return GerarExcelAutomatizado(dados, termos, headerConfigTipo1, "1a Planilha");
};

export const ExcelTipo2 = dados => {
  // console.log("Chamando ExcelTipo2");
  return GerarExcelAutomatizado(dados, termos, headerConfigTipo2, "2a Planilha");
};

export const ExcelTipo3 = dados => {
  // console.log("Chamando ExcelTipo3");
  return GerarExcelAutomatizado(dados, termos, headerConfigTipo3, "3a Planilha");
};

export const ExcelTipo4 = dados => {
  // console.log("Chamando ExcelTipo4");
  return GerarExcelAutomatizado(dados, termos, headerConfigTipo4, "4a Planilha");
};

export const ExcelTipo5 = dados => {
  // console.log("Chamando ExcelTipo5");
  return GerarExcelAutomatizado(dados, termos, headerConfigTipo5, "5a Planilha");
};

export const ExcelTipo6 = dados => {
  // console.log("Chamando ExcelTipo6");
  return GerarExcelAutomatizado(dados, termos, headerConfigTipo6, "6a Planilha");
};
