import React from "react";
import { useParams } from "react-router-dom";

import { Container } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import AdminParceiroFormInformacoesComplementares from "../../components/formularios/AdminParceiroFormInformacoesComplementares";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import parceiroService from "../../services/parceiro.service";

const PageVisualizarInformacoesComplementares = () => {
  let { id } = useParams();

  const {
    isPending,
    isError,
    data: initialData,
    error,
  } = useQuery({
    queryKey: ["empresaComplementar", id],
    queryFn: ({ queryKey }) => {
      const [, id] = queryKey;
      return parceiroService.getEmpresaComplementar(id);
    },
    enabled: !!id,
  });

  if (isError) {
    console.log("Erro de carregamento:", error);
  }

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/", text: "Parceiro" }]}
        currentPage={"Informações Complementares"}
      />

      <TitlePage title={"Informações Complementares"} />

      {isPending ? (
        <LoadingComponent
          isLoading={isPending}
          fullScreen={false}
        />
      ) : (
        <AdminParceiroFormInformacoesComplementares
          values={initialData}
          visualizacao={true}
        />
      )}

      {isError && <ErroCarregamentoModal isShow={true} />}
    </Container>
  );
};

export default PageVisualizarInformacoesComplementares;
