import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Container } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import AdminTrilha from "../../components/formularios/AdminTrilha";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import adminService from "../../services/admin.service";
import constantsService from "../../services/constants.service";

const PageEditarTrilha = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  // TODO: remover esse default
  id = id || "1";

  // Conexão com o back-end
  const {
    isPending,
    isError,
    data: initialData,
    error,
  } = useQuery({
    queryKey: ["trilhaCursoEditar", id],
    queryFn: ({ queryKey }) => {
      const [, id] = queryKey;
      return adminService.getTrilhaCurso(id);
    },
    enabled: !!id,
  });

  const handleSubmit = async values => {
    let files = values.anexo;

    try {
      if (files?.length > 0) {
        // console.log("values.anexo: ", files);
        const arquivo = {
          nome_anexo: files[0].name.split(".")[0],
          extensao_anexo: files[0].name.split(".")[1],
          file: files[0].file,
        };

        // console.log("Arquivos: ", arquivos);
        adminService
          .postInsereArquivoTrilha(arquivo) // Adiciona o arquivo
          .then(resposta => {
            console.log("Sucesso ao inserir arquivo:", resposta.message);

            const trilhaPayload = {
              nome_trilha: values.nome,
              descricao_trilha: values.descricao,
              anexo: resposta.id,
            };

            adminService
              .atualizarTrilhaCurso(trilhaPayload, id)
              .then(resposta => {
                console.log("Sucesso ao inserir trilha: ", resposta);
                if (values.situacao != "none") {
                  adminService
                    .postAtualizarStatusTrilha({
                      trilha_id: id,
                      status: constantsService.getCodeByStatus(values.situacao),
                      mensagem: values.descricao,
                    })
                    .then(resposta => {
                      console.log("Sucesso ao atualizar o status da trilha: ", resposta);
                    })
                    .catch(erro => {
                      console.error("Erro ao atualizar status da trilha", erro);
                    });
                }
                navigate("/trilhas/listar");
              })
              .catch(erro => {
                console.error("Erro ao inserir trilha", erro);
              });
          })
          .catch(erro => {
            console.error("Erro ao inserir arquivo", erro);
          });
      } else {
        console.log("Insira um arquivo");
      }
    } catch (erro) {
      console.error("Erro ao inserir trilha", erro);
    }
  };

  if (isError) {
    console.log("Erro de carregamento:", error);
  }

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "", text: "Trilhas" },
          { href: "/trilhas/listar", text: "Listar" },
        ]}
        currentPage="Editar"
      />

      <TitlePage title={"Editar"} />

      {isPending ? (
        <LoadingComponent
          isLoading={isPending}
          fullScreen={false}
        />
      ) : (
        <AdminTrilha
          initialValues={initialData}
          onSubmit={handleSubmit}
          editar={true}
        />
      )}

      {isError && <ErroCarregamentoModal isShow={true} />}
    </Container>
  );
};

export default PageEditarTrilha;
