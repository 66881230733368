import React, { useState } from "react";

import SaveIcon from "@mui/icons-material/Save";
import { Box, Button, Card, Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types";

import FormCursosTecnicoComplementar from "./cidadaoEditarCurriculo/FormCursosTecnicoComplementar";
import FormDadosPessoais from "./cidadaoEditarCurriculo/FormDadosPessoais";
import FormEscolaridade from "./cidadaoEditarCurriculo/FormEscolaridade";
import FormExperiencias from "./cidadaoEditarCurriculo/FormExperiencias";
import FormInteresse from "./cidadaoEditarCurriculo/FormInteresse";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CidadaoTabs() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNext = () => {
    if (value < 4) {
      setValue(prevValue => prevValue + 1);
    }
  };

  const handlePrevious = () => {
    if (value > 0) {
      setValue(prevValue => prevValue - 1);
    }
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
          }}
          style={{ transition: "1s" }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
            >
              <Tab
                label="1. Dados pessoais"
                {...a11yProps(0)}
                sx={{
                  color: value === 0 ? "primary.main" : "text.primary",
                  fontFamily: "Rawline Light",
                }}
              />
              <Tab
                label="2. Escolaridade"
                {...a11yProps(1)}
                sx={{
                  color: value === 1 ? "primary.main" : "text.primary",
                  fontFamily: "Rawline Light",
                }}
              />
              <Tab
                label="3. Cursos"
                {...a11yProps(2)}
                sx={{
                  color: value === 2 ? "primary.main" : "text.primary",
                  fontFamily: "Rawline Light",
                }}
              />
              <Tab
                label="Experiência"
                {...a11yProps(3)}
                sx={{
                  color: value === 3 ? "primary.main" : "text.primary",
                  fontFamily: "Rawline Light",
                }}
              />
              <Tab
                label="Interesse"
                {...a11yProps(4)}
                sx={{
                  color: value === 4 ? "primary.main" : "text.primary",
                  fontFamily: "Rawline Light",
                }}
              />
            </Tabs>
          </Box>
          <CustomTabPanel
            value={value}
            index={0}
          >
            <FormDadosPessoais />
          </CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={1}
          >
            <FormEscolaridade />
          </CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={2}
          >
            <FormCursosTecnicoComplementar />
          </CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={3}
          >
            <FormExperiencias />
          </CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={4}
          >
            <FormInteresse />
          </CustomTabPanel>
        </Card>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2, gap: "10px 10px" }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handlePrevious}
          disabled={value === 0}
        >
          ANTERIOR
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleNext}
          disabled={value === 4}
        >
          PRÓXIMO
        </Button>
        <Button
          variant="contained"
          startIcon={<SaveIcon />}
        >
          SALVAR
        </Button>
      </Box>
    </>
  );
}
