import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import InvertColorsIcon from "@mui/icons-material/InvertColors";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ListItemButton from "@mui/material/ListItemButton";
import Stack from "@mui/material/Stack";
import { alpha, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

import { useAuth } from "../../contexts/AuthContext";
import { usePathname } from "../../hooks/use-pathname";
import { useResponsive } from "../../hooks/use-responsive";
import { themeProvider } from "../../theme/index";
import Scrollbar from "../scrollbar";
import { HEADER, NAV } from "./config-layout";
import adminNavConfig from "./config-navigation-admin-menu-lateral";
import cidadaoNavConfig from "./config-navigation-cidadao-menu-lateral";
import defaultNavConfig from "./config-navigation-menu-lateral";
import parceiroNavConfig from "./config-navigation-parceiro-menu-lateral";
import RouterLink from "./router-link";
// ----------------------------------------------------------------------

export default function Nav({ openNav, onCloseNav }) {
  const pathname = usePathname();
  const lgUp = useResponsive("up", "lg");

  const theme = useTheme();

  const { toggleMode } = themeProvider();

  const user = JSON.parse(localStorage.getItem("user"));
  const perfilUser = user?.ds_perfil_sso?.substring(2, user.ds_perfil_sso.length - 2);

  const { logout, token } = useAuth();

  function logoutFunction() {
    logout(token);
  }
  const [renderNavContent, setRenderNavContent] = useState([]);

  useEffect(() => {
    switch (perfilUser) {
      case "Servidor":
        setRenderNavContent(adminNavConfig);
        break;
      case "Parceiro":
        setRenderNavContent(parceiroNavConfig);
        break;
      case "Trabalhador":
        setRenderNavContent(cidadaoNavConfig);
        break;
      default:
        setRenderNavContent(defaultNavConfig);
        break;
    }
  }, [perfilUser]);

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line
    // react-hooks/exhaustive-deps
  }, [pathname]);

  const renderMenu = (
    <Stack component="nav">
      {renderNavContent.map(item => (
        <NavItem
          key={item.title}
          item={item}
        />
      ))}
    </Stack>
  );

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        marginTop: lgUp ? `${HEADER.H_DESKTOP}px` : "10px",
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      {renderMenu}

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.WIDTH },
        bgcolor: theme.palette.background.default,
        transition: "1s",
      }}
    >
      {lgUp ? (
        <Box
          sx={{
            height: 1,
            position: "fixed",
            width: NAV.WIDTH,
            borderRight: theme.palette.border.layout,
            boxShadow: "1px 0px 10px 1px #0000001A",
            paddingBottom: "40px",
            transition: "1s",
            bgColor: theme.palette.background.default,
          }}
        >
          {renderContent}
        </Box>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.WIDTH,
            },
          }}
        >
          {!lgUp && (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ p: 2, bgColor: theme.palette.background.default }}
            >
              <IconButton
                component={Link}
                to="/profile"
                sx={{ ml: 1, color: theme => alpha(theme.palette.primary.main, 1) }}
              >
                <AccountCircleIcon
                  sx={{ color: theme => alpha(theme.palette.primary.main, 1) }}
                  style={{ transition: "1s" }}
                />
              </IconButton>

              <Stack>
                <a style={{ color: theme.palette.primary.main, transition: "1s", fontFamily: "Rawline Regular", fontSize: "14px" }}>
                  Bem vindo
                </a>
                <a style={{ color: theme.palette.primary.main, transition: "1s", fontFamily: "Rawline Bold", fontSize: "14px" }}>
                  {user?.no_usuario || "..."}
                </a>
              </Stack>
            </Stack>
          )}

          {renderContent}

          {!lgUp && (
            <Stack
              direction="column"
              alignItems="flex-start"
              spacing={0}
              sx={{ p: 2 }}
            >
              <IconButton
                onClick={toggleMode}
                sx={{ ml: 1, color: theme => alpha(theme.palette.text.disabled, 1) }}
              >
                <InvertColorsIcon
                  sx={{ color: theme => alpha(theme.palette.text.disabled, 1), mr: 1 }}
                  style={{ transition: "1s" }}
                />
                <a style={{ color: theme.palette.text.disabled, transition: "1s", fontFamily: "Rawline Bold", fontSize: "14px" }}>
                  Alto contraste
                </a>
              </IconButton>

              <IconButton
                onClick={logoutFunction}
                sx={{ ml: 1, color: theme => alpha(theme.palette.text.disabled, 1) }}
              >
                <ExitToAppIcon
                  sx={{ color: theme => alpha(theme.palette.text.disabled, 1), mr: 1 }}
                  style={{ transition: "1s" }}
                />
                <a style={{ color: theme.palette.text.disabled, transition: "1s", fontFamily: "Rawline Bold", fontSize: "14px" }}>
                  Sair da plataforma
                </a>
              </IconButton>
            </Stack>
          )}
        </Drawer>
      )}
    </Box>
  );
}

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

// ----------------------------------------------------------------------

function NavItem({ item }) {
  const pathname = usePathname();
  const theme = useTheme();
  let active = "";
  let objRoute = {};
  if (item.subTitles) {
    for (let subTitle in item.subTitles) {
      if (item.subTitles[subTitle] === pathname) {
        active = subTitle;
        objRoute = item;
        break;
      }
    }
  } else {
    active = item.path === pathname;
  }

  return (
    <ListItemButton
      sx={{
        typography: "body2",
        color: theme.palette.primary.main,
        textTransform: "capitalize",
        fontWeight: "fontWeightSmall",
        transition: "1s",
        p: 0,
        borderRadius: "0px",
        ...(!item.subTitles && {
          "&:hover": {
            bgcolor: theme => alpha(theme.palette.primary.dark, 0.6),
            color: theme.palette.text.inverter,
          },
          ...(active && {
            color: theme.palette.text.inverter,
            fontWeight: "fontWeightSemiBold",
            bgcolor: theme => alpha(theme.palette.primary.dark, 1),
            "&:hover": {
              bgcolor: theme => alpha(theme.palette.primary.dark, 0.6),
            },
          }),
        }),
      }}
    >
      {item.subTitles ? (
        <React.Fragment key={item.title}>
          <Accordion
            style={{ borderRadius: "0px" }}
            sx={{
              typography: "body2",
              color: theme.palette.mode == "dark" ? theme.palette.text.primary : theme.palette.primary.main,
              borderBottom: "1px solid #d3d3d3",
              fontFamily: "Rawline Regular",
              textTransform: "capitalize",
              fontWeight: "fontWeightSmall",
              transition: "1s",
              bgcolor: "transparent",
              width: "100%",
              ...(objRoute.title == item.title && {
                color: theme.palette.text.inverter,
                fontFamily: "Rawline Bold",
                bgcolor: theme => alpha(theme.palette.primary.dark, 1),
                border: "1px solid " + theme.palette.primary.dark,
              }),
            }}
          >
            <AccordionSummary
              expandIcon={
                <KeyboardArrowDownIcon
                  style={{
                    color: objRoute.title == item.title ? theme.palette.text.inverter : theme.palette.text.primary,
                  }}
                />
              }
              sx={{ bgcolor: "transparent", p: 3, width: "100%", margin: 0, height: 32 }}
            >
              {item.title}
            </AccordionSummary>
            <AccordionDetails
              sx={{
                bgcolor: "transparent",
                p: "0",
                width: "100%",
                marginTop: "-15px",
                marginBottom: "10px",
              }}
            >
              {Object.entries(item.subTitles).map(([title]) => (
                <div key={"div_" + title}>
                  <Box
                    component={RouterLink}
                    href={item.subTitles[title]}
                    sx={{
                      textDecoration: "none",
                      p: 4,
                      display: "flex",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      alignItems: "center",
                      width: "100%",
                      transition: "1s",
                      fontFamily: "Rawline Regular",
                      "&:hover": {
                        bgcolor: theme => alpha(theme.palette.primary.dark, 0.6),
                        color: theme.palette.text.inverter,
                      },
                      color: theme.palette.text.primary,
                      ...(objRoute.title == item.title && {
                        color: theme.palette.text.inverter,
                      }),
                      ...(active == title && {
                        color: theme.palette.primary.dark,
                        bgcolor: theme.palette.background.default,
                      }),
                    }}
                  >
                    {title}
                  </Box>
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
        </React.Fragment>
      ) : (
        <Box
          component="span"
          sx={{ justifyContent: "space-between", display: "flex", width: "100%", p: 1 }}
        >
          {item.title}
        </Box>
      )}
    </ListItemButton>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};
