const cidadaoNavConfig = [
  {
    title: "Página Inicial",
    path: "/cidadao",
    color: "default",
    subTitles: {
      home: "/",
      perfil: "/profile",
      "editar perfil": "/editar-perfil",
    },
  },
  {
    title: "Vagas de trabalho",
    path: "/cidadao",
    color: "default",
    subTitles: {
      Curriculo: "/vagas-de-trabalho/curriculo",
      "Editar curriculo": "/vagas-de-trabalho/curriculo/editar",
      "Visualizar curriculo": "/vagas-de-trabalho/visualizar-curriculo",
      "Listar vagas": "/vagas-de-trabalho/listar-vagas",
    },
  },
  {
    title: "Cursos",
    path: "/cidadao",
    color: "default",
    subTitles: {
      "Listar cursos": "/cursos/listar-cursos",
      "Trilha de cursos": "/cursos/trilhas-cursos",
    },
  },
  {
    title: "Empreendedorismo",
    path: "/cidadao",
    color: "default",
    subTitles: {
      "Acesso Microcrédito (HU 103)": "/empreendedorismo/microcredito/acesso-credito-mpo",
      "Microcrédito - MPO (HU 104)": "/empreendedorismo/microcredito/credito-mpo",
      "Acesso Microcrédito Aprovado (HU 105)": "/empreendedorismo/microcredito/acesso-credito-mpo/aprovado",
      "Questionário quero ser empreendedor (HU 107)": "/empreendedorismo/microcredito/quero-ser-empreendedor",
      "Questionário ja sou empreendedor (HU 108)": "/empreendedorismo/microcredito/ja-sou-empreendedor",
      "Perguntas frequentes (HU 109)": "/empreendedorismo/microcredito/perguntas-frequentes",
      "Informar situação de contato": "/empreendedorismo/microcredito/informar-situacao-contato",
      "Mobilização de parceiros": "/empreendedorismo/mobilizacao/mobilizacao-parceiros-operadores",
    },
  },
  {
    title: "[DEV] Components",
    path: "/components",
    color: "default",
    subTitles: {
      Components: "/components",
      "Default Table": "/components-default-table",
      "Dynamic Form": "/components-form",
    },
  },
];

export default cidadaoNavConfig;
