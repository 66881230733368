import React from "react";
import { useNavigate } from "react-router-dom";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import PropTypes from "prop-types";

const TitlePage = ({ title, mostrarVoltar = true }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Navegar para a página anterior
  };

  return (
    <div style={{ margin: "24px 0px 24px 0px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <Typography variant="h2">{title}</Typography>
      {mostrarVoltar && (
        <div
          style={{ cursor: "pointer", display: "flex", flexDirection: "row", alignItems: "center" }}
          onClick={handleGoBack}
        >
          <ArrowBackIosNewIcon
            fontSize="14px"
            sx={{ color: theme.palette.primary.main, marginTop: "3px" }}
          />
          <Typography
            variant="h5"
            sx={{ fontFamily: "Rawline Regular", color: theme.palette.primary.main, fontSize: "14px" }}
          >
            voltar
          </Typography>
        </div>
      )}
    </div>
  );
};

TitlePage.propTypes = {
  title: PropTypes.string,
  mostrarVoltar: PropTypes.bool,
};

export default TitlePage;
