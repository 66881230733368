import React from "react";

import { Box, Card, Container, Stack, Typography } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import { tabelaCursosPendentes } from "../../components/formularios/dadosMockados";
import DefaultTable from "../../components/table/DefaultTable";
import TitlePage from "../../components/title-page/TitlePage";

const tabelaColunas = [
  { field: "data", headerName: "Data" },
  { field: "momento", headerName: "Momento" },
  { field: "nome", headerName: "Nome do Arquivo" },
];

const PageListarAnexosCurso = () => {
  const linkRetorno = JSON.parse(localStorage.getItem("linkRetorno")) || "/listar-cursos-aprovados";
  const cursoID = JSON.parse(localStorage.getItem("cursosID")) || "1";
  const rows = tabelaCursosPendentes.find(curso => curso.id == cursoID).anexos;
  const termos = {
    "/listar-cursos-aprovados": "Cursos Aprovados",
    "/listar-cursos-reprovados": "Cursos Reprovados",
  };
  const getTabelaActions = () => [
    {
      title: "Download",
      href: "",
      icon: "download",
    },
  ];

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: linkRetorno, text: termos[linkRetorno] }]}
        currentPage="Listar Anexos"
      />

      <TitlePage title={"Listar Anexos do Curso"} />

      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
          }}
        >
          <Typography variant="subtitle2">Lista de documentos anexados</Typography>
          {/* Tabela */}
          <Box sx={{ flexGrow: 1, mt: "20px" }}>
            <DefaultTable
              rows={rows}
              notFoundText={"Não foi localizado Cursos na situação reprovado!"}
              columns={tabelaColunas}
              actionButtons={getTabelaActions}
            ></DefaultTable>
          </Box>
        </Card>
      </Stack>
    </Container>
  );
};

export default PageListarAnexosCurso;
