import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import InfoIcon from "@mui/icons-material/Info";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Link,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";

import optionsService from "../../../services/options.service.js";
import ConfirmacaoModal from "../../modals/ConfirmacaoModal";
import ErroDoUsuarioModal from "../../modals/ErroDoUsuarioModal.js";
import ErroModal from "../../modals/ErroModal.js";
import FinanceiroModal from "../../modals/FinanceiroModal";
import { SelectAtuacaoParceiro } from "../fields/SelectAtuacaoParceiro.js";
import { SelectCidade } from "../fields/SelectCidade.js";
import { SelectNaturezaJuridica } from "../fields/SelectNaturezaJuridica.js";
import { SelectUF } from "../fields/SelectUF.js";
import FormMultipleInsert from "../FormMultipleInsert.js";
import { formatCEP, formatCNPJ, formatCPF, formatSite, formatTelefone, validarCNPJ } from "../utils.js";
import FormInscricaoParceiroUtils from "./FormInscricaoParceiroUtils.js";

const FormInscricaoParceiro = ({
  loading,
  handleSubmit,
  confirmacaoModal,
  setConfirmacaoModal,
  conteudoConfirmacaoModal,
  erroModal,
  setErroModal,
  erroDoUsuarioModal,
  setErroDoUsuarioModal,
  initialValues,
  visualizacao = false,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [financeiroModal, setFinanceiroModal] = useState(false);
  const [financeiro, setFinanceiro] = useState(false);

  const [atuacoes, setAtuacoes] = useState([]);
  const [ufs, setUfs] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [cidadesRepresentante, setCidadesRepresentante] = useState([]);

  const [selectedUf, setSelectedUf] = useState("");
  const [selectedCidade, setSelectedCidade] = useState("");
  const [selectedUfRepresentante, setSelectedUfRepresentante] = useState("");
  const [selectedCidadeRepresentante, setSelectedCidadeRepresentante] = useState("");

  const [temDadosRepresentante, setTemDadosRepresentante] = useState(false);

  // UseEffect para obter os valores iniciais
  useEffect(() => {
    setFinanceiro(initialValues.checkFinanceiro);
    setCidades(optionsService.cidadesJSON.filter(cidade => cidade.estado === initialValues.uf));
    setCidadesRepresentante(optionsService.cidadesJSON.filter(cidade => cidade.estado === initialValues.ufRepresentante));
    setSelectedUf(initialValues.uf);
    setSelectedCidade(initialValues.cidade);
    setSelectedUfRepresentante(initialValues.ufRepresentante);
    setSelectedCidadeRepresentante(initialValues.cidadeRepresentante);
    setTemDadosRepresentante(
      !!(
        initialValues.nomeRepresentante &&
        initialValues.cpfRepresentante &&
        initialValues.telefoneRepresentante &&
        initialValues.ufRepresentante &&
        initialValues.cidadeRepresentante
      ),
    );
  }, [initialValues]);

  // UseEffect para obter as UFs
  useEffect(() => {
    const fetchUfs = async () => {
      await optionsService
        .getAllUFs()
        .then(ufsData => {
          setUfs(ufsData);
        })
        .catch(error => {
          console.error("Erro ao obter UFs:", error);
        });
    };
    fetchUfs();
  }, []);

  const fetchCidadesByUf = async (ufSigla, setCidadesFunction) => {
    try {
      const cidadesData = await optionsService.getCidadesFromUF(ufSigla);
      setCidadesFunction(cidadesData);
    } catch (error) {
      console.error("Erro ao obter cidades:", error);
    }
  };

  // UseEffect para obter as Atuações
  useEffect(() => {
    const fetchAtuacoes = async () => {
      await optionsService
        .getAreasAtuacao()
        .then(atuacoesData => {
          setAtuacoes(atuacoesData);
        })
        .catch(error => {
          console.error("Erro ao obter Atuações:", error);
        });
    };
    fetchAtuacoes();
  }, []);

  const handleFinanceiro = () => {
    setFinanceiroModal(true);
  };

  const handleFinanceiroClose = (event, setFieldValue) => {
    const { name } = event.target;
    setFieldValue(name, false);
    setFinanceiro(false);
    setFinanceiroModal(false);
  };

  const handleFinanceiroContinuar = (event, setFieldValue) => {
    const { name } = event.target;
    setFieldValue(name, true);
    setFinanceiro(true);
    setFinanceiroModal(false);
  };

  const handleSelectUf = (event, setFieldValue) => {
    const { name, value } = event.target;
    setFieldValue(name, value);

    if (name === "uf") {
      setSelectedUf(value);
      setSelectedCidade("");
      setFieldValue("cidade", "");
      fetchCidadesByUf(event.target.value, setCidades);
    } else if (name === "ufRepresentante") {
      setSelectedUfRepresentante(event.target.value);
      setSelectedCidadeRepresentante("");
      setFieldValue("cidadeRepresentante", "");
      fetchCidadesByUf(event.target.value, setCidadesRepresentante);
    }
  };

  const handleSelectCidade = (event, setFieldValue) => {
    const { name, value } = event.target;
    setFieldValue(name, value);

    if (name === "cidade") {
      setSelectedCidade(event.target.value);
    } else if (name === "cidadeRepresentante") {
      setSelectedCidadeRepresentante(event.target.value);
    }
  };

  const handleChangeCNPJ = async (event, setFieldValue) => {
    const { name, value } = event.target;
    setFieldValue(name, value);

    if (!validarCNPJ(value)) {
      resetCNPJFields(setFieldValue);
      return;
    }

    try {
      const dados = await optionsService.verificarCNPJ(value);
      // Atenção, os dados aqui vem da APi do optionsService e não do nosso backend
      setFieldValue("razaoSocial", dados.razao_social);
      setFieldValue("nomeFantasia", dados.nome_fantasia ? dados.nome_fantasia : dados.razao_social);
      setFieldValue("naturezaJuridica", dados.natureza_juridica);
      setFieldValue("naturezaJuridicaCodigo", dados.codigo_natureza_juridica.toString());
      setFieldValue("porte", dados.porte);
      // TODO: na nova API será recuperado porte também
      // setFieldValue("tipo", dados.tipo);
    } catch (error) {
      console.error("Erro ao obter CNPJ", error);
      resetCNPJFields(setFieldValue);
    }
  };

  const resetCNPJFields = setFieldValue => {
    setFieldValue("razaoSocial", "");
    setFieldValue("nomeFantasia", "");
    setFieldValue("naturezaJuridica", "");
    setFieldValue("naturezaJuridicaCodigo", "");
  };

  const handleSite = (event, setFieldValue) => {
    const { name, value } = event.target;
    setFieldValue(name, formatSite(value));
  };

  const handleRedesSociais = (fields, setFieldValue, setFieldTouched, validateField) => {
    setFieldValue(
      "redesSociais",
      fields.map(field => field.toString().trim()),
    );

    fields.forEach((value, index) => {
      value = value.toString().trim();
      if (value.trim() !== "") {
        setFieldTouched(`redesSociais.${index}`, true, false);
        validateField(`redesSociais.${index}`);
      }
    });
  };

  const handleChangeCEP = async (event, setFieldValue) => {
    const { name, value } = event.target;
    setFieldValue(name, value);
    if (value.replace(/\D/g, "").length == 8) {
      await optionsService
        .verificarCEP(value)
        .then(async dados => {
          if (dados) {
            setSelectedUf(dados.uf);
            setFieldValue("uf", dados.uf);
            await fetchCidadesByUf(dados.uf, setCidades);
            setSelectedCidade(dados.localidade.toUpperCase());
            setFieldValue("cidade", dados.localidade);
            setFieldValue("endereco", dados.logradouro);
            setFieldValue("bairro", dados.bairro);
            setFieldValue("complemento", dados.complemento);
          }
        })
        .catch(() => console.error("Erro obtendo CEP"));
    }
  };

  const handleConfirmacaoClose = () => {
    setConfirmacaoModal(false);
    navigate("/parceiro/listar-parceiros");
  };

  const handleErroClose = () => {
    setErroModal(false);
  };

  const handleErroDoUsuarioClose = () => {
    setErroDoUsuarioModal(false);
  };

  const verificarCamposAntesDoSubmit = (error, values) => {
    if (
      Object.keys(error).length !== 0 ||
      !(
        values.checkVagaEmprego ||
        values.checkVagaEstagio ||
        values.checkVagaJovem ||
        values.checkCursos ||
        values.checkFinanceiro ||
        values.checkMobilidadePublico ||
        values.checkMobilidadeParceiro
      )
    ) {
      setErroDoUsuarioModal(true);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={FormInscricaoParceiroUtils.validationSchema}
      onSubmit={handleSubmit}
      validateOnChange={false}
    >
      {({
        values,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        isSubmitting,
        errors,
        touched,
        validateField,
        validateForm,
      }) => (
        <Form
          onKeyDown={FormInscricaoParceiroUtils.handleKeyDown}
          disabled={visualizacao}
        >
          <Stack spacing={2}>
            {/* DADOS */}
            <Card
              color="#ffffff"
              sx={{
                borderRadius: "8px",
                padding: "16px",
              }}
              style={{ transition: "1s" }}
            >
              <Typography
                variant="h5"
                sx={{
                  marginBottom: "8px",
                }}
              >
                DADOS
              </Typography>
              <Grid
                spacing={2}
                container
              >
                <Grid
                  item
                  xs={11.5}
                >
                  <FormGroup>
                    <TextField
                      label="E-mail"
                      disabled
                      id="email"
                      name="email"
                      value={values.email}
                      placeholder="Insira seu E-mail aqui"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.email && touched.email}
                      helperText={errors.email && touched.email && errors.email}
                    >
                      <Tooltip
                        title="E-mail do representante da empresa"
                        placement="right"
                      >
                        <InfoIcon
                          color="primary"
                          sx={{ width: "15px", height: "15px" }}
                        />
                      </Tooltip>
                    </TextField>
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={0.1}
                >
                  <Tooltip
                    title="E-mail do representante da empresa"
                    placement="right"
                  >
                    <InfoIcon
                      color="primary"
                      sx={{ width: "15px", height: "15px" }}
                    />
                  </Tooltip>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <FormGroup>
                    <TextField
                      label="* CNPJ"
                      id="cnpj"
                      name="cnpj"
                      value={formatCNPJ(values.cnpj)}
                      placeholder="Insira o CNPJ"
                      type="text"
                      onChange={event => handleChangeCNPJ(event, setFieldValue)}
                      onBlur={handleBlur}
                      inputProps={{
                        maxLength: 18,
                      }}
                      disabled={visualizacao}
                      error={errors.cnpj && touched.cnpj}
                      helperText={errors.cnpj && touched.cnpj && errors.cnpj}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <FormGroup>
                    <TextField
                      label="* Razão social"
                      id="razaoSocial"
                      name="razaoSocial"
                      value={values.razaoSocial}
                      placeholder="Insira a Razão Social"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.razaoSocial && touched.razaoSocial}
                      helperText={errors.razaoSocial && touched.razaoSocial && errors.razaoSocial}
                      disabled
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <FormGroup>
                    <TextField
                      label="Nome Fantasia"
                      id="nomeFantasia"
                      name="nomeFantasia"
                      value={values.nomeFantasia}
                      placeholder="Insira o Nome Fantasia"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.nomeFantasia && touched.nomeFantasia}
                      helperText={errors.nomeFantasia && touched.nomeFantasia && errors.nomeFantasia}
                      disabled
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                >
                  <FormGroup>
                    <TextField
                      label="* CEP"
                      id="cep"
                      name="cep"
                      value={formatCEP(values.cep)}
                      placeholder="Insira o CEP"
                      type="text"
                      onChange={event => handleChangeCEP(event, setFieldValue)}
                      onBlur={handleBlur}
                      inputProps={{
                        maxLength: 10,
                      }}
                      disabled={visualizacao}
                      error={errors.cep && touched.cep}
                      helperText={errors.cep && touched.cep && errors.cep}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormGroup>
                    <TextField
                      label="* Logradouro"
                      id="endereco"
                      name="endereco"
                      value={values.endereco}
                      placeholder="Insira o Endereço"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={visualizacao}
                      error={errors.endereco && touched.endereco}
                      helperText={errors.endereco && touched.endereco && errors.endereco}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                >
                  <FormGroup>
                    <TextField
                      label="Número"
                      id="numero"
                      name="numero"
                      value={values.numero}
                      placeholder="Insira o Número"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={visualizacao}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormGroup>
                    <TextField
                      label="Complemento"
                      id="complemento"
                      name="complemento"
                      value={values.complemento || ""}
                      placeholder="Insira o Complemento"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={visualizacao}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormGroup>
                    <TextField
                      label="* Bairro"
                      id="bairro"
                      name="bairro"
                      value={values.bairro}
                      placeholder="Insira o Bairro"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={visualizacao}
                      error={errors.bairro && touched.bairro}
                      helperText={errors.bairro && touched.bairro && errors.bairro}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormGroup>
                    <SelectUF
                      idSelect={"uf"}
                      nameSelect={"uf"}
                      value={values.uf}
                      ufs={ufs}
                      uf={selectedUf}
                      handleSelectUf={event => handleSelectUf(event, setFieldValue)}
                      onBlur={handleBlur}
                      visualizacao={visualizacao}
                      errors={errors.uf}
                      touched={touched.uf}
                    />
                    {errors.uf && touched.uf && errors.uf && (
                      <Typography
                        sx={{
                          color: "#FF5630",
                          fontSize: "12px",
                          ml: "12px",
                        }}
                      >
                        {errors.uf}
                      </Typography>
                    )}
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormGroup>
                    <SelectCidade
                      idSelect={"cidade"}
                      nameSelect={"cidade"}
                      value={values.cidade}
                      cidades={cidades}
                      cidade={selectedCidade}
                      handleSelectCidade={event => handleSelectCidade(event, setFieldValue)}
                      onBlur={handleBlur}
                      visualizacao={visualizacao}
                      errors={errors.cidade}
                      touched={touched.cidade}
                    />
                    {errors.cidade && touched.cidade && errors.cidade && (
                      <Typography
                        sx={{
                          color: "#FF5630",
                          fontSize: "12px",
                          ml: "12px",
                        }}
                      >
                        {errors.cidade}
                      </Typography>
                    )}
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormGroup>
                    <TextField
                      label="* Telefone"
                      id="telefone"
                      name="telefone"
                      value={formatTelefone(values.telefone)}
                      placeholder="Insira o Telefone"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        maxLength: 15,
                      }}
                      disabled={visualizacao}
                      error={errors.telefone && touched.telefone}
                      helperText={errors.telefone && touched.telefone && errors.telefone}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormGroup>
                    <TextField
                      label="Site"
                      id="site"
                      name="site"
                      value={values.site || ""}
                      onChange={event => handleSite(event, setFieldValue)}
                      placeholder="Insira o Site"
                      type="text"
                      onBlur={handleBlur}
                      disabled={visualizacao}
                      error={errors.site && touched.site}
                      helperText={errors.site && touched.site && errors.site}
                    />
                    <FormHelperText id="site-valido">{FormInscricaoParceiroUtils.avisoSiteValido(errors, values.site)}</FormHelperText>
                  </FormGroup>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                >
                  <FormMultipleInsert
                    initialValues={values.redesSociais}
                    placeHolder={"Insira a Rede Social"}
                    label={"Redes Sociais"}
                    disabled={visualizacao}
                    onFieldsChange={fields => handleRedesSociais(fields, setFieldValue, setFieldTouched, validateField)}
                    errors={errors.redesSociais}
                    touched={touched.redesSociais}
                  />
                </Grid>
              </Grid>
            </Card>
            {/* REPRESENTANTE  */}
            <Card
              color="#ffffff"
              sx={{
                borderRadius: "8px",
                padding: "16px",
              }}
              style={{ transition: "1s" }}
            >
              <Typography
                variant="h5"
                sx={{
                  marginBottom: "8px",
                }}
              >
                REPRESENTANTE{" "}
                <Tooltip
                  title="Responsável pelo cadastramento da instituição na rede de parceiros no portal Acredita"
                  placement="right"
                >
                  <InfoIcon
                    color="primary"
                    sx={{ width: "15px", height: "15px" }}
                  />
                </Tooltip>
              </Typography>
              <Grid
                spacing={2}
                container
              >
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={9}
                >
                  <FormGroup>
                    <TextField
                      label="* Nome"
                      id="nomeRepresentante"
                      name="nomeRepresentante"
                      value={values.nomeRepresentante}
                      placeholder="Insira o Nome"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled
                      error={errors.nomeRepresentante && touched.nomeRepresentante}
                      helperText={errors.nomeRepresentante && touched.nomeRepresentante && errors.nomeRepresentante}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={3}
                >
                  <FormGroup>
                    <TextField
                      label="* CPF"
                      id="cpfRepresentante"
                      name="cpfRepresentante"
                      value={formatCPF(values.cpfRepresentante)}
                      placeholder="Insira o CPF"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        maxLength: 14,
                      }}
                      disabled={visualizacao || temDadosRepresentante}
                      error={errors.cpfRepresentante && touched.cpfRepresentante}
                      helperText={errors.cpfRepresentante && touched.cpfRepresentante && errors.cpfRepresentante}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <FormGroup>
                    <TextField
                      label="* Telefone"
                      id="telefoneRepresentante"
                      name="telefoneRepresentante"
                      value={formatTelefone(values.telefoneRepresentante)}
                      placeholder="Insira o Telefone"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        maxLength: 15,
                      }}
                      disabled={visualizacao || temDadosRepresentante}
                      error={errors.telefoneRepresentante && touched.telefoneRepresentante}
                      helperText={errors.telefoneRepresentante && touched.telefoneRepresentante && errors.telefoneRepresentante}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <FormGroup>
                    <SelectUF
                      idSelect={"ufRepresentante"}
                      nameSelect={"ufRepresentante"}
                      value={values.ufRepresentante}
                      ufs={ufs}
                      uf={selectedUfRepresentante}
                      handleSelectUf={event => handleSelectUf(event, setFieldValue)}
                      onBlur={handleBlur}
                      visualizacao={visualizacao || temDadosRepresentante}
                      errors={errors.ufRepresentante}
                      touched={touched.ufRepresentante}
                    />
                    {errors.ufRepresentante && touched.ufRepresentante && errors.ufRepresentante && (
                      <Typography
                        sx={{
                          color: "#FF5630",
                          fontSize: "12px",
                          ml: "12px",
                        }}
                      >
                        {errors.ufRepresentante}
                      </Typography>
                    )}
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <FormGroup>
                    <SelectCidade
                      idSelect={"cidadeRepresentante"}
                      nameSelect={"cidadeRepresentante"}
                      value={values.cidadeRepresentante}
                      cidades={cidadesRepresentante}
                      cidade={selectedCidadeRepresentante}
                      handleSelectCidade={event => handleSelectCidade(event, setFieldValue)}
                      onBlur={handleBlur}
                      visualizacao={visualizacao || temDadosRepresentante}
                      errors={errors.cidadeRepresentante}
                      touched={touched.cidadeRepresentante}
                    />
                    {errors.cidadeRepresentante && touched.cidadeRepresentante && errors.cidadeRepresentante && (
                      <Typography
                        sx={{
                          color: "#FF5630",
                          fontSize: "12px",
                          ml: "12px",
                        }}
                      >
                        {errors.cidadeRepresentante}
                      </Typography>
                    )}
                  </FormGroup>
                </Grid>
              </Grid>
            </Card>
            {/* PONTO FOCAL  */}
            <Card
              color="#ffffff"
              sx={{
                width: "100%",
                borderRadius: "8px",
                padding: "16px",
                mt: "25px",
              }}
              style={{ transition: "1s" }}
            >
              <Typography
                variant="h5"
                sx={{
                  marginBottom: "8px",
                }}
              >
                PONTO FOCAL{" "}
                <Tooltip
                  title="Responsável operacional pelos serviços ofertados em parceria com o Programa Acredita no Primeiro Passo"
                  placement="right"
                >
                  <InfoIcon
                    color="primary"
                    sx={{ width: "15px", height: "15px" }}
                  />
                </Tooltip>
              </Typography>
              <Grid
                spacing={2}
                container
              >
                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <FormGroup>
                    <TextField
                      label="* Nome"
                      id="nomePontoFocal"
                      name="nomePontoFocal"
                      value={values.nomePontoFocal}
                      placeholder="Insira o Nome"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={visualizacao}
                      error={errors.nomePontoFocal && touched.nomePontoFocal}
                      helperText={errors.nomePontoFocal && touched.nomePontoFocal && errors.nomePontoFocal}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <FormGroup>
                    <TextField
                      label="* E-mail"
                      id="emailPontoFocal"
                      name="emailPontoFocal"
                      value={values.emailPontoFocal}
                      placeholder="Insira o E-mail"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={visualizacao}
                      error={errors.emailPontoFocal && touched.emailPontoFocal}
                      helperText={errors.emailPontoFocal && touched.emailPontoFocal && errors.emailPontoFocal}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <FormGroup>
                    <TextField
                      label="* Telefone"
                      id="telefonePontoFocal"
                      name="telefonePontoFocal"
                      value={formatTelefone(values.telefonePontoFocal)}
                      placeholder="Insira o Telefone"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        maxLength: 15,
                      }}
                      disabled={visualizacao}
                      error={errors.telefonePontoFocal && touched.telefonePontoFocal}
                      helperText={errors.telefonePontoFocal && touched.telefonePontoFocal && errors.telefonePontoFocal}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Card>
            {/* Área de atuação, Natureza Juridica*/}
            {/* Novos: tipo e porte*/}
            <Card
              color="#ffffff"
              sx={{
                width: "100%",
                padding: "16px",
                mt: "25px",
              }}
              style={{ transition: "1s" }}
            >
              <Grid
                spacing={1}
                container
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormGroup>
                    <SelectAtuacaoParceiro
                      idSelect={"areaAtuacao"}
                      nameSelect={"areaAtuacao"}
                      handleSelect={handleChange}
                      list={atuacoes}
                      item={values.areaAtuacao}
                      label={"* Área de Atuação"}
                      placeholder={"Selecione a Área de Atuação"}
                      visualizacao={visualizacao}
                      errors={errors.areaAtuacao}
                      touched={touched.areaAtuacao}
                    />
                    {errors.areaAtuacao && touched.areaAtuacao && errors.areaAtuacao && (
                      <Typography
                        sx={{
                          color: "#FF5630",
                          fontSize: "12px",
                          ml: "12px",
                        }}
                      >
                        {errors.areaAtuacao}
                      </Typography>
                    )}
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormGroup>
                    <SelectNaturezaJuridica
                      idSelect={"naturezaJuridica"}
                      nameSelect={"naturezaJuridica"}
                      handleSelect={handleChange}
                      // Esse select recebe o código para buscar da lista pré-populada
                      item={values.naturezaJuridicaCodigo ? values.naturezaJuridicaCodigo : ""}
                      label="* Natureza Jurídica"
                      placeholder={"Insira um CNPJ"}
                      visualizacao={visualizacao}
                      errors={errors.naturezaJuridica}
                      touched={touched.naturezaJuridica}
                    />
                    {errors.naturezaJuridica && touched.naturezaJuridica && errors.naturezaJuridica && (
                      <Typography
                        sx={{
                          color: "#FF5630",
                          fontSize: "12px",
                          ml: "12px",
                        }}
                      >
                        {errors.naturezaJuridica}
                      </Typography>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <TextField
                      id="hiddenField"
                      name="hiddenField"
                      type="hidden"
                      // Aqui vamos guardar o texto da natureza juridica pq temos que mandar para o backend
                      value={values.naturezaJuridica}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormGroup>
                    <TextField
                      label="* Tipo"
                      id="tipo"
                      name="tipo"
                      value={values.tipo}
                      placeholder="Insira o Tipo"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={visualizacao}
                      error={errors.tipo && touched.tipo}
                      helperText={errors.tipo && touched.tipo && errors.tipo}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormGroup>
                    <TextField
                      label="* Porte"
                      id="porte"
                      name="porte"
                      value={values.porte}
                      placeholder="Insira o Porte"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={visualizacao}
                      error={errors.porte && touched.porte}
                      helperText={errors.porte && touched.porte && errors.porte}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Card>
            {/* Serviços */}
            <Card
              sx={{
                width: "100%",
                padding: "16px",
                mt: "25px",
              }}
              style={{ transition: "1s" }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                * Que tipo de serviço essa instituição irá ofertar?
              </Typography>
              <FormGroup>
                <Grid
                  container
                  sx={{ width: "100%" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="checkVagaEmprego"
                        name="checkVagaEmprego"
                        checked={values.checkVagaEmprego}
                        onChange={handleChange}
                        style={{ color: theme.palette.text.disabled }}
                        disabled={visualizacao}
                      />
                    }
                    label={
                      <Typography>
                        Vagas de Emprego &nbsp;
                        <Tooltip
                          title="Para oferta de vaga(s) formal(is) de emprego com carteira assinada."
                          placement="right"
                        >
                          <InfoIcon
                            color="primary"
                            sx={{ width: "15px", height: "15px" }}
                          />
                        </Tooltip>
                      </Typography>
                    }
                    sx={{
                      border: "solid 1px",
                      borderRadius: "4px",
                      borderColor: "#CCCCCC",
                      margin: "4px 24px 4px 4px",
                      pr: "10px",
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="checkVagaEstagio"
                        name="checkVagaEstagio"
                        checked={values.checkVagaEstagio}
                        onChange={handleChange}
                        style={{ color: theme.palette.text.disabled }}
                        disabled={visualizacao}
                      />
                    }
                    label={
                      <Typography>
                        Vagas de Estágio &nbsp;
                        <Tooltip
                          title="Para oferta de vaga(s) de estágio (nível médio e superior)."
                          placement="right"
                        >
                          <InfoIcon
                            color="primary"
                            sx={{ width: "15px", height: "15px" }}
                          />
                        </Tooltip>
                      </Typography>
                    }
                    sx={{
                      border: "solid 1px",
                      borderRadius: "4px",
                      borderColor: "#CCCCCC",
                      margin: "4px 24px 4px 4px",
                      pr: "10px",
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="checkVagaJovem"
                        name="checkVagaJovem"
                        checked={values.checkVagaJovem}
                        onChange={handleChange}
                        style={{ color: theme.palette.text.disabled }}
                        disabled={visualizacao}
                      />
                    }
                    label={
                      <Typography>
                        Vagas de Jovem Aprendiz &nbsp;
                        <Tooltip
                          title="Para oferta de vaga(s) de trabalho para Jovem Aprendiz (14 a 24 anos)."
                          placement="right"
                        >
                          <InfoIcon
                            color="primary"
                            sx={{ width: "15px", height: "15px" }}
                          />
                        </Tooltip>
                      </Typography>
                    }
                    sx={{
                      border: "solid 1px",
                      borderRadius: "4px",
                      borderColor: "#CCCCCC",
                      margin: "4px 24px 4px 4px",
                      pr: "10px",
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="checkCursos"
                        name="checkCursos"
                        checked={values.checkCursos}
                        onChange={handleChange}
                        style={{ color: theme.palette.text.disabled }}
                        disabled={visualizacao}
                      />
                    }
                    label={
                      <Typography>
                        Cursos &nbsp;
                        <Tooltip
                          title="Para oferta de cursos (presenciais ou a distância) para o público do Programa Acredita no Primeiro Passo/Cadastro Único."
                          placement="right"
                        >
                          <InfoIcon
                            color="primary"
                            sx={{ width: "15px", height: "15px" }}
                          />
                        </Tooltip>
                      </Typography>
                    }
                    sx={{
                      border: "solid 1px",
                      borderRadius: "4px",
                      borderColor: "#CCCCCC",
                      margin: "4px 24px 4px 4px",
                      pr: "10px",
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="checkFinanceiro"
                        name="checkFinanceiro"
                        value={financeiro}
                        checked={financeiro}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ color: theme.palette.text.disabled }}
                        disabled={visualizacao}
                      />
                    }
                    onClick={handleFinanceiro}
                    label={
                      <Typography>
                        Financeiros e Pagamentos &nbsp;
                        <Tooltip
                          title="Para instituições que operam com oferta de microcrédito produtivo orientado para empreendedores formais ou informais."
                          placement="right"
                        >
                          <InfoIcon
                            color="primary"
                            sx={{ width: "15px", height: "15px" }}
                          />
                        </Tooltip>
                      </Typography>
                    }
                    sx={{
                      border: "solid 1px",
                      borderRadius: "4px",
                      borderColor: "#CCCCCC",
                      margin: "4px 24px 4px 4px",
                      pr: "10px",
                    }}
                  />
                  <FinanceiroModal
                    showModal={financeiroModal}
                    handleContinuar={event => handleFinanceiroContinuar(event, setFieldValue)}
                    handleClose={event => handleFinanceiroClose(event, setFieldValue)}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="checkMobilidadePublico"
                        name="checkMobilidadePublico"
                        checked={values.checkMobilidadePublico}
                        onChange={handleChange}
                        style={{ color: theme.palette.text.disabled }}
                        disabled={visualizacao}
                      />
                    }
                    label={
                      <Typography>
                        Mobilização de Público &nbsp;
                        <Tooltip
                          title="Para instituições com capacidade para mobilizar público e oferecer os serviços do Programa Acredita no Primeiro Passo no seu município."
                          placement="right"
                        >
                          <InfoIcon
                            color="primary"
                            sx={{ width: "15px", height: "15px" }}
                          />
                        </Tooltip>
                      </Typography>
                    }
                    sx={{
                      border: "solid 1px",
                      borderRadius: "4px",
                      borderColor: "#CCCCCC",
                      margin: "4px 24px 4px 4px",
                      pr: "10px",
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="checkMobilidadeParceiro"
                        name="checkMobilidadeParceiro"
                        checked={values.checkMobilidadeParceiro}
                        onChange={handleChange}
                        style={{ color: theme.palette.text.disabled }}
                        disabled={visualizacao}
                      />
                    }
                    label={
                      <Typography>
                        Mobilização de Parceiro &nbsp;
                        <Tooltip
                          title="Para associações, confederações, cooperativas, grupos, e entidades com potencial para articular a adesão de novos integrantes da Rede."
                          placement="right"
                        >
                          <InfoIcon
                            color="primary"
                            sx={{ width: "15px", height: "15px" }}
                          />
                        </Tooltip>
                      </Typography>
                    }
                    sx={{
                      border: "solid 1px",
                      borderRadius: "4px",
                      borderColor: "#CCCCCC",
                      margin: "4px 24px 4px 4px",
                      pr: "10px",
                    }}
                  />
                </Grid>
              </FormGroup>
            </Card>

            {/* Fora de card */}

            {!visualizacao && (
              <>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="toggleCienteNormas"
                          name="toggleCienteNormas"
                          checked={values.toggleCienteNormas}
                          onChange={handleChange}
                          style={{ color: theme.palette.text.disabled }}
                        />
                      }
                      label={
                        <Typography variant="body1">
                          Declaro, para os devidos fins, estar ciente e conforme com todos os termos, cláusulas, condições e normas da{" "}
                          <Link href="#">Medida Provisória nº 1213/2024</Link>, e manifesto o interesse em me credenciar como INTEGRANTE DA
                          REDE DE PARCEIROS DO DESENVOLVIMENTO SOCIAL.
                        </Typography>
                      }
                    />
                    {touched.toggleCienteNormas && errors.toggleCienteNormas && (
                      <div style={{ color: "#FF5630" }}>{errors.toggleCienteNormas}</div>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="toggleCienteGratuito"
                          name="toggleCienteGratuito"
                          checked={values.toggleCienteGratuito}
                          onChange={handleChange}
                          style={{ color: theme.palette.text.disabled }}
                        />
                      }
                      label={
                        <Typography variant="body1">
                          Declaro, para os devidos fins, que estou ciente que todos os serviços ofertados por esta Instituição ao público
                          inscrito no Programa Acredita no Primeiro Passo serão gratuitos.
                        </Typography>
                      }
                      sx={{ mt: "24px" }}
                    />
                    {touched.toggleCienteGratuito && errors.toggleCienteGratuito && (
                      <div style={{ color: "#FF5630" }}>{errors.toggleCienteGratuito}</div>
                    )}
                  </Grid>
                </Grid>

                {/* Botoes */}
                <Grid
                  container
                  spacing={1}
                  justifyContent="flex-end"
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={() => window.history.back()}
                    >
                      <Typography variant={"BUTTON TEXT"}>CANCELAR</Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      onClick={async () => {
                        const erro = await validateForm();
                        verificarCamposAntesDoSubmit(erro, values);
                      }}
                      loading={loading || isSubmitting}
                      fullWidth
                      sx={{ gap: "8px" }}
                    >
                      {loading && <span className="spinner-border spinner-border-sm"></span>}
                      <SaveIcon fontSize="small" />
                      <Typography variant={"BUTTON TEXT"}>SALVAR INFORMAÇÕES</Typography>
                    </LoadingButton>
                  </Grid>
                </Grid>
              </>
            )}
          </Stack>
          {/* Modais */}
          <ConfirmacaoModal
            showModal={confirmacaoModal}
            handleClose={handleConfirmacaoClose}
            conteudo={conteudoConfirmacaoModal}
          />
          <ErroModal
            showModal={erroModal}
            handleClose={handleErroClose}
          />
          <ErroDoUsuarioModal
            showModal={erroDoUsuarioModal}
            handleClose={handleErroDoUsuarioClose}
            erros={errors}
          />
        </Form>
      )}
    </Formik>
  );
};

FormInscricaoParceiro.propTypes = {
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  confirmacaoModal: PropTypes.bool,
  setConfirmacaoModal: PropTypes.func,
  conteudoConfirmacaoModal: PropTypes.node,
  setErroModal: PropTypes.func,
  erroModal: PropTypes.bool,
  setErroDoUsuarioModal: PropTypes.func,
  erroDoUsuarioModal: PropTypes.bool,
  initialValues: PropTypes.object,
  visualizacao: PropTypes.bool,
};

export default FormInscricaoParceiro;
