import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, Card, CardContent, FormControl, FormGroup, Grid, InputLabel, Select, Stack, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import optionsService from "../../services/options.service";
import parceiroService from "../../services/parceiro.service";
import ErroCarregamentoModal from "../errors/ErrorModal";
import { SelectCidade } from "../formularios/fields/SelectCidade";
import { SelectUF } from "../formularios/fields/SelectUF";
import LoadingComponent from "../loading/LoadingComponent";
import InformacoesParceiroModal from "../modals/InformacoesParceiroModal";
import DefaultTable from "../table/DefaultTable";

const tabelaColunas = [
  { field: "razaoSocial", headerName: "Razão Social" },
  { field: "nomeFantasia", headerName: "Nome Fantasia" },
];

const keysHidden = ["cnpj", "nomeResponsavel", "nomePontoFocal", "uf", "cidade", "situacao"];

// Retornar a pesquisa vazia
const initialFilterState = {
  razaoSocial: "",
  nomeFantasia: "",
  nomeResponsavel: "",
  nomePontoFocal: "",
  ufSelect: "",
  cidadeSelect: "",
  tipoDeServico: [],
  dataCadastroInicio: null,
  dataCadastroFim: null,
  dataUltimaModificacaoInicio: null,
  dataUltimaModificacaoFim: null,
};

const ParceiroListarParceirosAssociados = ({ informacoesModal, setInformacoesModal }) => {
  const navigate = useNavigate();

  const [filter, setFilter] = useState(initialFilterState);
  const [filteredData, setFilteredData] = useState([]);
  const [ufs, setUfs] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [selectedUf, setSelectedUf] = useState("");
  const [selectedCidade, setSelectedCidade] = useState("");
  const [parceiroSelecionado, setParceiroSelecionado] = useState({});

  // ---------------------------------------------------------- Conexão com o backend
  const {
    isPending,
    isError,
    data: initialData,
    error,
  } = useQuery({
    queryKey: ["empresasParceirasComDetalhes"],
    queryFn: parceiroService.getEmpresasParceirasComDetalhes,
  });

  useEffect(() => {
    // useEffect para atualizar os dados filtrados
    setFilteredData(Array.isArray(initialData) ? initialData : []);
  }, [initialData]);

  const tabelaHiddenLinhas = useMemo(
    () =>
      (Array.isArray(filteredData) ? filteredData : []).map(row =>
        keysHidden.reduce((acc, key) => {
          acc[key] = row[key];
          return acc;
        }, {}),
      ),
    [filteredData, keysHidden],
  );

  // ----------------------------------------------------------

  useEffect(() => {
    const fetchUfs = async () => {
      await optionsService
        .getAllUFs()
        .then(ufsData => {
          setUfs(ufsData);
        })
        .catch(error => {
          console.error("Erro ao obter UFs:", error);
        });
    };
    fetchUfs();
  }, []);

  const fetchCidadesByUf = async (ufSigla, setCidadesFunction) => {
    try {
      const cidadesData = await optionsService.getCidadesFromUF(ufSigla);
      setCidadesFunction(cidadesData);
    } catch (error) {
      console.error("Erro ao obter cidades:", error);
    }
  };

  const handleSelectUf = event => {
    const { name, value } = event.target;

    // Atualize o estado do filtro com os valores de UF e cidade
    setFilter(prevFilter => ({
      ...prevFilter,
      [name]: value,
      cidade: "", // Reseta o campo cidade quando UF é alterado
    }));

    setSelectedUf(value);
    setSelectedCidade("");
    fetchCidadesByUf(event.target.value, setCidades);
  };

  // Sincroniza o estado selectedUf com o estado filter.ufSelect
  useEffect(() => {
    setSelectedUf(filter.ufSelect);
  }, [filter.ufSelect]);

  const handleSelectCidade = event => {
    const { name, value } = event.target;
    setSelectedCidade(event.target.value);
    setFilter({ ...filter, [name]: value });
  };

  // Sincroniza o estado selectedUf com o estado filter.ufSelect
  useEffect(() => {
    setSelectedCidade(filter.cidadeSelect);
  }, [filter.cidadeSelect]);

  const handleFilterChange = event => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
  };

  const handleFilterSelectChange = event => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: typeof value === "string" ? value.split(",") : value });
  };

  const applyFilter = () => {
    console.log("Filtro aplicado:", filter);
    const filtered = initialData.filter(parceiro => {
      const matchesTextFilter =
        parceiro.nomeResponsavel.toLowerCase().includes(filter.nomeResponsavel.toLowerCase()) &&
        parceiro.nomePontoFocal.toLowerCase().includes(filter.nomePontoFocal.toLowerCase()) &&
        parceiro.nomeFantasia.toLowerCase().includes(filter.nomeFantasia.toLowerCase()) &&
        parceiro.razaoSocial.toLowerCase().includes(filter.razaoSocial.toLowerCase()) &&
        (filter.tipoDeServico.length === 0 ||
          Object.keys(parceiro.tipoDeServico)
            .map(servico => {
              return parceiro.tipoDeServico[servico] === true && filter.tipoDeServico.includes(servico);
            })
            .includes(true));

      const matchesDateFilter =
        (!filter.dataCadastroInicio ||
          !filter.dataCadastroFim ||
          dayjs(parceiro.dataCadastro).isBetween(filter.dataCadastroInicio, filter.dataCadastroFim, "day", "[]")) &&
        (!filter.dataUltimaModificacaoInicio ||
          !filter.dataUltimaModificacaoFim ||
          dayjs(parceiro.dataUltimaModificacao).isBetween(
            filter.dataUltimaModificacaoInicio,
            filter.dataUltimaModificacaoFim,
            "day",
            "[]",
          ));

      const matchesUfFilter =
        !filter.ufSelect ||
        parceiro.uf.toLowerCase() === filter.ufSelect.toLowerCase() ||
        parceiro.ufResponsavel.toLowerCase() === filter.ufSelect.toLowerCase();

      const matchesCidadeFilter =
        !filter.cidadeSelect ||
        parceiro.cidade.toLowerCase() === filter.cidadeSelect.toLowerCase() ||
        parceiro.cidadeResponsavel.toLowerCase() === filter.cidadeSelect.toLowerCase();

      return matchesTextFilter && matchesDateFilter && matchesUfFilter && matchesCidadeFilter;
    });
    setFilteredData(filtered);
  };

  const resetFilter = () => {
    setFilter(initialFilterState);
    setFilteredData(initialData);
  };

  const handleOpenInformacoesModal = id => {
    setParceiroSelecionado(filteredData.filter(parceiro => parceiro.id == id)[0]);
    setInformacoesModal(true);
  };

  const handleCloseInformacoesModal = () => {
    setInformacoesModal(false);
  };

  const getTabelaActions = row => {
    const actions = [
      {
        title: "Visualizar informações do Parceiro",
        href: "",
        icon: "visibility",
        onClick: rowId => handleOpenInformacoesModal(rowId),
      },
      {
        title: "Edição",
        icon: "create",
        onClick: rowId => {
          navigate("/parceiro/editar/" + rowId);
        },
      },
      {
        title: "Representante",
        icon: "person",
        onClick: rowId => {
          localStorage.setItem("analisarID", JSON.stringify(rowId));
          navigate("/parceiro/usuarios/" + rowId);
        },
      },
      {
        title: "Excluir",
        href: "",
        icon: "cancel",
        onClick: rowId => {
          localStorage.setItem("analisarID", JSON.stringify(rowId));
          navigate("/parceiro/excluir/" + rowId);
        },
      },
    ];

    if (row?.empresaComplementar) {
      // Usando unshift pois quero esse valor no começo do array
      actions.unshift({
        title: "Visualizar Informações Complementares",
        href: "",
        icon: "paid",
        onClick: rowId => {
          navigate("/parceiro/empresa-complementar/visualizar/" + rowId);
        },
      });
    }

    return actions;
  };

  if (isPending) {
    return (
      <LoadingComponent
        isLoading={isPending}
        fullScreen={false}
      />
    );
  }

  if (isError) {
    console.error("Erro de carregamento: " + error);
    return <ErroCarregamentoModal isShow={true} />;
  }

  return (
    <>
      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          {/* Campos para filtrar */}
          <Grid
            spacing={2}
            container
          >
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormGroup>
                <TextField
                  id="razaoSocial"
                  name="razaoSocial"
                  label="Razão Social"
                  placeholder="Razão Social"
                  value={filter.razaoSocial}
                  type="text"
                  onChange={handleFilterChange}
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormGroup>
                <TextField
                  id="nomeFantasia"
                  name="nomeFantasia"
                  label="Nome Fantasia"
                  placeholder="Nome Fantasia"
                  value={filter.nomeFantasia}
                  type="text"
                  onChange={handleFilterChange}
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormGroup>
                <TextField
                  id="nomeResponsavel"
                  name="nomeResponsavel"
                  label="Nome Responsável"
                  placeholder="Nome Responsável"
                  value={filter.nomeResponsavel}
                  type="text"
                  onChange={handleFilterChange}
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormGroup>
                <TextField
                  id="nomePontoFocal"
                  name="nomePontoFocal"
                  label="Nome Ponto Focal"
                  placeholder="Nome Ponto Focal"
                  value={filter.nomePontoFocal}
                  type="text"
                  onChange={handleFilterChange}
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid
            mt={1}
            spacing={2}
            container
          >
            <Grid
              item
              xs={12}
            >
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="label-tipo-servico">Tipo de Serviço</InputLabel>
                <Select
                  id="tipoDeServico"
                  name="tipoDeServico"
                  multiple
                  value={filter.tipoDeServico || "none"}
                  type="text"
                  onChange={handleFilterSelectChange}
                  labelId="label-tipo-servico"
                >
                  <MenuItem
                    value="none"
                    disabled
                  >
                    Selecione uma opção
                  </MenuItem>
                  <MenuItem value="VEP">Vaga de Emprego</MenuItem>
                  <MenuItem value="VET">Vaga de Estágio</MenuItem>
                  <MenuItem value="VJA">Vaga de Jovem Aprendiz</MenuItem>
                  <MenuItem value="CUR">Cursos</MenuItem>
                  <MenuItem value="FPG">Financeiros e de Pagamentos</MenuItem>
                  <MenuItem value="MPU">Mobilização de Público</MenuItem>
                  <MenuItem value="MPA">Mobilização de Parceiro</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Card style={{ border: "1px solid #d3d3d3" }}>
                <CardContent>
                  <p style={{ fontFamily: "Rawline Regular", color: "grey" }}>Data do Cadastro</p>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={6}
                    >
                      <FormGroup>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Início"
                            id="dataCadastroInicio"
                            name="dataCadastroInicio"
                            value={filter.dataCadastroInicio}
                            format="DD/MM/YYYY"
                            onChange={valor => setFilter({ ...filter, ["dataCadastroInicio"]: valor })}
                          />
                        </LocalizationProvider>
                      </FormGroup>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                    >
                      <FormGroup>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            id="dataCadastroFim"
                            name="dataCadastroFim"
                            value={filter.dataCadastroFim}
                            label="Fim"
                            format="DD/MM/YYYY"
                            onChange={valor => setFilter({ ...filter, ["dataCadastroFim"]: valor })}
                          />
                        </LocalizationProvider>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Card style={{ border: "1px solid #d3d3d3" }}>
                <CardContent>
                  <p style={{ fontFamily: "Rawline Regular", color: "grey" }}>Data da última modificação</p>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={6}
                    >
                      <FormGroup>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            id="dataUltimaModificacaoInicio"
                            name="dataUltimaModificacaoInicio"
                            value={filter.dataUltimaModificacaoInicio}
                            label="Início"
                            format="DD/MM/YYYY"
                            onChange={valor => setFilter({ ...filter, ["dataUltimaModificacaoInicio"]: valor })}
                          />
                        </LocalizationProvider>
                      </FormGroup>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                    >
                      <FormGroup>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            id="dataUltimaModificacaoFim"
                            name="dataUltimaModificacaoFim"
                            value={filter.dataUltimaModificacaoFim}
                            label="Fim"
                            format="DD/MM/YYYY"
                            onChange={valor => setFilter({ ...filter, ["dataUltimaModificacaoFim"]: valor })}
                          />
                        </LocalizationProvider>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid
            mt={1}
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
            >
              <FormGroup>
                <SelectUF
                  idSelect={"ufSelect"}
                  nameSelect={"ufSelect"}
                  value={filter.ufSelect}
                  ufs={ufs}
                  uf={selectedUf}
                  handleSelectUf={event => handleSelectUf(event)}
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
            >
              <FormGroup>
                <SelectCidade
                  idSelect={"cidadeSelect"}
                  nameSelect={"cidadeSelect"}
                  value={filter.cidadeSelect}
                  cidades={cidades}
                  cidade={selectedCidade}
                  handleSelectCidade={event => handleSelectCidade(event)}
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              container
              spacing={1}
              justifyContent="flex-end"
            >
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={resetFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                >
                  Limpar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={applyFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                >
                  <SearchIcon />
                  Pesquisar
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {/* Tabela */}
          <Box sx={{ flexGrow: 1, mt: "40px" }}>
            <DefaultTable
              rows={filteredData}
              notFoundText={"Não foi localizado Parceiro para condição informada!"}
              columns={tabelaColunas}
              hiddenRows={tabelaHiddenLinhas}
              actionButtons={getTabelaActions}
            />
          </Box>
        </Card>
      </Stack>
      <div>
        <InformacoesParceiroModal
          showModal={informacoesModal}
          handleClose={handleCloseInformacoesModal}
          parceiro={parceiroSelecionado}
        />
      </div>
    </>
  );
};

ParceiroListarParceirosAssociados.propTypes = {
  informacoesModal: PropTypes.bool,
  setInformacoesModal: PropTypes.func,
};

export default ParceiroListarParceirosAssociados;
