import React, { useState } from "react";

import { Button, Container, Grid } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import MensagemEmail from "../../components/modals/MensagemEmail";
import TitlePage from "../../components/title-page/TitlePage";

const PageEmails = () => {
  const [modal, setModal] = useState(false);
  const [info, setInfo] = useState({});
  return (
    <>
      <Container
        maxWidth="lg"
        sx={{ mt: 0 }}
      >
        <CardBreadcrumb currentPage="E-mail" />

        <TitlePage title={"Visualizar as modais de E-mail"} />

        <Grid container>
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                setModal(true);
                setInfo({
                  remetente: "<e-mail padrão>",
                  destinatario: "<e-mail cidadão>",
                  assunto: "O SISPR te convida a participar do curso " + "<Nome do curso>",
                  nome: "<Nome do cidadão>",
                  mensagem: [
                    "Daqui a dois dias começam as aulas do(s) curso(s) de " +
                      "< NOME DO CURSO EM QUE O CIDADÃO REGISTROU INTERESSE)" +
                      ". Sua inscrição está confirmada. Por favor, acesse o Programa Acredita, < Link da URL > e informe a sua situação no curso clicando nas opções “cursando” ou “desistiu do curso” (opções disponíveis no sistema para o usuário marcar)",
                  ],
                });
              }}
              sx={{ gap: "8px" }}
              style={{ fontFamily: "Rawline Medium" }}
            >
              HU154
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                setModal(true);
                setInfo({
                  remetente: "<e-mail padrão>",
                  destinatario: "<e-mail cidadão>",
                  assunto: "O Programa Acredita te convida a atualizar sua situação no curso que participou",
                  nome: "<Nome do cidadão>",
                  mensagem: [
                    "Agora que você finalizou o(s) curso(s) " +
                      "<NOME DO CURSO>" +
                      ", acesse o Programa Acredita “ação ou caminho para alteração do status”, " +
                      "<link >" +
                      " e informe: curso concluído (opção disponível no sistema para o usuário marcar).",
                    "Não se esqueça de baixar seu certificado no local indicado e de atualizar seu currículo com este novo curso que possui.",
                    "Parabéns pela aprovação no curso e sucesso na conquista de uma vaga ou em seu negócio!",
                  ],
                });
              }}
              sx={{ gap: "8px" }}
              style={{ fontFamily: "Rawline Medium" }}
            >
              HU155
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                setModal(true);
                setInfo({
                  remetente: "<e-mail padrão>",
                  destinatario: "<e-mail cidadão>",
                  assunto: "O Programa Acredita te convida a atualizar a situação dos participantes do curso.",
                  nome: "<Nome do cidadão>",
                  mensagem: [
                    "Agora que o(s) curso(s) " +
                      "<NOME DO CURSO>" +
                      " iniciou, pedimos que acesse o Programa Acredita “ação ou caminho para alteração do status” " +
                      "< link >" +
                      " e informe o status dos ALUNOS inscritos. Altere para “cursando” ou “desistiu do curso” conforme a frequência do aluno para que possamos acompanhar nosso público.",
                  ],
                });
              }}
              sx={{ gap: "8px" }}
              style={{ fontFamily: "Rawline Medium" }}
            >
              HU156
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                setModal(true);
                setInfo({
                  remetente: "<e-mail padrão>",
                  destinatario: "<e-mail cidadão>",
                  assunto: "O Programa Acredita te convida a atualizar a situação dos participantes do curso.",
                  nome: "<Nome do cidadão>",
                  mensagem: [
                    "Agora que o(s) curso(s) " +
                      "<NOME DO CURSO>" +
                      " finalizou(ram), pedimos que acesse o Programa Acredita “ação ou caminho para alteração do status”, " +
                      "<link>" +
                      " e informe o status dos cidadãos que concluíram o curso como concluído.",
                    "O Programa Acredita agradece a oportunidade e parceria, com a oferta de mais este curso. Parabéns pela ação cidadã e de responsabilidade social.",
                  ],
                });
              }}
              sx={{ gap: "8px" }}
              style={{ fontFamily: "Rawline Medium" }}
            >
              HU157
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                setModal(true);
                setInfo({
                  remetente: "<e-mail padrão>",
                  destinatario: "<e-mail cidadão>",
                  assunto: "O Programa Acredita informa que existem cidadãos interessados em obter crédito para empreender",
                  nome: "<Nome do cidadão>",
                  mensagem: [
                    "O " +
                      "<Nome do cidadão>" +
                      " demonstrou interesse em obter crédito para empreender. Acesse o Programa Acredita " +
                      "< link >" +
                      " e conheça o perfil deste empreendedor.",
                    "Link da URL = O sistema irá apresentar a chamada a funcionalidade da HU160 (Menu Principal >> Empreendedorismo >> Consultar Cidadãos interessados em microcrédito) apresentando a lista já filtrada pelos cidadãos interessados em microcrédito.",
                    "Observação: Será avaliada a viabilidade técnica considerando que existe uma autenticação antes do acesso à funcionalidade específica.",
                  ],
                });
              }}
              sx={{ gap: "8px" }}
              style={{ fontFamily: "Rawline Medium" }}
            >
              HU161
            </Button>
          </Grid>
        </Grid>
      </Container>
      <div>
        <MensagemEmail
          showModal={modal}
          handleClose={() => setModal(false)}
          info={info}
        />
      </div>
    </>
  );
};

export default PageEmails;
