import React, { useState } from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, Card, Grid, Typography } from "@mui/material";

import CustomModal from "../modals/CustomModal";

const ParceiroCidadaoQuestionarioMpo = () => {
  const [step, setStep] = useState(1);

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleSetStep = newStep => () => {
    setStep(newStep);
  };

  return (
    <div>
      {step === 1 && (
        <div>
          <Card
            color="background.paper"
            sx={{
              borderRadius: "8px",
              padding: "16px",
              marginTop: "16px",
            }}
            style={{ transition: "1s" }}
          >
            <Grid
              container
              mb={1}
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <Typography
                  variant="h6"
                  style={{
                    fontSize: "14px",
                    mt: "8px",
                    mb: "16px",
                    fontFamily: "Rawline Bold",
                    color: "text.primary",
                  }}
                >
                  Siga o passo a passo
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
              >
                <Card
                  sx={{
                    backgroundColor: "primary.main",
                    color: "text.secondary",
                    fontFamily: "Rawline Regular",
                    display: "flex",
                    padding: "8px",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <a>1. Responder o questionário [ nome do questionário ]</a>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
              >
                <Card
                  sx={{
                    backgroundColor: "#E8E8E8",
                    color: "grey",
                    fontFamily: "Rawline Regular",
                    display: "flex",
                    padding: "8px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a>2. Autorizar disponibilização dos seus dados e informações sobre seu negócio</a>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
              >
                <Card
                  sx={{
                    backgroundColor: "#E8E8E8",
                    color: "grey",
                    fontFamily: "Rawline Regular",
                    display: "flex",
                    padding: "8px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a>3. Aguardar contato de algum operador de microcrédito parceiro do Acredita</a>
                </Card>
              </Grid>
            </Grid>
          </Card>

          <Card
            color="background.paper"
            sx={{
              borderRadius: "8px",
              padding: "16px",
              marginTop: "16px",
            }}
            style={{ transition: "1s" }}
          >
            <Grid
              container
              mb={1}
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <Typography
                  variant="h6"
                  style={{
                    fontSize: "14px",
                    mt: "8px",
                    mb: "16px",
                    fontFamily: "Rawline Thin",
                    color: "text.primary",
                  }}
                >
                  Olá, [ nome completo do cidadão ]!
                  <br />
                  <br />
                  Seja bem-vindo! Clique no botão Responder o Questionário e dê o primeiro passo para se candidatar a um contato de acesso
                  ao microcrédito produtivo orientado.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={handleNext}
                  fullWidth
                >
                  RESPONDER QUESTIONÁRIO
                </Button>
              </Grid>
            </Grid>
          </Card>
        </div>
      )}

      {(step === 2 || step === 3) && (
        <div>
          <Card
            color="background.paper"
            sx={{
              borderRadius: "8px",
              padding: "16px",
              marginTop: "16px",
            }}
            style={{ transition: "1s" }}
          >
            <Grid
              container
              mb={1}
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <Typography
                  variant="h6"
                  style={{
                    fontSize: "14px",
                    mt: "8px",
                    mb: "16px",
                    fontFamily: "Rawline Bold",
                    color: "text.primary",
                  }}
                >
                  Siga o passo a passo
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
              >
                <Card
                  sx={{
                    backgroundColor: "#C3D6C4",
                    color: "green",
                    border: "green",
                    fontFamily: "Rawline Regular",
                    display: "flex",
                    padding: "8px",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CheckCircleIcon style={{ color: "green", marginRight: "8px" }} />
                  <a>1. Responder o questionário [ nome do questionário ]</a>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
              >
                <Card
                  sx={{
                    backgroundColor: "primary.main",
                    color: "text.secondary",
                    fontFamily: "Rawline Regular",
                    display: "flex",
                    padding: "8px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a>2. Autorizar disponibilização dos seus dados e informações sobre seu negócio</a>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
              >
                <Card
                  sx={{
                    backgroundColor: "#E8E8E8",
                    color: "grey",
                    fontFamily: "Rawline Regular",
                    display: "flex",
                    padding: "8px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a>3. Aguardar contato de algum operador de microcrédito parceiro do Acredita</a>
                </Card>
              </Grid>
            </Grid>
          </Card>

          <Card
            color="background.paper"
            sx={{
              borderRadius: "8px",
              padding: "16px",
              marginTop: "16px",
            }}
            style={{ transition: "1s" }}
          >
            <Grid
              container
              mb={1}
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <Typography
                  variant="h6"
                  style={{
                    fontSize: "14px",
                    mt: "8px",
                    mb: "16px",
                    fontFamily: "Rawline Thin",
                    color: "text.primary",
                  }}
                >
                  Parabéns, [ nome completo do cidadão ]!
                  <br />
                  <br />
                  Agora que você respondeu o questionário, as informações sobre seu negócio estarão disponíveis para que os operadores de
                  microcrédito parceiros do Acredita analisem seus dados e informações sobre o seu negócio e decidam se vão ou não fazer o
                  contato para conceder ou não o empréstimo.
                  <br />
                  <br />
                  AUTORIZO a consulta em meu nome de informações e dados que constem ou venham a constar no Sistema de Informações de
                  Crédito (SCR), gerido pelo Banco Central do Brasil – BC, ou dos sistemas que venham a complementá-lo ou substituí-lo.
                  <br />
                  <br />
                  AUTORIZO a disponibilização, realizada por operadores de microcrédito parceiros do Acredita, dos meus dados e informações
                  sobre o meu negócio constantes das respostas ao questionário.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
              >
                <Button
                  size="large"
                  type="submit"
                  variant="outlined"
                  fullWidth
                  onClick={handleSetStep(1)}
                >
                  NÃO AUTORIZAR
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
              >
                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  fullWidth
                  onClick={handleNext}
                >
                  AUTORIZAR
                </Button>
              </Grid>
            </Grid>
          </Card>
        </div>
      )}

      {step === 4 && (
        <div>
          <Card
            color="background.paper"
            sx={{
              borderRadius: "8px",
              padding: "16px",
              marginTop: "16px",
            }}
            style={{ transition: "1s" }}
          >
            <Grid
              container
              mb={1}
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <Typography
                  variant="h6"
                  style={{
                    fontSize: "14px",
                    mt: "8px",
                    mb: "16px",
                    fontFamily: "Rawline Bold",
                    color: "text.primary",
                  }}
                >
                  Siga o passo a passo
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
              >
                <Card
                  sx={{
                    backgroundColor: "#C3D6C4",
                    color: "green",
                    border: "green",
                    fontFamily: "Rawline Regular",
                    display: "flex",
                    padding: "8px",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CheckCircleIcon style={{ color: "green", marginRight: "8px" }} />
                  <a>1. Responder o questionário [ nome do questionário ]</a>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
              >
                <Card
                  sx={{
                    backgroundColor: "#C3D6C4",
                    color: "green",
                    border: "green",
                    fontFamily: "Rawline Regular",
                    display: "flex",
                    padding: "8px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CheckCircleIcon style={{ color: "green", marginRight: "8px" }} />
                  <a>2. Autorizar disponibilização dos seus dados e informações sobre seu negócio</a>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
              >
                <Card
                  sx={{
                    backgroundColor: "primary.main",
                    color: "text.secondary",
                    fontFamily: "Rawline Regular",
                    display: "flex",
                    padding: "8px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a>3. Aguardar contato de algum operador de microcrédito parceiro do Acredita</a>
                </Card>
              </Grid>
            </Grid>
          </Card>

          <Card
            color="background.paper"
            sx={{
              borderRadius: "8px",
              padding: "16px",
              marginTop: "16px",
            }}
            style={{ transition: "1s" }}
          >
            <Grid
              container
              mb={1}
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <Typography
                  variant="h6"
                  style={{
                    fontSize: "14px",
                    mt: "8px",
                    mb: "16px",
                    fontFamily: "Rawline Thin",
                    color: "text.primary",
                  }}
                >
                  Legal, [ nome completo do cidadão ]!
                  <br />
                  <br />
                  Agora, é aguardar o contato para ver a possibilidade de acesso ao microcrédito produtivo orientado. Atenção: fazer o
                  contato e conceder o empréstimo são decisões que cabem somente ao operador de microcrédito parceiro do Acredita.
                  Mantenha-se atualizado, capacite-se. Veja se tem curso disponível na área de empreendedorismo aqui no Acredita ou em outro
                  lugar.
                  <br />
                  <br />
                  Lembre-se, um empreendedor não desiste dos seus sonhos!
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
              >
                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  fullWidth
                  onClick={() => console.log("fim do form")}
                >
                  FINALIZAR
                </Button>
              </Grid>
            </Grid>
          </Card>
        </div>
      )}

      <CustomModal
        showModal={step === 3}
        handleClose={handleSetStep(2)}
        title={"Atenção"}
        content={
          <p>
            Não sendo autorizada a consulta e a disponibilização dos seus dados e informações aos operadores de microcrédito, as respostas
            dadas ao questionário não serão gravadas pelo sistema e o Acredita não poderá ajudar a você na tentativa de acesso ao
            microcrédito produtivo orientado! <br />
            <br />
            Então, o que você quer fazer?
          </p>
        }
        buttons={[
          { label: "NÃO AUTORIZAR", onClick: handleSetStep(2), variant: "outlined" },
          { label: "AUTORIZAR", onClick: handleSetStep(4), variant: "contained" },
        ]}
      />
    </div>
  );
};

export default ParceiroCidadaoQuestionarioMpo;
