import React, { useEffect, useState } from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminUsuario from "../../components/formularios/AdminUsuario";
import { tabelaUsuarios } from "../../components/formularios/dadosMockados";
import TitlePage from "../../components/title-page/TitlePage";

const initialValues = {
  nome: "",
  login: "",
  cpf: "",
  tipo: "",
  email: "",
  telefone: "",
  perfil: "",
  situacao: "none",
  motivo: "",
};

const PageExcluirUsuario = () => {
  const [valores, setValores] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(true);
  const usuarioID = JSON.parse(localStorage.getItem("usuarioID"));

  useEffect(() => {
    if (usuarioID) {
      const usuarioEncontrado = tabelaUsuarios.find(usuario => usuario.id === usuarioID);

      if (usuarioEncontrado) {
        setValores(usuarioEncontrado);
      }
    }
    setIsLoading(false);
  }, []);

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/listar-usuarios", text: "Usuário" }]}
        currentPage="Excluir"
      />

      <TitlePage title={"Excluir Usuário"} />

      {isLoading ? (
        <div>Carregando...</div>
      ) : valores ? (
        <AdminUsuario
          editar={true}
          visualizar={true}
          valores={valores}
          setValores={setValores}
        />
      ) : (
        <div>Parceiro não encontrado</div>
      )}
    </Container>
  );
};

export default PageExcluirUsuario;
