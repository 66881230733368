import React, { useEffect, useState } from "react";

import { Box, Container, Grid, Typography } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminAnalisarCursoPendente from "../../components/formularios/AdminAnalisarCursoPendente";
import { tabelaCursosPendentes } from "../../components/formularios/dadosMockados";

const PageAnalisarCursoPendente = () => {
  const initialData = tabelaCursosPendentes;
  const cursoID = JSON.parse(localStorage.getItem("cursosID"));
  const [valores, setValores] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (cursoID) {
      const cursoEncontrado = initialData.find(curso => curso.id === cursoID);

      if (cursoEncontrado) {
        setValores(cursoEncontrado);
      }
    }

    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <div>Carregando...</div>;
  }

  if (!valores) {
    return <div>Curso não encontrado</div>;
  }

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/listar-cursos-pendentes", text: "Listar Cursos Pendentes" }]}
        currentPage="Analisar Curso Pendente"
      />
      <Grid container>
        <Grid
          item
          xs={6}
          sm={9}
        >
          <Typography
            variant="h4"
            mt={3}
            mb={3}
            sx={{ fontFamily: "Rawline Bold" }}
            style={{ transition: "1s" }}
          >
            Analisar
          </Typography>
        </Grid>

        <Grid
          item
          xs={6}
          sm={3}
          mt={2}
        >
          <Box
            py={1}
            px={1}
            alignContent="center"
            sx={{
              bgcolor:
                valores.situacao === "Pendente"
                  ? "rgba(255, 0, 0, 0.1)"
                  : valores.situacao === "Reprovar"
                    ? "rgba(236, 186, 67, 0.48)"
                    : "rgba(0, 255, 0, 0.1)",
              border: "2px solid",
              borderColor: valores.situacao === "Pendente" ? "red" : valores.situacao === "Reprovar" ? "orange" : "green",
              borderRadius: "4px",
            }}
          >
            <Typography
              align="center"
              sx={{
                color: valores.situacao === "Pendente" ? "red" : valores.situacao === "Reprovar" ? "orange" : "green",
                fontFamily: "Rawline Bold",
              }}
            >
              {valores.situacao === "Pendente" ? "Pendente de aprovação" : valores.situacao === "Reprovar" ? "Reprovado" : "Aprovado"}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <AdminAnalisarCursoPendente
        valores={valores}
        setValores={setValores}
      />
    </Container>
  );
};

export default PageAnalisarCursoPendente;
