import constantsService from "../services/constants.service";

export const transformarDadosTrilha = dadosRecebidos => {
  const trilha = {
    id: dadosRecebidos.id,
    nome: dadosRecebidos.nome_trilha,
    descricao: dadosRecebidos.descricao_trilha,
    anexo: [dadosRecebidos.anexo],
    dataUltimaModificacao: dadosRecebidos.data_atualizacao,
    origem: "Sistema",
    cursos: [], // A Page listar precisa (Não sabemos ainda de onde vem e não está no Back)
    situacao: constantsService.getStatusByCode(dadosRecebidos.status ? dadosRecebidos.status : "Desconhecido"),
  };

  return trilha;
};
