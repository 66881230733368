import React from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Card, CardContent, Container, Grid, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminParceiroFormInformacoesComplementares from "../../components/formularios/AdminParceiroFormInformacoesComplementares";
// import adminService from "../../services/admin.service";
import TitlePage from "../../components/title-page/TitlePage";

const PageInformacoesComplementares = () => {
  const handleSubmit = async values => {
    console.log(values);
    // return adminService.postCadastrarEmpresaComplementar(values)
    //   .then(mensagem => {
    //     console.log("Sucesso:", mensagem);
    //     // setConfirmacaoModal(true);
    //     alert("Operação realizada com sucesso!");
    //   })
    //   .catch(erro => {
    //     console.error(" ", erro);
    //     // setErroModal(true);
    //     alert("Erro ao realizar operação!");
    //   })
    //   .finally(() => {
    //     // setSubmitting(false);
    //     // setLoading(false);
    //   });
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/", text: "Parceiro" }]}
        currentPage={"Informações Complementares"}
      />

      <TitlePage title={"Informações Complementares"} />

      {/* Aviso na página */}
      <Card>
        <CardContent>
          <Grid container>
            <Grid
              item
              xs={1}
              sm={0.5}
            >
              <IconButton>
                <InfoOutlinedIcon />
              </IconButton>
            </Grid>
            <Grid
              item
              xs={11}
              mt={1}
              pr={1}
              pl={1}
            >
              <Typography variant="h7">
                Atenção!
                <p>
                  Para ser avaliada a opção de oferta do tipo de serviço {'"Financeiros e de Pagamentos"'} é necessário responder a todas as
                  perguntas desta tela e salvar as informações clicando no botão {'"Salvar informações"'}.
                </p>
                <p>
                  Declaro, sob as penas da lei, que os dados e informações aqui prestados são verídicos e que a institução dispõe dos
                  correspondentes documentos de comprovação estando-os à disposição para o caso de verificações se necessário for.
                </p>
                <p>
                  Caros Parceiros de Serviços Financeiros e de Pagamentos, é necessário também cadastrar filiais em cada UF para que ocorra
                  o match com o Cidadão daquela microrregião.
                </p>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <AdminParceiroFormInformacoesComplementares handleSubmit={handleSubmit} />
    </Container>
  );
};

export default PageInformacoesComplementares;
