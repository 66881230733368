import React from "react";

import PropTypes from "prop-types";

const ButtonSecondary = ({ onClick, title, color, icon, disabled }) => {
  const buttonStyle = {
    color: color || "#9E9E9E",
    fontWeight: 600,
    fontSize: "14px",
    textAlign: "left",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    gap: "10px",
    display: "flex",
    flexDirection: "row",
  };

  const divStyle = {
    height: "36px",
    borderBottom: `1px solid ${color || "#9E9E9E"}`,
    paddingBottom: "7px",
    cursor: "pointer",
    padding: "4px 4px 8px 4px",
    textAlign: "center",
  };

  return (
    <div style={divStyle}>
      <button
        type="button"
        onClick={onClick}
        style={buttonStyle}
        disabled={disabled}
      >
        {icon}
        {title}
      </button>
    </div>
  );
};

ButtonSecondary.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.element,
  disabled: PropTypes.bool,
};

export default ButtonSecondary;
