import React, { useEffect, useState } from "react";

import { Box, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

import imagemCarregamento from "../../assets/images/page-carregamento.png";
import imagemCarregamentoDarkmode from "../../assets/images/page-carregamento-darkmode.png";

const ErroCarregamentoModal = ({ isShow }) => {
  const theme = useTheme();
  const [imageSrc, setImageSrc] = useState(imagemCarregamento);

  useEffect(() => {
    if (theme.palette.mode === "dark") {
      setImageSrc(imagemCarregamentoDarkmode);
    } else {
      setImageSrc(imagemCarregamento);
    }
  }, [theme.palette.mode]);

  return (
    isShow && (
      <div
        style={{
          zIndex: "10",
          width: "100vw",
          height: "100vh",
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          top: "0px",
          right: "0px",
          paddingTop: "80px ",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <p style={{ fontSize: "32px", fontFamily: "Rawline Bold", color: theme.palette.primary.main }}>Erro de carregamento!</p>
        </div>
        <div style={{ textAlign: "center" }}>
          <p style={{ fontSize: "16px", fontFamily: "Rawline Regular", color: theme.palette.text.primary }}>
            Ocorreu um problema ao carregar a página.
            <br />
            Por favor, recarregue a página ou tente novamente em breve.
          </p>
        </div>
        <Box sx={{ mx: "auto", width: "30vh", mb: 2 }}>
          <img
            src={imageSrc}
            alt="Carregando"
          />
        </Box>
        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            onClick={() => window.location.reload()}
          >
            Recarregar a página
          </Button>
        </div>
      </div>
    )
  );
};

ErroCarregamentoModal.propTypes = {
  isShow: PropTypes.bool.isRequired,
};

export default ErroCarregamentoModal;
