import React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useTheme } from "@mui/material/styles";

const ParceiroCidadaoMicrocreditoFAQ = () => {
  const theme = useTheme();

  return (
    <div>
      <Accordion style={{ border: "1px solid grey" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ fontFamily: "Rawline SemiBold", fontSize: "16px" }}
        >
          Quem pode participar das ações do Programa Acredita no Primeiro Passo voltadas ao empreendedorismo?
        </AccordionSummary>
        <AccordionDetails style={{ fontFamily: "Rawline Regular" }}>
          Todas as pessoas inscritas no Cadastro Único que já possuem um negócio ou querem começar um negócio podem participar das ações de
          empreendedorismo do Programa Acredita no Primeiro Passo, incluindo-se o acesso ao microcrédito produtivo orientado.
          <br />
          <br />
          Para participar das ações do Programa Acredita no Primeiro Passo é preciso estar regularmente inscrito no Cadastro Único e se
          cadastrar no Programa Acredita no Primeiro Passo
          <a
            href="https://www.gov.br/mds/pt-br/acoes-e-programas/programa-acredita-no-primeiro-passo"
            style={{ color: theme.palette.primary.main }}
          >
            <b>https://www.gov.br/mds/pt-br/acoes-e-programas/programa-acredita-no-primeiro-passo</b>
          </a>
          .<br />
          <br />
          No caso de acesso ao microcrédito produtivo orientado, depois que você realizar o seu cadastro no Programa Acredita no Primeiro
          Passo, e de ter respondido a um dos Questionários “Quero Ser Empreendedor” ou “Já Sou Empreendedor”, o MDS colocará suas
          informações para serem consultadas pelos parceiros do Programa Acredita no Primeiro Passo operadores de microcrédito a fim de que
          eles avaliem a proposta de financiamento do seu negócio.
          <br />
          <br />
          Os operadores de microcrédito parceiros do Programa Acredita no Primeiro Passo bancos, cooperativas de crédito, agências de
          fomento, sociedades de crédito ao microempreendedor e à empresa de pequeno porte, organizações da sociedade civil de interesse
          público, pessoas jurídicas especializadas no apoio, no fomento ou na orientação de microcrédito produtivo orientado e instituição
          financeira que realiza operações exclusivamente por meio de sítio eletrônico ou de aplicativo.
          <br />
          <br />
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ border: "1px solid grey" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{ fontFamily: "Rawline SemiBold", fontSize: "16px" }}
        >
          O que é Microcrédito Produtivo Orientado?
        </AccordionSummary>
        <AccordionDetails style={{ fontFamily: "Rawline Regular" }}>
          O microcrédito produtivo orientado é um financiamento para a criação, manutenção, melhoria de negócios produtivos. Por ter uma
          destinação específica, NÃO É UM EMPRÉSTIMO, mas sim um financiamento, embora os operadores também o chamem de empréstimo.
          <br />
          <br />
          O dinheiro do microcrédito produtivo orientado é destinado especificamente para atividade empreendedora de produção,
          comercialização, prestação de serviços, NÃO podendo ser usado para comprar material escolar, reformar a casa própria, pagar
          dívidas, atender necessidades pessoais, etc.
          <br />
          <br />
          Uma das fontes de recursos para o microcrédito produtivo orientado está no Programa Nacional de Microcrédito Produtivo Orientado –
          PNMPO, de que trata a Lei nº 13.636, de 20 de março de 2018. No PNMPO, podem tomar um financiamento as pessoas físicas e jurídicas
          empreendedoras de atividades produtivas urbanas e rurais, de forma individual ou coletiva, cuja renda ou a receita bruta anual
          seja de até R$ 360.000,00 (trezentos e sessenta mil reais) no ano-calendário.
          <br />
          <br />
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ border: "1px solid grey" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
          sx={{ fontFamily: "Rawline SemiBold", fontSize: "16px" }}
        >
          A operação de crédito de microcrédito produtivo orientado é um empréstimo ou um financiamento?
        </AccordionSummary>
        <AccordionDetails style={{ fontFamily: "Rawline Regular" }}>
          Empréstimo é operação de crédito cuja utilização dos recursos não tem uma vinculação específica.
          <br />
          <br />
          Financiamento é operação de crédito cujo uso do dinheiro tem destinação obrigatória para determinada coisa, por exemplo, no
          microcrédito produtivo orientado, aquisição de insumos e ou equipamentos para o negócio do empreendedor.
          <br />
          <br />
          Assim, tecnicamente, o termo mais correto é financiamento de microcrédito produtivo orientado, porém, como há uma prática de uso e
          para facilitar o entendimento do cidadão, os operadores falam empréstimo de microcrédito.
          <br />
          <br />
          Agora, lembrando, o <b>EMPRÉSTIMO DE MICROCRÉDITO PRODUTIVO ORIENTADO NÃO É PARA CONSUMO</b>, você NÃO deve usar o dinheiro do
          empréstimo de microcrédito para pagar despesas pessoais, quitar dívidas, comprar material escolar, etc., pois, se fizer isso
          estará quebrando o contrato de financiamento, ficando sujeito as penalidades estabelecidas no contrato.
          <br />
          <br />
          Atenção: o Cadastro Único, o Bolsa Família e o Programa Acredita no Primeiro Passo não oferecem empréstimo e nem financiamento,
          mas sim os parceiros do Programa Acredita no Primeiro Passo operadores de microcrédito.
          <br />
          <br />
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ border: "1px solid grey" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4-content"
          id="panel4-header"
          sx={{ fontFamily: "Rawline SemiBold", fontSize: "16px" }}
        >
          Estou interessado em realizar/reforçar/renovar meu próprio negócio. Como o Programa Acredita no Primeiro Passo pode me ajudar?
        </AccordionSummary>
        <AccordionDetails style={{ fontFamily: "Rawline Regular" }}>
          O Programa Acredita no Primeiro Passo funciona como um canal de contato com os operadores de microcrédito parceiros do Programa
          Acredita no Primeiro Passo, que operam o microcrédito produtivo orientado, facilitando e estimulando o contato das pessoas
          inscritas no Cadastro Único que queiram ter ou já têm um negócio.
          <br />
          <br />
          Para você ser contatado, faça o seu cadastro no Programa Acredita no Primeiro Passo, mas, lembre-se de que primeiro é necessário
          estar regularmente inscrito no Cadastro Único. O endereço do site do Programa Acredita no Primeiro Passo
          https://www.gov.br/mds/pt-br/acoes-e-programas/programa-acredita-no-primeiro-passo. Depois de se cadastrar no site, clique em
          “Empreendedorismo”, depois em “Acessar Microcrédito”. Aparecerão na tela duas opções de questionário para você preencher. Se você
          quiser iniciar um negócio, clique na 1° opção: “Quero Ser Microempreendedor”. Se você já possui um negócio, clique na 2° opção:
          “Já Sou Microempreendedor”.
          <br />
          <br />
          Para manifestar interesse em acessar o microcrédito produtivo orientado, é necessário que você autorize o MDS a colocar seus dados
          e informações para serem consultados pelos operadores de microcrédito parceiros do Programa Acredita no Primeiro Passo.
          <br />
          <br />
          No próprio sistema do Programa Acredita no Primeiro Passo, depois que você autorizou, os operadores de microcrédito parceiros do
          Programa Acredita no Primeiro Passo poderão consultar, de forma sigilosa, o conjunto dessas manifestações de interesse, que por
          sua vez, decidem contatar ou não os interessados para oferecer seus serviços.
          <br />
          <br />
          OU SEJA, O Programa Acredita no Primeiro Passo E O BOLSA FAMÍLIA NÃO CONCEDEM EMPRÉSTIMO E NEM FINANCIAMENTO PARA NINGUÉM. Apenas
          atua como facilitador de acesso ao microcrédito produtivo orientado para as pessoas inscritas no Cadastro Único.
          <br />
          <br />
          Além disso, o Programa Acredita no Primeiro Passo também facilita o acesso a cursos que podem ajudar você a administrar o seu
          negócio. Acesse o site{" "}
          <a
            href="https://www.gov.br/mds/pt-br/acoes-e-programas/programa-acredita-no-primeiro-passo"
            style={{ color: theme.palette.primary.main }}
          >
            <b>https://www.gov.br/mds/pt-br/acoes-e-programas/programa-acredita-no-primeiro-passo</b>
          </a>
          , no lado esquerdo da tela, clique em Empreendedorismo, depois em Cursos.
          <br />
          <br />
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ border: "1px solid grey" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5-content"
          id="panel5-header"
          sx={{ fontFamily: "Rawline SemiBold", fontSize: "16px" }}
        >
          Quero pegar um empréstimo para comprar material escolar / reformar minha casa / pagar dívidas sérias e/ou outras. O que devo
          fazer?
        </AccordionSummary>
        <AccordionDetails style={{ fontFamily: "Rawline Regular" }}>
          Procurar uma instituição financeira que trabalha com empréstimo para consumo, por exemplo, o empréstimo consignado. Ou outra forma
          de crédito que não obrigue o tomador do empréstimo a aplicar o dinheiro somente na destinação acordada com a instituição
          financeira.
          <br />
          <br />
          No caso de reforma de casa própria, há instituições financeiras que trabalham com linhas de crédito específicas para isso.
          <br />
          <br />
          Vale esclarecer que o microcrédito produtivo orientado NÃO é a solução para atendimento dessas suas necessidades. Pois, o
          microcrédito produtivo orientado é um empréstimo para financiar a criação, manutenção e melhoria de negócios produtivos. Portanto,
          o dinheiro do microcrédito produtivo orientado é destinado, especificamente, para atividade empreendedora de produção,
          comercialização, prestação de serviços, NÃO podendo ser usado para comprar material escolar, reformar a casa própria, pagar
          dívidas, atender necessidades pessoais, etc.
          <br />
          <br />{" "}
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ border: "1px solid grey" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6-content"
          id="panel6-header"
          sx={{ fontFamily: "Rawline SemiBold", fontSize: "16px" }}
        >
          Como faço para ter acesso ao microcrédito produtivo orientado?
        </AccordionSummary>
        <AccordionDetails style={{ fontFamily: "Rawline Regular" }}>
          Para ter acesso ao microcrédito produtivo orientado pelo Programa Acredita no Primeiro Passoário se cadastrar no site do Programa{" "}
          <a
            href="https://www.gov.br/mds/pt-br/acoes-e-programas/programa-acredita-no-primeiro-passo"
            style={{ color: theme.palette.primary.main }}
          >
            <b>https://www.gov.br/mds/pt-br/acoes-e-programas/programa-acredita-no-primeiro-passo</b>
          </a>{" "}
          e estar inscrito no Cadastro Único.
          <br />
          <br />
          Atenção: esse empréstimo é somente para quem quer iniciar ou já tem seu próprio negócio. Caso você esteja inscrito no Cadastro
          Único, o seu CPF será identificado ao preencher o seu cadastro no site do Programa Acredita no Primeiro Passo
          <br />
          <br />
          Ao se cadastrar no site do Programa Acredita no Primeiro Passo, clique em “Empreendedorismo”, depois em “Acessar Microcrédito”.
          Aparecerão na tela duas opções de questionário para você preencher. Se você quiser iniciar um negócio, clique na 1° opção: “Quero
          Ser Microempreendedor”. Se você já possui um negócio, clique na 2° opção: “Já Sou Microempreendedor”.
          <br />
          <br />
          Se você quiser que as informações do questionário estejam disponíveis para os parceiros do Programa Acredita no Primeiro Passo
          operadores de microcrédito, é necessário que autorize o envio de seus dados para esses parceiros, ao final do questionário.
          <br />
          <br />
          O MDS, então, encaminha seus dados para os parceiros operadores de microcrédito que, após avaliarem o seu perfil, decidem se
          entram em contato com você para oferecer o empréstimo de microcrédito produtivo orientado.
          <br />
          <br />
          Além disso, o Programa Acredita no Primeiro Passo também oferece cursos que podem ajudar você a administrar o seu negócio. Acesse
          o site{" "}
          <a
            href="https://www.gov.br/mds/pt-br/acoes-e-programas/programa-acredita-no-primeiro-passo"
            style={{ color: theme.palette.primary.main }}
          >
            <b>
              https://www.gov.br/mds/pt-br/acoes-e-programas/programa-acredita-no-primeiro-passo, no lado esquerdo da tela, clique em
              Empreendedorismo, depois em Cursos
            </b>
          </a>
          .
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ border: "1px solid grey" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7-content"
          id="panel7-header"
          sx={{ fontFamily: "Rawline SemiBold", fontSize: "16px" }}
        >
          Se eu preencher e for enviado o questionário de Microcrédito do site Programa Acredita no Primeiro Passo, vou conseguir o
          empréstimo? Os parceiros operadores de microcrédito vão entrar em contato comigo?{" "}
        </AccordionSummary>
        <AccordionDetails style={{ fontFamily: "Rawline Regular" }}>
          É uma decisão do banco parceiro emprestar ou não o microcrédito, e é o próprio banco que determina o valor do empréstimo. Após o
          preenchimento e envio do questionário, o MDS encaminha os dados do cidadão para os bancos parceiros que, após avaliarem o cadastro
          e analisarem o perfil, decidem se entram em contato ou não com o cidadão para ofertar microcrédito, e apresentam ou não uma
          proposta de empréstimo.
          <br />
          <br />
          Além disso, lembramos que o Programa Acredita no Primeiro Passo oferece curso de educação financeira e de qualificação
          profissional que podem ajudar a administrar o seu negócio. Acesse o site
          https://www.gov.br/mds/pt-br/acoes-e-programas/programa-acredita-no-primeiro-passo e faça o seu cadastro.
          <br />
          <br />
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ border: "1px solid grey" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8-content"
          id="panel8-header"
          sx={{ fontFamily: "Rawline SemiBold", fontSize: "16px" }}
        >
          Qual o valor do empréstimo de microcrédito?
        </AccordionSummary>
        <AccordionDetails style={{ fontFamily: "Rawline Regular" }}>
          O valor do empréstimo depende de cada parceiro do Programa Acredita no Primeiro Passo operador de microcrédito. Cada caso será
          analisado individualmente pelo parceiro. E atenção: é uma decisão do parceiro conceder ou não o microcrédito, e é o próprio
          parceiro que determina o valor do empréstimo de microcrédito produtivo orientado.
          <br />
          <br />
          No Programa Nacional de Microcrédito Produtivo Orientado – PNMPO, de que trata a Lei nº 13.636, de 20 de março de 2018, há um
          valor máximo a ser concedido em cada empréstimo, que é estabelecido pelo Conselho Monetário Nacional – CMN. Atualmente, o valor
          máximo do empréstimo de microcrédito é de R$ 21.000,00 (vinte e um mil reais). Ou seja, no PNMPO, mesmo sendo uma decisão do
          parceiro conceder ou não o empréstimo, o valor a ser concedido não pode ultrapassar o valor máximo estabelecido pelo CMN
          <br />
          <br />
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ border: "1px solid grey" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel9-content"
          id="panel9-header"
          sx={{ fontFamily: "Rawline SemiBold", fontSize: "16px" }}
        >
          Qual é a taxa de juros?
        </AccordionSummary>
        <AccordionDetails style={{ fontFamily: "Rawline Regular" }}>
          As taxas de juros do empréstimo de microcrédito e o valor a ser financiado a cada empreendedor dependem da avaliação do parceiro
          do Programa Acredita no Primeiro Passo operador de microcrédito. Cada parceiro tem sua política de negociação, portanto, também
          cada parceiro tem a sua própria taxa de juros.{" "}
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ border: "1px solid grey" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel10-content"
          id="panel10-header"
          sx={{ fontFamily: "Rawline SemiBold", fontSize: "16px" }}
        >
          Os parceiros do programa de microcrédito podem me oferecer valores diferentes de empréstimo?
        </AccordionSummary>
        <AccordionDetails style={{ fontFamily: "Rawline Regular" }}>
          Sim. O valor de cada empréstimo dependerá da avaliação de cada parceiro do Programa Acredita no Primeiro Passo operador de
          microcrédito. Assim, a negociação é entre o parceiro e você. No momento em que você conversar com o parceiro, é importante que
          pense bastante sobre a real capacidade da sua proposta de negócio, se realmente o negócio terá condições de gerar recursos
          suficientes para pagar o valor do empréstimo que você está pretendendo. E estar ciente de que é uma dívida que você terá que pagar
          ao parceiro tenha o seu negócio dado certo ou não, dentre outros fatores a serem considerados. Além disso, o próprio parceiro
          tratará com você, se ele entender que as informações iniciais que você prestou merecem ter um aprofundamento para possibilidade
          futura de concessão de empréstimo.
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ border: "1px solid grey" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel11-content"
          id="panel11-header"
          sx={{ fontFamily: "Rawline SemiBold", fontSize: "16px" }}
        >
          Meu CPF está negativado/com restrição, posso pegar empréstimo de microcrédito?{" "}
        </AccordionSummary>
        <AccordionDetails style={{ fontFamily: "Rawline Regular" }}>
          Quando o CPF está negativado, ou com restrição, o acesso ao empréstimo de microcrédito depende de cada parceiro do Programa
          Programa Acredita no Primeiro Passo. Normalmente, o cadastro não é aprovado até que a restrição seja resolvida. Mas, isso pode
          variar de parceiro para parceiro.
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ border: "1px solid grey" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel11-content"
          id="panel11-header"
          sx={{ fontFamily: "Rawline SemiBold", fontSize: "16px" }}
        >
          Para acessar as ofertas de microcrédito produtivo orientado é preciso fazer algum tipo de curso? Já terminei um curso de educação
          financeira, e agora o que devo fazer para conseguir o empréstimo de microcrédito?
        </AccordionSummary>
        <AccordionDetails style={{ fontFamily: "Rawline Regular" }}>
          Bem no início do Programa Acredita no Primeiro Passo, havia a exigência de os interessados no microcrédito produtivo orientado
          fazerem o curso de educação financeira ofertado à época para ter acesso ao microcrédito. Depois, avaliou-se que não era adequada
          essa exigência pelo Programa Acredita no Primeiro Passo.
          <br />
          <br />
          Cada parceiro do Programa Acredita no Primeiro Passo operador de microcrédito tem a sua política de empréstimo, com critérios que
          julga suficientes para a seleção dos futuros empreendedores a serem financiados. Um dos critérios pode ser a comprovação de que o
          cidadão tenha participado de algum tipo de curso, ou mesmo, que participe de curso ofertado pelo parceiro.
          <br />
          <br />
          Aliás, o fato de ter concluído o curso de educação financeira ofertado pelo Programa Acredita no Primeiro Passo não é uma garantia
          de que o crédito seria ou será concedido, mas sim um requisito para o interessado se candidatar a um possível contato de acesso a
          crédito.
          <br />
          <br />
          Sendo ou não obrigatória a comprovação ou participação em qualquer curso, é interessante que você se mantenha atualizado, que
          conheça novas técnicas de negócio, processos de produção e alternativas de gestão, para melhor empreender o seu negócio. Com
          certeza, quanto mais qualificado e capacitado você for, maiores são as chances de você conseguir empréstimo para o seu negócio.
          <br />
          <br />
          Se você já fez ou não o curso, e ainda não foi contatado por um dos parceiros operadores de microcrédito, aguarde, pois, um
          empreendedor não desiste facilmente dos seus sonhos!
          <br />
          <br />
        </AccordionDetails>
      </Accordion>
      <Accordion style={{ border: "1px solid grey" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel12-content"
          id="panel12-header"
          sx={{ fontFamily: "Rawline SemiBold", fontSize: "16px" }}
        >
          O empréstimo de microcrédito é descontado do Bolsa Família? Posso pagar o empréstimo com o cartão do Bolsa Família? Posso parcelar
          o empréstimo do Bolsa Família?
        </AccordionSummary>
        <AccordionDetails style={{ fontFamily: "Rawline Regular" }}>
          O Programa Acredita no Primeiro Passo garante que o microcrédito produtivo orientado não será descontado do benefício do Bolsa
          Família. Os recursos destinados ao Bolsa Família têm finalidade exclusiva de transferência de renda e não podem ser utilizados
          para pagamento de empréstimos.
          <br />
          <br />
          O pagamento do empréstimo de microcrédito deve ser realizado de acordo com as condições estabelecidas pelo operador do crédito,
          que geralmente inclui parcelas mensais compatíveis com a capacidade de pagamento do empreendedor. Não é possível utilizar o cartão
          do Bolsa Família para esse fim.
          <br />
          <br />
          O valor e o prazo de pagamento do empréstimo serão definidos com base na análise da viabilidade do negócio e na capacidade de
          geração de renda do empreendedor, garantindo que as parcelas sejam acessíveis e não comprometam a sustentabilidade financeira do
          empreendimento.
          <br />
          <br />
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ border: "1px solid grey" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel13-content"
          id="panel13-header"
          sx={{ fontFamily: "Rawline SemiBold", fontSize: "16px" }}
        >
          Depois que eu realizar o cadastro no site, o parceiro do Programa Acredita no Primeiro Passo operador de microcrédito vai entrar
          em contato comigo?
        </AccordionSummary>
        <AccordionDetails style={{ fontFamily: "Rawline Regular" }}>
          Após realizar o cadastro no site do Programa Acredita no Primeiro Passo e responder ao Questionário “Quero Ser Empreendedor” ou
          “Já Sou Empreendedor”, o Ministério do Desenvolvimento Social (MDS) encaminhará suas informações aos operadores de microcrédito
          parceiros.
          <br />
          <br />
          Os operadores de microcrédito avaliarão a viabilidade da sua proposta de financiamento e, caso aprovada, entrarão em contato com
          você para dar continuidade ao processo de concessão do crédito. Portanto, é importante fornecer informações de contato corretas e
          atualizadas no momento do cadastro.
          <br />
          <br />
          Lembre-se de verificar regularmente os seus meios de comunicação, como e-mail e telefone, para não perder o contato dos operadores
          de microcrédito e poder seguir com o processo de obtenção do financiamento.
          <br />
          <br />
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ border: "1px solid grey" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel14-content"
          id="panel14-header"
          sx={{ fontFamily: "Rawline SemiBold", fontSize: "16px" }}
        >
          Já me cadastrei no site do Programa Acredita no Primeiro Passo o questionário, fiz cursos, mas até agora ninguém entrou em contato
          comigo. O que eu posso fazer?
        </AccordionSummary>
        <AccordionDetails style={{ fontFamily: "Rawline Regular" }}>
          Se você já se cadastrou no site do Programa Acredita no Primeiro Passo, respondeu ao questionário e realizou cursos, mas ainda não
          foi contatado por um operador de microcrédito, há algumas ações que você pode tomar:
          <br />
          <br />
          1. Verifique se todos os seus dados de contato estão corretos e atualizados no cadastro do Programa Acredita no Primeiro Passo.
          <br />
          2. Acesse regularmente o site do Programa para acompanhar eventuais atualizações ou novas orientações.
          <br />
          3. Entre em contato diretamente com o Programa Acredita no Primeiro Passo através dos canais de atendimento disponíveis no site.
          <br />
          4. Participe de eventos, oficinas e encontros promovidos pelo Programa para obter mais informações e fortalecer a sua rede de
          contatos.
          <br />
          <br />
          É importante manter-se proativo e buscar informações constantemente para garantir que o seu processo de acesso ao microcrédito
          produtivo orientado avance de forma eficiente.
          <br />
          <br />
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ border: "1px solid grey" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel15-content"
          id="panel15-header"
          sx={{ fontFamily: "Rawline SemiBold", fontSize: "16px" }}
        >
          O que é um Microempreendedor Individual (MEI)?
        </AccordionSummary>
        <AccordionDetails style={{ fontFamily: "Rawline Regular" }}>
          O Microempreendedor Individual (MEI) é a pessoa que trabalha por conta própria e que se legaliza como pequeno empresário. Para se
          enquadrar como MEI, é necessário faturar no máximo R$ 81.000,00 por ano, não ter participação em outra empresa como sócio ou
          titular e ter no máximo um empregado.
          <br />
          <br />
          O MEI tem acesso a benefícios como CNPJ, alvará de funcionamento, emissão de nota fiscal, acesso a crédito bancário, além de
          contribuições previdenciárias que garantem direitos como aposentadoria e auxílio-doença.
          <br />
          <br />
          A formalização como MEI é simples e pode ser feita pela internet, no Portal do Empreendedor, onde é possível realizar o cadastro e
          obter o CNPJ imediatamente.
          <br />
          <br />
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ border: "1px solid grey" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel16-content"
          id="panel16-header"
          sx={{ fontFamily: "Rawline SemiBold", fontSize: "16px" }}
        >
          Onde eu encontro o número/inscrição do MEI?
        </AccordionSummary>
        <AccordionDetails style={{ fontFamily: "Rawline Regular" }}>
          O número/inscrição do MEI está disponível no Certificado da Condição de Microempreendedor Individual (CCMEI), que é gerado após a
          formalização do MEI. Este documento pode ser acessado e impresso no Portal do Empreendedor, onde estão disponíveis todas as
          informações cadastrais do MEI.
          <br />
          <br />
          Para acessar o CCMEI, basta entrar no Portal do Empreendedor, fazer login com o CPF e a senha cadastrada, e consultar o seu
          certificado.
          <br />
          <br />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ParceiroCidadaoMicrocreditoFAQ;
