import React from "react";
import { useNavigate } from "react-router-dom";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { LoadingButton } from "@mui/lab";
import { Button, Card, Grid, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";

const ParceiroInteressesPessoaMobilizacao = ({ value, visualizacao }) => {
  const navigate = useNavigate();
  const values = value
    ? value
    : {
        nome: "",
        pcd: "",
        escolaridade: "",
        municipio_uf: "",
        habilidade: "",
        experiencia: "",
        cursos_interesse: "",
        vagas_interesse: "",
      };

  return (
    <>
      <Card
        sx={{
          padding: 2,
          mt: 2,
          borderRadius: 2,
        }}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <TextField
              id="nome"
              name="nome"
              label="Nome"
              value={values.nome}
              disabled={visualizacao}
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <TextField
              id="pcd"
              name="pcd"
              label="PCD"
              value={values.pcd}
              disabled={visualizacao}
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <TextField
              id="escolaridade"
              name="escolaridade"
              label="Escolaridade"
              value={values.escolaridade}
              disabled={visualizacao}
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <TextField
              id="municipio_uf"
              name="municipio_uf"
              label="Município-UF"
              value={values.municipio_uf}
              disabled={visualizacao}
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <TextField
              id="habilidade"
              name="habilidade"
              label="Habilidade"
              value={values.habilidade}
              disabled={visualizacao}
              rows={4}
              multiline
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <TextField
              id="experiencia"
              name="experiencai"
              label="Experiência"
              value={values.experiencia}
              disabled={visualizacao}
              rows={4}
              multiline
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <TextField
              id="cursos_interesse"
              name="cursos_interesse"
              label="Cursos de Interesse"
              value={values.cursos_interesse}
              disabled={visualizacao}
              rows={4}
              multiline
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <TextField
              id="vagas_interesse"
              name="vagas_interesse"
              label="Vagas de Interesse"
              value={values.vagas_interesse}
              disabled={visualizacao}
              rows={4}
              multiline
              fullWidth
            />
          </Grid>
        </Grid>
      </Card>

      <Grid
        container
        spacing={2}
        mt={2}
        justifyContent={"flex-end"}
      >
        <Grid item>
          <Button
            variant="outlined"
            onClick={() => navigate("/cursos")}
          >
            <Typography variant={"BUTTON TEXT"}>CANCELAR</Typography>
          </Button>
        </Grid>
        <Grid item>
          <LoadingButton
            variant="contained"
            type="submit"
            onSubmit={values => {
              console.log(values);
            }}
            sx={{ gap: "8px" }}
          >
            <PictureAsPdfIcon />
            <Typography variant={"BUTTON TEXT"}>GERAR PDF</Typography>
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
};

ParceiroInteressesPessoaMobilizacao.propTypes = {
  value: PropTypes.object,
  visualizacao: PropTypes.bool,
};

export default ParceiroInteressesPessoaMobilizacao;
