import React, { useState } from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminUsuario from "../../components/formularios/AdminUsuario";
import TitlePage from "../../components/title-page/TitlePage";

const initialValues = {
  dataUltimaModificacao: null,
  nome: "",
  descricao: "",
  situacao: "none",
};

const PageNovoUsuario = () => {
  const [valores, setValores] = useState(initialValues);

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/listar-usuarios", text: "Usuário" }]}
        currentPage="Novo"
      />

      <TitlePage title={"Novo Usuário"} />

      <AdminUsuario
        valores={valores}
        setValores={setValores}
      />
    </Container>
  );
};

export default PageNovoUsuario;
