import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminHistoricoSituacaoPerfil from "../../components/filtros/AdminHistoricoSituacaoPerfil";
import TitlePage from "../../components/title-page/TitlePage";

const PageHistoricoSituacaoPerfil = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "", text: "Perfis" },
          { href: "/visualizar-usuario", text: "Visualizar" },
        ]}
        currentPage="Histórico Ativação"
      />

      <TitlePage title={"Histórico de Ativação do Perfil"} />

      <AdminHistoricoSituacaoPerfil />
    </Container>
  );
};

export default PageHistoricoSituacaoPerfil;
