import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ParceiroListarVagaTrabalho from "../../components/formularios/ParceiroListarVagasTrabalho";
import TitlePage from "../../components/title-page/TitlePage";

const PageListarVagaTrabalho = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/", text: "Página Inicial" },
          { href: "/parceiro", text: "Parceiro" },
          { href: "/parceiro/vagas", text: "Vagas" },
        ]}
        currentPage={"Listar Vagas"}
      />

      <TitlePage title={"Lista de Vagas"} />

      <ParceiroListarVagaTrabalho />
    </Container>
  );
};

export default PageListarVagaTrabalho;
