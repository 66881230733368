import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ParceiroListarCursos from "../../components/formularios/ParceiroListarCursos";
import TitlePage from "../../components/title-page/TitlePage";

const PageListarCursos = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/", text: "Página Inicial" },
          { href: "/cursos", text: "Cursos" },
        ]}
        currentPage="Listar Cursos Pendentes"
      />

      <TitlePage title={"Listar Cursos Pendentes"} />

      <ParceiroListarCursos />
    </Container>
  );
};

export default PageListarCursos;
