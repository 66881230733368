import React, { useState } from "react";

import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PrintIcon from "@mui/icons-material/Print";
import { Button, FormControl, FormGroup, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Card, Grid } from "@mui/material";
import PropTypes from "prop-types";

import CardCurriculo from "../cards/CardCurriculo";
import ContentModalEmail from "../modals/contents/ContentModalEmail";
import CustomModal from "../modals/CustomModal";

const MobilizacaoCurriculo = ({ infoItems }) => {
  const [showSendEmailModal, setShowSendEmailModal] = useState(false);
  const [showConfirmEmailModal, setShowConfirmEmailModal] = useState(false);
  const [showPdfModal, setShowPdfModal] = useState(false);

  const handleOpenModal = () => {
    setShowSendEmailModal(true);
  };

  const handleCloseModal = () => {
    setShowSendEmailModal(false);
  };

  return (
    <>
      <CustomModal
        showModal={showPdfModal}
        handleClose={() => {
          setShowPdfModal(false);
        }}
        title={"Gerar PDF"}
        content={<CardCurriculo infoItems={infoItems} />}
        buttons={[]}
      />

      <CustomModal
        showModal={showConfirmEmailModal}
        handleClose={() => {
          setShowConfirmEmailModal(false);
        }}
        title={""}
        content={<ContentModalEmail />}
        buttons={[]}
      />

      <CustomModal
        showModal={showSendEmailModal}
        handleClose={handleCloseModal}
        title={"Escolha o e-mail de envio"}
        content={
          <FormGroup variant="filled">
            <FormControl variant="filled">
              <InputLabel>E-mail do destinatário</InputLabel>
              <Select
                id="status"
                name="status"
                value={""}
                type="text"
              >
                <MenuItem
                  value="Pendente"
                  disabled
                >
                  Selecione o e-mail
                </MenuItem>
                <MenuItem value="Reprovar">Teste</MenuItem>
              </Select>
            </FormControl>
            <Grid
              item
              xs={2}
              mt={4}
              sx={{ alignSelf: "flex-end" }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setShowConfirmEmailModal(true);
                }}
              >
                <Typography variant={"BUTTON TEXT"}>Encaminhar e-mail</Typography>
              </Button>
            </Grid>
          </FormGroup>
        }
        buttons={[]}
      />
      <Card
        color="#ffffff"
        sx={{ borderRadius: "8px", padding: "16px" }}
        style={{ transition: "1s" }}
      >
        <Typography
          variant="h5"
          sx={{ fontSize: "20", mb: "16px", fontFamily: "Rawline Regular" }}
        >
          Mobilização de currículo
        </Typography>
        <Grid
          spacing={1}
          container
          sx={{ justifyContent: "center", flexDirection: "column", alignItems: "center" }}
        ></Grid>

        <Grid
          container
          spacing={1}
          sx={{ mt: "5px", justifyContent: "flex-end" }}
        >
          <Grid
            item
            md={1}
            sm={1}
            xs={4}
          >
            <Button
              size="large"
              type="submit"
              variant="outlined"
              onClick={() => {
                setShowPdfModal(true);
              }}
              fullWidth
            >
              <PictureAsPdfIcon />
            </Button>
          </Grid>
          <Grid
            item
            md={1}
            sm={1}
            xs={4}
          >
            <Button
              size="large"
              type="submit"
              variant="outlined"
              onClick={async () => {}}
              fullWidth
            >
              <PrintIcon />
            </Button>
          </Grid>
          <Grid
            item
            md={1}
            sm={1}
            xs={4}
          >
            <Button
              size="large"
              type="submit"
              variant="outlined"
              onClick={handleOpenModal}
              fullWidth
            >
              <MarkAsUnreadIcon />
            </Button>
          </Grid>
          <Grid
            item
            md={3}
            sm={3}
            xs={12}
          >
            <Button
              size="large"
              type="submit"
              variant="outlined"
              onClick={async () => {}}
              fullWidth
            >
              EDITAR CURRÍCULO
            </Button>
          </Grid>
          <Grid
            item
            md={3}
            sm={3}
            xs={12}
          >
            <Button
              size="large"
              type="submit"
              variant="outlined"
              onClick={async () => {}}
              fullWidth
            >
              PERGUNTAS FREQUENTES
            </Button>
          </Grid>
          <Grid
            item
            md={3}
            sm={3}
            xs={12}
          >
            <Button
              size="large"
              type="submit"
              variant="outlined"
              onClick={async () => {}}
              fullWidth
            >
              VOLTAR
            </Button>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

MobilizacaoCurriculo.propTypes = {
  infoItems: PropTypes.array,
};

export default MobilizacaoCurriculo;
