import React from "react";

import { Grid, Typography } from "@mui/material";

const ContentModalEmail = function () {
  return (
    <div>
      <Grid container>
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
        >
          <Typography
            variant="h5"
            sx={{ fontSize: "16px", mb: "8px", fontFamily: "Rawline Bold", mr: "5px" }}
          >
            Remetente: [e-mail padrão de envio]
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
        >
          <Typography
            variant="h5"
            sx={{ fontSize: "16px", mb: "8px", fontFamily: "Rawline Bold", mr: "5px" }}
          >
            Destintário: [e-mail do cidadão]
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
        >
          <Typography
            variant="h5"
            sx={{ fontSize: "16px", mb: "8px", fontFamily: "Rawline Bold", mr: "5px" }}
          >
            Assunto: Currículo de [nome da pessoa]
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          style={{ borderBottom: "1px solid grey" }}
        />
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
        >
          <Typography
            variant="h5"
            sx={{ fontSize: "14px", mt: "8px", color: "grey", fontFamily: "Rawline Thin", mr: "5px" }}
          >
            Assunto: Currículo de [nome da pessoa]
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default ContentModalEmail;
