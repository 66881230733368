import React, { useState } from "react";

import SaveIcon from "@mui/icons-material/Save";
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import { formatCNPJ, formatTelefone } from "../formularios/utils.js";
import FileUpload from "../inputs/fileUpload";

const AdminAnalisarCursoPendente = ({ valores, setValores }) => {
  const theme = useTheme();
  const [files, setFiles] = useState([]);

  const handleFilesChange = values => {
    // Dummy console log só para não reclamar do files
    console.log(files);
    setFiles(values);
  };

  const handleChanges = event => {
    const { name, value } = event.target;
    setValores({ ...valores, [name]: value });
  };

  const handleSalvar = () => {
    if (valores.situacao != "Pendente") {
      console.log("Informações salvas com sucesso!", valores);
      window.history.back();
    } else {
      console.log("Status do parceiro ainda é pendente!");
    }
  };

  return (
    <Stack spacing={1}>
      <Card
        color="#ffffff"
        sx={{
          borderRadius: "8px",
          padding: "16px",
          mt: "24px",
        }}
      >
        <Grid
          item
          xs={12}
        >
          <Typography variant="subtitle1">Analisar Curso Pendente</Typography>
        </Grid>

        {/* Nome e CNPJ */}
        <Grid
          spacing={1}
          container
        >
          <Grid
            item
            xs={12}
          >
            <Typography variant="subtitle2">Ofertante</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
          >
            <FormGroup>
              <TextField
                id="ofertante"
                name="ofertante"
                label="Nome"
                placeholder="Nome"
                value={valores.ofertante}
                type="text"
                disabled
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
          >
            <FormGroup>
              <TextField
                id="cnpj"
                name="cnpj"
                label="CNPJ"
                placeholder="CNPJ"
                value={formatCNPJ(valores.cnpj) || ""}
                type="text"
                disabled
              />
            </FormGroup>
          </Grid>
        </Grid>

        {/* Cadastro e Ultima Modificação e Telefone*/}
        <Grid
          container
          spacing={1}
          mt={1}
        >
          <Grid
            item
            xs={12}
            sm={4}
          >
            <FormGroup>
              <TextField
                id="cadastro"
                name="cadastro"
                label="Cadastro"
                placeholder="Cadastro"
                value={valores.cadastro}
                type="text"
                disabled
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
          >
            <FormGroup>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id="dataUltimaModificacao"
                  name="dataUltimaModificacao"
                  label="Última Modificação"
                  placeholder="Última Modificação"
                  value={dayjs(valores.dataUltimaModificacao)}
                  format="DD/MM/YYYY"
                  onChange={valor => setValores({ ...valores, ["dataUltimaModificacao"]: valor })}
                  disabled
                />
              </LocalizationProvider>
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
          >
            <FormGroup>
              <TextField
                id="telefone"
                name="telefone"
                label="Telefone"
                placeholder="Telefone"
                value={formatTelefone(valores.telefone) || ""}
                type="text"
                disabled
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Card>

      <Card
        color="#ffffff"
        sx={{
          borderRadius: "8px",
          padding: "16px",
          mt: "24px",
        }}
      >
        {/* Status e Botão Visualizar Alterações */}
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            xs={12}
          >
            <Typography variant="subtitle2">Motivo da pendência do curso</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Grid
              item
              xs={12}
            >
              <Checkbox
                id="novoCadastro"
                name="novoCadastro"
                style={{ color: theme.palette.text.disabled }}
                value={valores.novoCadastro}
              />
              Novo cadastro
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Checkbox
                id="cadastroAlterado"
                name="cadastroAlterado"
                style={{ color: theme.palette.text.disabled }}
                value={valores.cadastroAlterado}
              />
              Cadastro Alterado
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            container
            justifyContent="end"
          >
            <Grid item>
              <Button
                variant="outlined"
                href="analisar/alteracoes"
                sx={{ gap: "8px" }}
              >
                <Typography
                  variant={"BUTTON TEXT"}
                  sx={{
                    fontSize: "12px",
                    mt: "8px",
                    mb: "8px",
                  }}
                >
                  VISUALIZAR ALTERAÇÕES DO CURSO
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {/* Mudança de status*/}
        <Grid
          item
          xs={12}
          sm={9}
        >
          <FormGroup variant="filled">
            <FormControl variant="filled">
              <InputLabel>Status do Parceiro</InputLabel>
              <Select
                id="situacao"
                name="situacao"
                value={valores.situacao}
                type="text"
                onChange={handleChanges}
              >
                <MenuItem
                  value="Pendente"
                  disabled
                >
                  Altere o Status do Parceiro
                </MenuItem>
                <MenuItem value="Reprovar">Reprovar</MenuItem>
                <MenuItem value="Aprovar">Aprovar</MenuItem>
              </Select>
            </FormControl>
          </FormGroup>
        </Grid>

        {/* Motivo */}
        <Grid
          container
          mt={1}
          spacing={1}
        >
          <Grid
            item
            xs={12}
          >
            <FormGroup>
              <TextField
                multiline
                rows={4}
                id="motivo"
                name="motivo"
                label="Motivo"
                placeholder="Motivo"
                value={valores.motivo}
                type="text"
                onChange={handleChanges}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Card>

      <Card
        color="#ffffff"
        sx={{
          borderRadius: "8px",
          padding: "16px",
          mt: "24px",
        }}
      >
        {/* Anexar Documentos */}
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            xs={12}
          >
            <Typography variant="subtitle2">Anexar Documentos</Typography>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FileUpload
              onFieldsChange={handleFilesChange}
              fieldLabel={"Anexar Imagem"}
            />
          </Grid>
        </Grid>
      </Card>

      {/* Botões */}
      <Grid
        container
        spacing={1}
        mt={3}
        justifyContent="flex-end"
      >
        <Grid item>
          <Button
            variant="outlined"
            onClick={() => window.history.back()}
          >
            <Typography variant={"BUTTON TEXT"}>CANCELAR</Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            href=""
          >
            <Typography
              variant={"BUTTON TEXT"}
              sx={{
                whiteSpace: "wrap",
                minWidth: "auto",
              }}
            >
              VISUALIZAR OFERTANTE
            </Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            href="/listar-cursos-pendentes/analisar/historico"
          >
            <Typography
              variant={"BUTTON TEXT"}
              sx={{
                whiteSpace: "wrap",
                minWidth: "auto",
              }}
            >
              HISTÓRICO DO CURSO
            </Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            href=""
          >
            <Typography
              variant={"BUTTON TEXT"}
              sx={{
                whiteSpace: "wrap",
                minWidth: "auto",
              }}
            >
              VISUALIZAR CURSO
            </Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={handleSalvar}
            sx={{ gap: "8px" }}
          >
            <SaveIcon fontSize="small" />
            <Typography variant={"BUTTON TEXT"}>SALVAR</Typography>
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
};

AdminAnalisarCursoPendente.propTypes = {
  valores: PropTypes.any.isRequired,
  setValores: PropTypes.func.isRequired,
};

export default AdminAnalisarCursoPendente;
