import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Card, Container, Grid, Typography } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import { dadosParceirosPendentes } from "../../components/formularios/dadosMockados";
import DefaultTable from "../../components/table/DefaultTable";
import TitlePage from "../../components/title-page/TitlePage";

const PageVisualizarAlteracoes = () => {
  const [parceiro, setParceiro] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  let { id } = useParams();
  // TODO: remover esse default
  id = id || "1";

  // TODO: trocar para conexão com o back-end
  useEffect(() => {
    if (id) {
      const parceiroEncontrado = dadosParceirosPendentes.find(parceiro => parceiro.id === parseInt(id));

      if (parceiroEncontrado) {
        const servicos = Object.keys(parceiroEncontrado.tipoDeServico).filter(servico => parceiroEncontrado.tipoDeServico[servico]);

        setParceiro({
          ...parceiroEncontrado,
          tipoDeServico: servicos,
        });
      }
    }

    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <div>Carregando...</div>;
  }

  if (!parceiro) {
    return <div>Parceiro não encontrado</div>;
  }

  const colunas = [
    { field: "modificacao", headerName: "Modificação" },
    { field: "campoAlterado", headerName: "Campo alterado" },
    { field: "informacaoAnterior", headerName: "Informação anterior" },
    { field: "novaInformacao", headerName: "Nova informação" },
  ];

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/parceiro/listar/pendentes", text: "Listar Parceiros Pendentes" },
          { href: "/parceiro/analisar/" + id, text: "Analisar" },
        ]}
        currentPage="Informações alteradas"
      />

      <TitlePage title={"Informações alteradas"} />

      <Card
        color="#ffffff"
        sx={{
          borderRadius: "8px",
          padding: "16px",
          mt: "24px",
        }}
      >
        <Grid
          item
          xs={12}
        >
          <Typography variant="subtitle1">Informações alteradas no cadastro do parceiro {parceiro.nomePontoFocal}</Typography>
        </Grid>

        <DefaultTable
          columns={colunas}
          rows={[parceiro]}
        />
      </Card>
    </Container>
  );
};

export default PageVisualizarAlteracoes;
