import JSON_ATUACOES from "../assets/json/areas-atuacao-parceiro.json";
import { naturezaJuridicaDict, statusCodeDict, statusDict, termosDict, tipoDeServicoDict } from "../services/constants";

// Function to get status by code
const getStatusByCode = code => {
  code = code.toUpperCase();
  return statusDict[code] || "Desconhecido";
};

// Function to code by status
const getCodeByStatus = status => {
  return statusCodeDict[status.toLowerCase()] || "Desconhecido";
};

// Function to get type of service by code
const getTipoDeServicoByCode = code => {
  return tipoDeServicoDict[code] || "Desconhecido";
};

const getAreaAtuacaByCode = code => {
  const codeStr = String(code);
  const foundItem = JSON_ATUACOES.atuacoes.find(itemDaLista => itemDaLista.id === codeStr);

  const value = foundItem ? foundItem.descricao : "";
  return value;
};

// Function to get natureza juridica by description
const getNaturezaJuridicaByDescription = description => {
  for (const [key, value] of Object.entries(naturezaJuridicaDict)) {
    if (value === description) {
      return key;
    }
  }
  return "Desconhecido";
};

const getAllNaturezaJuridica = () => {
  return naturezaJuridicaDict;
};

// Function to get natureza juridica by code
const getNaturezaJuridicaByCode = code => {
  for (const [key, value] of Object.entries(naturezaJuridicaDict)) {
    if (key === code) {
      return value;
    }
  }
  return "Desconhecido";
};

const getAllServicosTermos = () => {
  return { ...tipoDeServicoDict };
};

const getAllTermos = () => {
  return { ...termosDict, ...tipoDeServicoDict };
};

const transformDictIntoOptions = dict => {
  return Object.entries(dict).map(([key, value]) => ({
    id: key.toString(),
    descricao: value,
  }));
};

const constantsService = {
  getStatusByCode,
  getCodeByStatus,
  getTipoDeServicoByCode,
  getAreaAtuacaByCode,
  getNaturezaJuridicaByDescription,
  getNaturezaJuridicaByCode,
  getAllNaturezaJuridica,
  getAllTermos,
  getAllServicosTermos,
  transformDictIntoOptions,
};

export default constantsService;
