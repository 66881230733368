import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ParceiroMobilizacaoVisualizarInformacoesCurso from "../../components/formularios/ParceiroMobilizacaoVisualizarInformacoesCurso";
import TitlePage from "../../components/title-page/TitlePage";

const PageMobilizacaoVisualizarInformacoesCurso = () => {
  const values = {
    ofertante: "Teste",
    curso: "Teste",
    vigencia: "Teste",
    modalidade: "Teste",
    tipo: "Teste",
    pronatec: "Teste",
    abrangencia: "Teste",
    uf: "Teste",
    municipio: "Teste",
    turno: "Teste",
    cargaHoraria: "Teste",
    vagas: "Teste",
    idadeMin: "Teste",
    idadeMax: "Teste",
    inicioCurso: "Teste",
    fimCurso: "Teste",
    escolaridadeMin: "Teste",
    endereco: "Teste",
    telefone: "Teste",
    requisitos: "Teste",
    linkExterno: "Teste",
    ementa: "Teste",
    descricao: "Teste",
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/", text: "Parceiro" },
          { href: "/mobilizacao", text: "Mobilizacao" },
          { href: "/mobilizacao/mobilizacao-individual-cidadao", text: "Mobilizacao Individual" },
        ]}
        currentPage={"Informações Curso"}
      />

      <TitlePage title={"Visualizar informações do curso"} />

      <ParceiroMobilizacaoVisualizarInformacoesCurso values={values} />
    </Container>
  );
};

export default PageMobilizacaoVisualizarInformacoesCurso;
