import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ParceiroCidadaoMicrocreditoFAQ from "../../components/formularios/ParceiroCidadaoMicrocreditoFAQ";
import TitlePage from "../../components/title-page/TitlePage";

const PageMicrocreditoFAQ = () => {
  const linksBreadCrumb = [
    { href: "/", text: "Parceiro" },
    { href: "/parceiro/microcrédito", text: "Microcrédito" },
  ];

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        currentPage="Questionário - Quero ser empreendedor"
        links={linksBreadCrumb}
      />

      <TitlePage title={"Perguntas frequentes - esclareça suas dúvidas"} />

      <ParceiroCidadaoMicrocreditoFAQ />
    </Container>
  );
};

export default PageMicrocreditoFAQ;
