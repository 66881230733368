import React, { useState } from "react";

import { Box, Button, Typography } from "@mui/material";
import { Card, Grid } from "@mui/material";
import PropTypes from "prop-types";

const FormAcessoMicrocreditoMPO = ({ aprovado = false }) => {
  const textOptions = [
    [
      "Obrigado por ter respondido ao questionário < nome do questionário > !",
      "Parabéns, você conseguiu o empréstimo e já está realizando o seu sonho de empreender o seu próprio negócio!",
      "Agora, empreenda com esperança, confiança, disciplina, excelência na produção e na prestação de serviços, foco na satisfação dos seus clientes, buscando sempre a sustentabilidade social, ambiental e econômica do seu negócio!",
      "Atenção: o dinheiro emprestado a você é para ser usado somente no seu negócio! Não use esse dinheiro para outra finalidade! Sem esse dinheiro o seu negócio não poderá gerar recursos para pagar a sua dívida do empréstimo tomado junto ao operador de microcrédito parceiro do Acredita, e você poderá ficar impedido de acessar a novos créditos!",
      "Mantenha-se atualizado, capacite-se. Veja se tem curso disponível na área de empreendedorismo aqui no Acredita ou em outro lugar.",
      "Lembre-se, um empreendedor não desiste dos seus sonhos!",
    ],
    [
      "Obrigado por ter respondido ao questionário < nome do questionário > !",
      "Parabéns, você foi contato por operador de microcrédito parceiro do Acredita!",
      "Agora, é aguardar a decisão do operador de microcrédito se vai ou não conceder o empréstimo de microcrédito produtivo orientado a você.",
      "Atenção: fazer o contato e conceder o empréstimo são decisões que cabem somente ao operador de microcrédito parceiro do Acredita.",
      "Mantenha-se atualizado, capacite-se. Veja se tem curso disponível na área de empreendedorismo aqui no Acredita ou em outro lugar.",
      "Lembre-se, um empreendedor não desiste dos seus sonhos!",
    ],
    [
      "Obrigado por ter respondido ao questionário < nome do questionário > !",
      "Parabéns, você conseguiu o empréstimo para realizar o seu sonho de empreender o seu negócio!",
      "Agora, mãos à obra para iniciar o seu negócio!",
      "Atenção: o dinheiro emprestado a você é para ser usado somente no seu negócio! Não use esse dinheiro para outra finalidade! Sem esse dinheiro o seu negócio não poderá gerar recursos para pagar a sua dívida do empréstimo tomado junto ao operador de microcrédito parceiro do Acredita, e você poderá ficar impedido de acessar a novos créditos!",
      "Mantenha-se atualizado, capacite-se. Veja se tem curso disponível na área de empreendedorismo aqui no Acredita ou em outro lugar.",
      "Lembre-se, um empreendedor não desiste dos seus sonhos!",
    ],
    [
      "Obrigado por ter respondido ao questionário < nome do questionário > !",
      "Parabéns, você já iniciou o seu negócio!",
      "Agora, é aguardar a decisão do operador de microcrédito se vai ou não conceder o empréstimo de microcrédito produtivo orientado a você. Não desanime, empreenda com esperança, confiança, disciplina, excelência na produção e na prestação de serviços, foco na satisfação dos seus clientes, buscando sempre a sustentabilidade social, ambiental e econômica do seu negócio.",
      "Atenção: conceder o empréstimo é decisão que cabe somente ao operador de microcrédito parceiro do Acredita.",
      "Mantenha-se atualizado, capacite-se. Veja se tem curso disponível na área de empreendedorismo aqui no Acredita ou em outro lugar.",
      "Lembre-se, um empreendedor não desiste dos seus sonhos!",
    ],
    [
      "Obrigado por ter respondido ao questionário < nome do questionário > !",
      "Parabéns, você já iniciou o seu negócio!",
      "Agora, é aguardar o contato para ver a possibilidade de acesso ao microcrédito produtivo orientado. Não desanime, empreenda com esperança, confiança, disciplina, excelência na produção e na prestação de serviços, foco na satisfação dos seus clientes, buscando sempre a sustentabilidade social, ambiental e econômica do seu negócio.",
      "Atenção: fazer o contato e conceder o empréstimo são decisões que cabem somente ao operador de microcrédito parceiro do Acredita.",
      "Mantenha-se atualizado, capacite-se. Veja se tem curso disponível na área de empreendedorismo aqui no Acredita ou em outro lugar.",
      "Lembre-se, um empreendedor não desiste dos seus sonhos!",
    ],
  ];

  const [selectedTextIndex, setSelectedTextIndex] = useState(0);
  const [showSecondCard, setShowSecondCard] = useState(false);

  return (
    <>
      {!aprovado && (
        <div>
          <Grid
            container
            spacing={2}
            mb={2}
          >
            <Grid
              item
              md={6}
              sm={6}
              xs={6}
            >
              <Button
                size="large"
                type="submit"
                variant="outlined"
                onClick={() => setSelectedTextIndex(prevIndex => (prevIndex + 1) % textOptions.length)}
                fullWidth
              >
                SITUAÇÃO DE CONTATO
              </Button>
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={6}
            >
              <Button
                size="large"
                type="submit"
                variant="contained"
                onClick={() => setSelectedTextIndex(prevIndex => (prevIndex + 1) % textOptions.length)}
                fullWidth
              >
                PERGUNTAS E RESPOSTAS
              </Button>
            </Grid>
          </Grid>

          <Card
            color="#ffffff"
            sx={{
              borderRadius: "8px",
              padding: "16px",
            }}
            style={{ transition: "1s" }}
          >
            <Typography
              variant="h6"
              style={{
                fontSize: "14px",
                mt: "8px",
                mb: "16px",
                fontFamily: "Rawline Regular",
                color: "#333333",
              }}
            >
              {textOptions[selectedTextIndex].map((line, index) => (
                <Box
                  key={index}
                  marginBottom="16px"
                >
                  {line}
                </Box>
              ))}
            </Typography>
          </Card>
        </div>
      )}
      {aprovado && (
        <div>
          <Grid
            container
            spacing={2}
            mb={2}
          >
            <Grid
              item
              md={6}
              sm={6}
              xs={6}
            >
              <Button
                size="large"
                type="submit"
                variant="outlined"
                onClick={() => console.log("situacao de contato")}
                fullWidth
              >
                SITUAÇÃO DE CONTATO
              </Button>
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={6}
            >
              <Button
                size="large"
                type="submit"
                variant="contained"
                onClick={() => setShowSecondCard(true)}
                fullWidth
              >
                PERGUNTAS E RESPOSTAS
              </Button>
            </Grid>
          </Grid>
          {!showSecondCard && (
            <Card
              color="#ffffff"
              sx={{ borderRadius: "8px", padding: "16px" }}
              style={{ transition: "1s" }}
            >
              <Typography
                variant="h6"
                style={{
                  fontSize: "14px",
                  mt: "8px",
                  mb: "16px",
                  fontFamily: "Rawline Regular",
                  color: "#333333",
                }}
              >
                <Box
                  key={0}
                  marginBottom="16px"
                >
                  Obrigado por ter respondido ao questionário [ nome do questionário ]
                  <br />
                  <br />
                  Parabéns, você já iniciou o seu negócio!
                  <br />
                  <br />
                  Agora, é aguardar o contato para ver a possibilidade de acesso ao microcrédito produtivo orientado. Não desanime,
                  empreenda com esperança, confiança, disciplina, excelência na produção e na prestação de serviços, foco na satisfação dos
                  seus clientes, buscando sempre a sustentabilidade social, ambiental e econômica do seu negócio
                  <br />
                  <br />
                  Atenção: fazer o contato e conceder o empréstimo são decisões que cabem somente ao operador de microcrédito parceiro do
                  Acredita.
                  <br />
                  <br />
                  Mantenha-se atualizado, capacite-se. Veja se tem curso disponível na área de empreendedorismo aqui no Acredita ou em outro
                  lugar.
                  <br />
                  <br />
                  Lembre-se, um empreendedor não desiste dos seus sonhos!
                </Box>
              </Typography>
              <Grid
                container
                spacing={2}
                mt={2}
              >
                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={6}
                >
                  <Button
                    size="large"
                    type="submit"
                    variant="outlined"
                    onClick={() => console.log("situacao de contato")}
                    fullWidth
                  >
                    INFORMAR SITUAÇÃO DO CONTATO
                  </Button>
                </Grid>
                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={6}
                >
                  <Button
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={() => setShowSecondCard(true)}
                    fullWidth
                  >
                    NÃO QUERO INFORMAR SITUAÇÃO
                  </Button>
                </Grid>
              </Grid>
            </Card>
          )}
          {showSecondCard && (
            <Card
              color="#ffffff"
              sx={{ borderRadius: "8px", padding: "16px" }}
              style={{ transition: "1s" }}
            >
              <Typography
                variant="h6"
                style={{
                  fontSize: "14px",
                  mt: "8px",
                  mb: "16px",
                  fontFamily: "Rawline Regular",
                  color: "#333333",
                }}
              >
                <Box
                  key={0}
                  marginBottom="16px"
                >
                  Obrigado por ter respondido ao questionário [ nome do questionário ] ! Agora, é aguardar o contato para ver a
                  possibilidade de acesso ao microcrédito produtivo orientado.
                  <br />
                  <br />
                  Atenção: fazer o contato e conceder o empréstimo são decisões que cabem somente ao operador de microcrédito parceiro do
                  Acredita.
                  <br />
                  <br />
                  Mantenha-se atualizado, capacite-se. Veja se tem curso disponível na área de empreendedorismo aqui no Acredita ou em outro
                  lugar.
                  <br />
                  <br />
                  Lembre-se, um empreendedor não desiste dos seus sonhos!
                </Box>
              </Typography>
              <Grid
                container
                spacing={2}
                mt={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Button
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={() => console.log("situacao de contato")}
                    fullWidth
                  >
                    REATIVAR INFORMAÇÃO
                  </Button>
                </Grid>
              </Grid>
            </Card>
          )}
        </div>
      )}
    </>
  );
};

FormAcessoMicrocreditoMPO.propTypes = {
  aprovado: PropTypes.bool,
};

export default FormAcessoMicrocreditoMPO;
