import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminParceiroListarInteressadosMPO from "../../components/filtros/AdminParceiroListarInteressadosMPO";
import TitlePage from "../../components/title-page/TitlePage";

const PageListarInteressadosMPO = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb currentPage="Listar Interessados MPO" />

      <TitlePage title={"Listar Interessados em MPO"} />

      <AdminParceiroListarInteressadosMPO />
    </Container>
  );
};

export default PageListarInteressadosMPO;
