import axios from "axios";

import authService from "../services/auth.service";
import API_URL from "./config";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: API_URL,
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      const errorMessage = error.response.data.message;
      const errorDetail = error.response.data.error;

      // Se for erro de token inválido, realizar o logout
      if (errorMessage === "Token inválido") {
        console.error("Token inválido:", errorDetail);
        alert("Erro: Token inválido ou expirado. Por favor, faça login novamente.");

        // Necessário obter o token para mandar para o logout
        const accessToken = localStorage.getItem("token");
        authService
          .logout(accessToken)
          .catch(logoutError => {
            // Se o erro for de token inválido, ignore-o
            if (logoutError.response && logoutError.response.data.message === "Token inválido") {
              console.error("Erro de logout suprimido: Token inválido");
            } else {
              // Se o erro for diferente, lançar novamente
              return Promise.reject(logoutError);
            }
          })
          .finally(() => {
            localStorage.removeItem("token");
            window.location.href = "/login";
          });
      }
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
