import React, { useState } from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import FormInscricaoParceiro from "../../components/formularios/empresaParceira/FormInscricaoParceiro";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import { useAuth } from "../../contexts/AuthContext";
import parceiroService from "../../services/parceiro.service";
import { transformaParaBackend } from "../../utils/parceiroEmpresaUtils";

const valoresIniciais = {
  email: "",
  cnpj: "",
  razaoSocial: "",
  nomeFantasia: "",
  cep: "",
  endereco: "",
  numero: "",
  complemento: "",
  bairro: "",
  uf: "",
  cidade: "",
  telefone: "",
  site: "",
  redesSociais: [""],
  nomeRepresentante: "",
  cpfRepresentante: "",
  telefoneRepresentante: "",
  ufRepresentante: "",
  cidadeRepresentante: "",
  nomePontoFocal: "",
  emailPontoFocal: "",
  telefonePontoFocal: "",
  areaAtuacao: "",
  naturezaJuridica: "",
  tipo: "",
  porte: "",
  checkVagaEmprego: false,
  checkVagaEstagio: false,
  checkVagaJovem: false,
  checkCursos: false,
  checkFinanceiro: false,
  checkMobilidadePublico: false,
  checkMobilidadeParceiro: false,
  toggleCienteNormas: false,
  toggleCienteGratuito: false,
};

const PageNovaInscricaoParceiro = () => {
  const { user, token, getAuthUser } = useAuth();

  const [loading, setLoading] = useState(false);
  const [confirmacaoModal, setConfirmacaoModal] = useState(false);
  const [erroModal, setErroModal] = useState(false);
  const [erroDoUsuarioModal, setErroDoUsuarioModal] = useState(false);

  const conteudoConfirmacaoModal = (
    <>
      <center>
        {
          "Cadastro realizado com sucesso e enviado para aprovação da Secretaria de Inclusão Socioeconômica do Ministério do Desenvolvimento e Assistência Social, Família e Combate à Fome."
        }
      </center>
      <center>
        {"Assim que seu cadastro for validado, você será informado da aprovação por e-mail e poderá iniciar a oferta de serviços."}
      </center>
    </>
  );

  const dadosIniciais = {
    ...valoresIniciais,
    email: user.ds_email,
    nomeRepresentante: user.no_usuario,
    cpfRepresentante: user.nu_cpf,
    telefoneRepresentante: user.telefone,
    ufRepresentante: user.uf,
    cidadeRepresentante: user.cidade,
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    if (
      values.checkVagaEmprego ||
      values.checkVagaEstagio ||
      values.checkVagaJovem ||
      values.checkCursos ||
      values.checkFinanceiro ||
      values.checkMobilidadePublico ||
      values.checkMobilidadeParceiro
    ) {
      const novoParceiro = transformaParaBackend(values);

      return parceiroService
        .postCadastrarEmpresa(novoParceiro)
        .then(mensagem => {
          console.log("Sucesso:", mensagem);
          setConfirmacaoModal(true);
          // After successful registration, call getAuthUser()
          getAuthUser(token);
        })
        .catch(erro => {
          console.error(" ", erro);
          setErroModal(true);
        })
        .finally(() => {
          setSubmitting(false);
          setLoading(false);
        });
    } else {
      setErroDoUsuarioModal(true);
      setSubmitting(false);
      setLoading(false);
      console.error("Há campos em branco");
    }
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "", text: "Rede" },
          { href: "/parceiro/listar-parceiros", text: "Parceiro" },
        ]}
        currentPage="Nova inscrição"
      />

      <TitlePage title={"Nova inscrição"} />

      {loading ? (
        <LoadingComponent
          isLoading={loading}
          fullScreen={false}
        />
      ) : (
        <FormInscricaoParceiro
          initialValues={dadosIniciais}
          loading={loading}
          handleSubmit={handleSubmit}
          confirmacaoModal={confirmacaoModal}
          setConfirmacaoModal={setConfirmacaoModal}
          conteudoConfirmacaoModal={conteudoConfirmacaoModal}
          erroModal={erroModal}
          setErroModal={setErroModal}
          erroDoUsuarioModal={erroDoUsuarioModal}
          setErroDoUsuarioModal={setErroDoUsuarioModal}
        />
      )}
    </Container>
  );
};

export default PageNovaInscricaoParceiro;
