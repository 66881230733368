import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import SaveIcon from "@mui/icons-material/Save";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, Chip, FormControl, FormGroup, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";

import { st_pronatec, tp_abrangencia, tp_curso, tp_escolaridade, tp_modalidade, tp_turno } from "../../services/constants.js";
import optionsService from "../../services/options.service";
import { SelectCidade } from "./fields/SelectCidade";
import { SelectUF } from "./fields/SelectUF";
import { formatTelefone } from "./utils.js";

const FormCurso = ({ value, visualizacao, handleSubmit }) => {
  const navigate = useNavigate();
  const values = value
    ? value
    : {
        cnpjOfertante: "",
        nomeOfertante: "",
        cargaHoraria: "",
        turno: "",
        nomeDoCurso: "",
        escolaridade: "",
        tipoDeCurso: "",
        pronatec: "",
        pre_requisitos: [],
        inicio_matricula: null,
        fim_matricula: null,
        idade_min: "",
        idade_max: "",
        modalidade: "",
        trilhas: "",
        inicio_curso: null,
        fim_curso: null,
        link: "",
        cep: "",
        uf: "",
        endereco: "",
        numero: "",
        complemento: "",
        bairro: "",
        municipio: "",
        telefone: "",
        vagas: "",
        abrangencia: "",
        uf_abrangencia: "",
        municipio_abrangencia: "",
        ementa: "",
        descricao: "",
        vigencia: "",
        ultima_modificacao: "",
        situacao: "",
        motivo_situacao: "",
      };

  const listaPreRequisitos = [
    "Ensino Fundamental",
    "Ensino Médio",
    "Ensino Superior",
    "Conhecimento em Informática",
    "Conhecimento em Matemática",
    "Conhecimento em Português",
    "Conhecimento em Inglês",
    "Conhecimento em Espanhol",
    "Conhecimento em Francês",
    "Conhecimento em Alemão",
    "Conhecimento em Chinês",
    "Ciências Humanas",
  ];

  const [ufs, setUfs] = useState([]);
  const [municipios, setMunicipios] = value
    ? useState(optionsService.cidadesJSON.filter(cidade => cidade.estado === values.uf))
    : useState([]);
  const [municipiosAbrangencia, setMunicipiosAbrangencia] = value
    ? useState(optionsService.cidadesJSON.filter(cidade => cidade.estado === values.uf_abrangencia))
    : useState([]);

  const [selectedUf, setSelectedUf] = value ? useState(value.uf) : useState("");
  const [selctedMunicipio, setSelectedMunicipio] = value ? useState(value.municipio) : useState("");

  const [selectedUfAbrangencia, setSelectedUfAbrangencia] = value ? useState(value.uf_abrangencia) : useState("");
  const [selectedMunicipioAbrangencia, setSelectedMunicipioAbrangencia] = value ? useState(value.municipio_abrangencia) : useState("");

  useEffect(() => {
    const fetchUfs = async () => {
      await optionsService
        .getAllUFs()
        .then(ufsData => {
          setUfs(ufsData);
        })
        .catch(error => {
          console.error("Erro ao obter UFs:", error);
        });
    };
    fetchUfs();
  }, []);

  const fetchCidadesByUf = async (ufSigla, setCidadesFunction) => {
    try {
      const cidadesData = await optionsService.getCidadesFromUF(ufSigla);
      setCidadesFunction(cidadesData);
    } catch (error) {
      console.error("Erro ao obter cidades:", error);
    }
  };

  const handleSelectUf = (event, setFieldValue) => {
    const { name, value } = event.target;
    setFieldValue(name, value);

    if (name === "uf") {
      setSelectedUf(value);
      setSelectedMunicipio("");
      setFieldValue("municipio", "");
      fetchCidadesByUf(event.target.value, setMunicipios);
    } else if (name === "uf_abrangencia") {
      setSelectedUfAbrangencia(event.target.value);
      setSelectedMunicipioAbrangencia("");
      setFieldValue("municipio_abrangencia", "");
      fetchCidadesByUf(event.target.value, setMunicipiosAbrangencia);
    }
  };

  const handleSelectMunicipio = (event, setFieldValue) => {
    const { name, value } = event.target;
    setFieldValue(name, value);

    if (name === "municipio") {
      setSelectedMunicipio(event.target.value);
    } else if (name === "municipio_abrangencia") {
      setSelectedMunicipioAbrangencia(event.target.value);
    }
  };

  return (
    <Card
      sx={{
        padding: 2,
        mt: 2,
        borderRadius: 2,
      }}
    >
      <Formik
        initialValues={values}
        onSubmit={values => {
          console.log(values);
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            <Grid
              container
              spacing={2}
            >
              {!visualizacao && (
                <Grid
                  item
                  xs={12}
                >
                  <Typography variant="h6">Dados do Curso</Typography>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormControl fullWidth>
                  <InputLabel>CNPJ ofertante</InputLabel>
                  <Select
                    id="cnpjOfertante"
                    name="cnpjOfertante"
                    value={values.cnpjOfertante}
                    onChange={handleChange}
                    disabled={visualizacao}
                  >
                    <MenuItem value={0}>000.000.000/0000-00</MenuItem>
                    <MenuItem value={1}>111.111.111/1111-11</MenuItem>
                    <MenuItem value={2}>222.222.222/2222-22</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  id="nomeOfertante"
                  name="nomeOfertante"
                  label="Nome do ofertante"
                  value={values.nomeOfertante}
                  onChange={handleChange}
                  disabled={visualizacao}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  id="cargaHoraria"
                  name="cargaHoraria"
                  label="*Carga horária"
                  value={values.cargaHoraria}
                  onChange={handleChange}
                  disabled={visualizacao}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormControl fullWidth>
                  <InputLabel>Turno</InputLabel>
                  <Select
                    id="turno"
                    name="turno"
                    value={values.turno}
                    onChange={handleChange}
                    disabled={visualizacao}
                  >
                    {Object.keys(tp_turno).map(turno => (
                      <MenuItem
                        key={turno}
                        value={turno}
                      >
                        {tp_turno[turno]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
              >
                <TextField
                  id="nomeDoCurso"
                  name="nomeDoCurso"
                  label="Nome do curso"
                  value={values.nomeDoCurso}
                  onChange={handleChange}
                  disabled={visualizacao}
                  fullWidth
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormControl fullWidth>
                  <InputLabel>Escolaridade exigida</InputLabel>
                  <Select
                    id="escolaridade"
                    name="escolaridade"
                    value={values.escolaridade}
                    onChange={handleChange}
                    disabled={visualizacao}
                  >
                    {Object.keys(tp_escolaridade).map(escolaridade => (
                      <MenuItem
                        key={escolaridade}
                        value={escolaridade}
                      >
                        {tp_escolaridade[escolaridade]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormControl fullWidth>
                  <InputLabel>Tipo de Curso</InputLabel>
                  <Select
                    id="tipoDeCurso"
                    name="tipoDeCurso"
                    value={values.tipoDeCurso}
                    onChange={handleChange}
                    disabled={visualizacao}
                  >
                    {Object.keys(tp_curso).map(curso => (
                      <MenuItem
                        key={curso}
                        value={curso}
                      >
                        {tp_curso[curso]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormControl fullWidth>
                  <InputLabel>Pronatec</InputLabel>
                  <Select
                    id="pronatec"
                    name="pronatec"
                    value={values.pronatec}
                    onChange={handleChange}
                    disabled={visualizacao}
                  >
                    {Object.keys(st_pronatec).map(value => (
                      <MenuItem
                        key={value}
                        value={value}
                      >
                        {st_pronatec[value]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormControl fullWidth>
                  <InputLabel>Pré-requisitos</InputLabel>
                  <Select
                    id="pre_requisitos"
                    name="pre_requisitos"
                    multiple
                    value={values.pre_requisitos}
                    onChange={handleChange}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Chip"
                      />
                    }
                    renderValue={selected => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map(value => (
                          <Chip
                            key={value}
                            label={value}
                          />
                        ))}
                      </Box>
                    )}
                    disabled={visualizacao}
                  >
                    {listaPreRequisitos.map(req => (
                      <MenuItem
                        key={req}
                        value={req}
                      >
                        {req}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
              >
                <FormGroup>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      id="inicio_matricula"
                      name="inicio_matricula"
                      label="Início matrícula"
                      value={dayjs(values.inicio_matricula)}
                      disabled={visualizacao}
                      fullWidth
                      format="DD/MM/YYYY"
                      onChange={value => {
                        setFieldValue("inicio_matricula", dayjs(value));
                      }}
                    />
                  </LocalizationProvider>
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
              >
                <FormGroup>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      id="fim_matricula"
                      name="fim_matricula"
                      label="Fim matrícula"
                      value={dayjs(values.fim_matricula)}
                      disabled={visualizacao}
                      fullWidth
                      format="DD/MM/YYYY"
                      onChange={value => {
                        setFieldValue("fim_matricula", dayjs(value));
                      }}
                    />
                  </LocalizationProvider>
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
              >
                <TextField
                  id="idade_min"
                  name="idade_min"
                  label="Idade mínima"
                  value={values.idade_min}
                  onChange={handleChange}
                  disabled={visualizacao}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
              >
                <TextField
                  id="idade_max"
                  name="idade_max"
                  label="Idade máxima"
                  value={values.idade_max}
                  onChange={handleChange}
                  disabled={visualizacao}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormControl fullWidth>
                  <InputLabel>Modalidade</InputLabel>
                  <Select
                    id="modalidade"
                    name="modalidade"
                    value={values.modalidade}
                    onChange={handleChange}
                    disabled={visualizacao}
                  >
                    {Object.keys(tp_modalidade).map(modalidade => (
                      <MenuItem
                        key={modalidade}
                        value={modalidade}
                      >
                        {tp_modalidade[modalidade]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  id="trilhas"
                  name="trilhas"
                  label="Trilhas"
                  value={values.trilhas}
                  onChange={handleChange}
                  disabled={visualizacao}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
              >
                <FormGroup>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      id="inicio_curso"
                      name="inicio_curso"
                      label="Início do curso"
                      value={dayjs(values.inicio_curso)}
                      disabled={visualizacao}
                      fullWidth
                      format="DD/MM/YYYY"
                      onChange={value => {
                        setFieldValue("inicio_curso", dayjs(value));
                      }}
                    />
                  </LocalizationProvider>
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
              >
                <FormGroup>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      id="fim_curso"
                      name="fim_curso"
                      label="Fim do curso"
                      value={dayjs(values.fim_curso)}
                      disabled={visualizacao}
                      fullWidth
                      format="DD/MM/YYYY"
                      onChange={value => {
                        setFieldValue("fim_curso", dayjs(value));
                      }}
                    />
                  </LocalizationProvider>
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
              >
                <TextField
                  id="link"
                  name="link"
                  label="Link Externo"
                  value={values.link}
                  disabled={visualizacao}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              <Grid
                item
                xs={12}
              >
                <Typography variant="h6">Informações adicionais para modalidade presencial ou semipresencial</Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
              >
                <FormGroup>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      id="inicio_curso"
                      name="inicio_curso"
                      label="Início do curso"
                      value={dayjs(values.inicio_curso)}
                      disabled={visualizacao}
                      fullWidth
                      format="DD/MM/YYYY"
                      onChange={value => {
                        setFieldValue("inicio_curso", dayjs(value));
                      }}
                    />
                  </LocalizationProvider>
                </FormGroup>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
              >
                <FormGroup>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      id="fim_curso"
                      name="fim_curso"
                      label="Fim do curso"
                      value={dayjs(values.fim_curso)}
                      disabled={visualizacao}
                      fullWidth
                      format="DD/MM/YYYY"
                      onChange={value => {
                        setFieldValue("fim_curso", dayjs(value));
                      }}
                    />
                  </LocalizationProvider>
                </FormGroup>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
              >
                <TextField
                  id="cep"
                  name="cep"
                  label="CEP"
                  value={values.cep}
                  disabled={visualizacao}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
              >
                <SelectUF
                  idSelect="uf"
                  nameSelect="uf"
                  value={values.uf}
                  ufs={ufs}
                  uf={selectedUf}
                  label="UF"
                  visualizacao={visualizacao}
                  handleSelectUf={event => handleSelectUf(event, setFieldValue)}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
              >
                <TextField
                  id="endereco"
                  name="endereco"
                  label="Logradouro"
                  value={values.endereco}
                  onChange={handleChange}
                  disabled={visualizacao}
                  fullWidth
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
              >
                <TextField
                  id="numero"
                  name="numero"
                  label="Número"
                  value={values.numero}
                  onChange={handleChange}
                  disabled={visualizacao}
                  fullWidth
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
              >
                <TextField
                  id="complemento"
                  name="complemento"
                  label="Complemento"
                  value={values.complemento}
                  onChange={handleChange}
                  disabled={visualizacao}
                  fullWidth
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  id="bairro"
                  name="bairro"
                  label="Bairro"
                  value={values.bairro}
                  onChange={handleChange}
                  disabled={visualizacao}
                  fullWidth
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
              >
                <SelectCidade
                  idSelect="municipio"
                  nameSelect="municipio"
                  value={values.municipio}
                  cidades={municipios}
                  cidade={selctedMunicipio}
                  label="Município"
                  visualizacao={visualizacao}
                  handleSelectCidade={event => handleSelectMunicipio(event, setFieldValue)}
                />
              </Grid>

              {/* Telefone e Vagas */}
              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  id="telefone"
                  name="telefone"
                  label="Telefone"
                  value={formatTelefone(values.telefone)}
                  onChange={handleChange}
                  disabled={visualizacao}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  id="vagas"
                  name="vagas"
                  label="Vagas"
                  value={values.vagas}
                  onChange={handleChange}
                  disabled={visualizacao}
                  fullWidth
                />
              </Grid>

              {/* Abrangência, Uf e Município */}
              <Grid
                item
                xs={12}
                sm={4}
              >
                <FormControl fullWidth>
                  <InputLabel>Abrangência</InputLabel>
                  <Select
                    id="abrangencia"
                    name="abrangencia"
                    value={values.abrangencia}
                    onChange={handleChange}
                    disabled={visualizacao}
                  >
                    {Object.keys(tp_abrangencia).map(abrangencia => (
                      <MenuItem
                        key={abrangencia}
                        value={abrangencia}
                      >
                        {tp_abrangencia[abrangencia]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
              >
                <SelectUF
                  idSelect="uf_abrangencia"
                  nameSelect="uf_abrangencia"
                  value={values.uf_abrangencia}
                  ufs={ufs}
                  uf={selectedUfAbrangencia}
                  label="UF de Abrangência"
                  visualizacao={visualizacao}
                  handleSelectUf={event => handleSelectUf(event, setFieldValue)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
              >
                <SelectCidade
                  idSelect="municipio_abrangencia"
                  nameSelect="municipio_abrangencia"
                  value={values.municipio_abrangencia}
                  cidades={municipiosAbrangencia}
                  cidade={selectedMunicipioAbrangencia}
                  label="Município de Abrangência"
                  visualizacao={visualizacao}
                  handleSelectCidade={event => handleSelectMunicipio(event, setFieldValue)}
                />
              </Grid>

              {/* Emente e Breve Descrição */}
              <Grid
                item
                xs={12}
              >
                <TextField
                  id="ementa"
                  name="ementa"
                  label="Ementa"
                  value={values.ementa}
                  onChange={handleChange}
                  disabled={visualizacao}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  multiline
                  id="descricao"
                  name="descricao"
                  label="Breve Descrição"
                  value={values.descricao}
                  onChange={handleChange}
                  disabled={visualizacao}
                  fullWidth
                />
              </Grid>

              {!visualizacao && (
                <Grid
                  item
                  xs={12}
                >
                  <Typography variant="h6">Anexar Imagem</Typography>
                  <FormGroup>
                    <TextField
                      variant="filled"
                      label="Anexar imagem"
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: (
                          <Button
                            component="label"
                            role={undefined}
                            variant="secondary"
                            tabIndex={-1}
                          >
                            <UploadFileRoundedIcon />
                          </Button>
                        ),
                      }}
                    />
                  </FormGroup>
                </Grid>
              )}
              {visualizacao && (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                  >
                    <TextField
                      id="vigencia"
                      name="vigencia"
                      label="Vigência"
                      value={values.vigencia}
                      onChange={handleChange}
                      disabled={visualizacao}
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                  >
                    <TextField
                      id="ultima_modificacao"
                      name="ultima_modificacao"
                      label="Última modificação"
                      value={values.ultima_modificacao}
                      onChange={handleChange}
                      disabled={visualizacao}
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                  >
                    <TextField
                      id="situacao"
                      name="situacao"
                      label="Situação"
                      value={values.situacao}
                      onChange={handleChange}
                      disabled={visualizacao}
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      id="motivo_situacao"
                      name="motivo_situacao"
                      label="Motivo da Situação"
                      value={values.motivo_situacao}
                      onChange={handleChange}
                      disabled={visualizacao}
                      multiline
                      fullWidth
                    />
                  </Grid>
                </>
              )}
            </Grid>

            <Grid
              container
              spacing={2}
              mt={2}
              justifyContent="flex-end"
            >
              {!visualizacao && (
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => navigate("/parceiro/cursos/listar")}
                    fullWidth
                  >
                    Cancelar
                  </Button>
                </Grid>
              )}
              {!visualizacao && (
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ gap: "8px" }}
                    onSubmit={values => handleSubmit(values)}
                  >
                    <SaveIcon fontSize="small" />
                    <Typography variant={"BUTTON TEXT"}>SALVAR</Typography>
                  </LoadingButton>
                </Grid>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

FormCurso.propTypes = {
  value: PropTypes.object,
  visualizacao: PropTypes.bool,
  handleSubmit: PropTypes.func,
};

export default FormCurso;
