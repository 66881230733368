import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminAlertaInteresseMPO from "../../components/filtros/AdminAlertaInteresseMPO";
import TitlePage from "../../components/title-page/TitlePage";

const PageAlertaInteresseMPO = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb currentPage="Listar Interessados em MPO" />

      <TitlePage title={"Listar Interessados em MPO"} />

      <AdminAlertaInteresseMPO />
    </Container>
  );
};

export default PageAlertaInteresseMPO;
