import React, { useEffect, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import SaveIcon from "@mui/icons-material/Save";
import { Button, Checkbox, FormControlLabel, FormLabel, Grid, MenuItem, TextField } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import FormGroup from "@mui/material/FormGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

import ReusableSwitch from "../../components/formularios/fields/ReusableSwitch";
import { SelectCidade } from "../../components/formularios/fields/SelectCidade";
import { SelectUF } from "../../components/formularios/fields/SelectUF";
import FormMultipleInsert from "../../components/formularios/FormMultipleInsert";
import optionsService from "../../services/options.service";
import InformationBox from "../boxes/InformationBox";

const FormJaSouEmpreendedor = ({ value }) => {
  const [ufs, setUfs] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [selectedUf, setSelectedUf] = useState("");
  const [selectedMunicipio, setSelectedMunicipio] = useState("");
  const theme = useTheme();

  const [values, setValues] = useState(
    value || {
      atividadeVariacaoNegocio: {
        atividadeNegocio: "",
        interesseVariarAtuacao: false,
      },
      gestaoFinanceira: {
        fontesRecurso: [],
        controleEntradas: false,
        separaComprasNegocioFamilia: false,
        compraProdutos: [],
        precisouFinanciamento: false,
        calculaCustoFixo: false,
        fazAlgoReduzirCusto: false,
        sabeDizerQuantoProduz: "",
      },
      tempoNegocio: {
        tempoNegocio: "",
        comoVeNegocio3Anos: "",
      },
      estrategiaMarketing: {
        comoFazPropaganda: [],
        porqueConsumidorDeveriaComprar: "",
      },
      crescimentoDesenvolvimento: {
        oQueNegocioPrecisaParaCrescer: [],
        situacaoNegocio: "",
        cnpj: "",
        gostariaReceberOrientacaoAdministracao: false,
      },
      aspectosPessoaisMotivacao: {
        jaPensouDesistir: false,
        porqueTemNegocio: [],
      },
      recursosHumanosCapacitacao: {
        temAlgumFuncionario: false,
        participouAlgumCurso: false,
        cursos: [
          {
            nome: "",
            instituicao: "",
          },
        ],
        gostariaParticiparCurso: false,
        motivoParticiparCurso: "",
        gostariaReceberOrientacao: false,
        motivoReceberOrientacao: "",
      },
      financasMicrocredito: {
        necessidadeFinancimento: false,
        motivoNecessidadeFinanciamento: "",
        conheceEmprestimos: false,
      },
      dadosPessoaisContato: {
        localNegocio: [],
        outroLocal: "",
        nomeCompleto: "",
        dataNascimento: "",
        rg: "",
        cpf: "",
        genero: "",
        escolaridade: "",
        cep: "",
        uf: "",
        localidade: "",
        bairro: "",
        endereco: "",
        numero: "",
        complemento: "",
        telefone: "",
        email: "",
        conheceEmprestimosEscolaridade: "",
      },
    },
  );

  const [multiplesInput, setMultiplesInput] = useState([]);

  useEffect(() => {
    const fetchUfs = async () => {
      await optionsService
        .getAllUFs()
        .then(ufsData => {
          setUfs(ufsData);
        })
        .catch(error => {
          console.error("Erro ao obter UFs:", error);
        });
    };
    fetchUfs();
  }, []);

  const fetchCidadesByUf = async (ufSigla, setCidadesFunction) => {
    try {
      const cidadesData = await optionsService.getCidadesFromUF(ufSigla);
      setCidadesFunction(cidadesData);
    } catch (error) {
      console.error("Erro ao obter cidades:", error);
    }
  };

  const handleSelectUf = event => {
    // eslint-disable-next-line no-unused-vars
    const { name, value } = event.target;

    setSelectedUf(value);
    setSelectedMunicipio("");
    fetchCidadesByUf(event.target.value, setMunicipios);
  };

  const handleSelectMunicipio = event => {
    setSelectedMunicipio(event.target.value);
  };

  const handleSwitchChange = (event, category, field) => {
    const { checked } = event.target;
    setValues(prevValues => ({
      ...prevValues,
      [category]: {
        ...prevValues[category],
        [field]: checked,
      },
    }));
  };

  const handleCheckboxChange = (event, category, field) => {
    const { checked, value } = event.target;
    setValues(prevValues => {
      const newArray = checked ? [...prevValues[category][field], value] : prevValues[category][field].filter(item => item !== value);
      return {
        ...prevValues,
        [category]: {
          ...prevValues[category],
          [field]: newArray,
        },
      };
    });
  };

  const handleInputChange = (event, category, field) => {
    const { value } = event.target;
    setValues(prevValues => ({
      ...prevValues,
      [category]: {
        ...prevValues[category],
        [field]: value,
      },
    }));
  };

  const handleRadioChange = (event, category, field) => {
    const { value } = event.target;
    setValues(prevValues => ({
      ...prevValues,
      [category]: {
        ...prevValues[category],
        [field]: value,
      },
    }));
  };

  const handleChange = event => {
    const { name, value } = event.target.value;
    setValues({ ...values, [name]: value });
  };

  const handleMultipleInputs = values => {
    console.log(multiplesInput);
    setMultiplesInput(values);
  };

  const handleSubmit = values => {
    console.log("SEND OBJ -> ", values);
  };

  return (
    <>
      <div>
        <Accordion style={{ border: "1px solid grey" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontFamily: "Rawline Bold", color: "primary.main" }}
          >
            <InfoIcon sx={{ marginRight: "8px", color: "primary.main" }} />
            Atividade e variação do negócio
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={12}
              >
                <TextField
                  label="Qual a atividade do seu negócio?"
                  name="atividade"
                  value={values.atividadeVariacaoNegocio.atividadeNegocio}
                  onChange={e => handleInputChange(e, "atividadeVariacaoNegocio", "atividadeNegocio")}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <p style={{ fontFamily: "rawline Bold", marginTop: "10px", marginBottom: "5px" }}>
                  Você tem interesse em variar a linha de atuação do seu negócio?
                </p>
                <ReusableSwitch
                  id="interesseVariarAtuacao"
                  name="interesseVariarAtuacao"
                  value={values.atividadeVariacaoNegocio.interesseVariarAtuacao}
                  onChange={e => handleSwitchChange(e, "atividadeVariacaoNegocio", "interesseVariarAtuacao")}
                  disabled={false}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ border: "1px solid grey" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontFamily: "Rawline Bold", color: "primary.main" }}
          >
            <InfoIcon sx={{ marginRight: "8px", color: "primary.main" }} />
            Gestão financeira
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <p style={{ fontFamily: "rawline SemiBold", marginTop: "10px", marginBottom: "5px" }}>
                  Qual(is) a(s) fonte(s) de recursos para manutenção do seu negócio?
                </p>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.gestaoFinanceira.fontesRecurso.includes("Dinheiro próprio")}
                        onChange={e => handleCheckboxChange(e, "gestaoFinanceira", "fontesRecurso")}
                        name="Dinheiro próprio"
                        value="Dinheiro próprio"
                      />
                    }
                    label="Dinheiro próprio"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.gestaoFinanceira.fontesRecurso.includes("Dinheiro de Familiares e amigos")}
                        onChange={e => handleCheckboxChange(e, "gestaoFinanceira", "fontesRecurso")}
                        name="Dinheiro de Familiares e amigos"
                        value="Dinheiro de Familiares e amigos"
                      />
                    }
                    label="Dinheiro de Familiares e amigos"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.gestaoFinanceira.fontesRecurso.includes("Faturamento do próprio negócio")}
                        onChange={e => handleCheckboxChange(e, "gestaoFinanceira", "fontesRecurso")}
                        name="Faturamento do próprio negócio"
                        value="Faturamento do próprio negócio"
                      />
                    }
                    label="Faturamento do próprio negócio"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.gestaoFinanceira.fontesRecurso.includes("Nenhuma")}
                        onChange={e => handleCheckboxChange(e, "gestaoFinanceira", "fo  ntesRecurso")}
                        name="setores"
                        value="outros"
                      />
                    }
                    label="Nenhuma"
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <p style={{ fontFamily: "rawline Bold", marginTop: "10px", marginBottom: "5px" }}>
                  Você tem controle de entradas e saídas de dinheiro do seu negócio?
                </p>
                <ReusableSwitch
                  id="controleEntradas"
                  name="controleEntradas"
                  value={values.gestaoFinanceira.controleEntradas}
                  onChange={e => handleSwitchChange(e, "gestaoFinanceira", "controleEntradas")}
                  disabled={false}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <p style={{ fontFamily: "rawline Bold", marginTop: "10px", marginBottom: "5px" }}>
                  Você separa as compras do seu negócio das que você faz para sua família?
                </p>
                <ReusableSwitch
                  id="interesseVariarAtuacao"
                  name="interesseVariarAtuacao"
                  value={values.gestaoFinanceira.separaComprasNegocioFamilia}
                  onChange={e => handleSwitchChange(e, "gestaoFinanceira", "separaComprasNegocioFamilia")}
                  disabled={false}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <p style={{ fontFamily: "rawline Bold", marginTop: "10px", marginBottom: "5px" }}>
                  Como você faz a compra de produtos e serviços para seu negócio?
                </p>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.gestaoFinanceira.compraProdutos.includes("Compras no comércio local")}
                        onChange={e => handleCheckboxChange(e, "gestaoFinanceira", "compraProdutos")}
                        name="Compras no comércio local"
                        value="Compras no comércio local"
                      />
                    }
                    label="Compras no comércio local"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.gestaoFinanceira.compraProdutos.includes("Compras via internet")}
                        onChange={e => handleCheckboxChange(e, "gestaoFinanceira", "compraProdutos")}
                        name="Compras via internet"
                        value="Compras via internet"
                      />
                    }
                    label="Compras via internet"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.gestaoFinanceira.compraProdutos.includes("Compras de grandes fornecedores")}
                        onChange={e => handleCheckboxChange(e, "gestaoFinanceira", "compraProdutos")}
                        name="Compras de grandes fornecedores"
                        value="Compras de grandes fornecedores"
                      />
                    }
                    label="Compras de grandes fornecedores"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.gestaoFinanceira.compraProdutos.includes("Outros meios...")}
                        onChange={e => handleCheckboxChange(e, "gestaoFinanceira", "compraProdutos")}
                        name="Outros meios..."
                        value="Outros meios..."
                      />
                    }
                    label="Outros meios..."
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <p style={{ fontFamily: "rawline Bold", marginTop: "10px", marginBottom: "5px" }}>
                  Você já precisou de financiamento/empréstimo para melhorar seu negócio?
                </p>
                <ReusableSwitch
                  id="PrecisouFinanciamento"
                  name="PrecisouFinanciamento"
                  value={values.gestaoFinanceira.precisouFinanciamento}
                  onChange={e => handleSwitchChange(e, "gestaoFinanceira", "PrecisouFinanciamento")}
                  disabled={false}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <p style={{ fontFamily: "rawline Bold", marginTop: "10px", marginBottom: "5px" }}>
                  Você calcula o custo fixo e variável por unidade do produto/serviço prestado?
                </p>
                <ReusableSwitch
                  id="calculaCustoFixo"
                  name="calculaCustoFixo"
                  value={values.gestaoFinanceira.calculaCustoFixo}
                  onChange={e => handleSwitchChange(e, "gestaoFinanceira", "calculaCustoFixo")}
                  disabled={false}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <p style={{ fontFamily: "rawline Bold", marginTop: "10px", marginBottom: "5px" }}>
                  Você faz algo para reduzir custos/despesas?
                </p>
                <ReusableSwitch
                  id="fazAlgoReduzirCusto"
                  name="fazAlgoReduzirCusto"
                  value={values.gestaoFinanceira.fazAlgoReduzirCusto}
                  onChange={e => handleSwitchChange(e, "gestaoFinanceira", "fazAlgoReduzirCusto")}
                  disabled={false}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <div>
                  <p style={{ fontFamily: "rawline Bold", marginTop: "10px", marginBottom: "5px" }}>
                    Você sabe dizer quanto você produz por mês?
                  </p>
                  <FormGroup>
                    <RadioGroup
                      row
                      name="situacao"
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="Sim, e meu negócio dá lucro"
                        control={
                          <Radio
                            style={{ color: theme.palette.primary.main, fontFamily: "Rawline Regular", marginRight: "8px" }}
                            checked={values.gestaoFinanceira.sabeDizerQuantoProduz === "Sim, e meu negócio dá lucro"}
                          />
                        }
                        label="Sim, e meu negócio dá lucro"
                      />
                      <FormControlLabel
                        value="Sim, mas não tenho lucro"
                        control={
                          <Radio
                            style={{ color: theme.palette.primary.main, fontFamily: "Rawline Regular", marginRight: "8px" }}
                            checked={values.gestaoFinanceira.sabeDizerQuantoProduz === "Sim, mas não tenho lucro"}
                          />
                        }
                        label="Sim, mas não tenho lucro"
                      />
                      <FormControlLabel
                        value="Não"
                        control={
                          <Radio
                            style={{ color: theme.palette.primary.main, fontFamily: "Rawline Regular", marginRight: "8px" }}
                            checked={values.gestaoFinanceira.sabeDizerQuantoProduz === "Não"}
                          />
                        }
                        label="Não"
                      />
                    </RadioGroup>
                  </FormGroup>
                </div>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ border: "1px solid grey" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontFamily: "Rawline Bold", color: "primary.main" }}
          >
            <InfoIcon sx={{ marginRight: "8px", color: "primary.main" }} />
            Tempo de negócio e perspectivas futuras
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={12}
              >
                <TextField
                  label="Há quanto tempo você tem esse negócio?"
                  name="Há quanto tempo você tem esse negócio?"
                  value={values.tempoNegocio.tempoNegocio}
                  onChange={e => handleInputChange(e, "tempoNegocio", "tempoNegocio")}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  label="Como você vê seu negócio daqui a 3 anos?"
                  name="Como você vê seu negócio daqui a 3 anos?"
                  value={values.tempoNegocio.comoVeNegocio3Anos}
                  onChange={e => handleInputChange(e, "tempoNegocio", "comoVeNegocio3Anos")}
                  fullWidth
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ border: "1px solid grey" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontFamily: "Rawline Bold", color: "primary.main" }}
          >
            <InfoIcon sx={{ marginRight: "8px", color: "primary.main" }} />
            Estratégias de marketing e vendas
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <p style={{ fontFamily: "rawline SemiBold", marginTop: "10px", marginBottom: "5px" }}>
                  Como você faz a propaganda do seu negócio?
                </p>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.estrategiaMarketing.comoFazPropaganda.includes("Boca a boca")}
                        onChange={e => handleCheckboxChange(e, "estrategiaMarketing", "comoFazPropaganda")}
                        name="Boca a boca"
                        value="Boca a boca"
                      />
                    }
                    label="Boca a boca"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.estrategiaMarketing.comoFazPropaganda.includes("Rádio")}
                        onChange={e => handleCheckboxChange(e, "estrategiaMarketing", "comoFazPropaganda")}
                        name="Rádio"
                        value="Rádio"
                      />
                    }
                    label="Rádio"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.estrategiaMarketing.comoFazPropaganda.includes("Cartaz / panfleto / cartão")}
                        onChange={e => handleCheckboxChange(e, "estrategiaMarketing", "comoFazPropaganda")}
                        name="Cartaz / panfleto / cartão"
                        value="Cartaz / panfleto / cartão"
                      />
                    }
                    label="Cartaz / panfleto / cartão"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.estrategiaMarketing.comoFazPropaganda.includes("Jornal")}
                        onChange={e => handleCheckboxChange(e, "estrategiaMarketing", "comoFazPropaganda")}
                        name="Jornal"
                        value="Jornal"
                      />
                    }
                    label="Jornal"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.estrategiaMarketing.comoFazPropaganda.includes("WhatsApp")}
                        onChange={e => handleCheckboxChange(e, "estrategiaMarketing", "comoFazPropaganda")}
                        name="WhatsApp"
                        value="WhatsApp"
                      />
                    }
                    label="WhatsApp"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.estrategiaMarketing.comoFazPropaganda.includes("Carro de som")}
                        onChange={e => handleCheckboxChange(e, "estrategiaMarketing", "comoFazPropaganda")}
                        name="Carro de som"
                        value="Carro de som"
                      />
                    }
                    label="Carro de som"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.estrategiaMarketing.comoFazPropaganda.includes("Facebook")}
                        onChange={e => handleCheckboxChange(e, "estrategiaMarketing", "comoFazPropaganda")}
                        name="Facebook"
                        value="Facebook"
                      />
                    }
                    label="Facebook"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.estrategiaMarketing.comoFazPropaganda.includes("Outros")}
                        onChange={e => handleCheckboxChange(e, "estrategiaMarketing", "comoFazPropaganda")}
                        name="Outros"
                        value="Outros"
                      />
                    }
                    label="Outros"
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
              >
                <TextField
                  label="Por que o consumidor deveria comprar seu produto ou serviço?"
                  name="Por que o consumidor deveria comprar seu produto ou serviço?"
                  value={values.estrategiaMarketing.porqueConsumidorDeveriaComprar}
                  onChange={e => handleInputChange(e, "estrategiaMarketing", "porqueConsumidorDeveriaComprar")}
                  rows={3}
                  minRows={3}
                  maxRows={6}
                  fullWidth
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ border: "1px solid grey" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontFamily: "Rawline Bold", color: "primary.main" }}
          >
            <InfoIcon sx={{ marginRight: "8px", color: "primary.main" }} />
            Crescimento e desenvolvimento
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <p style={{ fontFamily: "rawline SemiBold", marginTop: "10px", marginBottom: "5px" }}>
                  O que seu negócio precisa para crescer?
                </p>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.crescimentoDesenvolvimento.oQueNegocioPrecisaParaCrescer.includes("Dinheiro para investir")}
                        onChange={e => handleCheckboxChange(e, "crescimentoDesenvolvimento", "oQueNegocioPrecisaParaCrescer")}
                        name="Dinheiro para investir"
                        value="Dinheiro para investir"
                      />
                    }
                    label="Dinheiro para investir"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.crescimentoDesenvolvimento.oQueNegocioPrecisaParaCrescer.includes("Cursos / qualificação")}
                        onChange={e => handleCheckboxChange(e, "crescimentoDesenvolvimento", "oQueNegocioPrecisaParaCrescer")}
                        name="Cursos / qualificação"
                        value="Cursos / qualificação"
                      />
                    }
                    label="Cursos / qualificação"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.crescimentoDesenvolvimento.oQueNegocioPrecisaParaCrescer.includes(
                          "Máquinas / móveis / equipamentos",
                        )}
                        onChange={e => handleCheckboxChange(e, "crescimentoDesenvolvimento", "oQueNegocioPrecisaParaCrescer")}
                        name="Máquinas / móveis / equipamentos"
                        value="Máquinas / móveis / equipamentos"
                      />
                    }
                    label="Máquinas / móveis / equipamentos"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.crescimentoDesenvolvimento.oQueNegocioPrecisaParaCrescer.includes("Orientações de especialistas")}
                        onChange={e => handleCheckboxChange(e, "crescimentoDesenvolvimento", "oQueNegocioPrecisaParaCrescer")}
                        name="Orientações de especialistas"
                        value="Orientações de especialistas"
                      />
                    }
                    label="Orientações de especialistas"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.crescimentoDesenvolvimento.oQueNegocioPrecisaParaCrescer.includes("Reforma / construção")}
                        onChange={e => handleCheckboxChange(e, "crescimentoDesenvolvimento", "oQueNegocioPrecisaParaCrescer")}
                        name="Reforma / construção"
                        value="Reforma / construção"
                      />
                    }
                    label="Orientações de especialistas"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.crescimentoDesenvolvimento.oQueNegocioPrecisaParaCrescer.includes("Outros")}
                        onChange={e => handleCheckboxChange(e, "crescimentoDesenvolvimento", "oQueNegocioPrecisaParaCrescer")}
                        name="Outros"
                        value="Outros"
                      />
                    }
                    label="Outros"
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <div>
                  <p style={{ fontFamily: "rawline Bold", marginTop: "10px", marginBottom: "5px" }}>Qual a situação do seu negócio?</p>
                  <FormLabel sx={{ width: "100%", mb: 1 }}>
                    <input
                      type="radio"
                      name="Informal"
                      value="Informal"
                      checked={values.crescimentoDesenvolvimento.situacaoNegocio === "Informal"}
                      onChange={e => handleRadioChange(e, "crescimentoDesenvolvimento", "situacaoNegocio")}
                      style={{ fontFamily: "Rawline Regular", marginRight: "8px" }}
                    />
                    Informal
                  </FormLabel>
                  <FormLabel sx={{ width: "100%", mb: 1 }}>
                    <input
                      type="radio"
                      name="Formal – microempresa"
                      value="Formal – microempresa"
                      checked={values.crescimentoDesenvolvimento.situacaoNegocio === "Formal – microempresa"}
                      onChange={e => handleRadioChange(e, "crescimentoDesenvolvimento", "situacaoNegocio")}
                      style={{ fontFamily: "Rawline Regular", marginRight: "8px" }}
                    />
                    Formal – microempresa
                  </FormLabel>
                  <FormLabel sx={{ width: "100%", mb: 1 }}>
                    <input
                      type="radio"
                      name="Formal – Outros portes"
                      value="Formal – Outros portes"
                      checked={values.crescimentoDesenvolvimento.situacaoNegocio === "Formal – Outros portes"}
                      onChange={e => handleRadioChange(e, "crescimentoDesenvolvimento", "situacaoNegocio")}
                      style={{ fontFamily: "Rawline Regular", marginRight: "8px" }}
                    />
                    Formal – Outros portes
                  </FormLabel>
                  <FormLabel sx={{ width: "100%", mb: 1 }}>
                    <input
                      type="radio"
                      name="Formal – Microempreendedor Individual – MEI"
                      value="Formal – Microempreendedor Individual – MEI"
                      checked={values.crescimentoDesenvolvimento.situacaoNegocio === "Formal – Microempreendedor Individual – MEI"}
                      onChange={e => handleRadioChange(e, "crescimentoDesenvolvimento", "situacaoNegocio")}
                      style={{ fontFamily: "Rawline Regular", marginRight: "8px" }}
                    />
                    Formal – Microempreendedor Individual – MEI
                  </FormLabel>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
              >
                <TextField
                  label="CNPJ"
                  name="CNPJ"
                  value={values.crescimentoDesenvolvimento.cnpj}
                  onChange={e => handleInputChange(e, "crescimentoDesenvolvimento", "cnpj")}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <p style={{ fontFamily: "rawline Bold", marginTop: "10px", marginBottom: "5px" }}>
                  Você gostaria de receber orientação sobre como administrar seu negócio?
                </p>
                <ReusableSwitch
                  id="gostariaReceberOrientacaoAdministracao"
                  name="gostariaReceberOrientacaoAdministracao"
                  value={values.crescimentoDesenvolvimento.gostariaReceberOrientacaoAdministracao}
                  onChange={e => handleSwitchChange(e, "crescimentoDesenvolvimento", "gostariaReceberOrientacaoAdministracao")}
                  disabled={false}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ border: "1px solid grey" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontFamily: "Rawline Bold", color: "primary.main" }}
          >
            <InfoIcon sx={{ marginRight: "8px", color: "primary.main" }} />
            Aspectos pessoais e motivações
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <p style={{ fontFamily: "rawline Bold", marginTop: "10px", marginBottom: "5px" }}>Já pensou em desistir do negócio?</p>
                <ReusableSwitch
                  id="jaPensouDesistir"
                  name="jaPensouDesistir"
                  value={values.aspectosPessoaisMotivacao.jaPensouDesistir}
                  onChange={e => handleSwitchChange(e, "aspectosPessoaisMotivacao", "jaPensouDesistir")}
                  disabled={false}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <p style={{ fontFamily: "rawline Bold", marginTop: "10px", marginBottom: "5px" }}>
                  Por que você tem o seu próprio negócio?
                </p>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.aspectosPessoaisMotivacao.porqueTemNegocio.includes("Por que você tem o seu próprio negócio?")}
                        onChange={e => handleCheckboxChange(e, "aspectosPessoaisMotivacao", "porqueTemNegocio")}
                        name="Não tenho emprego, preciso de alguma atividade que me gere renda"
                        value="Não tenho emprego, preciso de alguma atividade que me gere renda"
                      />
                    }
                    label="Não tenho emprego, preciso de alguma atividade que me gere renda"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.aspectosPessoaisMotivacao.porqueTemNegocio.includes(
                          "Apesar de estar empregado, é um complemento da minha renda",
                        )}
                        onChange={e => handleCheckboxChange(e, "aspectosPessoaisMotivacao", "porqueTemNegocio")}
                        name="Apesar de estar empregado, é um complemento da minha renda"
                        value="Apesar de estar empregado, é um complemento da minha renda"
                      />
                    }
                    label="Apesar de estar empregado, é um complemento da minha renda"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.aspectosPessoaisMotivacao.porqueTemNegocio.includes(
                          "Quero ser meu próprio patrão, cansei de estar subordinado a um chefe",
                        )}
                        onChange={e => handleCheckboxChange(e, "aspectosPessoaisMotivacao", "porqueTemNegocio")}
                        name="Quero ser meu próprio patrão, cansei de estar subordinado a um chefe"
                        value="Quero ser meu próprio patrão, cansei de estar subordinado a um chefe"
                      />
                    }
                    label="Quero ser meu próprio patrão, cansei de estar subordinado a um chefe"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.aspectosPessoaisMotivacao.porqueTemNegocio.includes("É a realização de um sonho")}
                        onChange={e => handleCheckboxChange(e, "aspectosPessoaisMotivacao", "porqueTemNegocio")}
                        name="É a realização de um sonho"
                        value="É a realização de um sonho"
                      />
                    }
                    label="É a realização de um sonho"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.aspectosPessoaisMotivacao.porqueTemNegocio.includes(
                          "É um projeto familiar, precisam da minha participação",
                        )}
                        onChange={e => handleCheckboxChange(e, "aspectosPessoaisMotivacao", "porqueTemNegocio")}
                        name="É um projeto familiar, precisam da minha participação"
                        value="É um projeto familiar, precisam da minha participação"
                      />
                    }
                    label="É um projeto familiar, precisam da minha participação"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.aspectosPessoaisMotivacao.porqueTemNegocio.includes(
                          "Gosto da experiência de empreender um negócio",
                        )}
                        onChange={e => handleCheckboxChange(e, "aspectosPessoaisMotivacao", "porqueTemNegocio")}
                        name="Gosto da experiência de empreender um negócio"
                        value="Gosto da experiência de empreender um negócio"
                      />
                    }
                    label="Gosto da experiência de empreender um negócio"
                  />
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ border: "1px solid grey" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontFamily: "Rawline Bold", color: "primary.main" }}
          >
            <InfoIcon sx={{ marginRight: "8px", color: "primary.main" }} />
            Recursos Humanos e capacitação
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <p style={{ fontFamily: "rawline Bold", marginTop: "10px", marginBottom: "5px" }}>
                  Você tem algum funcionário trabalhando no seu negócio?
                </p>
                <ReusableSwitch
                  id="temAlgumFuncionario"
                  name="temAlgumFuncionario"
                  value={values.recursosHumanosCapacitacao.temAlgumFuncionario}
                  onChange={e => handleSwitchChange(e, "recursosHumanosCapacitacao", "temAlgumFuncionario")}
                  disabled={false}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <p style={{ fontFamily: "rawline Bold", marginTop: "10px", marginBottom: "5px" }}>
                  Você já participou de algum curso sobre empreendedorismo, microcrédito, educação financeira?
                </p>
                <ReusableSwitch
                  id="participouAlgumCurso"
                  name="participouAlgumCurso"
                  value={values.recursosHumanosCapacitacao.participouAlgumCurso}
                  onChange={handleSwitchChange}
                  disabled={false}
                />
              </Grid>
              <Grid
                item
                container
                spacing={2}
                style={{ display: "flex", alignItems: "end" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <TextField
                    label="Qual(is) curso(s)?"
                    name="cursoParticipado"
                    value={values.recursosHumanosCapacitacao.cursos.nome}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                >
                  <FormMultipleInsert
                    initialValue={""}
                    placeHolder={"placeHolder teste"}
                    label={"Qual a instituição realizadora?"}
                    value={values.recursosHumanosCapacitacao.cursos.instituicao}
                    onFieldsChange={handleMultipleInputs}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <p style={{ fontFamily: "rawline Bold", marginTop: "10px", marginBottom: "5px" }}>
                  Você gostaria de participar de algum curso sobre empreendedorismo, microcrédito, educação financeira, de outros assuntos
                  para melhor gerir o seu negócio?
                </p>
                <ReusableSwitch
                  id="participouAlgumCurso"
                  name="participouAlgumCurso"
                  value={values.recursosHumanosCapacitacao.gostariaParticiparCurso}
                  onChange={e => handleSwitchChange(e, "recursosHumanosCapacitacao", "gostariaParticiparCurso")}
                  disabled={false}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
              >
                <TextField
                  label="Por que motivo?"
                  name="motivoParticiparCurso"
                  value={values.recursosHumanosCapacitacao.motivoParticiparCurso}
                  onChange={e => handleInputChange(e, "recursosHumanosCapacitacao", "motivoParticiparCurso")}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <p style={{ fontFamily: "rawline Bold", marginTop: "10px", marginBottom: "5px" }}>
                  Você gostaria de receber orientação sobre como administrar seu negócio?
                </p>
                <ReusableSwitch
                  id="gostariaReceberOrientacao"
                  name="gostariaReceberOrientacao"
                  value={values.recursosHumanosCapacitacao.gostariaReceberOrientacao}
                  onChange={e => handleSwitchChange(e, "recursosHumanosCapacitacao", "gostariaReceberOrientacao")}
                  disabled={false}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
              >
                <TextField
                  label="Por que motivo?"
                  name="motivoReceberOrientacao"
                  value={values.recursosHumanosCapacitacao.motivoReceberOrientacao}
                  onChange={e => handleInputChange(e, "recursosHumanosCapacitacao", "motivoReceberOrientacao")}
                  fullWidth
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ border: "1px solid grey" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontFamily: "Rawline Bold", color: "primary.main" }}
          >
            <InfoIcon sx={{ marginRight: "8px", color: "primary.main" }} />
            Finanças e microcrédito
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <p style={{ fontFamily: "rawline Bold", marginTop: "10px", marginBottom: "5px" }}>
                  Você tem necessidade de um financiamento/empréstimo de microcrédito?
                </p>
                <ReusableSwitch
                  id="gostariaReceberOrientacao"
                  name="gostariaReceberOrientacao"
                  value={values.financasMicrocredito.necessidadeFinancimento}
                  onChange={e => handleSwitchChange(e, "financasMicrocredito", "necessidadeFinancimento")}
                  disabled={false}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
              >
                <TextField
                  label="Por que motivo?"
                  name="motivoReceberOrientacao"
                  value={values.financasMicrocredito.motivoNecessidadeFinanciamento}
                  onChange={e => handleInputChange(e, "financasMicrocredito", "motivoNecessidadeFinanciamento")}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <p style={{ fontFamily: "rawline Bold", marginTop: "10px", marginBottom: "5px" }}>
                  Você conhece os empréstimos para grupos de microempreendedores que utilizam o chamado aval solidário como garantia de
                  pagamento do empréstimo?
                </p>
                <ReusableSwitch
                  id="gostariaReceberOrientacao"
                  name="gostariaReceberOrientacao"
                  value={values.financasMicrocredito.conheceEmprestimos}
                  onChange={e => handleSwitchChange(e, "financasMicrocredito", "conheceEmprestimos")}
                  disabled={false}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ border: "1px solid grey" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontFamily: "Rawline Bold", color: "primary.main" }}
          >
            <InfoIcon sx={{ marginRight: "8px", color: "primary.main" }} />
            Dados pessoais e de contato
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <div>
                  <p style={{ fontFamily: "rawline Bold", marginTop: "10px", marginBottom: "5px" }}>Em que local funciona o seu negócio?</p>
                  <FormLabel sx={{ width: "100%", mb: 1 }}>
                    <input
                      type="radio"
                      name="Em minha residência"
                      value="Em minha residência"
                      checked={values.dadosPessoaisContato.localNegocio === "Em minha residência"}
                      onChange={e => handleRadioChange(e, "dadosPessoaisContato", "localNegocio")}
                      style={{ fontFamily: "Rawline Regular", marginRight: "8px" }}
                    />
                    Em minha residência
                  </FormLabel>
                  <FormLabel sx={{ width: "100%", mb: 1 }}>
                    <input
                      type="radio"
                      name="Em espaço comercial alugado"
                      value="Em espaço comercial alugado"
                      checked={values.dadosPessoaisContato.localNegocio === "Em espaço comercial alugado"}
                      onChange={e => handleRadioChange(e, "dadosPessoaisContato", "localNegocio")}
                      style={{ fontFamily: "Rawline Regular", marginRight: "8px" }}
                    />
                    Em espaço comercial alugado
                  </FormLabel>
                  <FormLabel sx={{ width: "100%", mb: 1 }}>
                    <input
                      type="radio"
                      name="Em espaço comercial próprio"
                      value="Em espaço comercial próprio"
                      checked={values.dadosPessoaisContato.localNegocio === "Em espaço comercial próprio"}
                      onChange={e => handleRadioChange(e, "dadosPessoaisContato", "localNegocio")}
                      style={{ fontFamily: "Rawline Regular", marginRight: "8px" }}
                    />
                    Em minha residência
                  </FormLabel>
                  <FormLabel sx={{ width: "100%", mb: 1 }}>
                    <input
                      type="radio"
                      name="Em espaço comercial cedido (sem custo de aluguel)"
                      value="Em espaço comercial cedido (sem custo de aluguel)"
                      checked={values.dadosPessoaisContato.localNegocio === "Em espaço comercial cedido (sem custo de aluguel)"}
                      onChange={e => handleRadioChange(e, "dadosPessoaisContato", "localNegocio")}
                      style={{ fontFamily: "Rawline Regular", marginRight: "8px" }}
                    />
                    Em espaço comercial cedido (sem custo de aluguel)
                  </FormLabel>
                  <FormLabel sx={{ width: "100%", mb: 1 }}>
                    <input
                      type="radio"
                      name="Ambulante"
                      value="Ambulante"
                      checked={values.dadosPessoaisContato.localNegocio === "Ambulante"}
                      onChange={e => handleRadioChange(e, "dadosPessoaisContato", "localNegocio")}
                      style={{ fontFamily: "Rawline Regular", marginRight: "8px" }}
                    />
                    Ambulante
                  </FormLabel>
                  <FormLabel sx={{ width: "100%", mb: 1 }}>
                    <input
                      type="radio"
                      name="Outros"
                      value="Outros"
                      checked={values.dadosPessoaisContato.localNegocio === "Outros"}
                      onChange={e => handleRadioChange(e, "dadosPessoaisContato", "localNegocio")}
                      style={{ fontFamily: "Rawline Regular", marginRight: "8px" }}
                    />
                    Outros
                  </FormLabel>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  label="Nome completo"
                  name="NomeCompleto"
                  value={values.dadosPessoaisContato.nomeCompleto}
                  onChange={e => handleInputChange(e, "dadosPessoaisContato", "nomeCompleto")}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  label="Data de nascimento"
                  name="DataNascimento"
                  value={values.dadosPessoaisContato.dataNascimento}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  label="RG"
                  name="rg"
                  value={values.dadosPessoaisContato.rg}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  label="CPF"
                  name="conheceEmprestimosCpf"
                  value={values.dadosPessoaisContato.cpf}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <p style={{ fontFamily: "Rawline Bold", marginTop: "10px", marginBottom: "5px" }}>Gênero</p>

                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={values.conheceEmprestimosSexo === "feminino"}
                        onChange={() => setValues(prevValues => ({ ...prevValues, conheceEmprestimosSexo: "feminino" }))}
                        name="conheceEmprestimosSexo"
                        value="feminino"
                      />
                    }
                    label="Feminino"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={values.conheceEmprestimosSexo === "masculino"}
                        onChange={() => setValues(prevValues => ({ ...prevValues, conheceEmprestimosSexo: "masculino" }))}
                        name="conheceEmprestimosSexo"
                        value="masculino"
                      />
                    }
                    label="Masculino"
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  select
                  label="Escolaridade"
                  name="conheceEmprestimosEscolaridade"
                  value={values.dadosPessoaisContato.conheceEmprestimosEscolaridade}
                  onChange={handleInputChange}
                  fullWidth
                >
                  <MenuItem value="Fundamental">Fundamental</MenuItem>
                  <MenuItem value="Médio">Médio</MenuItem>
                  <MenuItem value="Superior">Superior</MenuItem>
                  <MenuItem value="Pós-graduação">Pós-graduação</MenuItem>
                </TextField>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
              >
                <TextField
                  label="CEP"
                  name="cep"
                  value={values.dadosPessoaisContato.cep}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
              >
                <SelectUF
                  idSelect="uf"
                  nameSelect="uf"
                  value={values.dadosPessoaisContato.uf}
                  ufs={ufs}
                  uf={selectedUf}
                  label="UF"
                  handleSelectUf={event => handleSelectUf(event)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
              >
                <SelectCidade
                  idSelect="municipio"
                  nameSelect="municipio"
                  value={values.dadosPessoaisContato.municipio}
                  cidades={municipios}
                  cidade={selectedMunicipio}
                  label="Município"
                  handleSelectCidade={event => handleSelectMunicipio(event)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  label="Bairro/Distrito"
                  name="bairro"
                  value={values.dadosPessoaisContato.bairro}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  label="Logradouro"
                  name="endereco"
                  value={values.dadosPessoaisContato.endereco}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
              >
                <TextField
                  label="Número"
                  name="numero"
                  value={values.dadosPessoaisContato.numero}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
              >
                <TextField
                  label="Complemento"
                  name="conheceEmprestimosComplemento"
                  value={values.dadosPessoaisContato.complemento}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
              >
                <TextField
                  label="Telefone de contato"
                  name="conheceEmprestimosTelefoneContato"
                  value={values.dadosPessoaisContato.telefone}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  label="E-mail"
                  name="conheceEmprestimosEmail"
                  value={values.dadosPessoaisContato.email}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>

      <InformationBox
        title="Atenção:"
        message="Confira todas as respostas antes de clicar no botão 'Salvar Respostas'. Após salvar, não será permitido alterar as respostas."
      />
      <Grid
        container
        spacing={1}
        justifyContent="flex-end"
        mt={2}
      >
        <Grid item>
          <Button
            variant="outlined"
            onClick={() => console.log("cancelar")}
            sx={{ gap: "8px" }}
            style={{ fontFamily: "Rawline Medium" }}
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={() => handleSubmit(values)}
            sx={{ gap: "8px" }}
            style={{ fontFamily: "Rawline Medium" }}
          >
            <SaveIcon></SaveIcon>
            Salvar resposta
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

FormJaSouEmpreendedor.propTypes = {
  value: PropTypes.object,
};

export default FormJaSouEmpreendedor;
