import React, { useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Button, FormGroup, Grid, Stack, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";

const valoresIniciais = {
  cursoTecnico: "",
  situacao: "",
  curso: "",
  conclusao: "",
  instituicaoEnsino: "",
};

const FormCursosTecnicoComplementar = ({ initialValues = valoresIniciais }) => {
  const [formListCursoComplementar, setformListCursoComplementar] = useState([initialValues, initialValues]);
  const [formListCursoDistancia, setformListCursoDistancia] = useState([initialValues, initialValues]);

  const removeFormComplementar = indexToRemove => {
    setformListCursoComplementar(formListCursoComplementar.filter((_, index) => index !== indexToRemove));
  };

  const removeFormDistancia = indexToRemove => {
    setformListCursoDistancia(formListCursoDistancia.filter((_, index) => index !== indexToRemove));
  };

  const addNewFormComplementar = () => {
    setformListCursoComplementar([...formListCursoComplementar, initialValues]);
  };

  const addNewFormDistancia = () => {
    setformListCursoDistancia([...formListCursoDistancia, initialValues]);
  };

  return (
    <div>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={12}
        >
          <p style={{ fontFamily: "Rawline Bold", fontSize: "16px" }}>Fez ou está fazendo algum Curso Técnico?</p>
          <p style={{ fontFamily: "Rawline Regular", fontSize: "14px" }}>
            {
              '("Curso de longa duração (mínimo 800 horas) com o objetivo de preparar o aluno na teoria e na prática para determinada atividade. Exemplo: Técnico em Enfermagem, Técnico em Confeitaria, Técnico em Informática.)'
            }
          </p>
        </Grid>
      </Grid>
      {formListCursoComplementar.map((formValues, index) => (
        <Formik
          key={index}
          initialValues={formValues}
          validateOnChange={false}
        >
          {({ values, handleChange, handleBlur }) => (
            <Form>
              <Stack>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={4}
                  >
                    <FormGroup>
                      <TextField
                        label="* Curso técnico"
                        id={`cursoTecnico-${index}`}
                        name="cursoTecnico"
                        value={values.escolaridade}
                        placeholder="Informe seu curso técnico (ex: auxiliar de obras)"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                  >
                    <FormGroup>
                      <TextField
                        label="Situação"
                        id={`situacao-${index}`}
                        name="situacao"
                        value={values.situacao}
                        placeholder=""
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                  >
                    <FormGroup>
                      <TextField
                        label="Curso"
                        id={`curso-${index}`}
                        name="curso"
                        value={values.curso}
                        placeholder=""
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormGroup>
                      <TextField
                        label="Conclusão"
                        id={`conclusao-${index}`}
                        name="conclusao"
                        value={values.conclusao}
                        placeholder=""
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    sm={index > 0 ? 5 : 6}
                  >
                    <FormGroup>
                      <TextField
                        label="Instituição de ensino"
                        id={`instituicaoEnsino-${index}`}
                        name="instituicaoEnsino"
                        value={values.instituicaoEnsino}
                        placeholder=""
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormGroup>
                  </Grid>
                  {index > 0 && ( // Verifica se não é o primeiro curso técnico
                    <Grid
                      item
                      xs={2}
                      sm={1}
                      sx={{ display: "flex", alignItems: "flex-end" }}
                    >
                      <FormGroup>
                        <Button
                          variant="outlined"
                          onClick={() => removeFormComplementar(index)}
                        >
                          <DeleteOutlineIcon />
                        </Button>
                      </FormGroup>
                    </Grid>
                  )}
                  {index < formListCursoComplementar.length - 1 && ( // Adiciona a borda apenas se não for o último item
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      sx={{ mt: 3, mb: 3 }}
                    >
                      <FormGroup style={{ borderBottom: "1px solid LightGrey" }}></FormGroup>
                    </Grid>
                  )}
                </Grid>
              </Stack>
            </Form>
          )}
        </Formik>
      ))}
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            variant="outlined"
            onClick={addNewFormComplementar}
            sx={{ mt: 2 }}
            startIcon={<AddCircleIcon />}
          >
            ADICIONAR CURSO TÉCNICO
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={12}
        >
          <p style={{ fontFamily: "Rawline Bold", fontSize: "16px" }}>
            Fez ou está fazendo algum curso complementar presencial ou a distância?
          </p>
          <p style={{ fontFamily: "Rawline Regular", fontSize: "14px" }}>
            {
              "(Exemplo: Cursos de Inglês, Informática, Pronatec, dentre outros com menos de 800 horas de duração como confeitaria, pintura e etc.)"
            }
          </p>
        </Grid>
      </Grid>

      {formListCursoDistancia.map((formValues, index) => (
        <Formik
          key={index}
          initialValues={formValues}
          validateOnChange={false}
        >
          {({ values, handleChange, handleBlur }) => (
            <Form>
              <Stack>
                <Grid
                  container
                  spacing={2}
                  sx={{ mt: 1 }}
                >
                  <Grid
                    item
                    xs={10}
                    sm={11}
                  >
                    <FormGroup>
                      <TextField
                        label={"* Curso " + (index + 1)}
                        id={`cursoTecnico-${index}`}
                        name="cursoTecnico"
                        value={values.escolaridade}
                        placeholder="Informe seu curso técnico (ex: auxiliar de obras)"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormGroup>
                  </Grid>
                  {index > 0 && ( // Verifica se não é o primeiro curso técnico
                    <Grid
                      item
                      xs={2}
                      sm={1}
                      sx={{ display: "flex", alignItems: "flex-end" }}
                    >
                      <FormGroup>
                        <Button
                          variant="outlined"
                          onClick={() => removeFormDistancia(index)}
                        >
                          <DeleteOutlineIcon />
                        </Button>
                      </FormGroup>
                    </Grid>
                  )}
                </Grid>
              </Stack>
            </Form>
          )}
        </Formik>
      ))}
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            variant="outlined"
            onClick={addNewFormDistancia}
            sx={{ mt: 2 }}
            startIcon={<AddCircleIcon />}
          >
            ADICIONAR CURSO COMPLEMENTAR
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

FormCursosTecnicoComplementar.propTypes = {
  initialValues: PropTypes.shape({
    cursoTecnico: PropTypes.string,
    situacao: PropTypes.string,
    curso: PropTypes.string,
    conclusao: PropTypes.string,
    instituicaoEnsino: PropTypes.string,
  }),
};

export default FormCursosTecnicoComplementar;
