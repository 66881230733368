import React from "react";

import { Button, Card, Grid, TextField } from "@mui/material";
import PropTypes from "prop-types";

const ParceiroVisualizarInformacoesVaga = ({ values }) => {
  return (
    <Card
      color="#ffffff"
      sx={{
        borderRadius: "8px",
        padding: "16px",
        mt: "24px",
      }}
    >
      <Grid
        container
        spacing={2}
        justifyContent="flex-end"
        mt={1}
      >
        <Grid
          item
          xs={12}
          sm={4}
        >
          <TextField
            id="ofertante"
            name="ofertante"
            label="Ofertante"
            value={values.ofertante}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
        >
          <TextField
            id={"salario"}
            name={"salario"}
            label="Salário"
            value={values.salario}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
        >
          <TextField
            id={"vagas"}
            name={"vagas"}
            label="Vagas"
            value={values.vagas}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
        >
          <TextField
            id={"funcao"}
            name={"funcao"}
            label="Função"
            value={values.funcao}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
        >
          <TextField
            id={"areaAtuacao"}
            name={"areaAtuacao"}
            label="Área de Atuação"
            value={values.areaAtuacao}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
        >
          <TextField
            id={"areaEstudante"}
            name={"areaEstudante"}
            label="Área do Estudante"
            value={values.areaEstudante}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <TextField
            id="local"
            name="local"
            label="Local"
            value={values.local}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <TextField
            id="descricao"
            name="descricao"
            label="Descrição"
            value={values.local}
            disabled
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <TextField
            id="isNecessario"
            name="isNecessario"
            label="É Necessário"
            value={values.isNecessario}
            rows={4}
            disabled
            multiline
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
        >
          <TextField
            id="beneficios"
            name="beneficios"
            label="Benefícios"
            value={values.beneficios}
            rows={4}
            disabled
            multiline
            fullWidth
          />
        </Grid>
      </Grid>

      {/* Botões */}
      <Grid
        container
        spacing={2}
        justifyContent={"flex-end"}
        mt={2}
      >
        <Grid item>
          <Button
            variant="outlined"
            fullWidth
          >
            CANCELAR
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            fullWidth
          >
            <span
              className={"material-icons"}
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "left",
                marginRight: 5,
              }}
            >
              thumb_up
            </span>
            TENHO INTERESSE
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            fullWidth
          >
            <span
              className={"material-icons"}
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "left",
                marginRight: 5,
              }}
            >
              keyboard_return
            </span>
            ENCAMINHAR CURRÍCULO
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            fullWidth
          >
            <span
              className={"material-icons"}
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "left",
                marginRight: 5,
              }}
            >
              cancel
            </span>
            RETIRAR INTERESSE
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            fullWidth
          >
            <span
              className={"material-icons"}
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "left",
                marginRight: 5,
              }}
            >
              open_in_new
            </span>
            ACESSO EXTERNO
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

ParceiroVisualizarInformacoesVaga.propTypes = {
  values: PropTypes.object.isRequired,
};

export default ParceiroVisualizarInformacoesVaga;
