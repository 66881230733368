import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ParceiroMobilizacaoBuscarVagas from "../../components/formularios/ParceiroMobilizacaoBuscarVagas";
import TitlePage from "../../components/title-page/TitlePage";

const PageMobilizacaoBuscarVagas = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/parceiro", text: "Parceiro" },
          { href: "/mobilizacao", text: "Mobilização" },
        ]}
        currentPage="Buscar Vagas"
      />

      <TitlePage title={"Buscar Vagas"} />

      <ParceiroMobilizacaoBuscarVagas />
    </Container>
  );
};

export default PageMobilizacaoBuscarVagas;
