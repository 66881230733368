import React, { useRef, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import { Box, Card, CardActions, CardContent, CardMedia, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

const FileUpload = ({
  initialValues = [],
  fieldLabel = "Selecione os arquivos",
  maxFiles,
  onFieldsChange,
  showFiles = false,
  visualizar = false,
}) => {
  const [files, setFiles] = useState(initialValues);
  const fileInputRef = useRef(null);
  const theme = useTheme();

  const handleChange = e => {
    const newFiles = Array.from(e.target.files).slice(0, maxFiles ? maxFiles - files.length : undefined);

    const mappedFiles = newFiles.map(file => ({
      name: file.name,
      base64: "",
      file,
    }));

    mappedFiles.forEach(fileObj => {
      const reader = new FileReader();
      reader.onloadend = () => {
        fileObj.base64 = reader.result;
        const updatedFiles = [...files, fileObj];

        setFiles(updatedFiles);
        onFieldsChange && onFieldsChange(updatedFiles);
      };
      reader.readAsDataURL(fileObj.file);
    });
  };

  const handleTextFieldClick = () => {
    if (!maxFiles || files.length < maxFiles) {
      fileInputRef.current.click();
    } else {
      alert(`Você pode apenas carregar ${maxFiles} arquivos.`);
    }
  };

  const handleDelete = fileName => {
    const updatedFiles = files.filter(file => file.name !== fileName);
    setFiles(updatedFiles);
    onFieldsChange && onFieldsChange(updatedFiles); // Call onFieldsChange if it's provided
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1, mt: 2 }}>
      <input
        type="file"
        hidden
        ref={fileInputRef}
        onChange={handleChange}
        multiple
        style={{ cursor: "pointer" }}
        disabled={visualizar}
      />

      <TextField
        label={fieldLabel}
        onClick={visualizar ? undefined : handleTextFieldClick}
        // onClick={handleTextFieldClick}
        sx={{ input: { cursor: "pointer" } }}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={visualizar ? undefined : handleTextFieldClick}
                // onClick={handleTextFieldClick}
                disabled={visualizar}
              >
                <UploadFileRoundedIcon sx={{ color: "darkgray" }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        fullWidth
        disabled={visualizar}
      />

      <Box sx={{ width: "100%", mt: 2 }}>
        {files.map(file => (
          <div
            key={file.name}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "8px",
              backgroundColor: theme.palette.background.text,
              border: "1px solid black",
            }}
          >
            <CardContent>
              <Typography
                style={{ textDecoration: "underline", fontFamily: "Rawline Medium" }}
                variant="body2"
                color="primary"
              >
                {file.name}
              </Typography>
            </CardContent>
            <CardActions>
              <IconButton
                onClick={() => handleDelete(file.name)}
                sx={{
                  border: "1px solid",
                  borderColor: theme.palette.primary.main,
                  width: "36px",
                  height: "36px",
                  borderRadius: "5px",
                }}
                disabled={visualizar}
              >
                <DeleteIcon color="primary" />
              </IconButton>
            </CardActions>
          </div>
        ))}
      </Box>

      {showFiles && (
        <Box sx={{ width: "100%", mt: 2 }}>
          {files.map(file => (
            <Card
              sx={{ maxWidth: 345 }}
              key={file.name}
            >
              <CardMedia
                sx={{ height: 216 }}
                image={file.url}
                title={file.name}
              />
            </Card>
          ))}
        </Box>
      )}
    </Box>
  );
};

FileUpload.propTypes = {
  // files: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     name: PropTypes.string.isRequired,
  //     base64: PropTypes.string.isRequired,
  //     file: PropTypes.object.isRequired,
  //   }),
  // ).isRequired,
  // setFiles: PropTypes.func.isRequired,
  initialValues: PropTypes.arrayOf(PropTypes.object),
  fieldLabel: PropTypes.string,
  maxFiles: PropTypes.number,
  onFieldsChange: PropTypes.func,
  showFiles: PropTypes.bool,
  visualizar: PropTypes.bool,
};

export default FileUpload;
