import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminListarCursosSiteInativo from "../../components/filtros/AdminListarCursosSiteInativo";
import TitlePage from "../../components/title-page/TitlePage";

const PageListarCursosSiteInativo = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb currentPage="Listar Cursos com Site Inativo" />

      <TitlePage title={"Listar Cursos com Site Inativo"} />

      <AdminListarCursosSiteInativo />
    </Container>
  );
};

export default PageListarCursosSiteInativo;
