const defaultNavConfig = [
  {
    title: "Página Inicial",
    path: "/",
    color: "default",
    subTitles: {
      home: "/",
      perfil: "/profile",
    },
  },
];

export default defaultNavConfig;
