import React, { useEffect, useMemo, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import { Button, Card, Container, Grid, TextField, Typography } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import { SelectCidade } from "../../components/formularios/fields/SelectCidade";
import { SelectUF } from "../../components/formularios/fields/SelectUF";
import CustomModal from "../../components/modals/CustomModal";
import DefaultTable from "../../components/table/DefaultTable";
import TitlePage from "../../components/title-page/TitlePage";
import optionsService from "../../services/options.service";

const initialFilterState = {
  cnpj: "",
  nomeParceiro: "",
  uf: "",
  municipio: "",
};

const keysHidden = ["uf", "municipio", "emailParceiro", "telefone", "nomeResponsavel", "email", "nomePontoFocal", "emailPontoFocal"];

const PageListarConsultaParceiros = () => {
  const [showRemoverInteresseDeCurso, setShowRemoverInteresseDeCurso] = useState(false);
  const [showIncluirInteresseDeCurso, setShowIncluirInteresseDeCurso] = useState(false);

  const [filtros, setFiltros] = useState(initialFilterState);
  const [filtrosAplicados, setFiltrosAplicados] = useState(filtros);

  const aplicarFiltros = () => {
    setFiltrosAplicados(filtros);
  };

  // Preparação para UFS e Municípios
  const [ufs, setUfs] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [selectedUf, setSelectedUf] = useState("");
  const [selectedMunicipio, setSelectedMunicipio] = useState("");

  useEffect(() => {
    const fetchUfs = async () => {
      await optionsService
        .getAllUFs()
        .then(ufsData => {
          setUfs(ufsData);
        })
        .catch(error => {
          console.error("Erro ao obter UFs:", error);
        });
    };
    fetchUfs();
  }, []);

  const fetchCidadesByUf = async (ufSigla, setCidadesFunction) => {
    try {
      const cidadesData = await optionsService.getCidadesFromUF(ufSigla);
      setCidadesFunction(cidadesData);
    } catch (error) {
      console.error("Erro ao obter cidades:", error);
    }
  };

  const handleSelectUf = event => {
    const { name, value } = event.target;
    setFiltros({ ...filtros, [name]: value });

    setSelectedUf(value);
    setSelectedMunicipio("");
    setFiltros({ ...filtros, ["municipio"]: "" });
    fetchCidadesByUf(event.target.value, setMunicipios);
  };

  const handleSelectMunicipio = event => {
    const { name, value } = event.target;
    setFiltros({ ...filtros, [name]: value });
    setSelectedMunicipio(event.target.value);
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setFiltros({ ...filtros, [name]: value });
  };

  const linksBreadCrumb = [{ href: "/", text: "Cursos" }];

  const headerTabela = [
    { field: "cnpj", headerName: "CNPJ" },
    { field: "razaoSocial", headerName: "Razão social" },
    { field: "nomeFantasia", headerName: "Nome fantasia" },
  ];

  const dadosTabela = [
    {
      id: 1,
      cnpj: "00.000.000/0000-00",
      razaoSocial: "Empresas Alfa",
      nomeFantasia: "Alfa",
      uf: "DF",
      municipio: "Brasilia / DF",
      email: "email@email.com",
      emailParceiro: "email@email.com",
      emailPontoFocal: "email@email.com",
      telefone: "(00) 0000-0000",
      nomeResponsavel: "Nome do responsável",
      nomePontoFocal: "Ponto Focal",
    },
    {
      id: 2,
      cnpj: "00.000.000/0000-01",
      razaoSocial: "Empresas Beta",
      nomeFantasia: "Beta",
      uf: "SP",
      municipio: "São Paulo / SP",
      email: "email@email.com",
      emailParceiro: "email@email.com",
      emailPontoFocal: "email@email.com",
      telefone: "(00) 0000-0000",
      nomeResponsavel: "Nome do responsável",
      nomePontoFocal: "Ponto Focal",
    },
    {
      id: 1,
      cnpj: "00.000.000/0000-00",
      razaoSocial: "Empresas Omega",
      nomeFantasia: "Omega",
      uf: "RJ",
      municipio: "Rio de Janeiro / RJ",
      email: "email@email.com",
      emailParceiro: "email@email.com",
      emailPontoFocal: "email@email.com",
      telefone: "(00) 0000-0000",
      nomeResponsavel: "Nome do responsável",
      nomePontoFocal: "Ponto Focal",
    },
  ];

  const dadosFiltrados = useMemo(() => {
    return dadosTabela.filter(item =>
      Object.keys(filtros).every(coluna => {
        const valorFiltro = filtros[coluna];
        const valorItem = item[coluna];

        // Verifica se o valor do filtro e do item são strings antes de aplicar toLowerCase()
        if (typeof valorFiltro === "string" && typeof valorItem === "string") {
          return valorItem.toLowerCase().includes(valorFiltro.toLowerCase());
        }

        // Se não forem strings, não aplica toLowerCase() e retorna true para manter o item no filtro
        return true;
      }),
    );
  }, [filtrosAplicados]);

  const tabelaHiddenLinhas = useMemo(
    () =>
      (Array.isArray(dadosFiltrados) ? dadosFiltrados : []).map(row =>
        keysHidden.reduce((acc, key) => {
          acc[key] = row[key];
          return acc;
        }, {}),
      ),
    [dadosFiltrados, keysHidden],
  );

  const ContentModalRemoverInteresseCurso = function () {
    return (
      <Grid
        container
        spacing={1}
        sx={{ justifyContent: "flex-end", flexDirection: "row", alignItems: "flex-end", gap: 2 }}
      >
        <Grid
          item
          xs={3}
          md={1}
        >
          <Button
            size="large"
            type="submit"
            variant="outlined"
          >
            NÃO
          </Button>
        </Grid>
        <Grid
          item
          xs={3}
          md={1}
        >
          <Button
            size="large"
            type="submit"
            variant="contained"
          >
            SIM
          </Button>
        </Grid>
      </Grid>
    );
  };

  const ContentModalIncluirInteresseCurso = function () {
    return (
      <Grid
        container
        spacing={1}
        sx={{ justifyContent: "flex-end", flexDirection: "row", alignItems: "flex-end", gap: 2 }}
      >
        <Grid
          item
          xs={3}
          md={1}
        >
          <Button
            size="large"
            type="submit"
            variant="outlined"
          >
            NÃO
          </Button>
        </Grid>
        <Grid
          item
          xs={3}
          md={1}
        >
          <Button
            size="large"
            type="submit"
            variant="contained"
          >
            SIM
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        currentPage="Consulta parceiros operadores de microcrédito"
        links={linksBreadCrumb}
      />

      <CustomModal
        showModal={showRemoverInteresseDeCurso}
        handleClose={() => {
          setShowRemoverInteresseDeCurso(false);
        }}
        title={"Confirmar a remoção do seu interesse no curso"}
        content={<ContentModalRemoverInteresseCurso />}
        buttons={[]}
      />

      <CustomModal
        showModal={showIncluirInteresseDeCurso}
        handleClose={() => {
          setShowIncluirInteresseDeCurso(false);
        }}
        title={"Confirmar seu interesse no curso"}
        content={<ContentModalIncluirInteresseCurso />}
        buttons={[]}
      />

      <TitlePage title={"Consultas parceiros operadores de microcrédito"} />

      <Card>
        <Grid
          container
          spacing={2}
          sx={{ p: 2 }}
        >
          <Grid
            item
            xs={12}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: "16",
                fontFamily: "Rawline Regular",
              }}
            >
              Filtros
            </Typography>
          </Grid>

          {/* CNPJ e Nome do Parceiro */}
          <Grid
            item
            xs={12}
            sm={6}
          >
            <TextField
              fullWidth
              id="cnpj"
              name="cnpj"
              label="CNPJ"
              placeholder="CNPJ"
              value={filtros.cnpj}
              onChange={handleChange}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <TextField
              fullWidth
              id="nomeParceiro"
              name="nomeParceiro"
              label="Nome do Parceiro"
              placeholder="Nome do Parceiro"
              value={filtros.nomeParceiro}
              onChange={handleChange}
            />
          </Grid>

          {/* UF E Município */}
          <Grid
            item
            xs={12}
            sm={6}
          >
            <SelectUF
              idSelect="uf"
              nameSelect="uf"
              value={filtros.uf}
              ufs={ufs}
              uf={selectedUf}
              label="UF"
              handleSelectUf={event => handleSelectUf(event)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <SelectCidade
              idSelect="municipio"
              nameSelect="municipio"
              value={filtros.municipio}
              cidades={municipios}
              cidade={selectedMunicipio}
              label="Município"
              handleSelectCidade={event => handleSelectMunicipio(event)}
            />
          </Grid>

          <Grid
            item
            container
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end", gap: "10px 10px" }}
          >
            <Grid item>
              <Button
                size="large"
                type="submit"
                variant="outlined"
                fullWidth
              >
                LIMPAR
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="large"
                type="submit"
                startIcon={<SearchIcon />}
                variant="contained"
                onClick={aplicarFiltros}
                fullWidth
              >
                PESQUISAR
              </Button>
            </Grid>
          </Grid>

          <Grid
            item
            md={12}
            sx={{ mt: "16px" }}
          >
            <DefaultTable
              columns={headerTabela}
              rows={dadosFiltrados}
              hiddenRows={tabelaHiddenLinhas}
            ></DefaultTable>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default PageListarConsultaParceiros;
