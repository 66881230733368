import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminParceiroListarInteressadosMPO from "../../components/filtros/AdminParceiroListarInteressadosMPO";
import TitlePage from "../../components/title-page/TitlePage";

const PageListarInteressadosMPOParceiro = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb currentPage="Listar Interessados MPO por parceiro" />

      <TitlePage title={"Listar Interessados em MPO por parceiro"} />

      <AdminParceiroListarInteressadosMPO listaParceiro={true} />
    </Container>
  );
};

export default PageListarInteressadosMPOParceiro;
