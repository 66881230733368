import React from "react";

import { Container, Typography } from "@mui/material";

import CardBreadcrumb from "../components/cards/CardBreadcrumb";
import { dadosParceirosPendentes, dadosPessoas } from "../components/formularios/dadosMockados";
import DefaultTable from "../components/table/DefaultTable";
import TitlePage from "../components/title-page/TitlePage";

const columns = [
  { field: "cnpj", headerName: "CNPJ", sxProps: { width: "250px" } },
  { field: "status", headerName: "Status" },
  { field: "habilitacao", headerName: "Habilitação", sxRowProps: { textTransform: "uppercase", color: "primary.main" } },
];

const rows = dadosParceirosPendentes;

const keysHidden = ["dataCadastro", "dataUltimaModificacao", "cnpj"];

const hiddenRows = rows.map(row =>
  keysHidden.reduce((acc, key) => {
    acc[key] = row[key];
    return acc;
  }, {}),
);

const keysHiddenSegunda = [
  "dataCadastro",
  "dataUltimaModificacao",
  "cnpj",
  "nomeFantasia",
  "razaoSocial",
  "naturezaJuridica",
  "nomeResponsavel",
  "email",
  "nomePontoFocal",
  "emailPontoFocal",
  "telefone",
  "complemento",
  "uf",
  "cidade",
  "endereco",
];

const hiddenRowsSegunda = rows.map(row =>
  keysHiddenSegunda.reduce((acc, key) => {
    acc[key] = row[key];
    return acc;
  }, {}),
);

const getTabelaActions = () => [
  {
    title: "Visualizar",
    icon: "visibility",
    href: "",
  },
  {
    title: "Editar",
    icon: "edit",
    href: "",
  },
  {
    title: "Excluir",
    icon: "delete",
    href: "",
  },
];

/////////////////////////////////////////// Dados para tabela com problema na ordenação (agora para mostrar diferentes botões)
const rowsTabela = dadosPessoas;

const colunasTabela = [
  { field: "inscricao", headerName: "Inscrição" },
  { field: "nome", headerName: "Nome" },
  { field: "telefone", headerName: "Telefone" },
];
const keysHiddenTabela = ["email", "municipio", "uf", "dataInscricao", "situacao"];

const hiddenLinhasTabela = rowsTabela.map(row =>
  keysHiddenTabela.reduce((acc, key) => {
    acc[key] = row[key];
    return acc;
  }, {}),
);

const getTabelaActionsSecond = row => {
  const actions = [
    {
      title: "Visualizar",
      icon: "visibility",
      href: "",
    },
  ];

  if (row?.canEdit) {
    actions.push({
      title: "Edição",
      icon: "create",
      onClick: rowId => {
        console.log("Clicou em editar", rowId);
      },
    });
  }

  if (row?.canExclude) {
    actions.push({
      title: "Excluir",
      icon: "delete",
      onClick: rowId => {
        console.log("Clicou em excluir", rowId);
      },
    });
  }

  return actions;
};

const PageComponentsDefaultTable = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        homeLink="/home"
        homeText="Admin"
        currentPage="Components"
      />

      <TitlePage title={"PAGINA DE COMPONENTES"} />

      <Typography
        variant="h5"
        mt={2}
        sx={{ fontFamily: "Rawline Bold", marginBottom: "10px", marginTop: "40px" }}
        style={{ transition: "1s" }}
      >
        Default Table (sem termos)
      </Typography>

      <DefaultTable
        rows={rows}
        columns={columns}
        hiddenRows={hiddenRows}
        notFoundText={"Não foram encontrados registros"}
      ></DefaultTable>

      <Typography
        variant="h5"
        mt={2}
        sx={{ fontFamily: "Rawline Bold", marginBottom: "10px", marginTop: "40px" }}
        style={{ transition: "1s" }}
      >
        Default Table sem nenhum dado
      </Typography>

      <DefaultTable></DefaultTable>

      <Typography
        variant="h5"
        mt={2}
        sx={{ fontFamily: "Rawline Bold", marginBottom: "10px", marginTop: "40px" }}
        style={{ transition: "1s" }}
      >
        Default Table com action buttons e termos
      </Typography>

      <DefaultTable
        rows={rows}
        columns={columns}
        hiddenRows={hiddenRowsSegunda}
        actionButtons={getTabelaActions}
      ></DefaultTable>

      <Typography
        variant="h5"
        mt={2}
        sx={{ fontFamily: "Rawline Bold", marginBottom: "10px", marginTop: "40px" }}
        style={{ transition: "1s" }}
      >
        Default Table com caixas de seleção
      </Typography>

      <DefaultTable
        rows={rows}
        columns={columns}
        hiddenRows={hiddenRowsSegunda}
        actionButtons={getTabelaActions}
        mostrarCaixasSelecao={true}
        onSelecionadosChange={selecionados => {
          console.log("Custom onSelecionadosChange:", selecionados);
        }}
      ></DefaultTable>

      <Typography
        variant="h5"
        mt={2}
        sx={{ fontFamily: "Rawline Bold", marginBottom: "10px", marginTop: "40px" }}
        style={{ transition: "1s" }}
      >
        Default Table com círculos coloridos
      </Typography>

      <DefaultTable
        rows={rows}
        columns={columns}
        hiddenRows={hiddenRowsSegunda}
        actionButtons={getTabelaActions}
        mostrarCirculosColoridos={true}
        listaCirculosColoridos={["#FF0000", "#00FF00", "#0000FF"]}
      ></DefaultTable>

      <Typography
        variant="h5"
        mt={2}
        sx={{ fontFamily: "Rawline Bold", marginBottom: "10px", marginTop: "40px" }}
        style={{ transition: "1s" }}
      >
        Tabela ajustada com diferentes botões por linha
      </Typography>

      <DefaultTable
        columns={colunasTabela}
        rows={rowsTabela}
        hiddenRows={hiddenLinhasTabela}
        notFoundText={"Não foi localizado Parceiro na situação pendente de aprovação!"}
        actionButtons={getTabelaActionsSecond}
      />
    </Container>
  );
};

export default PageComponentsDefaultTable;
