import React, { useState } from "react";

import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import PropTypes from "prop-types";

import { ExcelTipo1, ExcelTipo2, ExcelTipo3, ExcelTipo4, ExcelTipo5, ExcelTipo6 } from "../table/TiposPlanilhasPadrao";
import CustomModal from "./CustomModal";

const TipoDeArquivoModal = ({ showModal, handleClose, dados, tipo }) => {
  const modalTitle = "Selecione o tipo de arquivo a ser gerado:";

  const [selectedTipo, setSelectedTipo] = useState(1);

  const handleRadioChange = event => {
    setSelectedTipo(Number(event.target.value));
  };

  const handleGenerateExcel = () => {
    switch (selectedTipo) {
      case 1:
        ExcelTipo1(dados);
        break;
      case 2:
        ExcelTipo2(dados);
        break;
      case 3:
        ExcelTipo3(dados);
        break;
      case 4:
        ExcelTipo4(dados);
        break;
      case 5:
        ExcelTipo5(dados);
        break;
      case 6:
        ExcelTipo6(dados);
        break;
      default:
        console.error("Tipo de Excel não suportado.");
    }
  };

  const modalContent = (
    <>
      <FormControl>
        <RadioGroup
          defaultValue={1}
          name="tipoArquivo"
          onChange={handleRadioChange}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <Box
                px={2}
                bgcolor={"#dfdfdf"}
                sx={{ "&:hover": { bgcolor: "#afafaf" } }}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="1º Tipo - Planilha padrão"
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Box
                px={2}
                bgcolor={"#dfdfdf"}
                sx={{ "&:hover": { bgcolor: "#afafaf" } }}
              >
                <FormControlLabel
                  value={2}
                  control={<Radio />}
                  label="2º Tipo - Planilha padrão + variáveis do Questionário Quero Ser Empreendedor "
                />
              </Box>
            </Grid>
            {!(tipo == "Consulta") ? (
              <>
                <Grid
                  item
                  xs={12}
                >
                  <Box
                    px={2}
                    bgcolor={"#dfdfdf"}
                    sx={{ "&:hover": { bgcolor: "#afafaf" } }}
                  >
                    <FormControlLabel
                      value={3}
                      control={<Radio />}
                      label="3º Tipo - Planilha padrão + variáveis do Questionário Já Sou Empreendedor"
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Box
                    px={2}
                    bgcolor={"#dfdfdf"}
                    sx={{ "&:hover": { bgcolor: "#afafaf" } }}
                  >
                    <FormControlLabel
                      value={4}
                      control={<Radio />}
                      label="4º Tipo - Planilha padrão + variáveis da Situação de Contato"
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Box
                    px={2}
                    bgcolor={"#dfdfdf"}
                    sx={{ "&:hover": { bgcolor: "#afafaf" } }}
                  >
                    <FormControlLabel
                      value={5}
                      control={<Radio />}
                      label="5º Tipo - Planilha padrão + variáveis da Situação de Contato + variáveis do Questionário Quero Ser Empreendedor"
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Box
                    px={2}
                    bgcolor={"#dfdfdf"}
                    sx={{ "&:hover": { bgcolor: "#afafaf" } }}
                  >
                    <FormControlLabel
                      value={6}
                      control={<Radio />}
                      label="6º Tipo - Planilha padrão + variáveis da Situação de Contato + variáveis do Questionário Já Sou Empreendedor"
                    />
                  </Box>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>
        </RadioGroup>
      </FormControl>
    </>
  );

  const modalButtons = [
    {
      label: "GERAR ARQUIVO EXCEL",
      onClick: handleGenerateExcel,
      variant: "contained",
    },
  ];

  return (
    <CustomModal
      showModal={showModal}
      handleClose={handleClose}
      title={modalTitle}
      content={modalContent}
      buttons={modalButtons}
      maxWidth="md"
    />
  );
};

TipoDeArquivoModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  dados: PropTypes.any.isRequired,
  tipo: PropTypes.string,
};

export default TipoDeArquivoModal;
