import React from "react";

import { Grid, TextField } from "@mui/material";
import PropTypes from "prop-types";

import CustomModal from "./CustomModal";

const VisualizarVagaTrabalhoModal = ({ showModal, handleClose }) => {
  const modalTitle = "Vaga de Trabalho";
  const modalContent = (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
      >
        <TextField
          label="Parceiro"
          value={"Teste"}
          disabled
          fullWidth
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
      >
        <TextField
          label="Vaga"
          value={"Teste"}
          disabled
          fullWidth
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
      >
        <TextField
          label="Função"
          value={"Teste"}
          disabled
          fullWidth
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
      >
        <TextField
          label="Área de Atuação"
          value={"Teste"}
          disabled
          fullWidth
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
      >
        <TextField
          label="Área do Estudante"
          value={"Teste"}
          disabled
          fullWidth
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
      >
        <TextField
          label={"Local"}
          value={"Teste"}
          disabled
          fullWidth
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
      >
        <TextField
          label="Descrição"
          value={"Teste"}
          disabled
          fullWidth
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
      >
        <TextField
          label="É Necessário"
          value={"Teste"}
          rows={3}
          disabled
          fullWidth
          multiline
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
      >
        <TextField
          label="Benefícios"
          value={"Teste"}
          rows={3}
          disabled
          fullWidth
          multiline
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
      >
        <TextField
          label="Salário"
          value={"Teste"}
          disabled
          fullWidth
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
      >
        <TextField
          label="Bolsa Auxílio"
          value={"Teste"}
          disabled
          fullWidth
        />
      </Grid>
    </Grid>
  );

  const modalButtons = [{ label: "FECHAR", onClick: handleClose, variant: "outlined" }];

  return (
    <CustomModal
      showModal={showModal}
      handleClose={handleClose}
      title={modalTitle}
      content={modalContent}
      buttons={modalButtons}
      maxWidth="sm"
    />
  );
};

VisualizarVagaTrabalhoModal.propTypes = {
  // values: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default VisualizarVagaTrabalhoModal;
