import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ParceiroListarInteressados from "../../components/formularios/ParceiroListarInteressados";
import TitlePage from "../../components/title-page/TitlePage";

const PageRelacaoInteressados = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/", text: "Página Inicial" },
          { href: "/cursos", text: "Cursos" },
        ]}
        currentPage="Listar Interessados"
      />

      <TitlePage title={"Listar Interessados"} />

      <ParceiroListarInteressados />
    </Container>
  );
};

export default PageRelacaoInteressados;
