import React from "react";

import SearchIcon from "@mui/icons-material/Search";
import { Button, Card, Grid, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";

const ParceiroMobilizacaoCidadao = ({ value, visualizacao }) => {
  const values = value
    ? value
    : {
        cpf: "",
        nome: "",
      };

  return (
    <Card
      sx={{
        padding: 2,
        mt: 2,
        borderRadius: 2,
      }}
    >
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
        >
          <TextField
            id="cpf"
            name="cpf"
            label={
              <Typography>
                CPF
                <SearchIcon />
              </Typography>
            }
            value={values.cpf}
            disabled={visualizacao}
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
        >
          <TextField
            id="nome"
            name="nome"
            label="Nome da pessoa"
            value={values.nome}
            disabled={visualizacao}
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
        >
          <Typography>
            O Programa Acredita no Primeiro Passo oferta cursos de qualificação profissional, vagas de emprego e acesso ao microcrédito.
            <br />
            Na mobilização individual de cidadão é possível:
            <br />
            - Cadastrar o currículo para o cidadão cadastrado no Programa Acredita no Primeiro Passo;
            <br />
            - Escolher cursos de interesse do cidadão;
            <br />
            - Registar interesse em vaga de emprego;
            <br />- Registrar interesse em acessar ao microcrédito produtivo orientado.
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        mt={2}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
        >
          <Button
            variant="contained"
            fullWidth
          >
            CADASTRE O CURRÍCULO NO Programa Acredita no Primeiro Passo
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
        >
          <Button
            variant="contained"
            type="submit"
            fullWidth
          >
            LOCALIZE VAGA DE EMPREGO
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
        >
          <Button
            variant="contained"
            type="submit"
            fullWidth
          >
            LOCALIZE CURSOS DISPONÍVEIS
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
        >
          <Button
            variant="contained"
            type="submit"
            fullWidth
          >
            MICROCRÉDITO
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

ParceiroMobilizacaoCidadao.propTypes = {
  value: PropTypes.object,
  visualizacao: PropTypes.bool,
};

export default ParceiroMobilizacaoCidadao;
