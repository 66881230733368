import React, { useState } from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminPerfil from "../../components/formularios/AdminPerfil";
import TitlePage from "../../components/title-page/TitlePage";

const initialValues = {
  nome: "",
  acoes: [],
  situacao: "",
};

const PageNovoPerfil = () => {
  const [valores, setValores] = useState(initialValues);

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/profile", text: "Perfil" }]}
        currentPage="Novo Perfil"
      />

      <TitlePage title={"Novo Perfil"} />

      <AdminPerfil
        valores={valores}
        setValores={setValores}
      />
    </Container>
  );
};

export default PageNovoPerfil;
