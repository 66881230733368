import React from "react";
import { Link } from "react-router-dom";

import InvertColorsIcon from "@mui/icons-material/InvertColors";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import PropTypes from "prop-types";

import { useAuth } from "../../contexts/AuthContext";
import { useResponsive } from "../../hooks/use-responsive";
import { bgBlur } from "../../theme/css";
import { themeProvider } from "../../theme/index";
import { HEADER } from "./config-layout";

// ----------------------------------------------------------------------

export default function Header({ onOpenNav }) {
  const theme = useTheme();
  const { toggleMode } = themeProvider();

  const lgUp = useResponsive("up", "lg");

  const { logout, user, token } = useAuth();

  function logoutFunction() {
    logout(token);
  }

  const renderContent = (
    <Stack
      style={{ width: "100%" }}
      direction="row"
      justifyContent="space-between"
    >
      <Stack
        direction="row"
        alignItems="flex-start"
      >
        {!lgUp ? (
          <IconButton
            onClick={onOpenNav}
            sx={{ color: theme.palette.primary.main }}
            style={{ transition: "1s" }}
          >
            <MenuIcon
              sx={{ color: theme.palette.primary.main }}
              style={{ transition: "1s" }}
            />
          </IconButton>
        ) : (
          <Stack sx={{ ml: 1 }}></Stack>
        )}

        <Stack sx={{ g: 1 }}>
          <a style={{ color: theme.palette.text.primary, transition: "1s", fontFamily: "Rawline Medium", fontSize: "18px" }}> Acredita</a>

          <a style={{ color: theme.palette.text.primary, transition: "1s", fontFamily: "Rawline Regular", fontSize: "14px" }}>
            Programa Acredita no Primeiro Passo
          </a>
        </Stack>
      </Stack>

      {lgUp && (
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
        >
          <IconButton
            onClick={toggleMode}
            sx={{ ml: 1, color: theme => alpha(theme.palette.primary.main, 1) }}
          >
            <InvertColorsIcon
              sx={{ color: theme => alpha(theme.palette.primary.main, 1) }}
              style={{ transition: "1s" }}
            />
          </IconButton>

          <div style={{ height: "50%", width: "1px", border: "1px solid #b5b5b5" }}></div>

          <IconButton
            component={Link}
            to="/profile"
            sx={{ ml: 1, color: theme => alpha(theme.palette.primary.main, 1) }}
          >
            <PersonIcon
              sx={{ color: theme => alpha(theme.palette.primary.main, 1) }}
              style={{ transition: "1s" }}
            />
          </IconButton>

          <Stack>
            <a style={{ color: theme.palette.primary.main, transition: "1s", fontFamily: "Rawline Regular", fontSize: "14px" }}>
              Bem vindo
            </a>
            <a style={{ color: theme.palette.primary.main, transition: "1s", fontFamily: "Rawline Bold", fontSize: "14px" }}>
              {user?.no_usuario || "..."}
            </a>
          </Stack>

          <IconButton
            onClick={logoutFunction}
            sx={{ ml: 1, color: theme => alpha(theme.palette.primary.main, 1) }}
          >
            <LogoutIcon
              sx={{ color: theme => alpha(theme.palette.primary.main, 1) }}
              style={{ transition: "1s" }}
            />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );

  return (
    <AppBar
      sx={{
        boxShadow: "0px 1px 6px 0px rgba(51, 51, 51, 0.16)",

        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.common.white,
        }),
        transition: theme.transitions.create(["height"], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          height: HEADER.H_DESKTOP,
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 2 },
          backgroundColor: theme => alpha(theme.palette.background.default, 0.95),
          borderBottom: theme.palette.border.layout,
        }}
        style={{ transition: "1s" }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
