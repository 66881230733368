import React from "react";

import { Grid, TextField } from "@mui/material";
import PropTypes from "prop-types";

import CustomModal from "./CustomModal";

const MobilizacaoVagaCidadaoModal = ({ showModal, handleClose }) => {
  const modalTitle = "Mobilização de Vaga por Cidadão";
  const modalContent = (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
      >
        <TextField
          label="CPF"
          value={"Teste"}
          fullWidth
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
      >
        <TextField
          label="Nome"
          value={"Teste"}
          disabled
          fullWidth
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
      >
        <TextField
          label="Município/UF"
          value={"Teste"}
          disabled
          fullWidth
        />
      </Grid>
    </Grid>
  );

  const modalButtons = [{ label: "PESQUISAR", onClick: handleClose, variant: "contained", icon: "search" }];

  return (
    <CustomModal
      showModal={showModal}
      handleClose={handleClose}
      title={modalTitle}
      content={modalContent}
      buttons={modalButtons}
      maxWidth="sm"
    />
  );
};

MobilizacaoVagaCidadaoModal.propTypes = {
  // values: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default MobilizacaoVagaCidadaoModal;
