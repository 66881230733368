import React from "react";

import { Container } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import AdminListarTrilhas from "../../components/filtros/AdminListarTrilhas";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import adminService from "../../services/admin.service";

const PageTrilhaListar = () => {
  // Conexão com o back-end
  const {
    isPending,
    isError,
    data: initialData,
    error,
  } = useQuery({
    queryKey: ["listarTrilhaCurso"],
    queryFn: adminService.getTrilhasCurso,
  });

  if (isError) {
    console.log("Erro de carregamento:", error);
  }

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "", text: "Trilhas" }]}
        currentPage="Listar Trilhas"
      />

      <TitlePage title={"Listar Trilhas"} />

      {isPending ? (
        <LoadingComponent
          isLoading={isPending}
          fullScreen={false}
        />
      ) : (
        <AdminListarTrilhas initialData={initialData} />
      )}

      {isError && <ErroCarregamentoModal isShow={true} />}
    </Container>
  );
};

export default PageTrilhaListar;
