import React from "react";

import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import { Card, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Radio, RadioGroup, Stack, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";

import ReusableSwitch from "./fields/ReusableSwitch.js";

const FormInformarSituacaoContato = ({ handleSubmit, values, readOnly = false }) => {
  const initialValues = {
    contatadoPorOperador: values?.contatadoPorOperador || false,
    dataContato: values?.dataContato || null,
    parceiroContato: values?.parceiroContato || "",
    emprestimoConcedido: values?.emprestimoConcedido || false,
    quantidadeParceirosEmprestimo: values?.quantidadeParceirosEmprestimo || "",
    dataEmprestimo: values?.dataEmprestimo || null,
    valorEmprestimo: values?.valorEmprestimo || "",
    parceiroCredito: values?.parceiroCredito || "",
  };

  const handleSwitchChange = (event, setFieldValue) => {
    const { name, checked } = event.target;
    setFieldValue(name, checked);
  };

  const handleDateChange = (name, value, setFieldValue) => {
    setFieldValue(name, value);
  };

  const validationSchema = Yup.object().shape({
    nomeMae: Yup.string().email("Forneça o nome da sua mãe").required("Nome da mãe é obrigatório"),
    nomePai: Yup.string().email("Forneça o nome da sua mãe").required("Nome da mãe é obrigatório"),
    dataNascimento: Yup.string().email("Forneça uma data de nascimento válida").required("Data de nascimento é obrigatório"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange={false}
    >
      {({ values, setFieldValue, handleChange, handleBlur }) => (
        <Form>
          <Stack>
            <Card
              color="#ffffff"
              sx={{
                borderRadius: "8px",
                padding: "16px",
              }}
              style={{ transition: "1s", marginBottom: "16px" }}
            >
              <Grid
                container
                spacing={1}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{ mb: "16px" }}
                >
                  <p style={{ fontFamily: "rawline Bold", marginBottom: "5px" }}>
                    1. Você já foi contatado por algum operador de microcrédito parceiro do Acredita?
                  </p>
                  <ReusableSwitch
                    id={"contatadoPorOperador"}
                    name={"contatadoPorOperador"}
                    value={values.contatadoPorOperador}
                    onChange={event => handleSwitchChange(event, setFieldValue)}
                    disabled={false}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                >
                  <FormGroup>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Mês e ano do contato"
                        id="dataContato"
                        name="dataContato"
                        value={values.dataContato}
                        format="DD/MM/YYYY"
                        onChange={value => handleDateChange("dataContato", value, setFieldValue)}
                      />
                    </LocalizationProvider>
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ mb: "16px" }}
                >
                  <FormGroup>
                    <TextField
                      label="Qual parceiro que fez o contato"
                      id="parceiroContato"
                      name="parceiroContato"
                      value={values.parceiroContato}
                      placeholder="Insira o nome do parceiro que fez o contato"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Card>

            <Card
              color="#ffffff"
              sx={{
                borderRadius: "8px",
                padding: "16px",
              }}
              style={{ transition: "1s", marginBottom: "16px" }}
            >
              <Grid
                container
                spacing={1}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{ mb: "16px" }}
                >
                  <p style={{ fontFamily: "rawline Bold", marginBottom: "5px" }}>
                    2. Você já teve o empréstimo concedido por algum operador de microcrédito parceiro do Acredita?
                  </p>
                  <ReusableSwitch
                    id={"emprestimoConcedido"}
                    name={"emprestimoConcedido"}
                    value={values.emprestimoConcedido}
                    onChange={event => handleSwitchChange(event, setFieldValue)}
                    disabled={false}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ mb: "16px" }}
                >
                  <FormGroup>
                    <TextField
                      label="De quantos parceiros obteve empréstimo?"
                      id="quantidadeParceirosEmprestimo"
                      name="quantidadeParceirosEmprestimo"
                      value={values.quantidadeParceirosEmprestimo}
                      placeholder="Insira a quantidade de parceiros que obtever empréstimo"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                >
                  <FormGroup>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Mês e ano do empréstimo"
                        id="dataEmprestimo"
                        name="dataEmprestimo"
                        value={values.dataEmprestimo}
                        format="DD/MM/YYYY"
                        onChange={value => handleDateChange("dataEmprestimo", value, setFieldValue)}
                      />
                    </LocalizationProvider>
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ mb: "16px" }}
                >
                  <FormGroup>
                    <TextField
                      label="Valor do empréstimo"
                      id="valorEmprestimo"
                      name="valorEmprestimo"
                      value={values.valorEmprestimo}
                      placeholder="Insira o valor do empréstimo"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ mb: "16px" }}
                >
                  <FormGroup>
                    <TextField
                      label="Qual parceiro concedeu o empréstimo?"
                      id="parceiroCredito"
                      name="parceiroCredito"
                      value={values.parceiroCredito}
                      placeholder="Insira o nome do parceiro concedeu o empréstimo?"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Card>

            <Card
              color="#ffffff"
              sx={{
                borderRadius: "8px",
                padding: "16px",
              }}
              style={{ transition: "1s", marginBottom: "16px" }}
            >
              <Grid
                container
                spacing={1}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{ mb: "16px" }}
                >
                  <p style={{ fontFamily: "rawline Bold", marginBottom: "5px" }}>3. Você já iniciou o seu negócio?</p>
                  <ReusableSwitch
                    id={"iniciouNegocio"}
                    name={"iniciouNegocio"}
                    value={values.iniciouNegocio}
                    onChange={event => handleSwitchChange(event, setFieldValue)}
                    disabled={false}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  mb={2}
                >
                  <FormGroup>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Mês e ano do início"
                        id="dataInicioNegocio"
                        name="dataInicioNegocio"
                        value={values.dataInicioNegocio}
                        format="DD/MM/YYYY"
                        onChange={value => handleDateChange("dataInicioNegocio", value, setFieldValue)}
                      />
                    </LocalizationProvider>
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                >
                  <FormControl>
                    <FormLabel
                      sx={{ color: "text.primary" }}
                      id="demo-radio-buttons-group-label"
                    >
                      Qual a situação do negócio
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="situacaoPj"
                    >
                      <FormControlLabel
                        value="female"
                        control={<Radio sx={{ color: "text.primary" }} />}
                        label="Formal (MEI, CNPJ)"
                      />
                      <FormControlLabel
                        value="male"
                        control={<Radio sx={{ color: "text.primary" }} />}
                        label="Informal (sem registro)"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={9}
                  sx={{ mb: "16px", alignSelf: "end" }}
                >
                  <FormGroup>
                    <TextField
                      label="CNPJ"
                      id="cnpj"
                      name="cnpj"
                      value={values.cnpj}
                      placeholder="Insira o seu CNPJ"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Card>
            <Grid
              container
              sx={{ justifyContent: "flex-end" }}
              spacing={2}
            >
              <Grid
                item
                md={2}
              >
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="outlined"
                  onClick={async () => {
                    "";
                  }}
                  // loading={loading || isSubmitting}
                  fullWidth
                >
                  CANCELAR
                </LoadingButton>
              </Grid>
              <Grid
                item
                md={2}
              >
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={async () => {
                    "";
                  }}
                  // loading={loading || isSubmitting}
                  fullWidth
                >
                  <SaveIcon sx={{ mb: "4px", mr: "4px", width: "20px", height: "20px" }} />
                  Salvar
                </LoadingButton>
              </Grid>
            </Grid>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

FormInformarSituacaoContato.propTypes = {
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  confirmacaoModal: PropTypes.bool,
  setConfirmacaoModal: PropTypes.func,
  setErroModal: PropTypes.func,
  erroModal: PropTypes.bool,
  erroDoUsuarioModal: PropTypes.bool,
  values: PropTypes.object,
  readOnly: PropTypes.bool,
};

export default FormInformarSituacaoContato;
