import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ParceiroVagaTrabalho from "../../components/formularios/ParceiroVagaTrabalho";
import TitlePage from "../../components/title-page/TitlePage";

const valoresIniciais = {
  tipoVaga: 0,
  cnpj: "00.000.000/0000-00",
  nomeOfertante: "Teste",
  funcao: "Função Teste",
  quantidadeVagas: "100",
  validadeAnuncio: 0,
  escolaridadeRequerida: "Ensino Fundamental",
  salario: "1000",
  turno: 0,
  descricao: "Teste",
  uf: "DF",
  municipio: "Brasília",
  cep: "44444-444",
  endereco: "Teste",
  numero: "00",
  complemento: "Teste",
  referencia: "Teste",
  bairro: "Teste",
  detalheAuxilioAlimentacao: "Teste",
  detalheAuxilioTransporte: "Teste",
  detalhePlanoSaude: "Teste",
  detalheOutros: "Teste",
  areaAtuacaoProfissional: [[1, "Exemplo de Experiência", "Desejado"]],
  areaAtuacaoEstudante: [[2, "Outra Experiência", "Exigido"]],
  cursoComplementar: [[2, "Teste", "Desejado"]],
  cursoTecnico: [[3, "Teste", "Exigido"]],
  cursoSuperior: [[4, "Teste", "Desejado"]],
};

const PageEditarVagaTrabalho = () => {
  // TODO adicionar chamada GET ao backend
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          {
            href: "/",
            text: "Página Inicial",
          },
          {
            href: "/parceiro",
            text: "Parceiro",
          },
        ]}
        currentPage={"Editar Vaga de Trabalho"}
      />

      <TitlePage title={"Dados da vaga"} />

      <ParceiroVagaTrabalho initialValues={valoresIniciais} />
    </Container>
  );
};

export default PageEditarVagaTrabalho;
