import React, { useState } from "react";

import { FormGroup, Grid, TextField } from "@mui/material";
import PropTypes from "prop-types";

import FileUpload from "../../components/inputs/fileUpload";
import CustomModal from "./CustomModal";

const InativarCurso = ({ showModal, handleClose }) => {
  const [files, setFiles] = useState([]);
  const handleFilesChange = values => {
    // Dummy console log só para não reclamar do files
    console.log(files);
    setFiles(values);
  };

  const modalTitle = "Inativar Curso";
  const modalContent = (
    <Grid container>
      <Grid
        item
        xs={12}
      >
        <FormGroup>
          <TextField
            multiline
            id="motivo"
            name="motivo"
            rows={3}
            label="Motivo"
            placeholder="Motivo"
          />
        </FormGroup>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <FileUpload
          onFieldsChange={handleFilesChange}
          fieldLabel={"Anexar Imagem"}
        />
      </Grid>
    </Grid>
  );

  const modalButtons = [
    { label: "CANCELAR", onClick: handleClose, variant: "outlined" },
    { label: "VISUALIZAR OFERTANTE", onClick: handleClose, variant: "outlined" },
    { label: "VISUALIZAR CURSO", onClick: handleClose, variant: "outlined" },
    { label: "SALVAR", onClick: handleClose, variant: "contained", icon: "save" },
  ];

  return (
    <CustomModal
      showModal={showModal}
      handleClose={handleClose}
      title={modalTitle}
      content={modalContent}
      buttons={modalButtons}
      maxWidth="sm"
    />
  );
};

InativarCurso.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default InativarCurso;
