import React from "react";

import { Card, Container, Typography, useTheme } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { PieChart } from "@mui/x-charts";
import { BarChart } from "@mui/x-charts/BarChart";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import TitlePage from "../../components/title-page/TitlePage";

const meses = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

const valoresMeses = [11004, 2588, 1597, 1343, 1120, 994, 734, 530, 450, 323, 121, 55];

const itensLista = [
  "Amazoncred",
  "Avante",
  "Banco da Família",
  "Banco da Gente",
  "Banco do Empreendedor",
  "Banco do Nordeste",
  "Banestes",
  "Blusol",
  "BRB",
  "Caixa/GEMIC",
  "Casa do Empreendedor",
  "Casa do Microcrédito",
  "CredPopular",
  "Crediamai",
  "Credioeste",
  "Credisol",
  "Finsol",
  "Instituto Estrela",
  "Movera",
  "Programa Providência",
  "Santander",
  "Sicredi Aliança",
];

const PageGerarRelatorioIndicadores = () => {
  const theme = useTheme();
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        homeLink="/"
        homeText="Página Inicial"
      />

      <TitlePage title={"Relatório de indicadores"} />

      <Card
        color="#ffffff"
        sx={{
          borderRadius: "8px",
          padding: "16px",
          mt: "16px",
        }}
      >
        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
        >
          1. NÚMERO DE CIDADÃOS INSCRITOS NO PROGRAMA ACREDITA NO PRIMEIRO PASSO - EVOLUÇÃO MENSAL
        </Typography>

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Cidadãos inscritos no Programa Acredita no Primeiro Passo 2021
        </Typography>
        <BarChart
          xAxis={[
            {
              data: meses,
              scaleType: "band",
            },
          ]}
          series={[
            {
              data: valoresMeses,
              color: [theme.palette.primary.main],
            },
          ]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Cidadãos inscritos no Programa Acredita no Primeiro Passo 2020
        </Typography>
        <BarChart
          xAxis={[
            {
              data: meses,
              scaleType: "band",
            },
          ]}
          series={[
            {
              data: valoresMeses,
              color: [theme.palette.primary.main],
            },
          ]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Cidadãos inscritos no Programa Acredita no Primeiro Passo 2019
        </Typography>
        <BarChart
          xAxis={[
            {
              data: meses,
              scaleType: "band",
            },
          ]}
          series={[
            {
              data: valoresMeses,
              color: [theme.palette.primary.main],
            },
          ]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Cidadãos inscritos no Programa Acredita no Primeiro Passo 2018
        </Typography>
        <BarChart
          xAxis={[
            {
              data: meses,
              scaleType: "band",
            },
          ]}
          series={[
            {
              data: valoresMeses,
              color: [theme.palette.primary.main],
            },
          ]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Cidadãos inscritos no Programa Acredita no Primeiro Passo 2017
        </Typography>
        <BarChart
          xAxis={[
            {
              data: ["Setembro", "Outubro", "Novembro", "Dezembro"],
              scaleType: "band",
            },
          ]}
          series={[
            {
              data: valoresMeses.slice(0, 4),
              color: [theme.palette.primary.main],
            },
          ]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
        >
          2. NÚMERO DE CIDADÃOS INSCRITOS NO PROGRAMA ACREDITA NO PRIMEIRO PASSO - EVOLUÇÃO ANUAL E ACUMULADO
        </Typography>

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Cidadãos inscritos no Programa Acredita no Primeiro Passo Por Ano e Total
        </Typography>
        <BarChart
          xAxis={[
            {
              data: ["2017", "2018", "2019", "2020", "2021", "Total"],
              scaleType: "band",
            },
          ]}
          series={[
            {
              data: valoresMeses.slice(0, 6),
              color: [theme.palette.primary.main],
            },
          ]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
        >
          3. NÚMERO DE CIDADÃOS INSCRITOS NO PROGRAMA ACREDITA NO PRIMEIRO PASSO - POR REGIÃO
        </Typography>

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Percentual de Inscritos por Região
        </Typography>
        <PieChart
          series={[
            {
              data: [
                { id: 0, value: 10, label: "Sul" },
                { id: 1, value: 15, label: "Norte" },
                { id: 2, value: 20, label: "Nordeste" },
                { id: 3, value: 20, label: "Centro-oeste" },
                { id: 4, value: 20, label: "Sudeste" },
              ],
            },
          ]}
          width={400}
          height={200}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
        >
          4. ACESSOS AOS APLICATIVOS - EVOLUÇÃO MENSAL
        </Typography>

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Acesso ao Aplicativo 2021
        </Typography>

        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: meses,
            },
          ]}
          series={[{ data: [4, 3, 5, 2, 5, 7, 4, 3, 5, 2, 5, 7] }, { data: [2, 5, 7, 4, 3, 5, 2, 5, 7, 4, 3, 5] }]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Acesso ao Aplicativo 2020
        </Typography>

        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: meses,
            },
          ]}
          series={[{ data: [4, 3, 5, 2, 5, 7, 4, 3, 5, 2, 5, 7] }, { data: [2, 5, 7, 4, 3, 5, 2, 5, 7, 4, 3, 5] }]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Acesso ao Aplicativo 2019
        </Typography>

        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: meses,
            },
          ]}
          series={[{ data: [4, 3, 5, 2, 5, 7, 4, 3, 5, 2, 5, 7] }, { data: [2, 5, 7, 4, 3, 5, 2, 5, 7, 4, 3, 5] }]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Acesso ao Aplicativo 2018
        </Typography>

        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: meses,
            },
          ]}
          series={[{ data: [4, 3, 5, 2, 5, 7, 4, 3, 5, 2, 5, 7] }, { data: [2, 5, 7, 4, 3, 5, 2, 5, 7, 4, 3, 5] }]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
        >
          5. ACESSOS AOS APLICATIVOS - EVOLUÇÃO ANUAL E ACUMULADO
        </Typography>

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Acesso ao Aplicativo Por Ano e Total
        </Typography>

        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: ["2018", "2019", "2020", "2021", "Total"],
            },
          ]}
          series={[{ data: [4, 3, 5, 2, 5] }, { data: [2, 5, 7, 4, 3] }]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
        >
          6. ACESSO AO PORTAL DO PROGRAMA ACREDITA NO PRIMEIRO PASSO (WEB) - EVOLUÇÃO MENSAL
        </Typography>

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Acesso ao Site - Evolução Mensal
        </Typography>

        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: meses,
            },
          ]}
          series={[{ data: [4, 3, 5, 2, 5, 7, 4, 3, 5, 2, 5, 7] }, { data: [2, 5, 7, 4, 3, 5, 2, 5, 7, 4, 3, 5] }]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
        >
          7. NOTA DO APLICATIVO DO PROGRAMA ACREDITA NO PRIMEIRO PASSO NAS LOJAS DE APLICATIVOS APPLE E ANDROID
        </Typography>

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Nota do Aplicativo
        </Typography>

        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: ["Nota do Aplicativo de 0 a 5"],
            },
          ]}
          series={[{ data: [4] }, { data: [2] }]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
        >
          8. COMENTÁRIOS NAS LOJAS DE APLICATIVOS
        </Typography>

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Comentários nas lojas de aplicativos
        </Typography>

        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: meses,
            },
          ]}
          series={[
            { data: [4, 3, 5, 2, 5, 7, 4, 3, 5, 2, 5, 7] },
            { data: [2, 5, 7, 4, 3, 5, 2, 5, 7, 4, 3, 5] },
            { data: [2, 5, 7, 4, 3, 5, 2, 5, 7, 4, 3, 5] },
          ]}
          height={300}
        />

        <Typography
          variant="h3"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Indicadores Relacionados aos Parceiros
        </Typography>

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
        >
          9. NÚMERO TOTAL DE PARCEIROS (PÚBLICO/PRIVADO)
        </Typography>

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Rede de Parceiros do Desenvolvimento Social
        </Typography>

        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: ["Total"],
            },
          ]}
          series={[{ data: [4] }, { data: [2] }, { data: [3] }]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
        >
          10. NÚMERO TOTAL DE PARCEIROS (PÚBLICO/PRIVADO) - EVOLUÇÃO ANUAL E ACUMULADO
        </Typography>

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Rede de parceiros de desenvolvimento social por ano e total
        </Typography>

        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: ["2017", "2018", "2019", "2020", "2021", "Total"],
            },
          ]}
          series={[{ data: [4, 3, 5, 2, 5, 7] }, { data: [2, 5, 7, 4, 3, 5] }]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
        >
          11. NÚMERO DE PARCEIROS POR TIPO
        </Typography>

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Quantidade de parceiros por tipo de oportunidade ofertada
        </Typography>
        <BarChart
          xAxis={[
            {
              data: [
                "Emprego",
                "Jovem Aprendiz",
                "Estágio",
                "Cursos",
                "Financeiros e Pagamentos",
                "Prospecção de público",
                "Prospecção de parceiro",
              ],
              scaleType: "band",
            },
          ]}
          series={[
            {
              data: [11004, 2588, 1597, 1343, 1120, 994, 734],
              color: [theme.palette.primary.main],
            },
          ]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
        >
          12. NÚMERO DE PARCEIROS ATIVOS
        </Typography>

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Parceiros Ativos
        </Typography>

        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: ["Parceiros Ativos"],
            },
          ]}
          series={[{ data: [4] }, { data: [2] }, { data: [3] }]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
        >
          13. NÍVEL DE SATISFAÇÃO DOS PARCEIROS COM O PROGRAMA ACREDITA NO PRIMEIRO PASSO. PROBABILIDADE DOS PARCEIROS RECOMENDAREM O
          PROGRAMA AGREDITA NO RIMEIRO PASSO A OUTRAS INSTITUIÇÕES (COLETA EM 10 DE DEZEMBRO DE 2021)
        </Typography>

        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: ["Parceiros Ativos"],
            },
          ]}
          series={[{ data: [4] }, { data: [2] }, { data: [3] }]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
        >
          14. OFERTA DE NOVOS CURSOS NO PROGRAMA ACREDITA NO PRIMEIRO PASSO - EVOLUÇÃO MENSAL
        </Typography>

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Cursos por mês 2021
        </Typography>
        <BarChart
          xAxis={[
            {
              data: meses,
              scaleType: "band",
            },
          ]}
          series={[
            {
              data: valoresMeses,
              color: [theme.palette.primary.main],
            },
          ]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Cursos por mês 2020
        </Typography>
        <BarChart
          xAxis={[
            {
              data: meses,
              scaleType: "band",
            },
          ]}
          series={[
            {
              data: valoresMeses,
              color: [theme.palette.primary.main],
            },
          ]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Cursos por mês 2019
        </Typography>
        <BarChart
          xAxis={[
            {
              data: meses,
              scaleType: "band",
            },
          ]}
          series={[
            {
              data: valoresMeses,
              color: [theme.palette.primary.main],
            },
          ]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Cursos por mês 2018
        </Typography>
        <BarChart
          xAxis={[
            {
              data: meses,
              scaleType: "band",
            },
          ]}
          series={[
            {
              data: valoresMeses,
              color: [theme.palette.primary.main],
            },
          ]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
        >
          15. OFERTA DE NOVOS CURSOS NO PROGRAMA ACREDITA NO PRIMEIRO PASSO - EVOLUÇÃO ANUAL E ACUMULADO
        </Typography>

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Oferta de cursos no Programa Acredita no Primeiro Passo por ano e Total
        </Typography>
        <BarChart
          xAxis={[
            {
              data: ["2018", "2019", "2020", "2021", "Total"],
              scaleType: "band",
            },
          ]}
          series={[
            {
              data: [1004, 2588, 1597, 1343, 1120],
              color: [theme.palette.primary.main],
            },
          ]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
        >
          16. NÚMERO DE VAGAS EM CURSOS PRESENCIAIS - EVOLUÇÃO MENSAL
        </Typography>

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Nº de Vagas em cursos presenciais 2021
        </Typography>
        <BarChart
          xAxis={[
            {
              data: meses,
              scaleType: "band",
            },
          ]}
          series={[
            {
              data: valoresMeses,
              color: [theme.palette.primary.main],
            },
          ]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Nº de Vagas em cursos presenciais 2020
        </Typography>
        <BarChart
          xAxis={[
            {
              data: meses,
              scaleType: "band",
            },
          ]}
          series={[
            {
              data: valoresMeses,
              color: [theme.palette.primary.main],
            },
          ]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Nº de Vagas em cursos presenciais 2019
        </Typography>
        <BarChart
          xAxis={[
            {
              data: meses,
              scaleType: "band",
            },
          ]}
          series={[
            {
              data: valoresMeses,
              color: [theme.palette.primary.main],
            },
          ]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Nº de Vagas em cursos presenciais 2018
        </Typography>
        <BarChart
          xAxis={[
            {
              data: meses,
              scaleType: "band",
            },
          ]}
          series={[
            {
              data: valoresMeses,
              color: [theme.palette.primary.main],
            },
          ]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
        >
          17. NÚMERO DE VAGAS EM CURSOS PRESENCIAIS - EVOLUÇÃO ANUAL E ACUMULADO
        </Typography>

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Oferta de Vagas em cursos presenciais no Programa Acredita no Primeiro Passo por ano e total
        </Typography>
        <BarChart
          xAxis={[
            {
              data: ["2018", "2019", "2020", "2021", "Total"],
              scaleType: "band",
            },
          ]}
          series={[
            {
              data: [530, 450, 323, 121, 55],
              color: [theme.palette.primary.main],
            },
          ]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
        >
          18. CURSOS NOVOS INSERIDOS NO ÚLTIMO MÊS
        </Typography>

        <Typography mt={1}>Nenhum curso cadastrado no último mês.</Typography>

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
        >
          19. INTERESSE DOS CIDADÃOS INSCRITOS NO PROGRAMA ACREDITA NO PRIMEIRO PASSO PELOS CURSOS CADASTRADOS NO PORTAL - NÚMERO ACUMULADO
        </Typography>

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Cursos que despertam maior interesse dentre os ativos por parceiro
        </Typography>
        <BarChart
          xAxis={[
            {
              data: ["curso 1", "curso 2", "curso 3", "curso 4", "curso 5", "curso 6", "curso 7"],
              scaleType: "band",
            },
          ]}
          series={[
            {
              data: [994, 734, 530, 450, 323, 121, 55],
              color: [theme.palette.primary.main],
            },
          ]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
        >
          20. PRINCIPAIS PARCEIROS - NÚMERO ACUMULADO DE CURSOS OFERTADOS POR PARCEIRO
        </Typography>

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Cursos ofertados por parceiro
        </Typography>
        <BarChart
          xAxis={[
            {
              data: ["Parceiro 1", "Parceiro 2", "Parceiro 3", "Parceiro 4", "Parceiro 5", "Parceiro 6", "Parceiro 7"],
              scaleType: "band",
            },
          ]}
          series={[
            {
              data: [994, 734, 530, 450, 323, 121, 55],
              color: [theme.palette.primary.main],
            },
          ]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
        >
          21. NÚMERO DE PESSOAS INSCRITAS NO CADASTRO ÚNICO CAPACITADAS PELO SISTEMA S
        </Typography>
        <Typography mt={1}>Sem dados até o momento.</Typography>

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
        >
          22. NÚMERO DE PESSOAS INSCRITAS NO CADASTRO ÚNICO CAPACITADAS PELO PRONATEC
        </Typography>
        <Typography mt={1}>Sem dados até o momento.</Typography>

        <Typography
          variant="h3"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Indicadores de Intermediação de Mão de Obra
        </Typography>

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
        >
          23. Vagas de trabalho ofertadas no Programa Acredita no Primeiro Passo - Evolução Mensal
        </Typography>

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Vagas Cadastradas no Programa Acredita no Primeiro Passo 2021
        </Typography>
        <BarChart
          xAxis={[
            {
              data: meses,
              scaleType: "band",
            },
          ]}
          series={[
            {
              data: valoresMeses,
              color: [theme.palette.primary.main],
            },
          ]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Vagas Cadastradas no Programa Acredita no Primeiro Passo 2020
        </Typography>
        <BarChart
          xAxis={[
            {
              data: meses,
              scaleType: "band",
            },
          ]}
          series={[
            {
              data: valoresMeses,
              color: [theme.palette.primary.main],
            },
          ]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Vagas Cadastradas no Programa Acredita no Primeiro Passo 2019
        </Typography>
        <BarChart
          xAxis={[
            {
              data: meses,
              scaleType: "band",
            },
          ]}
          series={[
            {
              data: valoresMeses,
              color: [theme.palette.primary.main],
            },
          ]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Vagas Cadastradas no Programa Acredita no Primeiro Passo 2018
        </Typography>
        <BarChart
          xAxis={[
            {
              data: meses,
              scaleType: "band",
            },
          ]}
          series={[
            {
              data: valoresMeses,
              color: [theme.palette.primary.main],
            },
          ]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
        >
          24. VAGAS DE TRABALHO OFERTADAS NO PROGRAMA ACREDITA NO PRIMEIRO PASSO - EVOLUÇÃO ANUAL E ACUMULADA
        </Typography>
        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Vagas Cadastradas no Programa Acredita no Primeiro Passo Por Ano e Total
        </Typography>
        <BarChart
          xAxis={[
            {
              data: ["2021", "2020", "2019", "2018", "Total"],
              scaleType: "band",
            },
          ]}
          series={[
            {
              data: [530, 450, 323, 121, 55],
              color: [theme.palette.primary.main],
            },
          ]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
        >
          25. VAGAS DE TRABALHO OFERTADAS NO PROGRAMA ACREDITA NO PRIMEIRO PASSO NO ÚLTIMO MÊS
        </Typography>
        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Vagas Cadastradas por parceiro - Dezembro
        </Typography>
        <BarChart
          xAxis={[
            {
              data: ["Emprego", "Estágio/Jovem Aprendiz"],
              scaleType: "band",
            },
          ]}
          series={[
            {
              data: [121, 55],
              color: [theme.palette.primary.main],
            },
          ]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
        >
          26. VAGAS MAIS BUSCADAS PELOS CIDADÃOS NA PLATAFORMA DO PROGRAMA ACREDITA NO PRIMEIRO PASSO
        </Typography>
        <Typography mt={1}>Sem dados até o momento.</Typography>

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
        >
          27. NÚMERO DE MANIFESTAÇÕES DE INTERESSE POR MICROCRÉDITO PRODUTIVO ORIENTADO (QUESTIONÁRIOS PREENCHIDOS) - EVOLUÇÃO ANUAL E
          ACUMULADO
        </Typography>

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Manifestações de interesse em acessar MPO Por Ano e Total
        </Typography>

        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: ["2017", "2018", "2019", "2020", "2021", "Total"],
            },
          ]}
          series={[{ data: [4, 3, 5, 2, 5] }, { data: [2, 5, 7, 4, 3] }]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Manifestações de Interesse em Acessar MPO em 2021
        </Typography>

        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: meses,
            },
          ]}
          series={[{ data: [4, 3, 5, 2, 5, 7, 4, 3, 5, 2, 5, 7] }, { data: [2, 5, 7, 4, 3, 5, 2, 5, 7, 4, 3, 5] }]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Manifestações de Interesse em Acessar MPO em 2020
        </Typography>

        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: meses,
            },
          ]}
          series={[{ data: [4, 3, 5, 2, 5, 7, 4, 3, 5, 2, 5, 7] }, { data: [2, 5, 7, 4, 3, 5, 2, 5, 7, 4, 3, 5] }]}
          height={300}
        />
        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Manifestações de Interesse em Acessar MPO em 2019
        </Typography>

        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: meses,
            },
          ]}
          series={[{ data: [4, 3, 5, 2, 5, 7, 4, 3, 5, 2, 5, 7] }, { data: [2, 5, 7, 4, 3, 5, 2, 5, 7, 4, 3, 5] }]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Manifestações de Interesse em Acessar MPO em 2018
        </Typography>

        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: meses,
            },
          ]}
          series={[{ data: [4, 3, 5, 2, 5, 7, 4, 3, 5, 2, 5, 7] }, { data: [2, 5, 7, 4, 3, 5, 2, 5, 7, 4, 3, 5] }]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
          align={"center"}
        >
          Manifestações de Interesse em Acessar MPO em 2017
        </Typography>

        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: meses,
            },
          ]}
          series={[{ data: [4, 3, 5, 2, 5, 7, 4, 3, 5, 2, 5, 7] }, { data: [2, 5, 7, 4, 3, 5, 2, 5, 7, 4, 3, 5] }]}
          height={300}
        />

        <Typography
          variant="h6"
          mt={2}
          sx={{ fontFamily: "Rawline Bold" }}
          style={{ transition: "1s" }}
        >
          29. INSTITUIÇÕES FINANCEIRAS PARCEIRAS
        </Typography>

        <List>
          {itensLista.map((item, index) => (
            <ListItem
              key={index}
              sx={{ paddingTop: 0, paddingBottom: 0 }}
            >
              {index + 1} {item}
            </ListItem>
          ))}
        </List>
      </Card>
    </Container>
  );
};

export default PageGerarRelatorioIndicadores;
