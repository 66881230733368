import React, { useEffect, useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import CircleIcon from "@mui/icons-material/Circle";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, Card, FormGroup, Grid, Stack, TextField, Typography } from "@mui/material";

import { dadosParceirosAprovados, tabelaRepresentantes } from "../../components/formularios/dadosMockados";
import DefaultTable from "../../components/table/DefaultTable";

// Dados fictícios para teste da tabela
const initialData = tabelaRepresentantes;

// Retornar a pesquisa vazia
const initialFilterState = {
  cnpj: "",
};

const AdminUsuariosRepresentantesParceiro = () => {
  const [filter, setFilter] = useState(initialFilterState);
  const [filteredData, setFilteredData] = useState(initialData);

  const parceiroID = 1; // Não temos de onde saber qual o parceiro ainda // JSON.parse(localStorage.getItem("parceiroID"));
  const [dados, setDados] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // TODO: chamar backend
  useEffect(() => {
    if (parceiroID) {
      const parceiroEncontrado = dadosParceirosAprovados.find(parceiro => parceiro.id === parceiroID);

      if (parceiroEncontrado) {
        const servicos = Object.keys(parceiroEncontrado.tipoDeServico).filter(servico => parceiroEncontrado.tipoDeServico[servico]);

        setDados({
          ...parceiroEncontrado,
          tipoDeServico: servicos,
        });
      }
    }
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <div>Carregando...</div>;
  }
  if (!dados) {
    return <div>Parceiro não encontrado</div>;
  }

  const handleFilterChange = event => {
    const { name, value } = event.target;
    setFilter({ [name]: value });
  };

  const applyFilter = () => {
    const filtered = initialData.filter(parceiro => {
      return filter.cnpj == null || parceiro.cnpj == filter.cnpj;
    });
    setFilteredData(filtered);
  };

  return (
    <Stack spacing={1}>
      <Card
        color="#ffffff"
        sx={{
          borderRadius: "8px",
          padding: "16px",
          mt: "24px",
        }}
      >
        {/* Campos para filtrar */}
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            xs={10}
          >
            <FormGroup>
              <TextField
                id="cnpj"
                name="cnpj"
                label="CNPJ"
                placeholder="CNPJ"
                value={filter.cnpj}
                type="text"
                onChange={handleFilterChange}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={applyFilter}
              sx={{ gap: "8px", m: "10px" }}
              style={{ fontFamily: "Rawline Medium" }}
            >
              <SearchIcon />
              Buscar
            </Button>
          </Grid>
        </Grid>

        <Grid
          container
          my={2}
        >
          <Typography variant="subtitle1">Representantes do parceiro</Typography>
        </Grid>

        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={3}
          >
            <FormGroup>
              <TextField
                disabled
                id="razaoSocial"
                name="razaoSocial"
                label="Razão Social"
                placeholder="Razão Social"
                value={dados.razaoSocial}
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
          >
            <FormGroup>
              <TextField
                disabled
                id="nomefantasia"
                name="nomefantasia"
                label="Nome fantasia"
                placeholder="Nome fantasia"
                value={dados.nomefantasia}
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
          >
            <FormGroup>
              <TextField
                disabled
                id="dataCadastro"
                name="dataCadastro"
                label="Data do Cadastro"
                placeholder="Data do Cadastro"
                value={dados.dataCadastro}
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
          >
            <FormGroup>
              <TextField
                disabled
                id="dataUltimaModificacao"
                name="dataUltimaModificacao"
                label="Data da Última Modificação"
                placeholder="Data da Última Modificação"
                value={dados.dataUltimaModificacao}
              />
            </FormGroup>
          </Grid>
        </Grid>

        {/* Cores */}
        <Box>
          <Grid
            container
            mt={2}
          >
            <Grid
              item
              xs={12}
              sm={4}
            >
              <CircleIcon sx={{ color: "#FF7A00", fontSize: "small", marginRight: "5px" }} />
              Usuário com a senha resetada
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <CircleIcon sx={{ color: "#FFCF00", fontSize: "small", marginRight: "5px" }} />
              Usuário que nunca acessou o parceiro
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <CircleIcon sx={{ color: "#00CA45", fontSize: "small", marginRight: "5px" }} />
              Usuário que já acessou o parceiro
            </Grid>
          </Grid>
        </Box>

        {/* Adicionar Representante */}
        <Grid
          container
          mt={2}
          spacing={1}
        >
          <Grid
            item
            xs={9}
          >
            <FormGroup>
              <TextField
                id="emailUsuario"
                name="emailUsuario"
                label="E-mail do usuário"
                placeholder="E-mail do usuário"
                //value={novosDados.emailUsuario}
                type="text"
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={2}
          >
            <Button
              variant="contained"
              sx={{ gap: "8px", mt: "5px", ml: "10px" }}
            >
              <AddCircleIcon />
              <Typography
                variant={"BUTTON TEXT"}
                sx={{
                  fontSize: "12px",
                  mt: "8px",
                  mb: "8px",
                }}
              >
                Incluir representante
              </Typography>
            </Button>
          </Grid>
        </Grid>

        {/* Tabela */}
        <Box sx={{ flexGrow: 1, mt: 2 }}>
          <DefaultTable
            columns={[
              { headerName: "Nome Completo", field: "nome" },
              { headerName: "E-mail", field: "email" },
            ]}
            rows={filteredData}
            actionButtons={() => [
              {
                title: "Remover",
                icon: "block",
              },
            ]}
            mostrarCirculosColoridos={true}
            listaCirculosColoridos={["#FF7A00", "#FFCF00", "#00CA45"]}
          />
        </Box>
      </Card>
    </Stack>
  );
};

export default AdminUsuariosRepresentantesParceiro;
