import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ParceiroMobilizacaoCidadao from "../../components/formularios/ParceiroMobilizacaoCidadao";
import TitlePage from "../../components/title-page/TitlePage";

const PageMobilizacaoCidadao = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/parceiro", text: "Parceiro" },
          { href: "/parceiro/mobilizacao", text: "Mobilização" },
        ]}
        currentPage={"Mobilização individual do cidadão"}
      />

      <TitlePage title={"Mobilização individual do cidadão"} />

      <ParceiroMobilizacaoCidadao />
    </Container>
  );
};

export default PageMobilizacaoCidadao;
