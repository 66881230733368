import React, { useEffect, useState } from "react";

import { Box, Card, Container, Grid, Typography } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import { tabelaCursosPendentes } from "../../components/formularios/dadosMockados";
import DefaultTable from "../../components/table/DefaultTable";

const tabelaColunas = [
  { field: "modificacao", headerName: "Modificação" },
  { field: "campo", headerName: "Campo alterado" },
  { field: "anterior", headerName: "Informação Anterior" },
  { field: "nova", headerName: "Nova Informação" },
];

const PageVisualizarAlteracoesCursoPendente = () => {
  const cursoID = JSON.parse(localStorage.getItem("cursosID"));
  const [curso, setCurso] = useState(null);
  const [historico, sethistorico] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (cursoID) {
      const cursoEncontrado = tabelaCursosPendentes.find(curso => curso.id === cursoID);

      if (cursoEncontrado) {
        setCurso(cursoEncontrado);
        sethistorico(cursoEncontrado.historico);
      }
    }

    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <div>Carregando...</div>;
  }

  if (!curso) {
    return <div>Curso não encontrado</div>;
  }

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/listar-cursos-pendentes", text: "Listar Cursos Pendentes" },
          { href: "/listar-cursos-pendentes/analisar", text: "Analisar" },
        ]}
        currentPage="Visualizar Alterações"
      />
      <Grid container>
        <Grid
          item
          xs={12}
        >
          <Typography
            variant="h4"
            mt={3}
            sx={{ fontFamily: "Rawline Bold" }}
            style={{ transition: "1s" }}
          >
            Visualizar Alterações
          </Typography>
        </Grid>
      </Grid>
      <Card
        color="#ffffff"
        sx={{
          borderRadius: "8px",
          padding: "16px",
          mt: "24px",
        }}
      >
        <Grid>
          <Typography variant="subtitle2">Informações alteradas no curso: {curso.nome}</Typography>
        </Grid>
        {/* Tabela */}
        <Box sx={{ flexGrow: 1, mt: "40px" }}>
          <DefaultTable
            rows={historico}
            notFoundText={"Não foi localizado histórico!"}
            columns={tabelaColunas}
          />
        </Box>
      </Card>
    </Container>
  );
};

export default PageVisualizarAlteracoesCursoPendente;
