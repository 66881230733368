import axiosInstance from "./axiosConfig";
import API_URL from "./config";
// const acessToken = localStorage.getItem("token");

/**
 * Retrieves a cidadao using the provided access token and ID.
 *
 * @param {number} id - The ID of the cidadao to retrieve
 * @return {Promise} A Promise that resolves to the data of the retrieved cidadao
 */
const getCidadaoData = () => {
  return axiosInstance
    .get(API_URL + "v1/trabs/index/", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      // console.log("Service cidadao", response.data);
      return response.data;
    })
    .catch(error => {
      // console.error("Error fetching cidadao data:", error);
      throw error;
    });
};

const cidadaoService = {
  getCidadaoData,
};

export default cidadaoService;
