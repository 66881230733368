import React from "react";

import { FormGroup, Grid, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";

import CustomModal from "./CustomModal";

const RelacaoDeCursoModal = ({ showModal, handleClose, situacao, cursos }) => {
  const modalTitle = "Relação de cursos da trilha";
  const texto = cursos.join("\n");
  const modalContent = (
    <>
      <Typography variant="subtitle2">Situação: {situacao}</Typography>
      <Grid
        item
        xs={12}
      >
        <FormGroup>
          <TextField
            multiline
            disabled
            id="cursos"
            name="cursos"
            label="Cursos"
            placeholder="Cursos"
            value={texto}
          />
        </FormGroup>
      </Grid>
    </>
  );

  const modalButtons = [
    { label: "CANCELAR", onClick: handleClose, variant: "outlined" },
    { label: "SALVAR", onClick: handleClose, variant: "contained", icon: "save" },
  ];

  return (
    <CustomModal
      showModal={showModal}
      handleClose={handleClose}
      title={modalTitle}
      content={modalContent}
      buttons={modalButtons}
      maxWidth="sm"
    />
  );
};

RelacaoDeCursoModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  situacao: PropTypes.string.isRequired,
  cursos: PropTypes.array.isRequired,
};

export default RelacaoDeCursoModal;
