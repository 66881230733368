import React, { useState } from "react";

import Save from "@mui/icons-material/Save";
import { Box, Button, Card, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";

import TransferList from "../transferList/TransferList";
import { formatTelefone } from "./utils.js";

const AdminUsuario = ({ editar, visualizar, valores, setValores }) => {
  const [itemsTransferfList, setItemsTransferList] = useState({
    left: ["perfil 1", "perfil 2", "perfil 5"],
    right: ["perfil 3", "perfil 4", "perfil 6"],
  });

  const handleChange = event => {
    const { name, value } = event.target;
    setValores({ ...valores, [name]: value });
  };

  const handleItemsTransferListChange = updatedItems => {
    setItemsTransferList(updatedItems);
  };

  return (
    <>
      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          <Formik initialValues={valores}>
            <Form>
              {/* Campos */}
              <Grid
                container
                spacing={2}
              >
                {/* Nome, Login e CPF */}
                <Grid
                  item
                  spacing={2}
                  container
                >
                  <Grid
                    item
                    xs={12}
                    sm={4.5}
                  >
                    <TextField
                      fullWidth
                      disabled={visualizar}
                      id="nome"
                      name="nome"
                      value={valores.nome}
                      label="Nome"
                      placeholder="Nome"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4.5}
                  >
                    <TextField
                      fullWidth
                      disabled={visualizar}
                      id="login"
                      name="login"
                      value={valores.login}
                      label="Login"
                      placeholder="Login"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                  >
                    <TextField
                      fullWidth
                      disabled={visualizar}
                      id="cpf"
                      name="cpf"
                      value={valores.cpf}
                      label="CPF"
                      placeholder="CPF"
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>

                {/* Tipo, E-mail e Telefone */}
                <Grid
                  item
                  spacing={2}
                  container
                >
                  <Grid
                    item
                    xs={12}
                    sm={4}
                  >
                    <TextField
                      fullWidth
                      disabled={visualizar}
                      id="tipo"
                      name="tipo"
                      value={valores.tipo}
                      label="Tipo de usuário"
                      placeholder="Tipo de usuário"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                  >
                    <TextField
                      fullWidth
                      disabled={visualizar}
                      id="email"
                      name="email"
                      value={valores.email}
                      label="E-mail"
                      placeholder="E-mail"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                  >
                    <TextField
                      fullWidth
                      disabled={visualizar}
                      id="telefone"
                      name="telefone"
                      value={formatTelefone(valores.telefone) || ""}
                      label="Telefone"
                      placeholder="Telefone"
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>

                {/* Situação e Perfil */}
                {(editar || visualizar) && (
                  <Grid
                    item
                    xs={12}
                    sm={visualizar ? 6 : 12}
                  >
                    <FormControl style={{ width: "100%" }}>
                      <InputLabel id="label-situacao">Situação</InputLabel>
                      <Select
                        disabled={visualizar}
                        id="situacao"
                        name="situacao"
                        value={valores.situacao || "none"}
                        onChange={handleChange}
                        labelId="label-situacao"
                      >
                        <MenuItem
                          value="none"
                          disabled
                        >
                          Situação
                        </MenuItem>
                        <MenuItem value="Ativo">Ativo</MenuItem>
                        <MenuItem value="Inativo">Inativo</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {!visualizar && (
                  <Grid
                    item
                    mt={2}
                    xs={12}
                  >
                    <TransferList
                      items={itemsTransferfList}
                      titleLeft={"Perfil Disponível"}
                      titleRight={"Perfil selecionado para o usuário"}
                      onItemsChange={handleItemsTransferListChange}
                    />
                  </Grid>
                )}

                {visualizar && (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <TextField
                        fullWidth
                        disabled
                        id="perfil"
                        name="perfil"
                        value={valores.perfil}
                        label="Perfil do Usuário"
                        placeholder="Perfil do Usuário"
                        onChange={handleChange}
                      />
                    </Grid>

                    {/* Motivo */}
                    <Grid
                      item
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        multiline
                        disabled={!editar}
                        rows={3}
                        id="motivo"
                        name="motivo"
                        value={valores.motivo}
                        label="Motivo"
                        placeholder="Motivo"
                        onChange={handleChange}
                      />
                    </Grid>
                  </>
                )}
              </Grid>

              {/* Botões de salvar/cancelar */}
              <Grid>
                <Box sx={{ flexGrow: 1, mt: "16px" }}>
                  <Grid
                    container
                    spacing={1}
                    justifyContent="flex-end"
                  >
                    <Grid item>
                      <Button
                        variant="outlined"
                        sx={{ gap: "8px" }}
                        style={{ fontFamily: "Rawline Medium" }}
                        onClick={() => window.history.back()}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                    {visualizar ? (
                      <>
                        {!editar && (
                          <>
                            <Grid item>
                              <Button
                                variant="outlined"
                                href="/usuario/visualizar/historico-situacao"
                                sx={{ gap: "8px" }}
                                style={{ fontFamily: "Rawline Medium" }}
                              >
                                Histórico de ativação
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                variant="outlined"
                                href="/usuario/editar/"
                                sx={{ gap: "8px" }}
                                style={{ fontFamily: "Rawline Medium" }}
                              >
                                Editar
                              </Button>
                            </Grid>
                          </>
                        )}
                        <Grid item>
                          <Button
                            variant="contained"
                            sx={{ gap: "8px" }}
                            style={{ fontFamily: "Rawline Medium" }}
                          >
                            Excluir
                          </Button>
                        </Grid>
                      </>
                    ) : (
                      <Grid item>
                        <Button
                          variant="contained"
                          sx={{ gap: "8px" }}
                          style={{ fontFamily: "Rawline Medium" }}
                        >
                          <Save />
                          Salvar
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Grid>
            </Form>
          </Formik>
        </Card>
      </Stack>
    </>
  );
};

AdminUsuario.propTypes = {
  editar: PropTypes.bool,
  visualizar: PropTypes.bool,
  valores: PropTypes.object,
  setValores: PropTypes.func,
};

export default AdminUsuario;
