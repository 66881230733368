import React, { useEffect, useState } from "react";

import { Card, FormControl, FormGroup, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";

import { formatCNPJ } from "../formularios/utils.js";
import DefaultTable from "../table/DefaultTable";
import { dadosPessoas, tabelaCursosPendentes } from "./dadosMockados";

const AdminCursosPessoasInteressadas = () => {
  const valoresIniciais = {
    nomeDoCurso: "<Nome do Curso>",
    quantidade: "<Quantidade>",
    cargaHoraria: "<Carga Horária>",
    modalidade: "<Modalidade>",
    cnpj: "00.000.000/0000-00",
    ofertante: "<Ofertante>",
    mudarStatus: "Pendente",
  };

  const [valores, setValores] = useState(valoresIniciais);

  const colunas = [
    { field: "inscricao", headerName: "Inscrição" },
    { field: "nome", headerName: "Nome" },
    { field: "telefone", headerName: "Telefone" },
  ];

  const keysHidden = ["email", "municipio", "uf", "dataInscricao", "situacao"];

  const hiddenLinhas = dadosPessoas.map(row =>
    keysHidden.reduce((acc, key) => {
      acc[key] = row[key];
      return acc;
    }, {}),
  );

  const cursosID = JSON.parse(localStorage.getItem("cursosID"));

  useEffect(() => {
    if (cursosID) {
      const dados = tabelaCursosPendentes.find(item => item.id == cursosID);

      if (dados) {
        setValores(dados);
      }
    }
  });

  return (
    <Card
      color="#ffffff"
      sx={{
        borderRadius: "8px",
        padding: "16px",
        mt: "24px",
      }}
    >
      <Stack>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            xs={12}
          >
            <Typography variant="subtitle2">Curso - {valores.nome}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Typography variant="subtitle2">Quantidade de pessoas relacionadas - {valores.quantidade}</Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          mt={1}
        >
          <Grid
            item
            xs={3}
          >
            <FormGroup>
              <TextField
                disabled
                id="cargaHoraria"
                name="cargaHoraria"
                label="Carga Horária"
                placeholder="Carga Horária"
                value={valores.cargaHoraria}
                type="text"
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={3}
          >
            <FormGroup>
              <TextField
                disabled
                id="modalidade"
                name="modalidade"
                label="Modalidade"
                placeholder="Modalidade"
                value={valores.modalidade}
                type="text"
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={3}
          >
            <FormGroup>
              <TextField
                disabled
                id="cnpj"
                name="cnpj"
                label="CNPJ"
                placeholder="CNPJ"
                value={formatCNPJ(valores.cnpj) || ""}
                type="text"
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={3}
          >
            <FormGroup>
              <TextField
                disabled
                id="ofertante"
                name="ofertante"
                label="Ofertante"
                placeholder="Ofertante"
                value={valores.ofertante}
                type="text"
              />
            </FormGroup>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          my={1}
        >
          <FormGroup>
            <FormControl>
              <InputLabel>Mudar Situação</InputLabel>
              <Select
                id="mudarStatus"
                name="mudarStatus"
                label="Mudar Situação"
                placeholder="Mudar Situação"
                value={valores.mudarStatus || "Pendente"}
              >
                <MenuItem
                  value="Pendente"
                  disabled
                >
                  Mudar situação dos usuários selecionados
                </MenuItem>
                <MenuItem value="Aprovar"> Aprovar </MenuItem>
                <MenuItem value="Reprovar"> Reprovar </MenuItem>
              </Select>
            </FormControl>
          </FormGroup>
        </Grid>

        <DefaultTable
          columns={colunas}
          rows={dadosPessoas}
          hiddenRows={hiddenLinhas}
          notFoundText={"Não foi localizado Parceiro na situação pendente de aprovação!"}
          mostrarCaixasSelecao={true}
          onSelecionadosChange={selecionados => {
            console.log("Custom onSelecionadosChange:", selecionados);
          }}
        />
      </Stack>
    </Card>
  );
};

export default AdminCursosPessoasInteressadas;
