import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import CidadaoTabs from "../../components/formularios/CidadadaoEditarCurriculo";
import TitlePage from "../../components/title-page/TitlePage";

const PageEditarCurriculo = () => {
  const linksBreadCrumb = [
    { href: "/", text: "Cidadão" },
    { href: "/vagas-de-trabalho", text: "Vagas de trabalho" },
    { href: "/vagas-de-trabalho/curriculo", text: "Currículo" },
  ];

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        homeLink="/vagas-de-trabalho/curriculo/editar"
        currentPage="Editar currículo"
        links={linksBreadCrumb}
      />

      <TitlePage title={"Editar currículo"} />

      <CidadaoTabs></CidadaoTabs>
    </Container>
  );
};

export default PageEditarCurriculo;
