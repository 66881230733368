import React from "react";

import { FormControlLabel, Switch, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";

const ReusableSwitch = ({ id, name, value, onChange, disabled = false, labelLeft = "Não", labelRight = "Sim" }) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
    >
      <Typography>{labelLeft}</Typography>
      <FormControlLabel
        control={
          <Switch
            id={id}
            name={name}
            checked={value}
            onChange={onChange}
            disabled={disabled}
          />
        }
        label={labelRight}
      />
    </Stack>
  );
};

ReusableSwitch.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  labelLeft: PropTypes.string,
  labelRight: PropTypes.string,
};

export default ReusableSwitch;
