import React from "react";

import PropTypes from "prop-types";

import AvisoDePrivacidade from "../../assets/texto/avisoDePrivacidade";
import CustomModal from "./CustomModal";

const AvisoDePrivacidadeModal = ({ showModal, handleClose }) => {
  const modalTitle = "Aviso de Privacidade";
  const modalContent = (
    <>
      <AvisoDePrivacidade AvisoDePrivacidade></AvisoDePrivacidade>
    </>
  );

  const modalButtons = [{ label: "Concordar", onClick: handleClose }];

  return (
    <CustomModal
      showModal={showModal}
      handleClose={handleClose}
      title={modalTitle}
      content={modalContent}
      buttons={modalButtons}
    />
  );
};

AvisoDePrivacidadeModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AvisoDePrivacidadeModal;
