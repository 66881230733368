import React, { useEffect, useState } from "react";

import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { Button, Card, FormControl, FormGroup, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";

import optionsService from "../../services/options.service.js";
import { SelectCidade } from "./fields/SelectCidade.js";
import { SelectUF } from "./fields/SelectUF.js";
import { formatTelefone } from "./utils.js";

const FormCidadaoVisualizarCurso = ({ handleSubmit, valores, readOnly = false }) => {
  const initialValues = valores
    ? valores
    : {
        ofertante: "Empresas Alfas",
        curso: "Dev full-stack",
        vigencia: "",
        modalide: "",
        tipo: "",
        pronatec: "",
        turno: "",
        caragaHoraria: "",
        vagas: "",
        idadeMinina: "",
        idadeMaxima: "",
        inicioCurso: "",
        fimCurso: "",
        escolaridadeMinima: "",
        enderecoCurso: "",
        telefoneContato: "",
        requisito: "",
        linkExterno: "",
        ementa: "",
        breveDescricao: "",
      };

  const [values, setValues] = useState(initialValues);

  // Preparação para UFS e Municípios
  const [ufs, setUfs] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [selectedUf, setSelectedUf] = useState("");
  const [selectedMunicipio, setSelectedMunicipio] = useState("");

  useEffect(() => {
    const fetchUfs = async () => {
      await optionsService
        .getAllUFs()
        .then(ufsData => {
          setUfs(ufsData);
        })
        .catch(error => {
          console.error("Erro ao obter UFs:", error);
        });
    };
    fetchUfs();
  }, []);

  const fetchCidadesByUf = async (ufSigla, setCidadesFunction) => {
    try {
      const cidadesData = await optionsService.getCidadesFromUF(ufSigla);
      setCidadesFunction(cidadesData);
    } catch (error) {
      console.error("Erro ao obter cidades:", error);
    }
  };

  const handleSelectUf = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });

    setSelectedUf(value);
    setSelectedMunicipio("");
    setValues({ ...values, ["municipio"]: "" });
    fetchCidadesByUf(event.target.value, setMunicipios);
  };

  const handleSelectMunicipio = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
    setSelectedMunicipio(event.target.value);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange={false}
    >
      {({ values, handleChange, handleBlur }) => (
        <Form>
          <Stack>
            <Card
              color="#ffffff"
              sx={{
                borderRadius: "8px",
                padding: "16px",
              }}
              style={{ transition: "1s" }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontSize: "20",
                  mb: "16px",
                  fontFamily: "Rawline Regular",
                }}
              >
                Informações do curso
              </Typography>
              <Grid
                spacing={1}
                container
              >
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormGroup>
                    <TextField
                      label="Ofertante"
                      id="ofertante"
                      name="ofertante"
                      value={values.ofertante}
                      placeholder="Empresa responsável pela vaga"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormGroup>
                    <TextField
                      label="Curso"
                      id="curso"
                      name="curso"
                      value={values.curso}
                      placeholder="Curso ofertado"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormGroup>
                    <TextField
                      label="Vigência"
                      id="vigencia"
                      name="vigencia"
                      value={values.vigencia}
                      placeholder="Vigência do curso"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormGroup>
                    <TextField
                      label="Modalidade"
                      id="modalidade"
                      name="modalidade"
                      value={values.modalidade}
                      placeholder="Modalidade do curso"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id={`label-tipo`}>Tipo</InputLabel>
                    <Select
                      labelid={`label-tipo`}
                      id="tipo"
                      name="tipo"
                      value={values.tipo}
                      onChange={handleChange}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    >
                      <MenuItem
                        value=""
                        disabled
                      >
                        Selecione uma opção
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id={`label-pronatec`}>Pronatec</InputLabel>
                    <Select
                      labelid={`label-pronatec`}
                      id="pronatec"
                      name="pronatec"
                      value={values.pronatec}
                      onChange={handleChange}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    >
                      <MenuItem
                        value=""
                        disabled
                      >
                        Selecione uma opção
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <TextField
                    fullWidth
                    id="abrangencia"
                    name="abrangencia"
                    label="Abrangência"
                    placeholder="Abrangência"
                    value={values.abrangencia}
                    onChange={handleChange}
                  />
                </Grid>
                {/* UF E Município */}
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <SelectUF
                    idSelect="uf"
                    nameSelect="uf"
                    value={values.uf}
                    ufs={ufs}
                    uf={selectedUf}
                    label="UF"
                    visualizacao={readOnly}
                    handleSelectUf={event => handleSelectUf(event)}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <SelectCidade
                    idSelect="municipio"
                    nameSelect="municipio"
                    value={values.municipio}
                    cidades={municipios}
                    cidade={selectedMunicipio}
                    label="Município"
                    visualizacao={readOnly}
                    handleSelectCidade={event => handleSelectMunicipio(event)}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id={`label-turno`}>Turno</InputLabel>
                    <Select
                      labelid={`label-turno`}
                      id="turno"
                      name="turno"
                      value={values.turno}
                      onChange={handleChange}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    >
                      <MenuItem
                        value=""
                        disabled
                      >
                        Selecione uma opção
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormGroup>
                    <TextField
                      label="Carga Horária"
                      id="cargaHoraria"
                      name="cargaHoraria"
                      value={values.cargaHoraria}
                      placeholder="Carga horária do curso"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormGroup>
                    <TextField
                      label="Vagas"
                      id="vagas"
                      name="vagas"
                      value={values.vagas}
                      placeholder="Número de vagas do curso"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={3}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormGroup>
                    <TextField
                      label="Idade Mínima"
                      id="idadeMinima"
                      name="idadeMinima"
                      value={values.idadeMinima}
                      placeholder="Idade mínima para o curso"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormGroup>
                    <TextField
                      label="Idade Máxima"
                      id="idadeMaxima"
                      name="idadeMaxima"
                      value={values.idadeMaxima}
                      placeholder="Idade máxima para o curso"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormGroup>
                    <TextField
                      label="Início do Curso"
                      id="inicioCurso"
                      name="inicioCurso"
                      value={values.inicioCurso}
                      placeholder="Data de início do curso"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormGroup>
                    <TextField
                      label="Fim do Curso"
                      id="fimCurso"
                      name="fimCurso"
                      value={values.fimCurso}
                      placeholder="Data de término do curso"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormGroup>
                    <TextField
                      label="Escolaridade Mínima"
                      id="escolaridadeMinima"
                      name="escolaridadeMinima"
                      value={values.escolaridadeMinima}
                      placeholder="Escolaridade mínima para o curso"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormGroup>
                    <TextField
                      label="Endereço do Curso"
                      id="enderecoCurso"
                      name="enderecoCurso"
                      value={values.enderecoCurso}
                      placeholder="Endereço do curso"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormGroup>
                    <TextField
                      label="Telefone de Contato"
                      id="telefoneContato"
                      name="telefoneContato"
                      value={formatTelefone(values.telefoneContato)}
                      placeholder="Telefone para contato"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormGroup>
                    <TextField
                      label="Requisitos"
                      id="requisito"
                      name="requisito"
                      value={values.requisito}
                      placeholder="Requisitos para o curso"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormGroup>
                    <TextField
                      label="Link Externo"
                      id="linkExterno"
                      name="linkExterno"
                      value={values.linkExterno}
                      placeholder="Link externo do curso"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormGroup>
                    <TextField
                      label="Ementa"
                      id="ementa"
                      name="ementa"
                      value={values.ementa}
                      placeholder="Ementa do curso"
                      multiline
                      rows={4}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    mb: "16px",
                  }}
                >
                  <FormGroup>
                    <TextField
                      label="Breve Descrição"
                      id="breveDescricao"
                      name="breveDescricao"
                      value={values.breveDescricao}
                      placeholder="Breve descrição do curso"
                      multiline
                      rows={4}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </FormGroup>
                </Grid>

                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  spacing={1}
                >
                  <Grid item>
                    <Button
                      size="large"
                      type="submit"
                      fullWidth
                      variant="outlined"
                    >
                      CANCELAR
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      size="large"
                      type="submit"
                      fullWidth
                      startIcon={<ThumbUpIcon />}
                      variant="contained"
                    >
                      TENHO INTERESSE NESTE CURSO
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

FormCidadaoVisualizarCurso.propTypes = {
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  confirmacaoModal: PropTypes.bool,
  setConfirmacaoModal: PropTypes.func,
  setErroModal: PropTypes.func,
  erroModal: PropTypes.bool,
  erroDoUsuarioModal: PropTypes.bool,
  valores: PropTypes.object,
  readOnly: PropTypes.bool,
};

export default FormCidadaoVisualizarCurso;
