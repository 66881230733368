import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import CidadaoTabs from "../../components/formularios/ParceiroCidadaoEditarCurriculo";
import TitlePage from "../../components/title-page/TitlePage";

const PageEditarCurriculoCidadao = () => {
  const linksBreadCrumb = [
    { href: "/", text: "Parceiro" },
    { href: "/parceiro/mobilizacao", text: "Mobilização" },
    { href: "/parceiro/mobilizacao/curriculo-cidadao", text: "Currículo" },
  ];

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        currentPage="Editar currículo do cidadão"
        links={linksBreadCrumb}
      />

      <TitlePage title={"Editar currículo"} />

      <CidadaoTabs></CidadaoTabs>
    </Container>
  );
};

export default PageEditarCurriculoCidadao;
