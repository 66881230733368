import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ParceiroVisualizarInformacoesVaga from "../../components/formularios/ParceiroVisualizarInformacoesVaga";
import TitlePage from "../../components/title-page/TitlePage";

const PageVisualizarInformacoesVaga = () => {
  const values = {
    ofertante: "Teste",
    salario: "Teste",
    vagas: "Teste",
    funcao: "Teste",
    areaAtuacao: "Teste",
    areaEstudante: "Teste",
    local: "Teste",
    descricao: "Teste",
    isNecessario: "Teste",
    beneficios: "Teste",
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/", text: "Parceiro" }]}
        currentPage={"Informações Complementares"}
      />

      <TitlePage title={"Visualizar informações da vaga"} />

      <ParceiroVisualizarInformacoesVaga values={values} />
    </Container>
  );
};

export default PageVisualizarInformacoesVaga;
