import React, { useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Box, FormGroup, Grid, TextField } from "@mui/material";
import PropTypes from "prop-types";

import { useResponsive } from "../../hooks/use-responsive";
import ButtonSecondary from "../buttons/ButtonSecondary";

const FormMultipleInsertDouble = ({
  label1,
  placeHolder1,
  value1,
  label2,
  placeHolder2,
  value2,
  values,
  onFieldsChange,
  disabled,
  error,
}) => {
  const defaultFields =
    values && values.length > 0
      ? values.map((item, index) => ({ id: Date.now() + index, value1: item[value1], value2: item[value2] }))
      : [{ id: Date.now(), value1: "", value2: "" }];

  const [fields, setFields] = useState(defaultFields);
  const smDown = useResponsive("down", "sm");

  // console.log("ERRO NO FORM -> ", error);

  const handleAddField = () => {
    const newFields = [...fields, { id: Date.now(), value1: "", value2: "" }];
    setFields(newFields);
    onFieldsChange(newFields.map(field => ({ value1: field.value1, value2: field.value2 })));
  };

  const handleRemoveField = id => {
    const newFields = fields.filter(field => field.id !== id);
    setFields(newFields);
    onFieldsChange(newFields.map(field => ({ value1: field.value1, value2: field.value2 })));
  };

  const handleChange = (id, newValue, fieldKey) => {
    const newFields = fields.map(field => (field.id === id ? { ...field, [fieldKey]: newValue } : field));
    setFields(newFields);
    onFieldsChange(newFields.map(field => ({ value1: field.value1, value2: field.value2 })));
  };

  const allFieldsFilled = () => {
    return fields.every(
      field =>
        typeof field.value1 === "string" && field.value1.trim() !== "" && typeof field.value2 === "string" && field.value2.trim() !== "",
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2, mt: 1 }}>
      <Grid
        container
        spacing={2}
        px={2}
      >
        {fields.map((field, index) => (
          <Grid
            container
            spacing={2}
            key={field.id}
          >
            <Grid
              item
              xs={disabled ? 6 : 5}
              sx={{ mt: 1 }}
            >
              <FormGroup>
                <TextField
                  disabled={disabled}
                  label={label1}
                  id={"id_" + label1 + "_" + index}
                  value={field.value1}
                  placeholder={placeHolder1}
                  type="text"
                  onChange={e => handleChange(field.id, e.target.value, "value1")}
                  error={!!error}
                  helperText={error?.message}
                />
              </FormGroup>
            </Grid>

            <Grid
              item
              xs={disabled ? 6 : 5}
              sx={{ mt: 1 }}
            >
              <FormGroup>
                <TextField
                  disabled={disabled}
                  label={label2}
                  id={"id_" + label2 + "_" + index}
                  value={field.value2}
                  placeholder={placeHolder2}
                  type="text"
                  onChange={e => handleChange(field.id, e.target.value, "value2")}
                  error={!!error}
                  helperText={error?.message}
                />
              </FormGroup>
            </Grid>

            {index === 0 && allFieldsFilled() && disabled !== true && (
              <Grid
                item
                xs={2}
                sx={{ mt: 1 }}
                style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-end" }}
              >
                <ButtonSecondary
                  title={smDown ? "" : "adicionar"}
                  icon={<AddCircleIcon />}
                  onClick={handleAddField}
                />
              </Grid>
            )}

            {index !== 0 && disabled !== true && (
              <Grid
                item
                xs={2}
                sx={{ mt: 1 }}
                style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-end" }}
              >
                <ButtonSecondary
                  title={smDown ? "" : "remover"}
                  icon={<RemoveCircleIcon />}
                  onClick={() => handleRemoveField(field.id)}
                />
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

FormMultipleInsertDouble.propTypes = {
  label1: PropTypes.string,
  placeHolder1: PropTypes.string,
  value1: PropTypes.string,
  label2: PropTypes.string,
  placeHolder2: PropTypes.string,
  value2: PropTypes.string,
  values: PropTypes.array,
  error: PropTypes.object,
  disabled: PropTypes.bool,
  onFieldsChange: PropTypes.func.isRequired,
};

export default FormMultipleInsertDouble;
