import { useNavigate, useSearchParams } from "react-router-dom";

import { useAuth } from "../../contexts/AuthContext";

const RetornoCidadao = () => {
  const { loginGovBR } = useAuth();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  // Deixando aqui caso um dia resolvermos usar o path
  // const path = searchParams.get('path');

  const loginWithGovBR = code => {
    loginGovBR(code)
      .then(response => {
        navigate("/");
        alert(response);
      })
      .catch(error => {
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        alert(resMessage);
      })
      .finally(() => {
        navigate("/");
      });
  };
  loginWithGovBR(code);
};

export default RetornoCidadao;
