import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";

import { tp_escolaridadeVagaTrabalho, tp_turno, tp_vaga, validadeVaga } from "../../services/constants";
import optionsService from "../../services/options.service";
import { SelectCidade } from "./fields/SelectCidade";
import { SelectUF } from "./fields/SelectUF";
import FormExigenciaVagaTrabalho from "./FormExigenciaVagaTrabalho";

const valoresIniciais = {
  tipoVaga: "",
  cnpj: "",
  funcao: "",
  quantidadeVagas: "",
  validadeAnuncio: "",
  escolaridadeRequerida: "",
  escolaridadeMinima: false,
  salario: "",
  turno: "",
  descricao: "",
  uf: "",
  municipio: "",
  cep: "",
  endereco: "",
  numero: "",
  complemento: "",
  referencia: "",
  bairro: "",
  auxilioAlimentacao: false,
  auxilioTransporte: false,
  planoSaude: false,
  outros: false,
  detalheAuxilioAlimentacao: "",
  detalheAuxilioTransporte: "",
  detalhePlanoSaude: "",
  detalheOutros: "",
  areaAtuacaoProfissional: [],
  areaAtuacaoEstudante: [],
  cursoComplementar: [],
  cursoTecnico: [],
  cursoSuperior: [],
};

const ParceiroVagaTrabalho = ({ initialValues = valoresIniciais, visualizacao }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  // TODO: Usar valores do banco
  const listaAreaAtuacao = {
    profissional: [
      {
        id: "1",
        descricao: "Tecnologia da Informação",
      },
      {
        id: "2",
        descricao: "Saúde",
      },
      {
        id: "3",
        descricao: "Educação",
      },
      {
        id: "5",
        descricao: "Engenharia",
      },
    ],
    estudante: [
      { id: "1", descricao: "Administração" },
      { id: "2", descricao: "Agricultura" },
      { id: "3", descricao: "Artes" },
      { id: "5", descricao: "Ciências" },
      { id: "5", descricao: "Comunicação" },
      { id: "6", descricao: "Direito" },
      { id: "7", descricao: "Educação" },
      { id: "8", descricao: "Engenharia" },
      { id: "9", descricao: "Informática" },
      { id: "10", descricao: "Saúde" },
    ],
  };

  // TODO: Usar valores do banco
  const listaCursos = {
    complementar: [
      { id: "1", descricao: "Administração" },
      { id: "2", descricao: "Engenharia" },
      { id: "3", descricao: "Informática" },
      { id: "5", descricao: "Saúde" },
    ],
    tecnico: [
      { id: "1", descricao: "Administração" },
      { id: "2", descricao: "Engenharia" },
      { id: "3", descricao: "Informática" },
      { id: "5", descricao: "Saúde" },
    ],
    superior: [
      { id: "1", descricao: "Administração" },
      { id: "2", descricao: "Engenharia" },
      { id: "3", descricao: "Informática" },
      { id: "5", descricao: "Saúde" },
    ],
  };

  const [ufs, setUfs] = useState([]);
  const [municipios, setMunicipios] = useState([]);

  const [selectedUf, setSelectedUf] = useState("");
  const [selctedMunicipio, setSelectedMunicipio] = useState("");

  const [selectedAtuacaoProfissional, setSelectedAtuacaoProfissional] = useState([]);
  const [selectedAtuacaoEstudante, setSelectedAtuacaoEstudante] = useState([]);
  const [selectedCursoComplementar, setSelectedCursoComplementar] = useState([]);
  const [selectedCursoTecnico, setSelectedCursoTecnico] = useState([]);
  const [selectedCursoSuperior, setSelectedCursoSuperior] = useState([]);

  // UseEffect para obter os valores iniciais
  useEffect(() => {
    setSelectedUf(initialValues.uf);
    setMunicipios(optionsService.cidadesJSON.filter(cidade => cidade.uf === initialValues.uf));
    setSelectedMunicipio(initialValues.municipio);
    setSelectedAtuacaoProfissional(initialValues.areaAtuacaoProfissional);
    setSelectedAtuacaoEstudante(initialValues.areaAtuacaoEstudante);
  }, [initialValues]);

  useEffect(() => {
    const fetchUfs = async () => {
      await optionsService
        .getAllUFs()
        .then(ufsData => {
          setUfs(ufsData);
        })
        .catch(error => {
          console.error("Erro ao obter UFs:", error);
        });
    };
    fetchUfs();
  }, []);

  const fetchCidadesByUf = async (ufSigla, setCidadesFunction) => {
    try {
      const cidadesData = await optionsService.getCidadesFromUF(ufSigla);
      setCidadesFunction(cidadesData);
    } catch (error) {
      console.error("Erro ao obter cidades:", error);
    }
  };

  const handleSelectUf = (event, setFieldValue) => {
    const { name, value } = event.target;
    setFieldValue(name, value);

    setSelectedUf(value);
    setSelectedMunicipio("");
    setFieldValue("municipio", "");
    fetchCidadesByUf(event.target.value, setMunicipios);
  };

  const handleSelectMunicipio = (event, setFieldValue) => {
    const { name, value } = event.target;
    setFieldValue(name, value);
    setSelectedMunicipio(event.target.value);
  };

  const handleAreaAtuacaoProfissional = values => {
    console.log(selectedAtuacaoProfissional);
    setSelectedAtuacaoProfissional(values);
  };

  const handleAreaAtuacaoEstudante = values => {
    console.log(selectedAtuacaoEstudante);
    setSelectedAtuacaoEstudante(values);
  };

  const handleCursoComplementar = values => {
    console.log(selectedCursoComplementar);
    setSelectedCursoComplementar(values);
  };

  const handleCursoTecnico = values => {
    console.log(selectedCursoTecnico);
    setSelectedCursoTecnico(values);
  };

  const handleCursoSuperior = values => {
    console.log(selectedCursoSuperior);
    setSelectedCursoSuperior(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        console.log(values);
      }}
    >
      {({ values, handleChange, setFieldValue }) => (
        <Form>
          <Stack spacing={2}>
            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
              }}
            >
              {/* Tipo de vaga, CNPJ, Nome do ofertante */}
              {/* Nome da Função, Quantidade de Vagas, Validade do Anúncio */}
              {/* Escolaridade Requerida, Salário/Bolsa Auxílio, Turno  */}
              <Grid
                container
                spacing={2}
                justifyContent="flex-end"
              >
                <Grid
                  item
                  xs={12}
                  md={3}
                >
                  <FormControl fullWidth>
                    <InputLabel>Tipo de vaga</InputLabel>
                    <Select
                      id="tipoVaga"
                      name="tipoVaga"
                      value={values.tipoVaga}
                      onChange={handleChange}
                      disabled={visualizacao}
                    >
                      <MenuItem
                        key={""}
                        value={""}
                        disabled
                      >
                        Selecione um Tipo
                      </MenuItem>
                      {Object.keys(tp_vaga).map(value => (
                        <MenuItem
                          key={value}
                          value={value}
                        >
                          {tp_vaga[value]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                >
                  <TextField
                    id="cnpj"
                    name="cnpj"
                    label="CNPJ"
                    value={values.cnpj}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <TextField
                    id="nomeOfertante"
                    name="nomeOfertante"
                    label="Nome do ofertante"
                    value={values.nomeOfertante}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <TextField
                    id="funcao"
                    name="funcao"
                    label="Nome da Função"
                    value={values.funcao}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <TextField
                    id="quantidadeVagas"
                    name="quantidadeVagas"
                    label="Quantidade de Vagas"
                    value={values.quantidadeVagas}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <FormControl fullWidth>
                    <InputLabel>Validade do Anúncio</InputLabel>
                    <Select
                      id="validadeAnuncio"
                      name="validadeAnuncio"
                      value={values.validadeAnuncio}
                      onChange={handleChange}
                      disabled={visualizacao}
                    >
                      <MenuItem
                        key={""}
                        value={""}
                        disabled
                      >
                        Selecione a Validade
                      </MenuItem>
                      {Object.keys(validadeVaga).map(value => (
                        <MenuItem
                          key={value}
                          value={value}
                        >
                          {validadeVaga[value]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <FormControl fullWidth>
                    <InputLabel>Escolaridade Requerida</InputLabel>
                    <Select
                      id="escolaridadeRequerida"
                      name="escolaridadeRequerida"
                      label="Escolaridade Requerida"
                      value={values.escolaridadeRequerida}
                      onChange={handleChange}
                      disabled={visualizacao || !values.escolaridadeMinima}
                      fullWidth
                    >
                      <MenuItem
                        key={""}
                        value={""}
                        disabled
                      >
                        Selecione uma Escolaridade
                      </MenuItem>
                      {Object.keys(tp_escolaridadeVagaTrabalho).map(value => (
                        <MenuItem
                          key={value}
                          value={value}
                        >
                          {tp_escolaridadeVagaTrabalho[value]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <TextField
                    id="salario"
                    name="salario"
                    label="Salário/Bolsa Auxílio"
                    value={values.salario}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <FormControl fullWidth>
                    <InputLabel>Turno</InputLabel>
                    <Select
                      id="turno"
                      name="turno"
                      value={values.turno}
                      onChange={handleChange}
                      disabled={visualizacao}
                    >
                      <MenuItem
                        key={""}
                        value={""}
                        disabled
                      >
                        Selecione um Turno
                      </MenuItem>
                      {Object.keys(tp_turno).map(value => (
                        <MenuItem
                          key={value}
                          value={value}
                        >
                          {tp_turno[value]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {/* Escolaridade mínima exigida, descrição */}
              {/* Essa vaga é direcionada para pessoas com deficiência? */}
              {/* O local de trabalho será o mesmo cadastrado no endereço do parceiro? */}
              <Grid
                container
                spacing={1}
                justifyContent="flex-start"
                mt={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="escolaridadeMinima"
                        name="escolaridadeMinima"
                        checked={values.escolaridadeMinima}
                        value={values.escolaridadeMinima}
                        onChange={handleChange}
                        style={{ color: theme.palette.primary.main }}
                      />
                    }
                    label="Escolaridade mínima exigida?"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    id="descricao"
                    name="descricao"
                    label="* Descrição/Informações Adicionais"
                    value={values.descricao}
                    onChange={handleChange}
                    disabled={visualizacao}
                    rows={4}
                    fullWidth
                    multiline
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    // TODO: adicionar values
                    control={<Checkbox style={{ color: theme.palette.primary.main }} />}
                    label="Essa vaga é direcionada para pessoas com deficiência?"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    // TODO: adicionar values
                    control={<Checkbox style={{ color: theme.palette.primary.main }} />}
                    label="O local de trabalho será o mesmo cadastrado no logradouro do parceiro?"
                  />
                </Grid>
              </Grid>
            </Card>
            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
              }}
            >
              {/* Outro endereço */}
              <Grid
                container
                spacing={2}
                justifyContent="flex-end"
              >
                <Grid
                  item
                  xs={12}
                >
                  <Typography variant="h6">Outro logradouro</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <SelectUF
                    idSelect="uf"
                    nameSelect="uf"
                    value={values.uf}
                    ufs={ufs}
                    uf={selectedUf}
                    label="* UF"
                    handleSelectUf={event => handleSelectUf(event, setFieldValue)}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <SelectCidade
                    idSelect="municipio"
                    nameSelect="municipio"
                    value={values.municipio}
                    cidades={municipios}
                    cidade={selctedMunicipio}
                    label="* Município"
                    handleSelectCidade={event => handleSelectMunicipio(event, setFieldValue)}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <TextField
                    id="cep"
                    name="cep"
                    label="* CEP"
                    value={values.cep}
                    disabled={visualizacao}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <TextField
                    id="endereco"
                    name="endereco"
                    label="* Logradouro"
                    value={values.endereco}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <TextField
                    id="numero"
                    name="numero"
                    label="* Número"
                    value={values.numero}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <TextField
                    id="bairro"
                    name="bairro"
                    label="Bairro"
                    value={values.bairro}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <TextField
                    id="complemento"
                    name="complemento"
                    label="Complemento"
                    value={values.complemento}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <TextField
                    id="referencia"
                    name="referencia"
                    label="Referência"
                    value={values.referencia}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Card>

            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
              }}
            >
              {/* Benefícios */}
              <Grid
                container
                spacing={2}
                justifyContent="flex-end"
              >
                <Grid
                  item
                  xs={12}
                >
                  <Typography variant="h6">Benefícios</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                >
                  <FormControlLabel
                    id={"auxilioAlimentacao"}
                    name={"auxilioAlimentacao"}
                    control={
                      <Checkbox
                        checked={values.auxilioAlimentacao}
                        value={values.auxilioAlimentacao}
                        onChange={handleChange}
                        style={{ color: theme.palette.primary.main }}
                      />
                    }
                    label="Auxílio Alimentação"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={9}
                  md={9}
                >
                  <TextField
                    id="detalheAuxilioAlimentacao"
                    name="detalheAuxilioAlimentacao"
                    label="Detalhes do Auxílio Alimentação"
                    value={values.detalheAuxilioAlimentacao}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                >
                  <FormControlLabel
                    id={"auxilioTransporte"}
                    name={"auxilioTransporte"}
                    control={
                      <Checkbox
                        checked={values.auxilioTransporte}
                        value={values.auxilioTransporte}
                        onChange={handleChange}
                        style={{ color: theme.palette.primary.main }}
                      />
                    }
                    label="Auxílio Transporte"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={9}
                  md={9}
                >
                  <TextField
                    id="detalheAuxilioTransporte"
                    name="detalheAuxilioTransporte"
                    label="Detalhes do Auxílio Transporte"
                    value={values.detalheAuxilioTransporte}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                >
                  <FormControlLabel
                    id={"planoSaude"}
                    name={"planoSaude"}
                    control={
                      <Checkbox
                        checked={values.planoSaude}
                        value={values.planoSaude}
                        onChange={handleChange}
                        style={{ color: theme.palette.primary.main }}
                      />
                    }
                    label="Plano de Saúde"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={9}
                  md={9}
                >
                  <TextField
                    id="detalhePlanoSaude"
                    name="detalhePlanoSaude"
                    label="Detalhes do Plano de Saúde"
                    value={values.detalhePlanoSaude}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                >
                  <FormControlLabel
                    id={"outros"}
                    name={"outros"}
                    control={
                      <Checkbox
                        checked={values.outros}
                        value={values.outros}
                        onChange={handleChange}
                        style={{ color: theme.palette.primary.main }}
                      />
                    }
                    label="Outros"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={9}
                  md={9}
                >
                  <TextField
                    id="detalheOutros"
                    name="detalheOutros"
                    label="Outros Detalhes"
                    value={values.detalheOutros}
                    onChange={handleChange}
                    disabled={visualizacao}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Card>

            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
              }}
            >
              {/* EXPERIÊNCIA DO PROFISSIONAL */}
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <FormExigenciaVagaTrabalho
                    onFieldsChange={handleAreaAtuacaoProfissional}
                    label={"*Informe a(s) Área(s) de Atuação do Profissional"}
                    placeholder={"Escolha a área de atuação do profissional"}
                    list={listaAreaAtuacao.profissional}
                    initialValue={values.areaAtuacaoProfissional || selectedAtuacaoProfissional}
                    visualizacao={visualizacao}
                  />
                </Grid>
              </Grid>
            </Card>
            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
              }}
            >
              {/* EXPERIÊNCIA DO ESTUDANTE */}
              <Grid
                container
                spacing={2}
                justifyContent="flex-end"
              >
                <Grid
                  item
                  xs={12}
                >
                  <FormExigenciaVagaTrabalho
                    onFieldsChange={handleAreaAtuacaoEstudante}
                    label={"*Informe a(s) Área(s) de Atuação do Estudante"}
                    placeholder={"Escolha a área de atuação do estudante"}
                    list={listaAreaAtuacao.estudante}
                    initialValue={values.areaAtuacaoEstudante || selectedAtuacaoEstudante}
                    visualizacao={visualizacao}
                  />
                </Grid>
              </Grid>
            </Card>
            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
              }}
            >
              {/* CURSO EXIGIDO OU DESEJÁVEL */}
              <Grid
                container
                spacing={2}
                justifyContent="flex-end"
              >
                <Grid
                  item
                  xs={12}
                >
                  <FormExigenciaVagaTrabalho
                    onFieldsChange={handleCursoComplementar}
                    label={"*Algum Curso Complementar é Exigido ou Desejável? (Caso sim adicione um ou mais itens)"}
                    placeholder={"Escolha o curso complementar"}
                    list={listaCursos.complementar}
                    initialValue={values.cursoComplementar || selectedCursoComplementar}
                    visualizacao={visualizacao}
                  />
                </Grid>
              </Grid>
            </Card>

            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
              }}
            >
              {/* CURSO TÉCNICO EXIGIDO OU DESEJÁVEL */}
              <Grid
                container
                spacing={2}
                justifyContent="flex-end"
              >
                <Grid
                  item
                  xs={12}
                >
                  <FormExigenciaVagaTrabalho
                    onFieldsChange={handleCursoTecnico}
                    label={"*Algum Curso Técnico é Exigido ou Desejável? (Caso sim adicione um ou mais itens)"}
                    placeholder={"Escolha o curso técnico"}
                    list={listaCursos.tecnico}
                    initialValue={values.cursoTecnico || selectedAtuacaoEstudante}
                    visualizacao={visualizacao}
                  />
                </Grid>
              </Grid>
            </Card>

            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
              }}
            >
              {/* CURSO SUPERIOR EXIGIDO OU DESEJÁVEL */}
              <Grid
                container
                spacing={2}
                justifyContent="flex-end"
              >
                <Grid
                  item
                  xs={12}
                >
                  <FormExigenciaVagaTrabalho
                    onFieldsChange={handleCursoSuperior}
                    label={"*Algum Curso de Nível Superior é Exigido ou Desejável? (Caso sim adicione um ou mais itens)"}
                    placeholder={"Escolha o curso de nível superior"}
                    list={listaCursos.superior}
                    initialValue={values.cursoSuperior || selectedCursoSuperior}
                    visualizacao={visualizacao}
                  />
                </Grid>
              </Grid>
            </Card>
          </Stack>

          {/* Botões */}
          <Grid
            container
            mt={1}
            spacing={1}
            justifyContent="flex-end"
          >
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => navigate("/cursos")}
                fullWidth
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                variant="contained"
                type="submit"
                sx={{ gap: "8px" }}
                onSubmit={values => {
                  console.log(values);
                }}
              >
                <SaveIcon fontSize="small" />
                <Typography variant={"BUTTON TEXT"}>SALVAR</Typography>
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

ParceiroVagaTrabalho.propTypes = {
  initialValues: PropTypes.object,
  visualizacao: PropTypes.bool,
};

export default ParceiroVagaTrabalho;
