import React, { useEffect, useState } from "react";

import { Box, Button, Card, FormGroup, Grid, Stack, TextField } from "@mui/material";
import { Container, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import { tabelaInteressadosMPO } from "../../components/formularios/dadosMockados";
import TitlePage from "../../components/title-page/TitlePage";

const PageSituacaoInteressado = () => {
  const [valor, setValor] = useState({
    cpf: "",
    nome: "",
    retorno: "",
    dataInicio: null,
    cnpj: "",
    nomeParceiro: "",
    valor: "",
    dataEmprestimo: null,
  });

  const interessadoID = JSON.parse(localStorage.getItem("interessadoID"));

  useEffect(() => {
    if (interessadoID) {
      const valorEncontrado = tabelaInteressadosMPO.find(item => item.id == interessadoID);
      if (valorEncontrado) {
        setValor(valorEncontrado);
      }
    }
  }, []);

  const handleChange = event => {
    const { name, value } = event.target;
    setValor({ ...valor, [name]: value });
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/listar-mpo", text: "Listar Interessados MPO" }]}
        currentPage="Situação Interessado"
      />

      <TitlePage title={"Situação do Interessado"} />

      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          {/* Campos */}
          <Grid
            spacing={2}
            container
          >
            {/* CPF, Nome e Contato*/}
            <Grid
              item
              xs={12}
              sm={4}
            >
              <TextField
                disabled
                fullWidth
                id="cpf"
                name="cpf"
                label="CPF"
                placeholder="CPF"
                value={valor.cpf}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <TextField
                fullWidth
                disabled
                id="nome"
                name="nome"
                label="Nome"
                placeholder="Nome"
                value={valor.nome}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <TextField
                disabled
                fullWidth
                id="retorno"
                name="retorno"
                label="Retorno do contato"
                placeholder="Retorno do contato ativo"
                value={valor.retorno}
                onChange={handleChange}
              />
            </Grid>

            <Grid
              item
              xs={12}
            >
              <Typography variant="subtitle1"> Contato recebido </Typography>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <FormGroup>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disabled
                    label="Data do Início do negócio"
                    id="dataInicio"
                    name="dataInicio"
                    value={dayjs(valor.dataInicio)}
                    format="DD/MM/YYYY"
                  />
                </LocalizationProvider>
              </FormGroup>
            </Grid>

            <Grid
              item
              xs={12}
            >
              <Typography variant="subtitle1"> Parceiro </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <TextField
                disabled
                fullWidth
                id="cnpj"
                name="cnpj"
                label="CNPJ"
                placeholder="CNPJ"
                value={valor.cnpj}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <TextField
                disabled
                fullWidth
                id="nomeParceiro"
                name="nomeParceiro"
                label="Nome"
                placeholder="Nome"
                value={valor.nomeParceiro}
                onChange={handleChange}
              />
            </Grid>

            <Grid
              item
              xs={12}
            >
              <Typography variant="subtitle1"> Empréstimo recebido </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormGroup>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disabled
                    label="Data"
                    id="dataEmprestimo"
                    name="dataEmprestimo"
                    value={dayjs(valor.dataEmprestimo)}
                    format="DD/MM/YYYY"
                  />
                </LocalizationProvider>
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <TextField
                disabled
                fullWidth
                id="valor"
                name="valor"
                label="Valor"
                placeholder="Valor"
                value={valor.valor}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          {/* Botões de pesquisa/cancelar */}
          <Box sx={{ flexGrow: 1, mt: "16px" }}>
            <Grid
              container
              spacing={1}
              justifyContent="flex-end"
            >
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => window.history.back()}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                >
                  Voltar
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Stack>
    </Container>
  );
};

export default PageSituacaoInteressado;
