import React from "react";

import PropTypes from "prop-types";

import CustomModal from "./CustomModal";

const ConfirmacaoModal = ({ showModal, handleClose, conteudo }) => {
  const modalTitle = "Confirmação de envio";
  const modalContent = conteudo ? conteudo : "Confirmação de envio";

  const modalButtons = [{ label: "Fechar", onClick: handleClose }];

  return (
    <CustomModal
      showModal={showModal}
      handleClose={handleClose}
      title={modalTitle}
      content={modalContent}
      buttons={modalButtons}
    />
  );
};

ConfirmacaoModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  conteudo: PropTypes.node,
};

export default ConfirmacaoModal;
