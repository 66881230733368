import React from "react";

import { Grid } from "@mui/material";
import PropTypes from "prop-types";

import CustomModal from "./CustomModal";

const InteresseVagaModal = ({ showModal, handleClose, isConfirmado }) => {
  const modalTitle = isConfirmado ? "Confirma a remoção do seu interesse pela vaga?" : "Confirma seu interesse pela vaga?";
  const confirmacaoLabel = isConfirmado ? "CONFIRMAR REMOÇÃO" : "CONFIRMAR INTERESSE";

  const setLabel = () => {
    if (!isConfirmado) {
      isConfirmado = true;
    } else {
      isConfirmado = false;
    }
    handleClose();
  };

  const modalContent = (
    <Grid
      container
      spacing={2}
    ></Grid>
  );

  const modalButtons = [
    { label: "CANCELAR", onClick: handleClose, variant: "outlined" },
    { label: confirmacaoLabel, onClick: () => setLabel(), variant: "contained" },
  ];

  return (
    <CustomModal
      showModal={showModal}
      handleClose={handleClose}
      title={modalTitle}
      content={modalContent}
      buttons={modalButtons}
      maxWidth="sm"
    />
  );
};

InteresseVagaModal.propTypes = {
  // values: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isConfirmado: PropTypes.bool.isRequired,
};

export default InteresseVagaModal;
