import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminListarParceirosPendentes from "../../components/filtros/AdminListarParceirosPendentes";
import TitlePage from "../../components/title-page/TitlePage";

const PageParceirosPendentes = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        homeLink="/home"
        homeText="Admin"
        currentPage="Listar Parceiros Pendentes"
      />

      <TitlePage title={"Listar Parceiros Pendentes"} />

      <AdminListarParceirosPendentes />
    </Container>
  );
};

export default PageParceirosPendentes;
