import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminCursosPessoasInteressadas from "../../components/formularios/AdminCursosPessoasInteressadas";
import TitlePage from "../../components/title-page/TitlePage";

const CursosPessoasInteressadas = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/parceiro/listar/pendentes", text: "Listar Parceiros Pendentes" },
          { href: "/parceiro/visualizar", text: "Informações do Parceiro" },
        ]}
        currentPage="Listar pessoas interessadas"
      />

      <TitlePage title={"Listar pessoas interessadas"} />

      <AdminCursosPessoasInteressadas />
    </Container>
  );
};

export default CursosPessoasInteressadas;
