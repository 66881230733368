import React, { useEffect, useState } from "react";

import { Box, Card, Container, Grid, Typography } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import { tabelaCursosPendentes } from "../../components/formularios/dadosMockados";
import DefaultTable from "../../components/table/DefaultTable";

const tabelaColunas = [
  { field: "dataModificacao", headerName: "Data" },
  { field: "situacao", headerName: "Situação" },
  { field: "usuario", headerName: "Usuário" },
  { field: "motivo", headerName: "Motivo" },
];

const PageHistoricoAlteracoesCurso = () => {
  const cursoID = JSON.parse(localStorage.getItem("cursosID"));
  const [curso, setCurso] = useState(null);
  const [historico, sethistorico] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (cursoID) {
      const cursoEncontrado = tabelaCursosPendentes.find(curso => curso.id === cursoID);

      if (cursoEncontrado) {
        setCurso(cursoEncontrado);
        sethistorico(cursoEncontrado.historicoAnalises);
      }
    }

    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <div>Carregando...</div>;
  }

  if (!curso) {
    return <div>Curso não encontrado</div>;
  }

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/listar-cursos-pendentes", text: "Listar Cursos Pendentes" },
          { href: "/listar-cursos-pendentes/analisar", text: "Analisar" },
        ]}
        currentPage="Histórico de Análise"
      />
      <Grid container>
        <Grid
          item
          xs={12}
        >
          <Typography
            variant="h4"
            mt={3}
            sx={{ fontFamily: "Rawline Bold" }}
            style={{ transition: "1s" }}
          >
            Histórico de análise
          </Typography>
        </Grid>
      </Grid>
      <Card
        color="#ffffff"
        sx={{
          borderRadius: "8px",
          padding: "16px",
          mt: "24px",
        }}
      >
        <Grid>
          <Typography variant="subtitle2">Histórico de alterações do curso: {curso.nome}</Typography>
        </Grid>
        {/* Tabela */}
        <Box sx={{ flexGrow: 1, mt: "40px" }}>
          <DefaultTable
            rows={historico}
            notFoundText={"Não foi localizado histórico!"}
            columns={tabelaColunas}
          />
        </Box>
      </Card>
    </Container>
  );
};

export default PageHistoricoAlteracoesCurso;
