// Dados para simular os parceiros
export const dadosParceirosPendentes = [
  {
    id: 1,
    habilitacao: "Parceiro",
    status: "Pendente",
    naturezaJuridica: "Público",
    cnpj: "11.111.111/1000-00",
    nomeFantasia: "República Comércio",
    razaoSocial: "República Comércio de Produtos Ltda",
    nomeResponsavel: "Bail Organa",
    email: "bail@republica.com",
    cidade: "Brasília",
    uf: "DF",
    nomePontoFocal: "Leia Organa",
    emailPontoFocal: "leia@republica.com",
    endereco: "Bairro Central, Rua Alderaan, 1",
    telefone: "(61) 00000-0000",
    dataCadastro: "2024-03-20T00:00",
    dataUltimaModificacao: "2024-03-21T00:00",
    tipoDeServico: {
      VEP: true,
      VET: false,
      VJA: false,
      CUR: false,
      FPG: false,
      MPU: false,
      MPA: false,
    },
    modificacao: "00/00/0000",
    campoAlterado: "telefone",
    informacaoAnterior: "(00)00000-0000",
    novaInformacao: "(61)00000-0000",
  },
  {
    id: 2,
    habilitacao: "Parceiro",
    status: "Pendente",
    naturezaJuridica: "Privado",
    cnpj: "22.222.222/2000-00",
    nomeFantasia: "Academia Jedi",
    razaoSocial: "Academia Jedi Treinamentos Ltda",
    nomeResponsavel: "Anakin Skywalker",
    email: "anakin@jedi.com",
    cidade: "São Paulo",
    uf: "SP",
    nomePontoFocal: "Luke Skywalker",
    emailPontoFocal: "luke@jedi.com",
    endereco: "Bairro Norte, Rua Tatooine, 2",
    telefone: "(11) 11111-1111",
    dataCadastro: "2024-03-03T00:00",
    dataUltimaModificacao: "2024-04-10T00:00",
    tipoDeServico: {
      VEP: false,
      VET: true,
      VJA: false,
      CUR: false,
      FPG: false,
      MPU: false,
      MPA: false,
    },
    modificacao: "10/10/0000",
    campoAlterado: "telefone",
    informacaoAnterior: "(00)00000-0000",
    novaInformacao: "(61)00000-0000",
  },
  {
    id: 3,
    habilitacao: "Parceiro",
    status: "Pendente",
    cnpj: "33.333.333/3000-00",
    naturezaJuridica: "Terceiro Setor",
    nomeFantasia: "Ferragens de Beskar",
    razaoSocial: "Ferragens de Beskar Ltda",
    nomeResponsavel: "Paz Vizsla",
    email: "paz@beskar.com",
    cidade: "Rio de Janeiro",
    uf: "RJ",
    nomePontoFocal: "Din Djarin",
    emailPontoFocal: "din@beskar.com",
    endereco: "Bairro Oeste, Rua Mandalore, 3",
    telefone: "(21) 22222-2222",
    dataCadastro: "2024-02-27T00:00",
    dataUltimaModificacao: "2024-03-15T00:00",
    tipoDeServico: {
      VEP: false,
      VET: false,
      VJA: true,
      CUR: false,
      FPG: false,
      MPU: false,
      MPA: false,
    },
    modificacao: "00/00/0000",
    campoAlterado: "telefone",
    informacaoAnterior: "(00)00000-0000",
    novaInformacao: "(61)00000-0000",
  },
  {
    id: 4,
    habilitacao: "Parceiro",
    status: "Pendente",
    naturezaJuridica: "Público",
    cnpj: "44.444.444/4000-00",
    nomeFantasia: "Oráculo de Coruscant",
    razaoSocial: "Oráculo de Coruscant Consultoria Ltda",
    nomeResponsavel: "Ruwee Naberrie",
    email: "ruwee@oraculo.com",
    cidade: "Salvador",
    uf: "BA",
    nomePontoFocal: "Padmé Amidala",
    emailPontoFocal: "padme@oraculo.com",
    endereco: "Bairro Leste, Rua Naboo, 4",
    telefone: "(71) 33333-3333",
    dataCadastro: "2024-02-11T00:00",
    dataUltimaModificacao: "2024-02-13T00:00",
    tipoDeServico: {
      VEP: true,
      VET: false,
      VJA: false,
      CUR: false,
      FPG: false,
      MPU: false,
      MPA: false,
    },
    modificacao: "20/11/0000",
    campoAlterado: "telefone",
    informacaoAnterior: "(00)00000-0000",
    novaInformacao: "(61)00000-0000",
  },
  {
    id: 5,
    habilitacao: "Parceiro",
    status: "Pendente",
    naturezaJuridica: "Privado",
    cnpj: "55.555.555/5000-00",
    nomeFantasia: "Falcon Transportes",
    razaoSocial: "Falcon Transportes de Cargas Ltda",
    nomeResponsavel: "Chewbacca",
    email: "chewbacca@falcon.com",
    cidade: "Porto Alegre",
    uf: "RS",
    nomePontoFocal: "Han Solo",
    emailPontoFocal: "han@falcon.com",
    endereco: "Bairro Sul, Rua Corellia, 5",
    telefone: "(51) 44444-4444",
    dataCadastro: "2024-03-20T00:00",
    dataUltimaModificacao: "2024-03-21T00:00",
    tipoDeServico: {
      VEP: false,
      VET: true,
      VJA: true,
      CUR: false,
      FPG: false,
      MPU: false,
      MPA: false,
    },
    modificacao: "10/10/0000",
    campoAlterado: "telefone",
    informacaoAnterior: "(00)00000-0000",
    novaInformacao: "(61)00000-0000",
  },
  {
    id: 6,
    habilitacao: "Parceiro",
    status: "Pendente",
    naturezaJuridica: "Terceiro Setor",
    cnpj: "66.666.666/6000-00",
    nomeFantasia: "Sith Inovações",
    razaoSocial: "Sith Inovações Tecnológicas Ltda",
    nomeResponsavel: "Sheev Palpatine",
    email: "palpatine@sith.com",
    cidade: "Curitiba",
    uf: "PR",
    nomePontoFocal: "Darth Vader",
    emailPontoFocal: "vader@sith.com",
    endereco: "Bairro Escuro, Rua Mustafar, 6",
    telefone: "(41) 66666-6666",
    dataCadastro: "2024-02-28T00:00",
    dataUltimaModificacao: "2024-03-10T00:00",
    tipoDeServico: {
      VEP: false,
      VET: true,
      VJA: false,
      CUR: false,
      FPG: false,
      MPU: false,
      MPA: false,
    },
    modificacao: "00/09/0000",
    campoAlterado: "telefone",
    informacaoAnterior: "(00)00000-0000",
    novaInformacao: "(61)00000-0000",
  },
  {
    id: 7,
    habilitacao: "Parceiro",
    status: "Pendente",
    naturezaJuridica: "Público",
    cnpj: "77.777.777/7000-00",
    nomeFantasia: "Cantina Mos Eisley",
    razaoSocial: "Cantina Mos Eisley Entretenimento Ltda",
    nomeResponsavel: "Dexter Jettster",
    email: "jettster@cantina.com",
    cidade: "Fortaleza",
    uf: "CE",
    nomePontoFocal: "Obi-Wan Kenobi",
    emailPontoFocal: "kenobi@cantina.com",
    endereco: "Bairro Duna, Rua Tatooine, 7",
    telefone: "(85) 77777-7777",
    dataCadastro: "2024-03-29T00:00",
    dataUltimaModificacao: "2024-04-21T00:00",
    tipoDeServico: {
      VEP: false,
      VET: false,
      VJA: false,
      CUR: false,
      FPG: true,
      MPU: false,
      MPA: false,
    },
    modificacao: "11/11/0000",
    campoAlterado: "telefone",
    informacaoAnterior: "(00)00000-0000",
    novaInformacao: "(61)00000-0000",
  },
  {
    id: 8,
    habilitacao: "Parceiro",
    status: "Pendente",
    naturezaJuridica: "Privado",
    cnpj: "88.888.888/8000-00",
    nomeFantasia: "Caminho dos Clones",
    razaoSocial: "Caminho dos Clones Produções Ltda",
    nomeResponsavel: "Jango Fett",
    email: "jango@clones.com",
    cidade: "Manaus",
    uf: "AM",
    nomePontoFocal: "Boba Fett",
    emailPontoFocal: "boba@clones.com",
    endereco: "Bairro Floresta, Rua Kamino, 8",
    telefone: "(92) 88888-8888",
    dataCadastro: "2024-03-29T00:00",
    dataUltimaModificacao: "2024-04-21T00:00",
    tipoDeServico: {
      VEP: false,
      VET: false,
      VJA: false,
      CUR: false,
      FPG: true,
      MPU: false,
      MPA: false,
    },
    modificacao: "09/00/0000",
    campoAlterado: "telefone",
    informacaoAnterior: "(00)00000-0000",
    novaInformacao: "(61)00000-0000",
  },
  {
    id: 9,
    habilitacao: "Parceiro",
    status: "Pendente",
    naturezaJuridica: "Terceiro Setor",
    cnpj: "99.999.999/9000-00",
    nomeFantasia: "HoloNet Serviços",
    razaoSocial: "HoloNet Serviços de Comunicação Ltda",
    nomeResponsavel: "Lobot",
    email: "lobot@holonet.com",
    cidade: "Recife",
    uf: "PE",
    nomePontoFocal: "Lando Calrissian",
    emailPontoFocal: "lando@holonet.com",
    endereco: "Bairro Cloud, Rua Bespin, 9",
    telefone: "(81) 99999-9999",
    dataCadastro: "2024-03-29T00:00",
    dataUltimaModificacao: "2024-04-21T00:00",
    tipoDeServico: {
      VEP: false,
      VET: false,
      VJA: false,
      CUR: false,
      FPG: true,
      MPU: false,
      MPA: false,
    },
    modificacao: "00/00/0000",
    campoAlterado: "telefone",
    informacaoAnterior: "(00)00000-0000",
    novaInformacao: "(61)00000-0000",
  },
];

export const dadosParceirosAprovados = [
  {
    id: 1,
    habilitacao: "Parceiro",
    status: "Aprovado",
    situacao: "Aprovado",
    naturezaJuridica: "Privado",
    cnpj: "22.222.222/2000-00",
    nomeFantasia: "Pokémon Center",
    razaoSocial: "Pokémon Center Ltda",
    nomeResponsavel: "Ash Ketchum",
    email: "ash@pokemoncenter.com",
    cidade: "Pallet Town",
    municipio: "Pallet Town",
    uf: "SP",
    nomePontoFocal: "Misty Waterflower",
    emailPontoFocal: "misty@pokemoncenter.com",
    endereco: "Rua Pikachu, 25",
    telefone: "(11) 22222-2222",
    dataCadastro: "2024-04-10T00:00",
    dataUltimaModificacao: "2024-04-11T00:00",
    tipoDeServico: {
      VEP: true,
      VET: true,
      VJA: false,
      CUR: true,
      FPG: false,
      MPU: false,
      MPA: false,
    },
    infoComplementar: "Informações complementares 1",
  },
  {
    id: 2,
    habilitacao: "Parceiro",
    status: "Aprovado",
    situacao: "Aprovado",
    naturezaJuridica: "Terceiro Setor",
    cnpj: "33.333.333/3000-00",
    nomeFantasia: "Silph Co.",
    razaoSocial: "Silph Company Ltda",
    nomeResponsavel: "Giovanni",
    email: "giovanni@silphco.com",
    cidade: "Saffron City",
    municipio: "Saffron City",
    uf: "SP",
    nomePontoFocal: "Jessie",
    emailPontoFocal: "jessie@silphco.com",
    endereco: "Avenida Rocket, 100",
    telefone: "(11) 33333-3333",
    dataCadastro: "2024-02-15T00:00",
    dataUltimaModificacao: "2024-02-16T00:00",
    tipoDeServico: {
      VEP: false,
      VET: true,
      VJA: true,
      CUR: false,
      FPG: true,
      MPU: false,
      MPA: true,
    },
    infoComplementar: "Informações complementares 2",
  },
  {
    id: 3,
    habilitacao: "Parceiro",
    status: "Aprovado",
    situacao: "Aprovado",
    naturezaJuridica: "Público",
    cnpj: "44.444.444/4000-00",
    nomeFantasia: "Pewter Gym",
    razaoSocial: "Pewter Gym Ltda",
    nomeResponsavel: "Brock Harrison",
    email: "brock@pewtergym.com",
    cidade: "Pewter City",
    municipio: "Pewter City",
    uf: "RJ",
    nomePontoFocal: "Forrest Harrison",
    emailPontoFocal: "forrest@pewtergym.com",
    endereco: "Rua Onix, 50",
    telefone: "(21) 44444-4444",
    dataCadastro: "2024-01-20T00:00",
    dataUltimaModificacao: "2024-01-21T00:00",
    tipoDeServico: {
      VEP: true,
      VET: false,
      VJA: true,
      CUR: true,
      FPG: true,
      MPU: false,
      MPA: false,
    },
    infoComplementar: "Informações complementares 3",
  },
  {
    id: 4,
    habilitacao: "Parceiro",
    status: "Aprovado",
    situacao: "Aprovado",
    naturezaJuridica: "Privado",
    cnpj: "55.555.555/5000-00",
    nomeFantasia: "Cerulean Gym",
    razaoSocial: "Cerulean Gym Ltda",
    nomeResponsavel: "Misty Waterflower",
    email: "misty@ceruleangym.com",
    cidade: "Cerulean City",
    municipio: "Cerulean City",
    uf: "RJ",
    nomePontoFocal: "Daisy Waterflower",
    emailPontoFocal: "daisy@ceruleangym.com",
    endereco: "Rua Starmie, 75",
    telefone: "(21) 55555-5555",
    dataCadastro: "2024-03-05T00:00",
    dataUltimaModificacao: "2024-03-06T00:00",
    tipoDeServico: {
      VEP: false,
      VET: true,
      VJA: false,
      CUR: true,
      FPG: true,
      MPU: true,
      MPA: false,
    },
    infoComplementar: "Informações complementares 4",
  },
  {
    id: 5,
    habilitacao: "Parceiro",
    status: "Aprovado",
    situacao: "Aprovado",
    naturezaJuridica: "Terceiro Setor",
    cnpj: "66.666.666/6000-00",
    nomeFantasia: "Vermilion Gym",
    razaoSocial: "Vermilion Gym Ltda",
    nomeResponsavel: "Lt. Surge",
    email: "lt.surge@vermiliongym.com",
    cidade: "Vermilion City",
    municipio: "Vermilion City",
    uf: "SP",
    nomePontoFocal: "Raichu",
    emailPontoFocal: "raichu@vermiliongym.com",
    endereco: "Rua Thunderbolt, 200",
    telefone: "(11) 66666-6666",
    dataCadastro: "2024-04-01T00:00",
    dataUltimaModificacao: "2024-04-02T00:00",
    tipoDeServico: {
      VEP: true,
      VET: true,
      VJA: false,
      CUR: false,
      FPG: true,
      MPU: true,
      MPA: true,
    },
    infoComplementar: "Teste",
  },
];

export const dadosParceirosReprovados = [
  {
    id: 1,
    habilitacao: "Parceiro",
    status: "Reprovado",
    naturezaJuridica: "Público",
    cnpj: "77.777.777/7000-00",
    nomeFantasia: "Nação do Fogo Comércio",
    razaoSocial: "Nação do Fogo Comércio Ltda",
    nomeResponsavel: "Zuko",
    email: "zuko@nacaodofogo.com",
    cidade: "Capital da Nação do Fogo",
    uf: "RJ",
    nomePontoFocal: "Iroh",
    emailPontoFocal: "iroh@nacaodofogo.com",
    endereco: "Avenida Dragão Azul, 100",
    telefone: "(21) 77777-7777",
    dataCadastro: "2024-01-15T00:00",
    dataUltimaModificacao: "2024-01-16T00:00",
    tipoDeServico: {
      VEP: false,
      VET: false,
      VJA: false,
      CUR: false,
      FPG: true,
      MPU: false,
      MPA: true,
    },
  },
  {
    id: 2,
    habilitacao: "Parceiro",
    status: "Reprovado",
    naturezaJuridica: "Privado",
    cnpj: "88.888.888/8000-00",
    nomeFantasia: "Pólo do Sul Exportações",
    razaoSocial: "Pólo do Sul Exportações Ltda",
    nomeResponsavel: "Katara",
    email: "katara@polodosul.com",
    cidade: "Pólo do Sul",
    uf: "SP",
    nomePontoFocal: "Sokka",
    emailPontoFocal: "sokka@polodosul.com",
    endereco: "Rua Esquimó, 50",
    telefone: "(11) 88888-8888",
    dataCadastro: "2024-02-20T00:00",
    dataUltimaModificacao: "2024-02-21T00:00",
    tipoDeServico: {
      VEP: false,
      VET: true,
      VJA: true,
      CUR: false,
      FPG: false,
      MPU: true,
      MPA: false,
    },
  },
  {
    id: 3,
    habilitacao: "Parceiro",
    status: "Reprovado",
    naturezaJuridica: "Terceiro Setor",
    cnpj: "99.999.999/9000-00",
    nomeFantasia: "Ba Sing Se Comércio",
    razaoSocial: "Ba Sing Se Comércio Ltda",
    nomeResponsavel: "Toph Beifong",
    email: "toph@basingse.com",
    cidade: "Ba Sing Se",
    uf: "MG",
    nomePontoFocal: "Lin Beifong",
    emailPontoFocal: "lin@basingse.com",
    endereco: "Rua Terra, 75",
    telefone: "(31) 99999-9999",
    dataCadastro: "2024-03-10T00:00",
    dataUltimaModificacao: "2024-03-11T00:00",
    tipoDeServico: {
      VEP: true,
      VET: false,
      VJA: false,
      CUR: true,
      FPG: false,
      MPU: false,
      MPA: true,
    },
  },
  {
    id: 4,
    habilitacao: "Parceiro",
    status: "Reprovado",
    naturezaJuridica: "Privado",
    cnpj: "10.000.000/1000-00",
    nomeFantasia: "República Unida de Negócios",
    razaoSocial: "República Unida de Negócios Ltda",
    nomeResponsavel: "Korra",
    email: "korra@republicaunida.com",
    cidade: "Cidade República",
    uf: "SP",
    nomePontoFocal: "Asami Sato",
    emailPontoFocal: "asami@republicaunida.com",
    endereco: "Avenida Avatar, 200",
    telefone: "(11) 10000-1000",
    dataCadastro: "2024-04-01T00:00",
    dataUltimaModificacao: "2024-04-02T00:00",
    tipoDeServico: {
      VEP: true,
      VET: true,
      VJA: false,
      CUR: false,
      FPG: false,
      MPU: true,
      MPA: false,
    },
  },
  {
    id: 5,
    habilitacao: "Parceiro",
    status: "Reprovado",
    naturezaJuridica: "Público",
    cnpj: "11.111.111/1100-00",
    nomeFantasia: "Tribo da Água Comércio",
    razaoSocial: "Tribo da Água Comércio Ltda",
    nomeResponsavel: "Kya",
    email: "kya@triboagua.com",
    cidade: "Pólo do Norte",
    uf: "RJ",
    nomePontoFocal: "Tonraq",
    emailPontoFocal: "tonraq@triboagua.com",
    endereco: "Rua Gelo, 300",
    telefone: "(21) 11111-1111",
    dataCadastro: "2024-05-05T00:00",
    dataUltimaModificacao: "2024-05-06T00:00",
    tipoDeServico: {
      VEP: false,
      VET: true,
      VJA: true,
      CUR: true,
      FPG: false,
      MPU: false,
      MPA: true,
    },
  },
];

export const dadosPessoas = [
  {
    id: 1,
    inscricao: "1111",
    nome: "Leia Organa",
    status: "Pendente",
    pcd: "Sim",
    email: "bail@republica.com",
    criteriosDaVaga: "Sim",
    telefone: "(11)11111-1111",
    municipio: "Brasília",
    uf: "DF",
    dataInscricao: "10/10/2000",
    situacao: "Pendente",
    canEdit: true,
  },
  {
    id: 2,
    inscricao: "2222",
    nome: "Padmé Amidala",
    status: "Pendente",
    pcd: "Não",
    email: "anakin@jedi.com",
    criteriosDaVaga: "Sim",
    telefone: "(22)22222-2222",
    municipio: "Belo Horizonte",
    uf: "MG",
    dataInscricao: "10/10/2000",
    situacao: "Pendente",
  },
  {
    id: 3,
    inscricao: "3333",
    nome: "Boba Fett",
    status: "Pendente",
    pcd: "Não",
    email: "paz@beskar.com",
    criteriosDaVaga: "Não",
    telefone: "(33)33333-3333",
    municipio: "Brasília",
    uf: "DF",
    dataInscricao: "10/10/2000",
    situacao: "Pendente",
    canExclude: true,
  },
  {
    id: 4,
    inscricao: "4444",
    nome: "Darth Vader",
    status: "Pendente",
    pcd: "Não",
    email: "ruwee@oraculo.com",
    criteriosDaVaga: "Sim",
    telefone: "(22)22222-2222",
    municipio: "Brasília",
    uf: "DF",
    dataInscricao: "10/10/2000",
    situacao: "Pendente",
  },
  {
    id: 5,
    inscricao: "5555",
    nome: "Luke Skywalker",
    status: "Pendente",
    pcd: "Sim",
    email: "chewbacca@falcon.com",
    criteriosDaVaga: "Sim",
    telefone: "(55)55555-5555",
    municipio: "Brasília",
    uf: "DF",
    dataInscricao: "10/10/2000",
    situacao: "Pendente",
  },
  {
    id: 6,
    inscricao: "6666",
    nome: "Darth Vader",
    status: "Pendente",
    pcd: "Não",
    email: "palpatine@sith.com",
    criteriosDaVaga: "Não",
    telefone: "(33)33333-3333",
    municipio: "Goiânia",
    uf: "GO",
    dataInscricao: "10/10/2000",
    situacao: "Pendente",
  },
  {
    id: 7,
    inscricao: "7777",
    nome: "Anakin Skywalker",
    status: "Pendente",
    pcd: "Não",
    email: "jettster@cantina.com",
    criteriosDaVaga: "Sim",
    telefone: "(33)33333-3333",
    municipio: "Belo Horizonte",
    uf: "MG",
    dataInscricao: "10/10/2000",
    situacao: "Pendente",
  },
  {
    id: 8,
    inscricao: "8888",
    nome: "Chewbacca",
    status: "Pendente",
    pcd: "Sim",
    email: "jango@clones.com",
    criteriosDaVaga: "Não",
    telefone: "(44)44444-4444",
    municipio: "Brasília",
    uf: "DF",
    dataInscricao: "10/10/2000",
    situacao: "Pendente",
  },
  {
    id: 9,
    inscricao: "9999",
    nome: "Paz Vizsla",
    status: "Pendente",
    pcd: "Sim",
    email: "lobot@holonet.com",
    criteriosDaVaga: "Sim",
    telefone: "(22)22222-2222",
    municipio: "Belo Horizonte",
    uf: "MG",
    dataInscricao: "10/10/2000",
    situacao: "Pendente",
  },
];

export const tabelaVagas = [
  {
    id: 1,
    nome: "Desenvolvedor Front-end",
    funcao: "Desenvolvedor Front-end",
    quantidade: 3,
    descricao: "Desenvolvimento de interfaces web",
    ofertante: "LTDA",
    modalidade: "Remoto",
    salario: "R$ 6.000,00",
    dataCadastro: "2024-05-01",
    dataFinal: "2024-07-01",
    municipio: "São Paulo",
    uf: "SP",
    vagas: 30,
    vigencia: 30,
    situacao: "Pendente",
    interessados: 3,
    tipoVaga: "CLT",
  },
  {
    id: 2,
    nome: "Analista de Dados",
    funcao: "Analista de Dados",
    quantidade: 2,
    descricao: "Análise e interpretação de dados",
    ofertante: "LTDA",
    modalidade: "Presencial",
    salario: "R$ 7.500,00",
    dataCadastro: "2024-04-25",
    dataFinal: "2024-07-01",
    municipio: "São Paulo",
    uf: "SP",
    vagas: 30,
    vigencia: 60,
    situacao: "Aprovado",
    interessados: 3,
    tipoVaga: "Estágio",
  },
  {
    id: 3,
    nome: "Gerente de Projetos",
    funcao: "Gerente de Projetos",
    quantidade: 1,
    descricao: "Gestão de projetos e equipes",
    ofertante: "LTDA",
    modalidade: "Remoto",
    salario: "R$ 9.000,00",
    dataCadastro: "2024-04-15",
    dataFinal: "2024-07-01",
    municipio: "São Paulo",
    uf: "SP",
    vagas: 30,
    vigencia: 45,
    situacao: "Aprovado",
    interessados: 3,
    tipoVaga: "PJ",
  },
  {
    id: 4,
    nome: "Designer UX/UI",
    funcao: "Designer UX/UI",
    quantidade: 2,
    descricao: "Criação de interfaces de usuário",
    ofertante: "LTDA",
    modalidade: "Remoto",
    salario: "R$ 5.500,00",
    dataCadastro: "2024-05-10",
    dataFinal: "2024-07-01",
    municipio: "São Paulo",
    uf: "SP",
    vagas: 30,
    vigencia: 90,
    situacao: "Pendente",
    interessados: 3,
    tipoVaga: "CLT",
  },
];

export const tabelaRepresentantes = [
  { id: 1, nome: "Luke Skywalker", cpf: "123.456.789-00", email: "luke@starwars.com" },
  { id: 2, nome: "Leia Organa", cpf: "987.654.321-00", email: "leia@starwars.com" },
  { id: 3, nome: "Han Solo", cpf: "555.666.777-00", email: "han@starwars.com" },
];

export const tabelaInteressados = [
  {
    id: 1,
    nome: "Luke Skywalker",
    cpf: "123.456.789-00",
    telefone: "(61) 99999-9999",
    email: "luke@starwars.com",
    uf: "SP",
    municipio: "São Paulo",
    dataRegistro: "2024-05-01",
    situacao: "Pendente",
    pcd: "Sim",
    curriculo: ["Graduação em TI"],
    requisitos: ["Graduação em TI"],
    escolaridade: "Superior",
  },
  {
    id: 2,
    nome: "Leia Organa",
    cpf: "987.654.321-00",
    telefone: "(61) 99999-9999",
    email: "leia@starwars.com",
    uf: "SP",
    municipio: "São Paulo",
    dataRegistro: "2024-05-01",
    situacao: "Pendente",
    pcd: "Não",
    curriculo: ["Graduação em TI"],
    requisitos: ["Graduação em TI"],
    escolaridade: "Doutorado",
  },
  {
    id: 3,
    nome: "Han Solo",
    cpf: "555.666.777-00",
    telefone: "(61) 99999-9999",
    email: "han@starwars.com",
    uf: "SP",
    municipio: "São Paulo",
    dataRegistro: "2024-05-01",
    situacao: "Pendente",
    pcd: "Não",
    curriculo: ["Graduação em TI"],
    requisitos: ["Graduação em TI"],
    escolaridade: "Mestrado",
  },
];

export const tabelaCursosPendentes = [
  {
    id: 1,
    nome: "Curso de Programação Web",
    inicioPreMatricula: "2024-06-01",
    fimPreMatricula: "2024-06-15",
    uf: "SP",
    municipio: "São Paulo",
    vagas: 30,
    interessados: 45,
    situacao: "Pendente",
    status: "Ativo",
    quantidade: 5,
    cargaHoraria: 20,
    modalidade: "Presencial",
    ofertante: "Ash Ketchum",
    cadastro: "00000",
    cnpj: "22.222.222/2000-00",
    telefone: "(11) 22222-2222",
    dataCadastro: "2024-04-10T00:00",
    dataUltimaModificacao: "2024-04-11T00:00",
    anexos: [
      {
        data: "2024-04-10T00:00",
        momento: "",
        nome: "Plano de negócio",
      },
    ],
    historico: [
      {
        modificacao: "Edição",
        campo: "Vagas",
        anterior: "50",
        nova: "30",
      },
      {
        modificacao: "Edição",
        campo: "Inicio Pre-Matrícula",
        anterior: "2024-05-01",
        nova: "2024-06-01",
      },
      {
        modificacao: "Edição",
        campo: "Interessados",
        anterior: "30",
        nova: "45",
      },
    ],
    historicoAnalises: [
      {
        dataModificacao: "2024-04-10T00:00",
        situacao: "Pendente",
        usuario: "Admin",
        motivo: "Conferência de dados",
      },
    ],
  },
  {
    id: 2,
    nome: "Curso de Data Science",
    inicioPreMatricula: "2024-07-01",
    fimPreMatricula: "2024-07-15",
    uf: "SP",
    municipio: "São Paulo",
    vagas: 25,
    interessados: 40,
    situacao: "Pendente",
    status: "Ativo",
    quantidade: 9,
    cargaHoraria: 30,
    modalidade: "EAD",
    ofertante: "Giovanni",
    cadastro: "11111",
    cnpj: "33.333.333/3000-00",
    telefone: "(11) 33333-3333",
    dataCadastro: "2024-02-15T00:00",
    dataUltimaModificacao: "2024-02-16T00:00",
    anexos: [
      {
        data: "2024-02-15T00:00",
        momento: "",
        nome: "Plano de negócio",
      },
    ],
    historico: [
      {
        modificacao: "Edição",
        campo: "Vagas",
        anterior: "10",
        nova: "25",
      },
      {
        modificacao: "Edição",
        campo: "Carga Horária",
        anterior: "40",
        nova: "30",
      },
      {
        modificacao: "Edição",
        campo: "Interessados",
        anterior: "30",
        nova: "40",
      },
    ],
    historicoAnalises: [
      {
        dataModificacao: "2024-02-16T00:00",
        situacao: "Pendente",
        usuario: "Admin",
        motivo: "Conferência de dados",
      },
    ],
  },
  {
    id: 3,
    nome: "Curso de Design Gráfico",
    inicioPreMatricula: "2024-08-01",
    fimPreMatricula: "2024-08-15",
    uf: "SP",
    municipio: "São Paulo",
    vagas: 20,
    interessados: 30,
    situacao: "Pendente",
    status: "Inativo",
    quantidade: 2,
    cargaHoraria: 10,
    modalidade: "Presencial",
    ofertante: "Misty Waterflower",
    cadastro: "22222",
    cnpj: "22.222.222/0000-22",
    telefone: "(21) 55555-5555",
    dataCadastro: "2024-03-05T00:00",
    dataUltimaModificacao: "2024-03-06T00:00",
    anexos: [
      {
        data: "2024-03-05T00:00",
        momento: "",
        nome: "Plano de negócio",
      },
    ],
    historico: [
      {
        modificacao: "Edição",
        campo: "Modalidade",
        anterior: "EAD",
        nova: "Presencial",
      },
      {
        modificacao: "Edição",
        campo: "Interessados",
        anterior: "10",
        nova: "30",
      },
      {
        modificacao: "Edição",
        campo: "Vagas",
        anterior: "15",
        nova: "20",
      },
    ],
    historicoAnalises: [
      {
        dataModificacao: "2024-03-05T00:00",
        situacao: "Pendente",
        usuario: "Admin",
        motivo: "Conferência de dados",
      },
    ],
  },
  {
    id: 4,
    nome: "Curso de Inteligência Artificial",
    inicioPreMatricula: "2024-06-01",
    fimPreMatricula: "2024-06-15",
    uf: "SP",
    municipio: "São Paulo",
    vagas: 30,
    interessados: 45,
    situacao: "Pendente",
    status: "Ativo",
    quantidade: 5,
    cargaHoraria: 20,
    modalidade: "Presencial",
    ofertante: "James Rocket",
    cadastro: "00000",
    cnpj: "11.111.111/1111-11",
    telefone: "(11) 22222-2222",
    dataCadastro: "2024-04-10T00:00",
    dataUltimaModificacao: "2024-04-11T00:00",
    anexos: [
      {
        data: "2024-04-10T00:00",
        momento: "",
        nome: "Plano de negócio",
      },
    ],
    historico: [
      {
        modificacao: "Edição",
        campo: "Vagas",
        anterior: "50",
        nova: "30",
      },
      {
        modificacao: "Edição",
        campo: "Inicio Pre-Matrícula",
        anterior: "2024-05-01",
        nova: "2024-06-01",
      },
      {
        modificacao: "Edição",
        campo: "Interessados",
        anterior: "30",
        nova: "45",
      },
    ],
    historicoAnalises: [
      {
        dataModificacao: "2024-04-10T00:00",
        situacao: "Pendente",
        usuario: "Admin",
        motivo: "Conferência de dados",
      },
    ],
  },
  {
    id: 5,
    nome: "Curso de Marketing Digital",
    inicioPreMatricula: "2024-07-01",
    fimPreMatricula: "2024-07-15",
    uf: "SP",
    municipio: "São Paulo",
    vagas: 25,
    interessados: 40,
    situacao: "Pendente",
    status: "Ativo",
    quantidade: 9,
    cargaHoraria: 30,
    modalidade: "EAD",
    ofertante: "Jessie Rocket",
    cadastro: "11111",
    cnpj: "44.444.444/4444-44",
    telefone: "(11) 33333-3333",
    dataCadastro: "2024-02-15T00:00",
    dataUltimaModificacao: "2024-02-16T00:00",
    anexos: [
      {
        data: "2024-02-15T00:00",
        momento: "",
        nome: "Plano de negócio",
      },
    ],
    historico: [
      {
        modificacao: "Edição",
        campo: "Vagas",
        anterior: "10",
        nova: "25",
      },
      {
        modificacao: "Edição",
        campo: "Carga Horária",
        anterior: "40",
        nova: "30",
      },
      {
        modificacao: "Edição",
        campo: "Interessados",
        anterior: "30",
        nova: "40",
      },
    ],
    historicoAnalises: [
      {
        dataModificacao: "2024-02-16T00:00",
        situacao: "Pendente",
        usuario: "Admin",
        motivo: "Conferência de dados",
      },
    ],
  },
  {
    id: 6,
    nome: "Curso de Desenvolvimento de Jogos",
    inicioPreMatricula: "2024-08-01",
    fimPreMatricula: "2024-08-15",
    uf: "SP",
    municipio: "São Paulo",
    vagas: 20,
    interessados: 30,
    situacao: "Pendente",
    status: "Inativo",
    quantidade: 2,
    cargaHoraria: 10,
    modalidade: "Presencial",
    ofertante: "Brock Slate",
    cadastro: "22222",
    cnpj: "55.555.555/5555-55",
    telefone: "(21) 55555-5555",
    dataCadastro: "2024-03-05T00:00",
    dataUltimaModificacao: "2024-03-06T00:00",
    anexos: [
      {
        data: "2024-03-05T00:00",
        momento: "",
        nome: "Plano de negócio",
      },
    ],
    historico: [
      {
        modificacao: "Edição",
        campo: "Modalidade",
        anterior: "EAD",
        nova: "Presencial",
      },
      {
        modificacao: "Edição",
        campo: "Interessados",
        anterior: "10",
        nova: "30",
      },
      {
        modificacao: "Edição",
        campo: "Vagas",
        anterior: "15",
        nova: "20",
      },
    ],
    historicoAnalises: [
      {
        dataModificacao: "2024-03-05T00:00",
        situacao: "Pendente",
        usuario: "Admin",
        motivo: "Conferência de dados",
      },
    ],
  },
  {
    id: 7,
    nome: "Curso de E-commerce Avançado",
    inicioPreMatricula: "2024-06-01",
    fimPreMatricula: "2024-06-15",
    uf: "SP",
    municipio: "São Paulo",
    vagas: 30,
    interessados: 45,
    situacao: "Pendente",
    status: "Ativo",
    quantidade: 5,
    cargaHoraria: 20,
    modalidade: "Presencial",
    ofertante: "Professor Oak",
    cadastro: "00000",
    cnpj: "66.666.666/6666-66",
    telefone: "(11) 22222-2222",
    dataCadastro: "2024-04-10T00:00",
    dataUltimaModificacao: "2024-04-11T00:00",
    anexos: [
      {
        data: "2024-04-10T00:00",
        momento: "",
        nome: "Plano de negócio",
      },
    ],
    historico: [
      {
        modificacao: "Edição",
        campo: "Vagas",
        anterior: "50",
        nova: "30",
      },
      {
        modificacao: "Edição",
        campo: "Inicio Pre-Matrícula",
        anterior: "2024-05-01",
        nova: "2024-06-01",
      },
      {
        modificacao: "Edição",
        campo: "Interessados",
        anterior: "30",
        nova: "45",
      },
    ],
    historicoAnalises: [
      {
        dataModificacao: "2024-04-10T00:00",
        situacao: "Pendente",
        usuario: "Admin",
        motivo: "Conferência de dados",
      },
    ],
  },
  {
    id: 8,
    nome: "Curso de Análise de Dados",
    inicioPreMatricula: "2024-07-01",
    fimPreMatricula: "2024-07-15",
    uf: "SP",
    municipio: "São Paulo",
    vagas: 25,
    interessados: 40,
    situacao: "Pendente",
    status: "Ativo",
    quantidade: 9,
    cargaHoraria: 30,
    modalidade: "EAD",
    ofertante: "Gary Oak",
    cadastro: "11111",
    cnpj: "77.777.777/7777-77",
    telefone: "(11) 33333-3333",
    dataCadastro: "2024-02-15T00:00",
    dataUltimaModificacao: "2024-02-16T00:00",
    anexos: [
      {
        data: "2024-02-15T00:00",
        momento: "",
        nome: "Plano de negócio",
      },
    ],
    historico: [
      {
        modificacao: "Edição",
        campo: "Vagas",
        anterior: "10",
        nova: "25",
      },
      {
        modificacao: "Edição",
        campo: "Carga Horária",
        anterior: "40",
        nova: "30",
      },
      {
        modificacao: "Edição",
        campo: "Interessados",
        anterior: "30",
        nova: "40",
      },
    ],
    historicoAnalises: [
      {
        dataModificacao: "2024-02-16T00:00",
        situacao: "Pendente",
        usuario: "Admin",
        motivo: "Conferência de dados",
      },
    ],
  },
  {
    id: 9,
    nome: "Curso de Gestão de Projetos",
    inicioPreMatricula: "2024-08-01",
    fimPreMatricula: "2024-08-15",
    uf: "SP",
    municipio: "São Paulo",
    vagas: 20,
    interessados: 30,
    situacao: "Pendente",
    status: "Inativo",
    quantidade: 2,
    cargaHoraria: 10,
    modalidade: "Presencial",
    ofertante: "Sabrina Psychic",
    cadastro: "22222",
    cnpj: "88.888.888/8888-88",
    telefone: "(21) 55555-5555",
    dataCadastro: "2024-03-05T00:00",
    dataUltimaModificacao: "2024-03-06T00:00",
    anexos: [
      {
        data: "2024-03-05T00:00",
        momento: "",
        nome: "Plano de negócio",
      },
    ],
    historico: [
      {
        modificacao: "Edição",
        campo: "Modalidade",
        anterior: "EAD",
        nova: "Presencial",
      },
      {
        modificacao: "Edição",
        campo: "Interessados",
        anterior: "10",
        nova: "30",
      },
      {
        modificacao: "Edição",
        campo: "Vagas",
        anterior: "15",
        nova: "20",
      },
    ],
    historicoAnalises: [
      {
        dataModificacao: "2024-03-05T00:00",
        situacao: "Pendente",
        usuario: "Admin",
        motivo: "Conferência de dados",
      },
    ],
  },
  {
    id: 10,
    nome: "Curso de UX/UI Design",
    inicioPreMatricula: "2024-06-01",
    fimPreMatricula: "2024-06-15",
    uf: "SP",
    municipio: "São Paulo",
    vagas: 30,
    interessados: 45,
    situacao: "Pendente",
    status: "Ativo",
    quantidade: 5,
    cargaHoraria: 20,
    modalidade: "Presencial",
    ofertante: "Erika Flora",
    cadastro: "00000",
    cnpj: "99.999.999/9999-99",
    telefone: "(11) 22222-2222",
    dataCadastro: "2024-04-10T00:00",
    dataUltimaModificacao: "2024-04-11T00:00",
    anexos: [
      {
        data: "2024-04-10T00:00",
        momento: "",
        nome: "Plano de negócio",
      },
    ],
    historico: [
      {
        modificacao: "Edição",
        campo: "Vagas",
        anterior: "50",
        nova: "30",
      },
      {
        modificacao: "Edição",
        campo: "Inicio Pre-Matrícula",
        anterior: "2024-05-01",
        nova: "2024-06-01",
      },
      {
        modificacao: "Edição",
        campo: "Interessados",
        anterior: "30",
        nova: "45",
      },
    ],
    historicoAnalises: [
      {
        dataModificacao: "2024-04-10T00:00",
        situacao: "Pendente",
        usuario: "Admin",
        motivo: "Conferência de dados",
      },
    ],
  },
  {
    id: 11,
    nome: "Curso de Fotografia Digital",
    inicioPreMatricula: "2024-07-01",
    fimPreMatricula: "2024-07-15",
    uf: "SP",
    municipio: "São Paulo",
    vagas: 25,
    interessados: 40,
    situacao: "Pendente",
    status: "Ativo",
    quantidade: 9,
    cargaHoraria: 30,
    modalidade: "EAD",
    ofertante: "Todd Snap",
    cadastro: "11111",
    cnpj: "11.111.111/1111-00",
    telefone: "(11) 33333-3333",
    dataCadastro: "2024-02-15T00:00",
    dataUltimaModificacao: "2024-02-16T00:00",
    anexos: [
      {
        data: "2024-02-15T00:00",
        momento: "",
        nome: "Plano de negócio",
      },
    ],
    historico: [
      {
        modificacao: "Edição",
        campo: "Vagas",
        anterior: "10",
        nova: "25",
      },
      {
        modificacao: "Edição",
        campo: "Carga Horária",
        anterior: "40",
        nova: "30",
      },
      {
        modificacao: "Edição",
        campo: "Interessados",
        anterior: "30",
        nova: "40",
      },
    ],
    historicoAnalises: [
      {
        dataModificacao: "2024-02-16T00:00",
        situacao: "Pendente",
        usuario: "Admin",
        motivo: "Conferência de dados",
      },
    ],
  },
  {
    id: 12,
    nome: "Curso de Blockchain e Criptomoedas",
    inicioPreMatricula: "2024-08-01",
    fimPreMatricula: "2024-08-15",
    uf: "SP",
    municipio: "São Paulo",
    vagas: 20,
    interessados: 30,
    situacao: "Pendente",
    status: "Inativo",
    quantidade: 2,
    cargaHoraria: 10,
    modalidade: "Presencial",
    ofertante: "Lt. Surge",
    cadastro: "22222",
    cnpj: "12.222.222/2222-11",
    telefone: "(21) 55555-5555",
    dataCadastro: "2024-03-05T00:00",
    dataUltimaModificacao: "2024-03-06T00:00",
    anexos: [
      {
        data: "2024-03-05T00:00",
        momento: "",
        nome: "Plano de negócio",
      },
    ],
    historico: [
      {
        modificacao: "Edição",
        campo: "Modalidade",
        anterior: "EAD",
        nova: "Presencial",
      },
      {
        modificacao: "Edição",
        campo: "Interessados",
        anterior: "10",
        nova: "30",
      },
      {
        modificacao: "Edição",
        campo: "Vagas",
        anterior: "15",
        nova: "20",
      },
    ],
    historicoAnalises: [
      {
        dataModificacao: "2024-03-05T00:00",
        situacao: "Pendente",
        usuario: "Admin",
        motivo: "Conferência de dados",
      },
    ],
  },
  {
    id: 13,
    nome: "Curso de Escrita Criativa3",
    inicioPreMatricula: "2024-06-01",
    fimPreMatricula: "2024-06-15",
    uf: "SP",
    municipio: "São Paulo",
    vagas: 30,
    interessados: 45,
    situacao: "Pendente",
    status: "Ativo",
    quantidade: 5,
    cargaHoraria: 20,
    modalidade: "Presencial",
    ofertante: "Agatha Ghost",
    cadastro: "00000",
    cnpj: "13.333.333/3333-22",
    telefone: "(11) 22222-2222",
    dataCadastro: "2024-04-10T00:00",
    dataUltimaModificacao: "2024-04-11T00:00",
    anexos: [
      {
        data: "2024-04-10T00:00",
        momento: "",
        nome: "Plano de negócio",
      },
    ],
    historico: [
      {
        modificacao: "Edição",
        campo: "Vagas",
        anterior: "50",
        nova: "30",
      },
      {
        modificacao: "Edição",
        campo: "Inicio Pre-Matrícula",
        anterior: "2024-05-01",
        nova: "2024-06-01",
      },
      {
        modificacao: "Edição",
        campo: "Interessados",
        anterior: "30",
        nova: "45",
      },
    ],
    historicoAnalises: [
      {
        dataModificacao: "2024-04-10T00:00",
        situacao: "Pendente",
        usuario: "Admin",
        motivo: "Conferência de dados",
      },
    ],
  },
  {
    id: 14,
    nome: "Curso de Empreendedorismo",
    inicioPreMatricula: "2024-07-01",
    fimPreMatricula: "2024-07-15",
    uf: "SP",
    municipio: "São Paulo",
    vagas: 25,
    interessados: 40,
    situacao: "Pendente",
    status: "Ativo",
    quantidade: 9,
    cargaHoraria: 30,
    modalidade: "EAD",
    ofertante: "Blaine Cinnabar",
    cadastro: "11111",
    cnpj: "14.444.444/4444-33",
    telefone: "(11) 33333-3333",
    dataCadastro: "2024-02-15T00:00",
    dataUltimaModificacao: "2024-02-16T00:00",
    anexos: [
      {
        data: "2024-02-15T00:00",
        momento: "",
        nome: "Plano de negócio",
      },
    ],
    historico: [
      {
        modificacao: "Edição",
        campo: "Vagas",
        anterior: "10",
        nova: "25",
      },
      {
        modificacao: "Edição",
        campo: "Carga Horária",
        anterior: "40",
        nova: "30",
      },
      {
        modificacao: "Edição",
        campo: "Interessados",
        anterior: "30",
        nova: "40",
      },
    ],
    historicoAnalises: [
      {
        dataModificacao: "2024-02-16T00:00",
        situacao: "Pendente",
        usuario: "Admin",
        motivo: "Conferência de dados",
      },
    ],
  },
  {
    id: 15,
    nome: "Curso de Machine Learning",
    inicioPreMatricula: "2024-08-01",
    fimPreMatricula: "2024-08-15",
    uf: "SP",
    municipio: "São Paulo",
    vagas: 20,
    interessados: 30,
    situacao: "Pendente",
    status: "Inativo",
    quantidade: 2,
    cargaHoraria: 10,
    modalidade: "Presencial",
    ofertante: "Bill Researcher",
    cadastro: "22222",
    cnpj: "14.555.555/5555-44",
    telefone: "(21) 55555-5555",
    dataCadastro: "2024-03-05T00:00",
    dataUltimaModificacao: "2024-03-06T00:00",
    anexos: [
      {
        data: "2024-03-05T00:00",
        momento: "",
        nome: "Plano de negócio",
      },
    ],
    historico: [
      {
        modificacao: "Edição",
        campo: "Modalidade",
        anterior: "EAD",
        nova: "Presencial",
      },
      {
        modificacao: "Edição",
        campo: "Interessados",
        anterior: "10",
        nova: "30",
      },
      {
        modificacao: "Edição",
        campo: "Vagas",
        anterior: "15",
        nova: "20",
      },
    ],
    historicoAnalises: [
      {
        dataModificacao: "2024-03-05T00:00",
        situacao: "Pendente",
        usuario: "Admin",
        motivo: "Conferência de dados",
      },
    ],
  },
];

export const tabelaTrilhas = [
  {
    id: 1,
    nome: "Trilha 1",
    cursos: "Curso de Programação Web, Curso de Data Science",
    descricao: "Alguma descrição para a Trilha",
    numeroDeCursos: 2,
    relacao: "Relação dos cursos apresentados na Trilha",
    situacao: "Ativo",
    dataUltimaModificacao: "2024-03-06T00:00",
  },
  {
    id: 2,
    nome: "Trilha 2",
    cursos: "Curso de Data Science",
    descricao: "Alguma descrição para a Trilha",
    numeroDeCursos: 1,
    relacao: "Relação dos cursos apresentados na Trilha",
    situacao: "Inativo",
    dataUltimaModificacao: "2024-04-24T00:00",
  },
];

export const tabelaVagasTrabalho = [
  {
    id: 1,
    nome: "Vaga 1",
    nomeParceiro: "Ash Ketchum",
    cnpj: "22.222.222/2000-00",
    empresa: "Pikachu Ltda.",
    situacaoParceiro: "Aprovado",
    situacao: "Pendente",
    dataCadastro: "2024-04-10T00:00",
    funcao: "Função 1",
    vagas: 20,
    interessados: 30,
    validade: "30 dias",
    tipo: "Presencial",
    turno: 0,
    escolaridadeRequerida: "Ensino Médio",
    salario: "1000,00",
    cidade: "Salto de Pirapora",
    uf: "SP",
    cep: "77000-100",
    bairro: "Baixada do Pikachu",
    endereco: "Rua Pikachu",
    numero: 25,
    telefone: "(11) 22222-2222",
    areaAtuacao: "",
    descricao: "Uma descrição sobre a vaga",
  },
];

export const tabelaPerfil = [
  {
    id: 1,
    nome: "Perfil 1",
    descricao: "Descrição do perfil",
    situacao: "Inativo",
    acoes: "Ação 1, Ação 4",
    dataCadastro: "2024-05-29T00:00",
    historico: [
      {
        id: 1,
        usuario: "Ash",
        situacao: "Inativo",
        motivo: "Dados precisam ser revisados",
        dataModificacao: "2024-05-29T00:00",
      },
    ],
  },
];

export const tabelaAcoes = [
  {
    id: 1,
    nome: "Ação 1",
  },
  {
    id: 2,
    nome: "Ação 2",
  },
  {
    id: 3,
    nome: "Ação 3",
  },
  {
    id: 4,
    nome: "Ação 4",
  },
  {
    id: 5,
    nome: "Ação 5",
  },
];

export const tabelaUsuarios = [
  {
    id: 1,
    login: "leiaOrgana",
    cpf: "111.111.111-11",
    tipo: "Tipo",
    nome: "Leia Organa",
    email: "bail@republica.com",
    telefone: "(11)11111-1111",
    situacao: "Ativo",
    perfis: ["Perfil 1", "Perfil 3"],
    dataUltimaModificacao: "2024-05-29T00:00",
    historico: [
      {
        id: 1,
        usuario: "Ash",
        situacao: "Inativo",
        motivo: "Dados aprovados",
        dataModificacao: "2024-05-29T00:00",
      },
    ],
  },
  {
    id: 2,
    login: "zuko",
    cpf: "222.222.111-11",
    tipo: "Tipo",
    nome: "Zuko",
    email: "zuko@nacaodofogo.com",
    telefone: "(22)99999-1111",
    situacao: "Inativo",
    perfis: ["Perfil 2", "Perfil 3"],
    dataUltimaModificacao: "2024-04-19T00:00",
    historico: [],
  },
];

export const tabelaInteressadosMPO = [
  {
    id: 1,
    nome: "Ash",
    genero: "Masculino",
    cpf: "111.111.111-11",
    telefone: "(11) 11111-1111",
    email: "ash@gmail.com",
    cep: "70910-900",
    uf: "DF",
    codigoIbge: "00000000",
    municipio: "Brasília",
    bairro: "Asa norte",
    endereco: "Campus Universitário Darcy Ribeiro",
    numero: 12,
    complemento: "UnB",
    tipoQuestionarioRespondido: "Já Sou Empreendedor",
    tipo: "Já Sou Empreendedor",
    dataResposta: "2024-05-29T00:00",
    dataNascimento: "1994-03-01T00:00",
    dataInicio: "2024-06-02T00:00",
    cnpj: "22.222.222/2000-00",
    nomeParceiro: "Ash",
    dataEmprestimo: "2024-06-10T00:00",
    valor: "R$ 1.000.000,00",
    retorno: "",
    rg: "28351245",
    escolaridade: "Ensino Superior Completo",
    stc_retorno_contato_ativo: "Retorno",
    stc_dataInicioNegocio: "2024-26-10T00:00",
    stc_contato_data: "2024-025-29T00:00",
    stc_contato_parceiro: "Contato",
    stc_emprestimo_data: "2024-27-10T00:00",
    stc_emprestimo_valor: "R$ 1.000,00",
    stc_emprestimo_parceiro: "Parceiro Empréstimo",
    qse_primeira_vez: "Não",
    qse_motivo_abertura: "Independência financeira",
    qse_setor_negocio: "Serviços",
    qse_situacao_negocio: "Formalizado",
    qse_participou_curso: "Sim",
    qse_curso: "Curso de Empreendedorismo",
    qse_instituicao: "Sebrae",
    qse_empregabilidade: "1",
    qse_participar_curso: "Sim",
    qse_motivo_participar: "Aprimorar conhecimentos",
    qse_receber_orientacao: "Sim",
    qse_motivo_orientacao: "Melhorar a gestão",
    qse_necessidade_financiamento: "Sim",
    qse_motivo_financiamento: "Capital de giro",
    qse_conhece_aval_solidario: "Sim",
    qse_proprio_negocio: "Sim",
    jse_atividade_negocio: "Restaurante",
    jse_variar_linha_atuacao: "Sim",
    jse_motivo_abertura: "Autonomia e independência",
    jse_fontes_recursos: "Recursos próprios",
    jse_separa_compras: "Sim",
    jse_tempo_negocio: "2 anos",
    jse_compra_produtos_servicos: "Compra em atacado",
    jse_propaganda_negocio: "Boca a boca",
    jse_razao_consumidor_comprar: "Qualidade dos produtos",
    jse_necessidades_crescimento: "Expandir a clientela",
    jse_pensou_desistir: "Não",
    jse_financiamento_necessario: "Sim",
    jse_funcionarios_negocio: "Sim",
    jse_visao_futura: "Crescimento gradual",
    jse_calculo_custo_produto: "Sim",
    jse_reducao_custos: "Sim",
    jse_producao_mensal: "1000 refeições",
    jse_situacao_negocio: "Estável",
    jse_participou_curso: "Sim",
    jse_curso: "Gestão de Restaurantes",
    jse_instituicao: "Sebrae",
    jse_participar_curso: "Sim",
    jse_motivo_participar: "Melhorar a gestão",
    jse_necessidade_financiamento: "Sim",
    jse_motivo_financiamento: "Renovação do espaço",
    jse_receber_orientacao: "Sim",
    jse_motivo_orientacao: "Inovar no cardápio",
    jse_conhece_aval_solidario: "Sim",
    jse_motivo_proprio_negocio: "Paixão por culinária",
    jse_local_funcionamento: "Centro da cidade",
  },
  {
    id: 2,
    nome: "Misty",
    genero: "Feminino",
    cpf: "222.222.222-22",
    telefone: "(22) 22222-2222",
    email: "misty@gmail.com",
    cep: "21030-200",
    uf: "RJ",
    codigoIbge: "3304557",
    municipio: "Rio de Janeiro",
    bairro: "Copacabana",
    endereco: "Avenida Atlântica",
    numero: 320,
    complemento: "Apto 101",
    tipoQuestionarioRespondido: "Quero Ser Empreendedor",
    tipo: "Quero Ser Empreendedor",
    dataResposta: "2024-06-10T00:00",
    dataNascimento: "1995-05-02T00:00",
    dataInicio: "2024-07-15T00:00",
    cnpj: "33.333.333/3000-00",
    nomeParceiro: "Misty",
    dataEmprestimo: "2024-07-20T00:00",
    valor: "R$ 500.000,00",
    retorno: "",
    rg: "39210458",
    escolaridade: "Ensino Médio Completo",
    stc_retorno_contato_ativo: "Sim",
    stc_dataInicioNegocio: "2024-16-10T00:00",
    stc_contato_data: "2024-15-29T00:00",
    stc_contato_parceiro: "Contato",
    stc_emprestimo_data: "2024-17-10T00:00",
    stc_emprestimo_valor: "R$ 2.000,00",
    stc_emprestimo_parceiro: "Outro",
    qse_proprio_negocio: "Sim",
    qse_primeira_vez: "Sim",
    qse_motivo_abertura: "Realizar um sonho",
    qse_setor_negocio: "Comércio",
    qse_situacao_negocio: "Em fase de planejamento",
    qse_participou_curso: "Não",
    qse_curso: "",
    qse_instituicao: "",
    qse_empregabilidade: "0",
    qse_participar_curso: "Sim",
    qse_motivo_participar: "Aprender a gerir um negócio",
    qse_receber_orientacao: "Sim",
    qse_motivo_orientacao: "Evitar erros comuns",
    qse_necessidade_financiamento: "Sim",
    qse_motivo_financiamento: "Comprar equipamentos",
    qse_conhece_aval_solidario: "Não",
    jse_atividade_negocio: "Loja de roupas",
    jse_variar_linha_atuacao: "Não",
    jse_motivo_abertura: "Oportunidade de mercado",
    jse_fontes_recursos: "Empréstimo bancário",
    jse_separa_compras: "Sim",
    jse_tempo_negocio: "5 anos",
    jse_compra_produtos_servicos: "Fornecedores locais",
    jse_propaganda_negocio: "Redes sociais",
    jse_razao_consumidor_comprar: "Atendimento personalizado",
    jse_necessidades_crescimento: "Ampliar o estoque",
    jse_pensou_desistir: "Sim",
    jse_financiamento_necessario: "Sim",
    jse_funcionarios_negocio: "Sim",
    jse_visao_futura: "Abrir uma filial",
    jse_calculo_custo_produto: "Sim",
    jse_reducao_custos: "Sim",
    jse_producao_mensal: "500 peças",
    jse_situacao_negocio: "Crescendo",
    jse_participou_curso: "Sim",
    jse_curso: "Vendas e Marketing",
    jse_instituicao: "SENAC",
    jse_participar_curso: "Sim",
    jse_motivo_participar: "Expandir conhecimento em vendas",
    jse_necessidade_financiamento: "Sim",
    jse_motivo_financiamento: "Aumentar estoque",
    jse_receber_orientacao: "Sim",
    jse_motivo_orientacao: "Planejar expansão",
    jse_conhece_aval_solidario: "Não",
    jse_motivo_proprio_negocio: "Vontade de empreender",
    jse_local_funcionamento: "Shopping center",
  },
  {
    id: 3,
    nome: "Brock",
    genero: "Masculino",
    cpf: "333.333.333-33",
    telefone: "(33) 33333-3333",
    email: "brock@gmail.com",
    cep: "30140-002",
    uf: "MG",
    codigoIbge: "3106200",
    municipio: "Belo Horizonte",
    bairro: "Savassi",
    endereco: "Rua Alagoas",
    numero: 456,
    complemento: "Sala 302",
    tipoQuestionarioRespondido: "Já Sou Empreendedor",
    tipo: "Já Sou Empreendedor",
    dataResposta: "2024-07-01T00:00",
    dataNascimento: "1993-07-03T00:00",
    dataInicio: "2024-07-30T00:00",
    cnpj: "44.444.444/4000-00",
    nomeParceiro: "Brock",
    dataEmprestimo: "2024-07-25T00:00",
    valor: "R$ 200.000,00",
    retorno: "",
    rg: "45123678",
    escolaridade: "Ensino Superior Incompleto",
    stc_retorno_contato_ativo: "Sim",
    stc_dataInicioNegocio: "2024-06-10T00:00",
    stc_contato_data: "2024-05-29T00:00",
    stc_contato_parceiro: "Contato",
    stc_emprestimo_data: "2024-07-10T00:00",
    stc_emprestimo_valor: "R$ 3.000,00",
    stc_emprestimo_parceiro: "Outro",
    qse_proprio_negocio: "Sim",
    qse_primeira_vez: "Não",
    qse_motivo_abertura: "Independência financeira",
    qse_setor_negocio: "Serviços",
    qse_situacao_negocio: "Formalizado",
    qse_participou_curso: "Sim",
    qse_curso: "Curso de Gestão Financeira",
    qse_instituicao: "Sebrae",
    qse_empregabilidade: "1",
    qse_participar_curso: "Não",
    qse_motivo_participar: "",
    qse_receber_orientacao: "Não",
    qse_motivo_orientacao: "",
    qse_necessidade_financiamento: "Sim",
    qse_motivo_financiamento: "Expandir o negócio",
    qse_conhece_aval_solidario: "Sim",
    jse_atividade_negocio: "Oficina mecânica",
    jse_variar_linha_atuacao: "Sim",
    jse_motivo_abertura: "Experiência na área",
    jse_fontes_recursos: "Parceria com sócio",
    jse_separa_compras: "Não",
    jse_tempo_negocio: "3 anos",
    jse_compra_produtos_servicos: "Distribuidores autorizados",
    jse_propaganda_negocio: "Indicação de clientes",
    jse_razao_consumidor_comprar: "Serviço confiável",
    jse_necessidades_crescimento: "Ampliar espaço físico",
    jse_pensou_desistir: "Não",
    jse_financiamento_necessario: "Sim",
    jse_funcionarios_negocio: "Sim",
    jse_visao_futura: "Ser referência na cidade",
    jse_calculo_custo_produto: "Sim",
    jse_reducao_custos: "Sim",
    jse_producao_mensal: "300 serviços",
    jse_situacao_negocio: "Consolidado",
    jse_participou_curso: "Sim",
    jse_curso: "Gestão de Negócios",
    jse_instituicao: "Sebrae",
    jse_participar_curso: "Sim",
    jse_motivo_participar: "Aprimorar a gestão",
    jse_necessidade_financiamento: "Sim",
    jse_motivo_financiamento: "Expandir a oficina",
    jse_receber_orientacao: "Sim",
    jse_motivo_orientacao: "Planejar crescimento",
    jse_conhece_aval_solidario: "Sim",
    jse_motivo_proprio_negocio: "Continuar o negócio da família",
  },
];

export const dadosVisualizarInformacoesComplementares = {
  eOSCIP: true,
  oscips: ["ASS"],
  eRegistradoBCB: true,
  seguimentoBCB: "IPg",
  eCorrespondenteBancario: true,
  eFintech: true,
  eApoioAtividadeProdutiva: true,
  eEmpresaSimplesCredito: true,
  ePNMPO: true,
  dataDeInicio: "01/01/2024",
  recursosRadio: "terceirosRepasse",
  repassadorasCnpjs: "34.567.890/0001-11",
  repassadorasNomes: "Repassadora X",
  eMicrocreditoMandato: true,
  mandatariasCnpjs: "45.678.901/0001-22, 56.789.012/0001-33",
  mandatariasNomes: "Mandatária Y, Mandatária Z",
  abrangenciaOperacao: "NAC",
  uf: "SP",
  municipio: "São Paulo",
  situacao: "Ativo",
  instituicoes: [
    { value1: "Empresa alfa", value2: "123123123" },
    { value1: "Empresa beta", value2: "321321321" },
  ],
  mandatarias: [
    { value1: "Empresa alfa", value2: "123123123" },
    { value1: "Empresa beta", value2: "321321321" },
  ],
  repassadoras: [{ value1: "Empresa alfa", value2: "123123123" }],
  instituicoesCnpjs: "12.345.678/0001-99, 98.765.432/0001-00",
  instituicoesCnpjsNomes: "Empresa A, Empresa B",
  motivo:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin quis cursus augue. Integer interdum dolor non tempor commodo. Pellentesque porttitor felis ut ipsum ultricies dignissim. Suspendisse dignissim commodo risus, a pulvinar enim feugiat nec. Fusce efficitur, urna id facilisis mollis, metus felis finibus dui, quis iaculis libero lorem nec libero.",
};
