import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import { FormControl, FormControlLabel, FormHelperText } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

import DynamicForm from "../formularios/DynamicForm";
// import ReCAPTCHA from "../login/recaptcha";

const RegisterForm = ({ loading, recaptchaRef, handleSubmit, handleTermoDeUsoShow, handleAvisoDePrivacidadeShow }) => {
  const theme = useTheme();
  const formRef = useRef();
  const [showPassword, setShowPassword] = useState(false);

  const fields = [
    {
      name: "email",
      label: "E-mail",
      validation: {
        required: "E-mail é obrigatório",
        pattern: {
          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          message: "O e-mail deve ser válido",
        },
      },
      size: { xs: 12 },
    },
    {
      name: "name",
      label: "Nome",
      validation: { required: "Nome é obrigatório" },
      size: { xs: 12 },
    },
    // {
    //   name: "username",
    //   label: "Username",
    //   validation: {
    //     required: "Nome de Usuário é obrigatório",
    //     minLength: { value: 5, message: "Nome de usuário deve ter pelo menos 6 caracteres" },
    //   },
    //   size: { xs: 12 },
    // },
    {
      name: "password",
      label: "Senha",
      validation: { required: "Senha é obrigatória", minLength: { value: 5, message: "A senha deve ter pelo menos 6 caracteres" } },
      renderComponent: ({ value, onChange, onBlur, ref, error }) => (
        <TextField
          id="password"
          name="password"
          label="Senha"
          fullWidth
          InputLabelProps={value ? { shrink: true } : null}
          type={showPassword ? "text" : "password"}
          placeholder="Senha"
          value={value ?? ""}
          onChange={onChange}
          onBlur={onBlur}
          inputRef={ref}
          error={!!error}
          helperText={error?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ),
      size: { xs: 12 },
    },
    {
      name: "passwordConfirmation",
      label: "Confirmação de Senha",
      validation: { required: "Senha é obrigatória" },
      renderComponent: ({ value, onChange, onBlur, ref, error }) => (
        <TextField
          id="passwordConfirmation"
          name="passwordConfirmation"
          label="Confirmação de Senha"
          fullWidth
          InputLabelProps={value ? { shrink: true } : null}
          type={showPassword ? "text" : "password"}
          placeholder="Confirmação de Senha"
          value={value ?? ""}
          onChange={onChange}
          onBlur={onBlur}
          inputRef={ref}
          error={!!error}
          helperText={error?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ),
      size: { xs: 12 },
    },
    {
      name: "toggle",
      label: "Toggle",
      validation: { required: "Você precisa aceitar os termos e condições" },
      renderComponent: ({ value, onChange, onBlur, ref, error }) => (
        <FormControl
          error={!!error}
          component="fieldset"
          fullWidth
          style={{ marginTop: 16 }}
        >
          <FormControlLabel
            control={
              <Checkbox
                id="toggle"
                name="toggle"
                checked={value ?? false}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                style={{ color: theme.palette.text.disabled }}
                data-testid="termos-e-condicoes"
              />
            }
            label={
              <Typography variant="body1">
                Estou ciente e concordo com o
                <Link
                  component="a"
                  href="#"
                  onClick={handleTermoDeUsoShow}
                  color={theme.palette.primary.main}
                  underline="always"
                  sx={{ mx: 1 }}
                >
                  Termo de Uso
                </Link>
                e
                <Link
                  component="a"
                  href="#"
                  onClick={handleAvisoDePrivacidadeShow}
                  color={theme.palette.primary.main}
                  underline="always"
                  sx={{ mx: 1 }}
                >
                  Aviso de Privacidade
                </Link>
              </Typography>
            }
          />
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "token",
      label: "token",
      renderComponent: ({ onChange }) => (
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6LfH51EpAAAAAHhmahtgrjoaaKrO3n-hn9eCDGsv"
          onChange={onChange}
          data-testid="recaptcha"
        />
      ),
      size: { xs: 12 },
    },
  ];

  const handleButtonClick = () => {
    if (formRef.current) {
      // console.log(formRef.current.getValues());
      formRef.current.submitForm();
    }
  };

  return (
    <>
      <DynamicForm
        ref={formRef}
        fields={fields}
        onSubmit={handleSubmit}
        // onChange={handleFormChange}
      />
      <LoadingButton
        fullWidth
        size="large"
        type="button"
        variant="contained"
        color="primary"
        onClick={handleButtonClick}
        loading={loading}
        sx={{ mt: 2 }}
        data-testid="register-submit"
      >
        {loading && <span className="spinner-border spinner-border-sm"></span>}
        Registrar
      </LoadingButton>
    </>
  );
};

RegisterForm.propTypes = {
  handleSubmit: PropTypes.func,
  recaptchaRef: PropTypes.object,
  loading: PropTypes.bool,
  handleAvisoDePrivacidadeShow: PropTypes.func,
  handleTermoDeUsoShow: PropTypes.func,
};

export default RegisterForm;
