import React from "react";

import { FormGroup, Grid, Stack } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTheme } from "@mui/material/styles";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";

const FormInteresse = () => {
  const theme = useTheme();

  return (
    <div>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={12}
          sx={{ mb: 2 }}
        >
          <p style={{ fontFamily: "Rawline Bold", fontSize: "16px" }}>Eu tenho interesse em oportunidade de:</p>
        </Grid>
      </Grid>

      <Formik>
        <Form>
          <Stack>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={12}
                mb={1}
              >
                <div style={{ border: "1px solid lightGrey", borderRadius: "5px", padding: "8px" }}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox style={{ color: theme.palette.text.primary }} />}
                      label={<a style={{ fontFamily: "Rawline Bold", fontSize: "14px" }}>Emprego</a>}
                    />
                  </FormGroup>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                mb={1}
              >
                <div style={{ border: "1px solid lightGrey", borderRadius: "5px", padding: "8px" }}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox style={{ color: theme.palette.text.primary }} />}
                      label={<a style={{ fontFamily: "Rawline Bold", fontSize: "14px" }}>Estágio</a>}
                    />
                    <FormControlLabel
                      style={{ display: "flex", alignItems: "flex-start" }}
                      sx={{ ml: 2 }}
                      control={<Checkbox style={{ color: theme.palette.text.primary }} />}
                      label={
                        <a style={{ fontFamily: "Rawline Regular", fontSize: "14px" }}>
                          Estou ciente que o Ministério do Desenvolvimento e Assistência Social, Família e Combate à Fome e o Programa
                          Acredita no Primeiro Passo exercem o papel de facilitador no processo de intermediação de mão de obra e não se
                          responsabiliza pela contratação do estagiário em nenhuma fase. E me enquadro em uma das seguintes situações: i)
                          estudante matriculado regularmente em instituições de educação superior, ii) de educação profissional, iii) de
                          ensino médio, iv) da educação especial ou dos v) anos finais do ensino fundamental, na modalidade profissional da
                          educação de jovens e adultos (EJA).
                        </a>
                      }
                    />
                  </FormGroup>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
              >
                <div style={{ border: "1px solid lightGrey", borderRadius: "5px", padding: "8px" }}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox style={{ color: theme.palette.text.primary }} />}
                      label={<a style={{ fontFamily: "Rawline Bold", fontSize: "14px" }}>Jovem Aprendiz</a>}
                    />
                    <FormControlLabel
                      style={{ display: "flex", alignItems: "flex-start" }}
                      sx={{ ml: 2 }}
                      control={<Checkbox style={{ color: theme.palette.text.primary }} />}
                      label={
                        <a style={{ fontFamily: "Rawline Regular", fontSize: "14px" }}>
                          Estou ciente que o Ministério do Desenvolvimento e Assistência Social, Família e Combate à Fome e o Programa
                          Acredita no Primeiro Passo exercem o papel de facilitador no processo de intermediação de mão de obra e não se
                          responsabiliza pela contratação do estagiário em nenhuma fase. E me enquadro em uma das seguintes situações: i)
                          estudante matriculado regularmente em instituições de educação superior, ii) de educação profissional, iii) de
                          ensino médio, iv) da educação especial ou dos v) anos finais do ensino fundamental, na modalidade profissional da
                          educação de jovens e adultos (EJA).
                        </a>
                      }
                    />
                  </FormGroup>
                </div>
              </Grid>
            </Grid>
          </Stack>
        </Form>
      </Formik>
    </div>
  );
};

FormInteresse.propTypes = {
  initialValues: PropTypes.shape({
    ocupacao: PropTypes.string,
    dataInicio: PropTypes.string,
    dataFim: PropTypes.string,
    conclusao: PropTypes.string,
  }),
};

export default FormInteresse;
