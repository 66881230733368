import React, { useRef } from "react";

import { Button } from "@mui/material";

import DynamicForm from "../components/formularios/DynamicForm";
import ReusableSwitch from "../components/formularios/fields/ReusableSwitch";
import FormMultipleInsertDouble from "../components/formularios/FormMultipleInsertDouble";

const PageComponentsForm = () => {
  const formRef = useRef();

  const fields = [
    {
      name: "firstName",
      label: "First Name",
      validation: { required: "First name is required" },
      size: { xs: 12, sm: 6, md: 4, lg: 6 },
    },
    {
      name: "senha",
      label: "Senha forte",
      validation: { required: "Necessario senha!" },
      size: { xs: 12, sm: 12, md: 12, lg: 12 },
    },
    {
      name: "teste",
      label: "Teste",
      validation: { required: "First name is required" },
      renderComponent: ({ value = true, onChange }) => (
        <ReusableSwitch
          id="reusableSwitch2"
          name="reusableSwitch2"
          onChange={onChange}
          labelLeft="Label 1"
          labelRight="Label 2"
          value={value}
        />
      ),
      size: { xs: 12, sm: 6, md: 4, lg: 6 },
    },
    {
      name: "multiplesInputs",
      label: "MultiplesInputs",
      validation: { required: "Necessário preencher o campo!" },
      renderComponent: ({ value, onChange, error }) => (
        <FormMultipleInsertDouble
          initialValue1={"initial 1"}
          placeHolder1={"placeHolder teste"}
          values={value}
          label1={"Nome"}
          initialValue2={"initial 2"}
          placeHolder2={"placeHolder teste"}
          label2={"CNPJ"}
          onFieldsChange={onChange}
          error={error}
        />
      ),
      size: { xs: 12, sm: 12, md: 12, lg: 12 },
    },
    {
      name: "phone",
      label: "Phone Number",
      validation: { required: "Phone number is required" },
      size: { xs: 12, sm: 6, md: 4, lg: 3 },
    },
    {
      name: "email",
      label: "E-mail",
      validation: {
        required: "E-mail is required",
        pattern: {
          value: /^[^@]+@[^@]+\.[^@]+$/,
          message: "Invalid email address",
        },
      },
      size: { xs: 12, sm: 6, md: 6, lg: 4 },
    },
  ];

  const handleLogClick = () => {
    if (formRef.current) {
      formRef.current.submitForm(); // Chama o submit do formulário
    }
  };

  const handleLogValues = () => {
    console.log(formRef.current.getValues());
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>Dynamic Form</h1>
      <DynamicForm
        ref={formRef}
        fields={fields}
        onChange={e => console.log("CHANGE -> ", e)}
      />
      <Button
        type="button"
        onClick={handleLogClick}
      >
        Submit!
      </Button>
      <Button
        type="button"
        onClick={handleLogValues}
      >
        LOG!
      </Button>
    </div>
  );
};

export default PageComponentsForm;
