import React, { useRef, useState } from "react";

import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import { valoresOSCIP, valoresSeguimentoBCB } from "../../services/constants";
import constantsService from "../../services/constants.service";
import DynamicForm from "./DynamicForm";
import ReusableSwitch from "./fields/ReusableSwitch";
import { SelectAtuacaoParceiro } from "./fields/SelectAtuacaoParceiro";
import FormMultipleInsertAddress from "./FormMultipleInsertAddress";
import FormMultipleInsertDouble from "./FormMultipleInsertDouble";
import FormMultipleInsertSelect from "./FormMultipleInsertSelect";

const mapInstituicoes = (instituicoes, tipo) => {
  if (!instituicoes) return [];
  return instituicoes.map(item => ({
    tipo: tipo,
    nome: item.value1,
    cnpj: item.value2,
  }));
};

const valoresIniciais = {
  eOSCIP: false,
  oscips: [],
  eRegistradoBCB: false,
  seguimentoBCB: "",
  eCorrespondenteBancario: false,
  instituicoesCorrespondenteBancario: [],
  eFintech: false,
  eApoioAtividadeProdutiva: false,
  eEmpresaSimplesCredito: false,
  ePNMPO: false,
  dataDeInicio: "",
  recursosRadio: "",
  instituicoesRepassadoras: [],
  eMicrocreditoMandato: false,
  instituicoesMandatarias: [],
  abrangenciaOperacao: "",
  areaAbrangenciaLista: [],
  situacao: "",
  motivo: "",
};

const AdminParceiroFormInformacoesComplementares = ({ values: initialValuesRecebidos, visualizacao = false, handleSubmit, id }) => {
  const theme = useTheme();
  const formRef = useRef();

  // Tratar valoresOSCIP
  const optionsOSCIP = constantsService.transformDictIntoOptions(valoresOSCIP);
  const optionsSeguimentoBCB = constantsService.transformDictIntoOptions(valoresSeguimentoBCB);

  const [initialValues, setInitialValues] = initialValuesRecebidos ? useState(initialValuesRecebidos) : useState(valoresIniciais);

  const handleFormChange = updatedField => {
    setInitialValues(prevValues => ({
      ...prevValues,
      ...updatedField,
    }));
  };

  const fields = [
    {
      name: "eOSCIP",
      label: "É OSCIP?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">{"É OSCIP?"}</Typography>
          <ReusableSwitch
            id={"eOSCIP"}
            name={"eOSCIP"}
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 4 },
    },
    {
      name: "oscips",
      label: "Lista de Finalidades",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <FormMultipleInsertSelect
            initialValue={value ? value : []}
            placeHolder={"Escolha uma finalidade"}
            label={"Quais as finalidades?"}
            onFieldsChange={handleMultiplesFinalidadeOSCIP}
            onChange={onChange}
            list={optionsOSCIP}
            visualizacao={visualizacao}
          />
        </FormGroup>
      ),
      size: { xs: 8 },
    },
    {
      name: "eRegistradoBCB",
      label: "É registrado(a) no Banco Central do Brasil?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">É registrado(a) no Banco Central do Brasil?</Typography>
          <ReusableSwitch
            id={"eRegistradoBCB"}
            name={"eRegistradoBCB"}
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 4 },
    },
    {
      name: "seguimentoBCB",
      label: "Em qual seguimento do Sistema Financeiro Nacional?",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <SelectAtuacaoParceiro
            idSelect={"seguimentoBCB"}
            nameSelect={"seguimentoBCB"}
            handleSelect={onChange}
            list={optionsSeguimentoBCB}
            item={value ?? ""}
            label="Em qual seguimento do Sistema Financeiro Nacional?"
            placeholder={"Escolha um seguimento"}
            visualizacao={visualizacao}
          />
        </FormGroup>
      ),
      size: { xs: 8 },
    },
    {
      name: "eCorrespondenteBancario",
      label: "É Correspondente Bancário?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">É correspondente bancário?</Typography>

          <ReusableSwitch
            id={"eCorrespondenteBancario"}
            name={"eCorrespondenteBancario"}
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "instituicoesCorrespondenteBancario",
      label: "Nome do correspondente bancário",
      renderComponent: ({ value }) => (
        <FormMultipleInsertDouble
          label1="Nome do correspondente bancário"
          label2="CNPJ correspondente bancário"
          value1={"nome"}
          value2={"cnpj"}
          disabled={visualizacao}
          values={value}
          onFieldsChange={e => {
            value = e;
            formRef.current.setValue("instituicoesCorrespondenteBancario", e);
          }}
        ></FormMultipleInsertDouble>
      ),
      size: { xs: 12 },
    },
    {
      name: "eFintech",
      label: "É uma instituição financeira que realiza operações exclusivamente por meio de sítio eletrônico ou de aplicativo?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Grid
            item
            xs={12}
          >
            <Typography variant="subtitle2">
              É uma instituição financeira que realiza operações exclusivamente por meio de sítio eletrônico ou de aplicativo (Fintech)?
            </Typography>
          </Grid>

          <ReusableSwitch
            id={"eFintech"}
            name={"eFintech"}
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "eApoioAtividadeProdutiva",
      label: "É pessoa jurídica especializada no apoio, no fomento ou na orientação a atividades produtivas?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Grid
            item
            xs={12}
          >
            <Typography variant="subtitle2">
              É pessoa jurídica especializada no apoio, no fomento ou na orientação a atividades produtivas?
            </Typography>
          </Grid>

          <ReusableSwitch
            id={"eApoioAtividadeProdutiva"}
            name={"eApoioAtividadeProdutiva"}
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "eEmpresaSimplesCredito",
      label: "É empresa Simples de Crédito?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Grid
            item
            xs={12}
          >
            <Typography variant="subtitle2">É empresa Simples de Crédito?</Typography>
          </Grid>

          <ReusableSwitch
            id={"eEmpresaSimplesCredito"}
            name={"eEmpresaSimplesCredito"}
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "ePNMPO",
      label: "É operador de PNMPO?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">É operador de PNMPO?</Typography>

          <ReusableSwitch
            id={"ePNMPO"}
            name={"ePNMPO"}
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 5 },
    },
    {
      name: "dataDeInicio",
      label: "Data de Início",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Data de Início"
              id="dataDeInicio"
              name="dataDeInicio"
              value={value ? dayjs(value) : null}
              format="DD/MM/YYYY"
              onChange={onChange}
              disabled={visualizacao}
            />
          </LocalizationProvider>
        </FormGroup>
      ),
      size: { xs: 7 },
    },
    {
      name: "tipoDeRecurso",
      label: "É operador de carteira de microcrédito com que tipo de recurso?",
      renderComponent: () => {
        const handleRadioChange = event => {
          setRadioValue(event.target.value);
        };

        return (
          <>
            <Typography variant="subtitle2">É operador de carteira de microcrédito com que tipo de recursos?</Typography>
            <FormGroup>
              <RadioGroup
                name="tipoDeRecurso"
                onChange={handleRadioChange}
                value={radioValue}
                disabled={visualizacao}
              >
                <FormControlLabel
                  value="PRO"
                  control={
                    <Radio
                      style={{ color: theme.palette.primary.main }}
                      checked={radioValue === "PRO"}
                      disabled={visualizacao}
                    />
                  }
                  label="Próprios"
                />
                <FormControlLabel
                  value="TER"
                  control={
                    <Radio
                      style={{ color: theme.palette.primary.main }}
                      checked={radioValue === "TER"}
                      disabled={visualizacao}
                    />
                  }
                  label="De terceiros/repasse"
                />
                <FormControlLabel
                  value="PTR"
                  control={
                    <Radio
                      style={{ color: theme.palette.primary.main }}
                      checked={radioValue === "PTR"}
                      disabled={visualizacao}
                    />
                  }
                  label="Próprios e de terceiros/repasse"
                />
              </RadioGroup>
            </FormGroup>
          </>
        );
      },
      size: { sx: 12 },
    },
    {
      name: "instituicoesRepassadoras",
      label: "Nome da Repassadora",
      renderComponent: ({ value }) => (
        <FormMultipleInsertDouble
          label1="Nome da repassadora"
          label2="CNPJ da repassadora"
          value1={"nome"}
          value2={"cnpj"}
          disabled={visualizacao}
          values={value}
          onFieldsChange={e => {
            value = e;
            formRef.current.setValue("instituicoesRepassadoras", e);
          }}
        ></FormMultipleInsertDouble>
      ),
      size: { xs: 12 },
    },
    {
      name: "eMicrocreditoMandato",
      label: "É operador de carteira de microcrédito por mandato?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Grid
            item
            xs={12}
            mt={2}
          >
            <Typography variant="subtitle2">É operador de carteira de microcrédito por mandato?</Typography>
          </Grid>

          <ReusableSwitch
            id={"eMicrocreditoMandato"}
            name={"eMicrocreditoMandato"}
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
    },
    {
      name: "instituicoesMandatarias",
      label: "Nome das instituições mandatárias",
      renderComponent: ({ value }) => (
        <FormMultipleInsertDouble
          label1="Nome das intituições mandatárias"
          label2="CNPJ das instituições mandatárias"
          value1={"nome"}
          value2={"cnpj"}
          disabled={visualizacao}
          values={value}
          onFieldsChange={e => {
            value = e;
            formRef.current.setValue("instituicoesMandatarias", e);
          }}
        ></FormMultipleInsertDouble>
      ),
      size: { xs: 12 },
    },
    {
      name: "abrangenciaOperacao",
      label: "Qual a abrangência de operação?",
      renderComponent: ({ value }) => {
        return (
          <FormControl style={{ width: "100%" }}>
            <InputLabel>Qual a abrangência de operação?</InputLabel>
            <Select
              id="abrangenciaOperacao"
              name="abrangenciaOperacao"
              value={value ?? ""}
              type="text"
              onChange={event => handleChangeAbrangencia(event)}
              disabled={visualizacao}
            >
              <MenuItem
                value=""
                disabled
              >
                Selecione uma opção
              </MenuItem>
              <MenuItem value={"NAC"}>Nacional</MenuItem>
              <MenuItem value={"EST"}>Estadual</MenuItem>
              <MenuItem value={"MUN"}>Municipal</MenuItem>
            </Select>
          </FormControl>
        );
      },
      size: { xs: 4 },
    },
    {
      name: "areaAbrangenciaLista",
      label: "UF",
      renderComponent: ({ value }) => (
        <FormMultipleInsertAddress
          initialValues={value || ["", ""]}
          onFieldsChange={handleMultipleInputsAddress}
          multiplesInsert={true}
          desabilitarUf={areaAbrangencia === "NAC" || !areaAbrangencia}
          desabilitarMunicipio={areaAbrangencia === "NAC" || areaAbrangencia === "EST"}
          disabled={visualizacao}
        />
      ),
      size: { xs: 8 },
    },
    {
      name: "situacao",
      label: "Situação",
      renderComponent: ({ value }) => (
        <>
          {visualizacao && (
            <FormGroup>
              <TextField
                id="situacao"
                name="situacao"
                placeholder="Situação"
                label="Situação?"
                value={value}
                type="text"
                onChange={handleFormChange}
                disabled={visualizacao}
              />
            </FormGroup>
          )}
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "motivo",
      label: "Motivo",
      renderComponent: ({ value }) => (
        <>
          {visualizacao && (
            <FormGroup>
              <TextField
                multiline
                rows={4}
                id="motivo"
                name="motivo"
                label="Motivo"
                placeholder="Motivo"
                value={value}
                type="text"
                onChange={handleFormChange}
                disabled={visualizacao}
              />
            </FormGroup>
          )}
        </>
      ),
      size: { xs: 12 },
    },
  ];

  const [multiplesInputAddress, setMultiplesInputAddress] = useState([]);
  const [radioValue, setRadioValue] = initialValuesRecebidos ? useState(initialValues.recursosRadio) : useState("");
  const [multiplesFinalidadeOSCIP, setMultiplesFinalidadeOSCIP] = initialValuesRecebidos ? useState(initialValues.oscips) : useState([]);
  const [areaAbrangencia, setAreaAbrangencia] = useState(() => {
    initialValuesRecebidos ? initialValues.abrangenciaOperacao : "";
  });

  const handleMultipleInputsAddress = values => {
    setMultiplesInputAddress(values);
  };

  const handleMultiplesFinalidadeOSCIP = values => {
    setMultiplesFinalidadeOSCIP(values);
  };

  const handleChangeAbrangencia = event => {
    const { name, value } = event.target;

    setAreaAbrangencia(value);
    formRef.current.setValue(name, value);
  };

  const handleButtonClick = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const prepararCamposAntesDoSubmit = values => {
    // Tratamento para campos de Sim/Não - ReusableSwitch
    let eOSCIP = values.eOSCIP ? "SIM" : "NAO";
    let eRegistradoBCB = values.eRegistradoBCB ? "SIM" : "NAO";
    // let eCorrespondenteBancario = values.eCorrespondenteBancario ? "SIM" : "NAO";
    let eFintech = values.eFintech ? "SIM" : "NAO";
    let eApoioAtividadeProdutiva = values.eApoioAtividadeProdutiva ? "SIM" : "NAO";
    let eEmpresaSimplesCredito = values.eEmpresaSimplesCredito ? "SIM" : "NAO";
    let ePNMPO = values.ePNMPO ? "SIM" : "NAO";
    let eMicrocreditoMandato = values.eMicrocreditoMandato ? "SIM" : "NAO";

    // Tratamento para multiplos
    values.oscips = multiplesFinalidadeOSCIP;

    // Tratamento para radio
    values.recursosRadio = radioValue;

    // Tratamento para UF e Cidade - areaAbrangenciaLista
    let areaAbrangenciaLista = [];

    if (values.abrangenciaOperacao === "EST") {
      multiplesInputAddress.forEach(item => areaAbrangenciaLista.push([item.uf]));
    } else if (values.abrangenciaOperacao === "MUN") {
      multiplesInputAddress.forEach(item => areaAbrangenciaLista.push([item.cidade, item.uf]));
    }

    // Tratamento das instituicoes
    values.associadas = [
      ...mapInstituicoes(values.instituicoesCorrespondenteBancario, "CBN"),
      ...mapInstituicoes(values.instituicoesRepassadoras, "MCR"),
      ...mapInstituicoes(values.instituicoesMandatarias, "MCM"),
    ];

    const dados = {
      empresa_parceira: parseInt(id),
      st_fintech: eFintech,
      st_apoio_emp_simples: eApoioAtividadeProdutiva,
      st_emp_simples: eEmpresaSimplesCredito,
      st_operador_pnmpo: ePNMPO,
      dt_operador_pnmpo: dayjs(values.dataDeInicio).format("YYYY-MM-DD"),
      tp_microcredito_recurso: values.recursosRadio,
      tp_microcredito_mandataria: eMicrocreditoMandato,
      associadas: values.associadas,
      tp_area_abrangencia: values.abrangenciaOperacao,
      // Valores que se não estiverem setados não são mandados
      ...(eOSCIP && { oscips: values.oscips }),
      ...(eRegistradoBCB && { tp_bacen: values.seguimentoBCB }),
      ...(values.abrangenciaOperacao != "NAC" && { area_abrangencia_lista: areaAbrangenciaLista }),
    };

    return dados;
  };

  return (
    <>
      <Card
        color="#ffffff"
        sx={{
          borderRadius: "8px",
          padding: "16px",
          mt: "24px",
        }}
      >
        <DynamicForm
          ref={formRef}
          fields={fields}
          onSubmit={values => {
            const dados = prepararCamposAntesDoSubmit(values);
            handleSubmit(dados);
          }}
          initialValues={initialValues}
          onChange={handleFormChange}
        />

        {/* BOTOES DE ENVIO E CANCELAMENTO */}
        {!visualizacao && (
          <Grid
            container
            spacing={1}
            mt={3}
            justifyContent="flex-end"
          >
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => window.history.back()}
              >
                <Typography variant={"BUTTON TEXT"}>CANCELAR</Typography>
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                variant="contained"
                type="submit"
                onClick={handleButtonClick}
                sx={{ gap: "8px" }}
              >
                <SaveIcon fontSize="small" />
                <Typography variant={"BUTTON TEXT"}>SALVAR INFORMAÇÕES</Typography>
              </LoadingButton>
            </Grid>
          </Grid>
        )}
      </Card>
    </>
  );
};

AdminParceiroFormInformacoesComplementares.propTypes = {
  values: PropTypes.object,
  visualizacao: PropTypes.bool,
  handleSubmit: PropTypes.func,
  id: PropTypes.string,
};

export default AdminParceiroFormInformacoesComplementares;
