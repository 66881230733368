import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import PropTypes from "prop-types";

const CustomModal = ({ showModal, handleClose, title, content, buttons, maxWidth = "md" }) => {
  return (
    <Dialog
      maxWidth={maxWidth}
      onClose={handleClose}
      open={showModal}
      fullWidth={true}
    >
      <DialogTitle sx={{ m: 0, p: 2, fontFamily: "Rawline Bold" }}>{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      {/* <DialogContent dividers>{dangerousContent ? <div dangerouslySetInnerHTML={{ __html: dangerousContent }} /> : content}</DialogContent> */}

      <DialogContent dividers>{content}</DialogContent>

      <DialogActions sx={{ justifyContent: "center" }}>
        {buttons.map((button, index) => {
          const variacao = button.variant || "text";
          return (
            <Button
              key={index}
              onClick={button.onClick}
              variant={variacao}
              sx={{ gap: button.icon ? "8px" : "0px" }}
              disabled={button.disabled}
            >
              {/* TODO mudar para material symbols ? */}
              <span className="material-icons">{button.icon}</span> {button.label}
            </Button>
          );
        })}
      </DialogActions>
    </Dialog>
  );
};

CustomModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  buttons: PropTypes.array.isRequired,
  maxWidth: PropTypes.string,
};

export default CustomModal;
