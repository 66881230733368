import React, { useEffect, useState } from "react";

import { Card, Container, Grid, TextField } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import { tabelaVagasTrabalho } from "../../components/formularios/dadosMockados";
import TitlePage from "../../components/title-page/TitlePage";

const PageCriteriosVaga = () => {
  const [valores, setValores] = useState({
    empresa: "",
    tipo: "",
    vagas: "",
    areaAtuacao: "",
    local: "",
    descricao: "",
  });
  const id = JSON.parse(localStorage.getItem("vagaID"));

  useEffect(() => {
    if (id) {
      const vagaEncontrada = tabelaVagasTrabalho.find(vaga => vaga.id == id);
      if (vagaEncontrada) {
        setValores({ ...vagaEncontrada, ["local"]: vagaEncontrada.cidade + ", " + vagaEncontrada.uf });
      }
    }
  }, []);

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/parceiro/listar/aprovados", text: "Listar Parceiros Aprovados" },
          { href: "/parceiro/visualizar/" + id, text: "Visualizar" },
        ]}
        currentPage="Critérios da Vaga"
      />

      <TitlePage title={"Critérios da Vaga"} />

      <Card
        color="#ffffff"
        sx={{
          borderRadius: "8px",
          padding: "16px",
          mt: "12px",
        }}
      >
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            container
            spacing={1}
          >
            <Grid
              item
              xs={12}
              sm={4}
            >
              <TextField
                disabled
                fullWidth
                id="empresa"
                name="empresa"
                label="Empresa"
                placeholder="Empresa"
                value={valores.empresa}
                type="text"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <TextField
                disabled
                fullWidth
                id="tipo"
                name="tipo"
                label="Tipo Vaga"
                placeholder="Tipo Vaga"
                value={valores.tipo}
                type="text"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <TextField
                disabled
                fullWidth
                id="vagas"
                name="vagas"
                label="Vagas"
                placeholder="Vagas"
                value={valores.vagas}
                type="text"
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            spacing={1}
          >
            <Grid
              item
              xs={12}
              sm={6}
            >
              <TextField
                disabled
                fullWidth
                id="areaAtuacao"
                name="areaAtuacao"
                label="Área de Atuação"
                placeholder="Área de Atuação"
                value={valores.areaAtuacao}
                type="text"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <TextField
                disabled
                fullWidth
                id="local"
                name="local"
                label="Local"
                placeholder="Local"
                value={valores.local}
                type="text"
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            spacing={1}
          >
            <Grid
              item
              xs={12}
            >
              <TextField
                disabled
                fullWidth
                multiline
                minRows={3}
                id="descricao"
                name="descricao"
                label="Descrição"
                placeholder="Descrição"
                value={valores.descricao}
                type="text"
              />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default PageCriteriosVaga;
