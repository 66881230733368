import React from "react";

import { Box, Grid, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";

import CustomModal from "./CustomModal";

const AtivarVagaTrabalhoModal = ({ showModal, handleClose }) => {
  const modalTitle = "Ativar vaga < nome da vaga >";
  const subtitle = "Situação < situação da vaga >";
  const modalContent = (
    <Box>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
        >
          <Typography variant={"h6"}>{subtitle}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
        >
          <TextField
            label="Motivo"
            value={"Teste"}
            rows={3}
            disabled
            fullWidth
            multiline
          />
        </Grid>
      </Grid>
    </Box>
  );

  const modalButtons = [
    { label: "CANCELAR", onClick: handleClose, variant: "outlined" },
    { label: "CONFIRMAR ATIVAÇÃO", onClick: handleClose, variant: "contained", icon: "save" },
  ];

  return (
    <CustomModal
      showModal={showModal}
      handleClose={handleClose}
      title={modalTitle}
      content={modalContent}
      buttons={modalButtons}
      maxWidth="md"
    />
  );
};

AtivarVagaTrabalhoModal.propTypes = {
  // values: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AtivarVagaTrabalhoModal;
