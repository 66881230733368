import { transformarDadosTrilha } from "../utils/adminTrilhaUtils";
import transformDadosEmpresaComplementar from "../utils/parceiroEmpresaComplementarUtils";
import { transformarDadosUnicaEmpresa, transformEmpresasComDetalhes } from "../utils/parceiroEmpresaUtils";
import axiosInstance from "./axiosConfig";
import API_URL from "./config";

// const acessToken = localStorage.getItem("token");

/**
 * Retrieves a admin using the provided access token and ID.
 *
 * @param {number} id - The ID of the admin to retrieve
 * @return {Promise} A Promise that resolves to the data of the retrieved admin
 */
const getAdminData = () => {
  return axiosInstance
    .get(API_URL + "v1/admins/index/", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      // console.log("Service admin", response.data);
      return response.data;
    })
    .catch(error => {
      console.error("Error fetching admin data:", error);
      throw error;
    });
};

/**
 * Recupera os detalhes de uma empresa parceira usando o ID fornecido.
 *
 * @param {number} id - O ID da empresa parceira a ser recuperada.
 * @return {Promise<Object>} Uma Promessa que resolve para os detalhes da empresa parceira recuperada.
 * @throws {Error} Se ocorrer algum erro durante a recuperação dos detalhes da empresa parceira.
 */
const getEmpresaParceira = id => {
  return axiosInstance
    .get(API_URL + `v1/admins/empresa/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      return transformarDadosUnicaEmpresa(response.data);
    })
    .catch(error => {
      console.error("Error fetching empresa parceira details:", error);
      throw error;
    });
};

/**
 * Recupera uma lista de empresas parceiras com seus detalhes.
 *
 * @return {Promise<Array>} Uma promessa que resolve para um array de empresas parceiras com seus detalhes.
 */
const getEmpresasParceiras = async () => {
  try {
    const response = await axiosInstance.get(API_URL + `v1/admins/empresas`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    // Mapear cada empresa para buscar detalhes individuais
    const empresasObj = response.data;
    const empresas = Object.values(empresasObj);

    // Map each empresa to fetch individual details
    const detalhesPromises = empresas.map(empresa => {
      return getEmpresaParceira(empresa.pk_empresa_parceira);
    });

    // Esperar por todas as chamadas de detalhes
    const detalhes = await Promise.all(detalhesPromises);

    // Combinar dados brutos com detalhes
    const empresasComDetalhes = empresas.map((empresa, index) => ({
      ...empresa,
      detalhes: detalhes[index],
    }));

    return transformEmpresasComDetalhes(empresasComDetalhes);
  } catch (error) {
    console.error("Error fetching empresas parceiras:", error);
    throw error;
  }
};

/**
 * Atualiza o status de uma empresa.
 *
 * @param {object} dados - Os dados a serem enviados no corpo da requisição.
 * @returns {Promise<string>} Uma promessa que resolve com a mensagem do servidor.
 */
const postAtualizaStatusEmpresa = data => {
  return axiosInstance
    .post(API_URL + `v1/admins/empresa/atualiza_status`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      console.log("Service admin", response.data);
      return response.data.message;
    })
    .catch(error => {
      console.error("Error atualizando status da empresa parceira:", error);
      throw error;
    });
};

/**
 * Insere arquivos no servidor (para análise de Empresas Parceiras).
 *
 * @param {Array} arquivos - Os arquivos a serem enviados no corpo da requisição.
 * @returns {Promise<string>} Uma promessa que resolve com a mensagem do servidor.
 * @throws {Error} Se ocorrer algum erro durante a inserção dos arquivos.
 */
const postInsereArquivos = async arquivos => {
  try {
    const uploadPromises = arquivos.map(file =>
      axiosInstance.post(`${API_URL}v1/admins/anexo/insere_arquivo/`, file, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }),
    );

    const responses = await Promise.all(uploadPromises);
    responses.forEach(response => console.log("Service admin", response.data));
    return responses.map(response => response.data);
  } catch (error) {
    console.error("Error Post Insere Arquivos:", error);
    throw new Error("Erro Post Insere Arquivos: " + error.message);
  }
};

/**
 * Insere um arquivo no servidor.
 *
 * @param {object} file - O arquivo a ser inserido.
 * @return {Promise<Object>} Uma Promessa que resolve para o arquivo inserido.
 * @throws {Error} Se ocorrer algum erro durante a inserção do arquivo.
 */
const postInsereArquivoTrilha = file => {
  return axiosInstance
    .post(API_URL + `v1/admins/anexo/insere_foto_trilha/`, file, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      // console.log("Inseri arquivo", response.data);
      // Preciso pegar o response.data.id para colocar no id da trilha
      return response.data;
    })
    .catch(error => {
      console.error("Error Post Insere Arquivo Trilha:", error);
      throw error;
    });
};

/**
 * Recupera um arquivo pelo ID.
 *
 * @param {number} id - O ID do arquivo a ser recuperado.
 * @return {Promise<Object>} Uma Promessa que resolve para o arquivo recuperado.
 * @throws {Error} Se ocorrer algum erro durante a recuperação do arquivo.
 */
const getArquivo = id => {
  return axiosInstance
    .get(API_URL + `v1/admins/anexo/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      //console.log("Service admin", response.data);
      return response.data;
    })
    .catch(error => {
      console.error("Error obtendo arquivo:", error);
      throw error;
    });
};

/**
 * Recupera o blob do arquivo pelo ID.
 *
 * @param {number} id - O ID do arquivo a ser recuperado.
 * @return {Promise<Object>} Uma Promessa que resolve para o arquivo recuperado.
 * @throws {Error} Se ocorrer algum erro durante a recuperação do arquivo.
 */
const getArquivoDados = id => {
  return axiosInstance
    .get(API_URL + `v1/admins/file_anexo/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      responseType: "blob",
    })
    .then(response => {
      // console.log("Service admin", response.data);
      const url = URL.createObjectURL(response.data);
      return url;
    })
    .catch(error => {
      console.error("Error obtendo dados de arquivo:", error);
      throw error;
    });
};

/**
 * Obter dados de uma empresa complementar.
 *
 * @param {number} id - ID da empresa a qual a empresa complementar é vinculada.
 * @returns {Promise<object>} Uma promessa que resolve com a mensagem do servidor.
 * @throws {Error} Se ocorrer algum erro durante a atualização dos dados da trilha.
 */
const getEmpresaComplementar = id => {
  return axiosInstance
    .get(API_URL + `v1/admins/empresa_complementar/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      const dados = transformDadosEmpresaComplementar(response.data);
      return dados;
    })
    .catch(error => {
      console.error("Error buscando empresa complementar:", error);
      throw error;
    });
};

const getEmpresaParceiraEComplementar = id => {
  return Promise.all([getEmpresaParceira(id), getEmpresaComplementar(id)])
    .then(([parceiraResponse, complementarResponse]) => {
      return {
        ...parceiraResponse,
        empresaComplementarDados: complementarResponse,
      };
    })
    .catch(error => {
      console.error("Error fetching empresa parceira e complementar:", error);
      throw error;
    });
};

/**
 * Obter dados de uma empresa complementar.
 *
 * @param {Object} dados - Os dados a serem enviados no corpo da requisição.
 * @returns {Promise<string>} Uma promessa que resolve com a mensagem do servidor.
 * @throws {Error} Se ocorrer algum erro durante a atualização dos dados da trilha.
 */
const postAtualizarStatusEmpresaComplementar = data => {
  return axiosInstance
    .post(API_URL + `v1/admins/empresa_complementar/atualiza_status`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      console.log("Service parceiro", response.data);
      return response.data.message;
    })
    .catch(error => {
      console.error("Error atualizando status da empresa complementar parceira:", error);
      throw error;
    });
};

/**
 * Obter dados de uma empresa complementar.
 *
 * @param {Object} id - ID a ser enviada no corpo da requisição.
 * @returns {Promise<string>} Uma promessa que resolve com a mensagem do servidor.
 * @throws {Error} Se ocorrer algum erro durante a atualização dos dados da trilha.
 */
const putAtualizarEmpresaComplementar = id => {
  // TODO: usar put em vez de POST
  return axiosInstance
    .post(API_URL + `v1/admins/empresa_complementar/atualiza/` + id, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      console.log("Service parceiro", response.data);
      return response.data.message;
    })
    .catch(error => {
      console.error("Error atualizando empresa complementar parceira:", error);
      throw error;
    });
};

/**
 * Cadastra uma nova trilha.
 *
 * @param {object} data - Os dados a serem enviados no corpo da requisição.
 * @returns {Promise<string>} Uma promessa que resolve com a mensagem do servidor.
 * @throws {Error} Se ocorrer algum erro durante o cadastro da trilha.
 */
const postTrilhaCurso = data => {
  return axiosInstance
    .post(API_URL + `v1/admins/trilha_curso/cadastro/`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      // console.log("Service admin", response.data);
      return response.data.message;
    })
    .catch(error => {
      console.error("Error cadastrando trilha:", error);
      throw error;
    });
};

/**
 * Recupera os detalhes de uma trilha usando o ID fornecido.
 *
 * @param {number} id - O ID da trilha a ser recuperada.
 * @return {Promise<Object>} Uma Promessa que resolve para os detalhes da trilha recuperada.
 * @throws {Error} Se ocorrer algum erro durante a recuperação dos detalhes da trilha.
 */
const getTrilhaCurso = id => {
  return axiosInstance
    .get(API_URL + `v1/admins/trilha_curso/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      const anexoId = response.data?.anexo;

      // Use Promise.all to wait for both requests to complete
      return Promise.all([adminService.getArquivo(anexoId), adminService.getArquivoDados(anexoId)]).then(([anexoDados, anexoURL]) => {
        const name = anexoDados?.local?.split("/").pop();

        const anexo = { ...anexoDados, url: anexoURL, name };
        const dados = { ...response.data, anexo };

        // Transform and return the combined data
        return transformarDadosTrilha(dados);
      });
    })
    .catch(error => {
      console.error("Error fetching Trilha:", error);
      throw error;
    });
};

/**
 * Recupera os detalhes de uma trilha usando o ID fornecido.
 *
 * @return {Promise<Object>} Uma Promessa que resolve para as trilhas cadastradas.
 * @throws {Error} Se ocorrer algum erro durante a recuperação das trilhas.
 */
const getTrilhasCurso = () => {
  return axiosInstance
    .get(API_URL + `v1/admins/trilha_curso/`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      // console.log("Service admin", response.data);
      return response.data.map(trilha => transformarDadosTrilha(trilha));
    })
    .catch(error => {
      console.error("Error fetching Trilhas:", error);
      throw error;
    });
};

/**
 * Atualiza os dados de uma trilha.
 *
 * @param {object} data - Os dados a serem enviados no corpo da requisição.
 * @param {number} id - Os dados a serem enviados no corpo da requisição.
 * @returns {Promise<string>} Uma promessa que resolve com a mensagem do servidor.
 * @throws {Error} Se ocorrer algum erro durante a atualização dos dados da trilha.
 */
const atualizarTrilhaCurso = (data, id) => {
  return axiosInstance
    .put(API_URL + `v1/admins/trilha_curso/atualiza/${id}`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      // console.log("Service admin", response.data);
      return response.data.message;
    })
    .catch(error => {
      console.error("Error atualizando Trilha:", error);
      throw error;
    });
};

/**
 * Atualiza o status de uma trilha.
 *
 * @param {object} data - Os dados a serem enviados no corpo da requisição.
 * @returns {Promise<string>} Uma promessa que resolve com a mensagem do servidor.
 * @throws {Error} Se ocorrer algum erro durante a atualização de status da trilha.
 */
const postAtualizarStatusTrilha = data => {
  return axiosInstance
    .post(API_URL + `v1/admins/trilha_curso/atualiza_status/`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      const dados = transformDadosEmpresaComplementar(response.data);
      return dados;
    })
    .catch(error => {
      console.error("Error cadastrando trilha:", error);
      throw error;
    });
};

/**
 * Deleta uma trilha.
 *
 * @param {number} id - O id da trilha a ser deletada.
 * @param {object} motivo - O motivo da exclusão.
 * @returns {Promise<string>} Uma promessa que resolve com a mensagem do servidor.
 * @throws {Error} Se ocorrer algum erro durante a deleção da trilha.
 */
const deletarTrilha = (id, motivo) => {
  return axiosInstance
    .delete(API_URL + `v1/admins/trilha_curso/exclui/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: {
        motivo: motivo,
      },
    })
    .then(response => {
      // console.log("Service admin", response.data);
      return response.data;
    })
    .catch(error => {
      console.error("Error deletando trilha:", error);
      throw error;
    });
};

const adminService = {
  getAdminData,
  getEmpresaParceira,
  getEmpresasParceiras,
  postAtualizaStatusEmpresa,
  postInsereArquivos,
  postInsereArquivoTrilha,
  getArquivo,
  getArquivoDados,
  getEmpresaComplementar,
  postAtualizarStatusEmpresaComplementar,
  putAtualizarEmpresaComplementar,
  postTrilhaCurso,
  getTrilhaCurso,
  getTrilhasCurso,
  atualizarTrilhaCurso,
  deletarTrilha,
  postAtualizarStatusTrilha,
  getEmpresaParceiraEComplementar,
};

export default adminService;
