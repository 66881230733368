import React, { useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { Button, FormGroup, Grid, Stack, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTheme } from "@mui/material/styles";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";

const valoresIniciais = {
  ocupacao: "",
  dataInicio: "",
  dataFim: "",
  conclusao: "",
};

const FormExperiencias = ({ initialValues = valoresIniciais }) => {
  const theme = useTheme();

  const [formList, setFormList] = useState([initialValues, initialValues]);

  const addNewForm = () => {
    setFormList([...formList, initialValues]);
  };

  const removeForm = index => {
    const updatedFormList = formList.filter((_, i) => i !== index);
    setFormList(updatedFormList);
  };

  return (
    <div>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={12}
          sx={{ mb: 2 }}
        >
          <p style={{ fontFamily: "Rawline Bold", fontSize: "16px" }}>Tem alguma experiência de trabalho?</p>
          <p style={{ fontFamily: "Rawline Regular", fontSize: "14px" }}>
            {
              "(Informe aqui todos os trabalhos em que você teve renda/salário. Por exemplo: lavador de carro, empregado doméstico ou vendedor ambulante.)"
            }
          </p>
        </Grid>
      </Grid>
      {formList.map((formValues, index) => (
        <Formik
          key={index}
          initialValues={formValues}
          validateOnChange={false}
        >
          {({ values, handleChange, handleBlur }) => (
            <Form>
              <Stack>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                  >
                    <p style={{ fontFamily: "Rawline Bold", fontSize: "16px" }}>{`Experiência profissional ` + (index + 1)}</p>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{ mt: -3 }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox style={{ color: theme.palette.text.primary }} />}
                        label={
                          <a style={{ fontFamily: "Rawline Regular", fontSize: "14px" }}>
                            *Se o local de trabalho é/era uma empresa ou uma instituição, marque o campo abaixo
                          </a>
                        }
                      />
                    </FormGroup>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                  >
                    <FormGroup>
                      <TextField
                        label="Tipo e ocupação"
                        id={`ocupacao-${index}`}
                        name="ocupacao"
                        value={values.ocupacao}
                        placeholder=""
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                  >
                    <FormGroup>
                      <TextField
                        label="Primeiro mês e ano trabalhado"
                        id={`dataInicio-${index}`}
                        name="dataInicio"
                        value={values.dataInicio}
                        placeholder=""
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    sm={3}
                  >
                    <FormGroup>
                      <TextField
                        label="Último mês e ano trabalhado"
                        id={`dataFim-${index}`}
                        name="dataFim"
                        value={values.dataFim}
                        placeholder=""
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormGroup>
                  </Grid>
                  {index > 0 && (
                    <Grid
                      item
                      xs={2}
                      sm={1}
                      sx={{ display: "flex", alignItems: "flex-end" }}
                    >
                      <FormGroup>
                        <Button
                          variant="outlined"
                          onClick={() => removeForm(index)}
                        >
                          <DeleteOutline />
                        </Button>
                      </FormGroup>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                  >
                    <FormGroup>
                      <TextField
                        label="Descrição das atividades (máximo de 300 caracteres)"
                        id={`conclusao-${index}`}
                        name="conclusao"
                        value={values.conclusao}
                        placeholder=""
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        multiline
                        rows={3}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{ mt: 3, mb: 3 }}
                  >
                    <FormGroup style={{ borderBottom: "1px solid LightGrey" }}></FormGroup>
                  </Grid>
                </Grid>
              </Stack>
            </Form>
          )}
        </Formik>
      ))}
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            variant="outlined"
            onClick={addNewForm}
            sx={{ mt: 2 }}
            startIcon={<AddCircleIcon />}
          >
            ADICIONAR EXPERIÊNCIA PROFISSIONAL
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

FormExperiencias.propTypes = {
  initialValues: PropTypes.shape({
    ocupacao: PropTypes.string,
    dataInicio: PropTypes.string,
    dataFim: PropTypes.string,
    conclusao: PropTypes.string,
  }),
};

export default FormExperiencias;
