import React, { forwardRef, useImperativeHandle } from "react";
import { Controller, useForm } from "react-hook-form";

import { FormGroup, Grid, TextField } from "@mui/material";
import PropTypes from "prop-types";

const DynamicForm = forwardRef(({ fields, onSubmit, onChange, initialValues = {} }, ref) => {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: initialValues });

  // Expose the functions to the parent component
  useImperativeHandle(ref, () => ({
    submitForm: () => {
      // console.log("Submit no form - ", ref);
      // handleSubmit;
      handleSubmit(onSubmit)();
    },
    setValue: (name, value) => {
      setValue(name, value);
    },
    getValues: () => getValues(),
  }));

  return (
    <form
      ref={ref}
      onSubmit={handleSubmit(onSubmit)}
      onChange={onChange}
    >
      <Grid
        container
        spacing={2}
      >
        {fields.map(({ name, label, validation, size = {}, renderComponent }, index) => {
          const { xs = 12, sm = xs, md = xs, lg = xs } = size;
          return (
            <Grid
              item
              xs={xs}
              sm={sm !== undefined ? sm : xs}
              md={md !== undefined ? md : xs}
              lg={lg !== undefined ? lg : xs}
              key={index + "_" + name}
            >
              <Controller
                name={name}
                control={control}
                rules={validation}
                render={({ field: { onChange, onBlur, value, ref, touched } }) =>
                  renderComponent ? (
                    renderComponent({ value, onChange, onBlur, ref, error: errors[name], touched })
                  ) : (
                    <FormGroup>
                      <TextField
                        inputRef={ref}
                        label={label}
                        name={name}
                        value={value || ""}
                        onChange={onChange}
                        onBlur={onBlur}
                        fullWidth
                        error={!!errors[name]}
                        helperText={errors[name]?.message}
                      />
                    </FormGroup>
                  )
                }
              />
            </Grid>
          );
        })}
      </Grid>
    </form>
  );
});

DynamicForm.displayName = "DynamicForm";

DynamicForm.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      renderComponent: PropTypes.func,
      size: PropTypes.shape({
        xs: PropTypes.number,
        sm: PropTypes.number,
        md: PropTypes.number,
        lg: PropTypes.number,
      }),
      validation: PropTypes.object,
    }),
  ).isRequired,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  initialValues: PropTypes.object,
};

export default DynamicForm;
