import React from "react";

import { Grid, Typography } from "@mui/material";
import { Card } from "@mui/material";
import PropTypes from "prop-types";

const RenderCard = ({ content }) => {
  return (
    <div>
      <Card
        color="background.paper"
        sx={{
          borderRadius: "8px",
          padding: "16px",
          marginTop: "16px",
        }}
        style={{ transition: "1s" }}
      >
        <Grid
          container
          style={{ alignItems: "center" }}
          mb={1}
          spacing={2}
        >
          <Grid item>
            <div
              style={{
                backgroundColor: "#E8E8E8",
                border: "1px solid #9E9E9E",
                width: "62px",
                height: "62px",
                borderRadius: "4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {content.icon}
            </div>
          </Grid>
          <Grid item>
            <Typography
              variant="h6"
              style={{
                fontSize: "18px",
                mt: "8px",
                mb: "16px",
                fontFamily: "Rawline Bold",
                color: "text.primary",
              }}
            >
              {content.title}
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item>
            <Typography
              variant="h6"
              style={{
                fontSize: "14px",
                mt: "8px",
                mb: "16px",
                fontFamily: "Rawline Thin",
                color: "text.primary",
              }}
            >
              {content.text.split("\n").map((str, index) => (
                <React.Fragment key={index}>
                  {str}
                  <br />
                </React.Fragment>
              ))}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          mt={2}
        >
          <Grid
            item
            xs={12}
          >
            <Typography
              variant="h6"
              style={{
                fontSize: "16px",
                mt: "8px",
                mb: "16px",
                fontFamily: "Rawline Bold",
                color: "text.primary",
              }}
            >
              {content.subtitle}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Typography
              variant="h6"
              style={{
                fontSize: "14px",
                mt: "8px",
                mb: "16px",
                fontFamily: "Rawline Thin",
                color: "text.primary",
              }}
            >
              {content.subtext.split("\n").map((str, index) => (
                <React.Fragment key={index}>
                  {str}
                  <br />
                </React.Fragment>
              ))}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

RenderCard.propTypes = {
  content: PropTypes.any,
};

export default RenderCard;
