import React from "react";

import WarningIcon from "@mui/icons-material/Warning";
import { LoadingButton } from "@mui/lab";
import { Card, Grid, Typography } from "@mui/material";

const CardCurriculoInexistente = () => {
  return (
    <>
      <Card
        color="#ffffff"
        sx={{
          borderRadius: "8px",
          padding: "16px",
        }}
        style={{ transition: "1s" }}
      >
        <Typography
          variant="h5"
          sx={{
            fontSize: "20",
            mb: "16px",
            fontFamily: "Rawline Regular",
          }}
        >
          Meu perfil
        </Typography>
        <Grid
          spacing={1}
          container
          sx={{ justifyContent: "center", flexDirection: "column", alignItems: "center" }}
        >
          <WarningIcon
            color="disabled"
            style={{ fontSize: 32 }}
          ></WarningIcon>
          <Typography
            variant="h6"
            sx={{
              fontSize: "10px",
              mt: "8px",
              mb: "16px",
              fontFamily: "Rawline Regular",
              color: "grey",
            }}
          >
            Não foi localizado currículo cadastrado
          </Typography>
        </Grid>

        <Grid
          container
          spacing={1}
          sx={{ mt: "5px", justifyContent: "flex-end" }}
        >
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
          >
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              onClick={async () => {}}
              fullWidth
              sx={{ borderRadius: "24px", fontFamily: "Rawline Bold" }}
            >
              Cadastre seu currículo no Acredita
            </LoadingButton>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default CardCurriculoInexistente;
