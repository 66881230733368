import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminListarUsuarios from "../../components/filtros/AdminListarUsuarios";
import TitlePage from "../../components/title-page/TitlePage";

const PageListarUsuarios = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "", text: "Usuários" }]}
        currentPage="Listar"
      />

      <TitlePage title={"Listar Usuários"} />

      <AdminListarUsuarios />
    </Container>
  );
};

export default PageListarUsuarios;
