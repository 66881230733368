// Constant dictionaries for status and types
export const statusDict = {
  NVO: "Novo cadastro",
  ECA: "Em cadastramento",
  PND: "Pendente ADM",
  APR: "Aprovado",
  BLQ: "Bloqueado",
  REP: "Reprovado",
  EXL: "Exclusão lógica do registro",
  EXC: "Exclusão lógica do registro",
  INA: "Inativo",
};

export const statusCodeDict = {
  "novo cadastro": "NVO",
  "em cadastramento": "ECA",
  "pendente adm": "PND",
  aprovado: "APR",
  bloqueado: "BLQ",
  reprovado: "REP",
  "exclusão lógica do registro": "EXL",
  inativo: "INA",
};

export const termosDict = {
  id: "ID",
  habilitacao: "Habilitação",
  status: "Status",
  cnpj: "CNPJ",
  nomeFantasia: "Nome Fantasia",
  razaoSocial: "Razão Social",
  naturezaJuridica: "Natureza Jurídica",
  nomeResponsavel: "Nome do Responsável",
  email: "E-mail do Responsável",
  nomePontoFocal: "Nome do Ponto Focal",
  emailPontoFocal: "E-mail do Ponto Focal",
  emailParceiro: "E-mail do Parceiro",
  telefone: "Telefone",
  endereco: "Endereço",
  complemento: "Complemento",
  cidade: "Município",
  municipio: "Município",
  uf: "UF",
  tipoDeServico: "Tipo de Serviço",
  tipoCurso: "Tipo de Curso",
  situacao: "Situação",
  modalidade: "Modalidade",
  curriculo: "Currículo",
  requisitos: "Requisitos",
  link: "Link",
  tempoInativo: "Inativo (hr)",
  pcd: "PCD",
  habilidade: "Habilidade",
  experiencia: "Experiência",
  origem: "Origem",
  vaga: "Vaga",
  tipoVaga: "Tipo de Vaga",
  nDeVagas: "Nº de Vagas",
  salario: "Salário",
  vigencia: "Vigência",
  // Datas
  dataInicio: "Data Início",
  dataFim: "Data Fim",
  dataCadastro: "Data do cadastro",
  dataUltimaModificacao: "Data da última modificação",
  dataInscricao: "Data de Inscrição",
  dataRegistro: "Data do Registro de Interesse",
};

export const tipoDeServicoDict = {
  VEP: "Vaga de Emprego",
  VET: "Vaga de Estágio",
  VJA: "Vaga de Jovem Aprendiz",
  CUR: "Cursos",
  FPG: "Financeiros e de Pagamentos",
  MPU: "Mobilização de Público",
  MPA: "Mobilização de Parceiro",
};

// export const naturezaJuridicaDict = {
//   Público: "PUB",
//   Privado: "PRV",
//   "Terceiro Setor": "TRS",
// };

export const naturezaJuridicaDict = {
  1015: "Órgão Público do Poder Executivo Federal",
  1023: "Órgão Público do Poder Executivo Estadual ou do Distrito Federal",
  1031: "Órgão Público do Poder Executivo Municipal",
  1040: "Órgão Público do Poder Legislativo Federal",
  1058: "Órgão Público do Poder Legislativo Estadual ou do Distrito Federal",
  1066: "Órgão Público do Poder Legislativo Municipal",
  1074: "Órgão Público do Poder Judiciário Federal",
  1082: "Órgão Público do Poder Judiciário Estadual",
  1104: "Autarquia Federal",
  1112: "Autarquia Estadual ou do Distrito Federal",
  1120: "Autarquia Municipal",
  1139: "Fundação Pública de Direito Público Federal",
  1147: "Fundação Pública de Direito Público Estadual ou do Distrito Federal",
  1155: "Fundação Pública de Direito Público Municipal",
  1163: "Órgão Público Autônomo Federal",
  1171: "Órgão Público Autônomo Estadual ou do Distrito Federal",
  1180: "Órgão Público Autônomo Municipal",
  1198: "Comissão Polinacional",
  1210: "Consórcio Público de Direito Público (Associação Pública)",
  1228: "Consórcio Público de Direito Privado",
  1236: "Estado ou Distrito Federal",
  1244: "Município",
  1252: "Fundação Pública de Direito Privado Federal",
  1260: "Fundação Pública de Direito Privado Estadual ou do Distrito Federal",
  1279: "Fundação Pública de Direito Privado Municipal",
  1287: "Fundo Público da Administração Indireta Federal",
  1295: "Fundo Público da Administração Indireta Estadual ou do Distrito Federal",
  1309: "Fundo Público da Administração Indireta Municipal",
  1317: "Fundo Público da Administração Direta Federal",
  1325: "Fundo Público da Administração Direta Estadual ou do Distrito Federal",
  1333: "Fundo Público da Administração Direta Municipal",
  1341: "União",
  2011: "Empresa Pública",
  2038: "Sociedade de Economia Mista",
  2046: "Sociedade Anônima Aberta",
  2054: "Sociedade Anônima Fechada",
  2062: "Sociedade Empresária Limitada",
  2070: "Sociedade Empresária em Nome Coletivo",
  2089: "Sociedade Empresária em Comandita Simples",
  2097: "Sociedade Empresária em Comandita por Ações",
  2127: "Sociedade em Conta de Participação",
  2135: "Empresário (Individual)",
  2143: "Cooperativa",
  2151: "Consórcio de Sociedades",
  2160: "Grupo de Sociedades",
  2178: "Estabelecimento, no Brasil, de Sociedade Estrangeira",
  2194: "Estabelecimento, no Brasil, de Empresa Binacional Argentino-Brasileira",
  2216: "Empresa Domiciliada no Exterior",
  2224: "Clube/Fundo de Investimento",
  2232: "Sociedade Simples Pura",
  2240: "Sociedade Simples Limitada",
  2259: "Sociedade Simples em Nome Coletivo",
  2267: "Sociedade Simples em Comandita Simples",
  2275: "Empresa Binacional",
  2283: "Consórcio de Empregadores",
  2291: "Consórcio Simples",
  2305: "Empresa Individual de Responsabilidade Limitada (de Natureza Empresária)",
  2313: "Empresa Individual de Responsabilidade Limitada (de Natureza Simples)",
  2321: "Sociedade Unipessoal de Advogados",
  2330: "Cooperativas de Consumo",
  2348: "Empresa Simples de Inovação - Inova Simples",
  2356: "Investidor Não Residente",
  3034: "Serviço Notarial e Registral (Cartório)",
  3069: "Fundação Privada",
  3077: "Serviço Social Autônomo",
  3085: "Condomínio Edilício",
  3107: "Comissão de Conciliação Prévia",
  3115: "Entidade de Mediação e Arbitragem",
  3131: "Entidade Sindical",
  3204: "Estabelecimento, no Brasil, de Fundação ou Associação Estrangeiras",
  3212: "Fundação ou Associação Domiciliada no Exterior",
  3220: "Organização Religiosa",
  3239: "Comunidade Indígena",
  3247: "Fundo Privado",
  3255: "Órgão de Direção Nacional de Partido Político",
  3263: "Órgão de Direção Regional de Partido Político",
  3271: "Órgão de Direção Local de Partido Político",
  3280: "Comitê Financeiro de Partido Político",
  3298: "Frente Plebiscitária ou Referendária",
  3301: "Organização Social (OS)",
  3310: "Demais Condomínios",
  3328: "Plano de Benefícios de Previdência Complementar Fechada",
  3999: "Associação Privada",
  4014: "Empresa Individual Imobiliária",
  4022: "Segurado Especial",
  4081: "Contribuinte individual",
  4090: "Candidato a Cargo Político Eletivo",
  4111: "Leiloeiro",
  4120: "Produtor Rural (Pessoa Física)",
  5010: "Organização Internacional",
  5029: "Representação Diplomática Estrangeira",
  5037: "Outras Instituições Extraterritoriais",
};

export const valoresOSCIP = {
  ASS: "Assistencial",
  BNF: "Beneficente",
  CID: "Cidadania",
  CNT: "Científica",
  CPR: "Cooperativas",
  CRD: "Creditícia",
  CUL: "Cultural",
  ECO: "Ecológico",
  EDU: "Educacional",
  ESP: "Esportista",
  ETC: "Ética",
  INT: "Integração",
  JUR: "Jurídica",
  NUT: "Nutrição",
  PSQ: "Pesquisas",
  REC: "Recreativa",
  SAU: "Saúde",
  TCA: "Tecnologias Alternativas",
  TTX: "Tratamento de Toxicômano",
  VOL: "Voluntariado",
  OUT: "Outras",
};

export const valoresSeguimentoBCB = {
  AF: "Agência de Fomento",
  APE: "Associação de Poupança e Empréstimo",
  BC: "Banco Comercial",
  BCE: "Banco Comercial Estrangeiro - Filial no país",
  BCo: "Banco Cooperativo",
  BCa: "Banco de Câmbio",
  BDs: "Banco de Desenvolvimento",
  BIn: "Banco de Investimento",
  BMu: "Banco Múltiplo",
  BNDES: "Banco Nacional de Desenvolvimento Econômico e Social",
  CEE: "Caixa Econômica Estadual",
  CEF: "Caixa Econômica Federal",
  CHi: "Companhia Hipotecária",
  CCr: "Cooperativa de Crédito",
  IPg: "Instituição de Pagamento",
  SCC: "Sociedade Corretora de Câmbio",
  SCCTVM: "Sociedade Corretora de Títulos e Valores Mobiliários",
  SAM: "Sociedade de Arrendamento Mercantil",
  SCM: "Sociedade de Crédito ao Microempreendedor",
  SCD: "Sociedade de Crédito Direto",
  SCIR: "Sociedade de Crédito Imobiliário - Repassadora",
  SCFI: "Sociedade de Crédito, Financiamento e Investimento",
  SEP: "Sociedade de Empréstimo entre Pessoas",
  SDTVM: "Sociedade Distribuidora de Títulos e Valores Mobiliários",
};

export const st_pronatec = {
  S: "Sim",
  N: "Não",
};

export const tp_abrangencia = {
  N: "Nacional",
  E: "Estadual",
  M: "Municipal",
};

export const tp_curso = {
  CUT: "Curso Técnico",
  FIC: "Formação Inicial Continuada",
  SUP: "Superior",
  TCN: "Tecnológico",
  QUA: "Qualificação",
};

export const tp_modalidade = {
  EAD: "EAD",
  SPR: "Semipresencial",
  PRS: "Presencial",
};

export const tp_escolaridade = {
  NLN: "Não sei informar",
  NFR: "Não frequentei a escola",
  EF: "Ensino fundamenta (1 grau)",
  EM: "Ensino médio (2 grau)",
  EMT: "Ensino médio técnico (2 grau técnico)",
  ES: "Ensino superior",
};

export const tp_vaga = {
  EMP: "Vaga de emprego",
  EST: "Vaga de estágio",
  JVA: "Vaga de jovem aprendiz",
};

export const tp_turno = {
  ADF: "A definir",
  DIU: "Diurno",
  NOT: "Noturno",
};

export const tp_escolaridadeVagaTrabalho = {
  EF: "Ensino Fundamental (1° Grau)",
  EM: "Ensino Médio (2° Grau)",
  EMT: "Ensino Médio Técnico (2° Grau Técnico)",
  ES: "Ensino Superior",
  ESI: "Ensino Superior incompleto",
  PG: "Pós-graduação",
  EF1: "Ensino Fundamental EJA – séries iniciais (Supletivo – 1º a 4º)",
  EF2: "Ensino Fundamental EJA – séries finais (Supletivo – 5º a 8º)",
  EME: "Ensino Médio Especial",
};

export const validadeVaga = {
  "-1": "Indefinido",
  0: "Inativa",
  30: "30 dias",
  45: "45 dias",
  60: "60 dias",
};

export const tp_beneficio = {
  AA: "auxilio alimentacao",
  AT: "auxilio transporte",
  PS: "plano de saude",
  O: "outros",
};

export const tp_atuacao = {
  E: "Exigido",
  D: "Desejado",
};
