import React, { useEffect, useState } from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminPerfil from "../../components/formularios/AdminPerfil";
import { tabelaPerfil } from "../../components/formularios/dadosMockados";
import TitlePage from "../../components/title-page/TitlePage";

const initialValues = {
  dataUltimaModificacao: null,
  nome: "",
  descricao: "",
  situacao: "none",
};

const PageVisualizarPerfil = () => {
  const perfilID = JSON.parse(localStorage.getItem("perfilID"));
  const [valores, setValores] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (perfilID) {
      const perfilEncontrado = tabelaPerfil.find(perfil => perfil.id === perfilID);

      if (perfilEncontrado) {
        setValores({
          ...perfilEncontrado,
          ["acoes"]: perfilEncontrado.acoes.split(", "),
        });
      }
    }
    setIsLoading(false);
  }, []);

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/profile", text: "Perfil" }]}
        currentPage="Visualizar Perfil"
      />
      <TitlePage title={"Visualizar Perfil"} />

      {isLoading ? (
        <div>Carregando...</div>
      ) : valores ? (
        <AdminPerfil
          visualizar={true}
          valores={valores}
          setValores={setValores}
        />
      ) : (
        <div>Parceiro não encontrado</div>
      )}
    </Container>
  );
};

export default PageVisualizarPerfil;
