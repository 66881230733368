import React from "react";

import { Card, Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import DefaultTable from "../../components/table/DefaultTable";
import TitlePage from "../../components/title-page/TitlePage";

const PageVisualizarAlteracoesDocumentos = () => {
  const documentosAlterados = [
    {
      dataModificacao: "10/10/2004",
      momento: "Teste",
      nomeArquivo: "Currículo",
    },
  ];

  const colunas = [
    { field: "dataModificacao", headerName: "Data", sxProps: { width: "200px" } },
    { field: "momento", headerName: "Momento", sxProps: { width: "200px" } },
    { field: "nomeArquivo", headerName: "Nome do Arquivo", sxProps: { width: "200px" } },
  ];

  const getTabelaActions = () => [
    {
      title: "Download",
      icon: "download",
      href: "",
    },
  ];

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/parceiro/listar/pendentes", text: "Listar Parceiros Pendentes" },
          { href: "/parceiro/analisar/", text: "Analisar Parceiro Pendente" },
        ]}
        currentPage="Informações alteradas"
      />

      <TitlePage title={"Informações alteradas"} />

      <Card
        color="#ffffff"
        sx={{
          borderRadius: "8px",
          padding: "16px",
          mt: "24px",
        }}
      >
        <DefaultTable
          columns={colunas}
          rows={documentosAlterados}
          actionButtons={getTabelaActions}
        />
      </Card>
    </Container>
  );
};

export default PageVisualizarAlteracoesDocumentos;
