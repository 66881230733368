import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ParceiroMobilizacaoBuscarCursos from "../../components/formularios/ParceiroMobilizacaoBuscarCursos";
import TitlePage from "../../components/title-page/TitlePage";

const PageMobilizacaoBuscarCursos = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/parceiro", text: "Parceiro" },
          { href: "/mobilizacao", text: "Mobilização" },
        ]}
        currentPage={"Buscar Cursos"}
      />

      <TitlePage title={"Buscar Cursos"} />

      <ParceiroMobilizacaoBuscarCursos />
    </Container>
  );
};

export default PageMobilizacaoBuscarCursos;
