import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import SearchIcon from "@mui/icons-material/Search";
import { Button, Card, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import CustomModal from "../../components/modals/CustomModal";
import DefaultTable from "../../components/table/DefaultTable";
import TitlePage from "../../components/title-page/TitlePage";

const PageVisualizarTrilhaCurso = () => {
  const navigate = useNavigate();
  const { idUrlTrilha } = useParams();

  const [showRemoverInteresseDeCurso, setShowRemoverInteresseDeCurso] = useState(false);
  const [showIncluirInteresseDeCurso, setShowIncluirInteresseDeCurso] = useState(false);

  const [filtros, setFiltros] = useState({
    situacao: "",
    modalidade: "",
    "nome do curso": "",
    ofertante: "",
  });
  const [filtrosAplicados, setFiltrosAplicados] = useState(filtros);

  const aplicarFiltros = () => {
    setFiltrosAplicados(filtros);
  };

  const linksBreadCrumb = [
    { href: "/", text: "Cursos" },
    { href: "/cursos/trilhas-cursos", text: "Trilha de cursos em oferta" },
  ];

  const headerTabela = [
    { field: "nome do curso", headerName: "Curso" },
    { field: "descricao", headerName: "Descrição" },
    { field: "carga", headerName: "Carga" },
    { field: "ofertante", headerName: "Ofertante" },
  ];

  const dadosTabela = [
    {
      id: 1,
      "nome do curso": "Curso 1",
      ofertante: "Empresas alfa",
      descricao: "Descrição do curso 1",
      carga: "120 horas",
      modalidade: "Modalidade do curso 1",
      situacao: "Situação do curso 1",
    },
    {
      id: 3,
      "nome do curso": "Curso 3",
      ofertante: "Empresas alfa",
      descricao: "Descrição do curso 3",
      carga: "80 horas",
      modalidade: "Modalidade do curso 3",
      situacao: "Situação do curso 3",
    },
    {
      id: 4,
      "nome do curso": "Curso 4",
      ofertante: "Empresas beta",
      descricao: "Descrição do curso 4",
      carga: "160 horas",
      modalidade: "Modalidade do curso 4",
      situacao: "Situação do curso 4",
    },
    {
      id: 5,
      "nome do curso": "Curso 5",
      ofertante: "Empresas omega",
      descricao: "Descrição do curso 5",
      carga: "120 horas",
      modalidade: "Modalidade do curso 5",
      situacao: "Situação do curso 5",
    },
  ];

  const dadosFiltrados = useMemo(() => {
    return dadosTabela.filter(item =>
      Object.keys(filtros).every(coluna => {
        const valorFiltro = filtros[coluna];
        const valorItem = item[coluna];

        if (coluna === "Periodo de Início" && valorFiltro instanceof Date) {
          // Convertendo a string em objeto Date
          let partesData = valorItem.split("/");
          let dia = partesData[0];
          let mes = partesData[1] - 1;
          let ano = partesData[2];
          let itemDate = new Date(ano, mes, dia);

          const filtroDia = valorFiltro.getDate();
          const filtroMes = valorFiltro.getMonth();
          const filtroAno = valorFiltro.getFullYear();

          const itemDia = itemDate.getDate();
          const itemMes = itemDate.getMonth();
          const itemAno = itemDate.getFullYear();

          return filtroDia === itemDia && filtroMes === itemMes && filtroAno === itemAno;
        }

        // Verifica se o valor do filtro e do item são strings antes de aplicar toLowerCase()
        if (typeof valorFiltro === "string" && typeof valorItem === "string") {
          return valorItem.toLowerCase().includes(valorFiltro.toLowerCase());
        }

        // Se não forem strings, não aplica toLowerCase() e retorna true para manter o item no filtro
        return true;
      }),
    );
  }, [filtrosAplicados]);

  // eslint-disable-next-line no-unused-vars
  const tabelaHiddenLinhas = dadosTabela.map(({ id, curso, municipio, ...rest }) => rest);

  const getTabelaActions = () => [
    {
      title: "Visualizar Informações do curso",
      href: "",
      icon: "visibility",
      onClick: () => {
        navigate("/cursos/listar-cursos/visualizar-informacoes-do-curso/idCurso");
      },
    },
    {
      title: "Estou interessado nesse curso",
      href: "",
      icon: "thumb_up",
      onClick: () => {
        setShowIncluirInteresseDeCurso(true);
      },
    },
    {
      title: "Não estou interessado nesse curso",
      href: "",
      icon: "thumb_down",
      onClick: () => {
        setShowRemoverInteresseDeCurso(true);
      },
    },
  ];

  const ContentModalRemoverInteresseCurso = function () {
    return (
      <Grid
        container
        spacing={1}
        sx={{ justifyContent: "flex-end", flexDirection: "row", alignItems: "flex-end", gap: 2 }}
      >
        <Grid
          item
          xs={3}
          md={1}
        >
          <Button
            size="large"
            type="submit"
            variant="outlined"
          >
            NÃO
          </Button>
        </Grid>
        <Grid
          item
          xs={3}
          md={1}
        >
          <Button
            size="large"
            type="submit"
            variant="contained"
          >
            SIM
          </Button>
        </Grid>
      </Grid>
    );
  };

  const ContentModalIncluirInteresseCurso = function () {
    return (
      <Grid
        container
        spacing={1}
        sx={{ justifyContent: "flex-end", flexDirection: "row", alignItems: "flex-end", gap: 2 }}
      >
        <Grid
          item
          xs={3}
          md={1}
        >
          <Button
            size="large"
            type="submit"
            variant="outlined"
          >
            NÃO
          </Button>
        </Grid>
        <Grid
          item
          xs={3}
          md={1}
        >
          <Button
            size="large"
            type="submit"
            variant="contained"
          >
            SIM
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        currentPage={"Listar cursos da trilha "}
        links={linksBreadCrumb}
      />

      <CustomModal
        showModal={showRemoverInteresseDeCurso}
        handleClose={() => {
          setShowRemoverInteresseDeCurso(false);
        }}
        title={"Confirmar a remoção do seu interesse no curso"}
        content={<ContentModalRemoverInteresseCurso />}
        buttons={[]}
      />

      <CustomModal
        showModal={showIncluirInteresseDeCurso}
        handleClose={() => {
          setShowIncluirInteresseDeCurso(false);
        }}
        title={"Confirmar seu interesse no curso"}
        content={<ContentModalIncluirInteresseCurso />}
        buttons={[]}
      />

      <TitlePage title={`Listar cursos da trilha ${idUrlTrilha}`} />

      <Card>
        <Grid
          container
          spacing={2}
          sx={{ p: 2 }}
        >
          <Grid
            item
            md={12}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: "16",
                fontFamily: "Rawline Regular",
              }}
            >
              Filtros
            </Typography>
          </Grid>
          {Object.keys(filtros)
            .filter(coluna => coluna !== "id")
            .map(coluna => (
              <Grid
                item
                md={6}
                key={coluna}
              >
                {["modalidade", "situacao"].includes(coluna) ? (
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id={`label-${coluna}`}>{coluna}</InputLabel>
                    <Select
                      labelid={`label-${coluna}`}
                      id={coluna}
                      name={coluna}
                      value={filtros[coluna]}
                      onChange={e => setFiltros({ ...filtros, [coluna]: e.target.value })}
                    >
                      <MenuItem value="">Selecione uma opção</MenuItem>
                      {dadosTabela.map(item => (
                        <MenuItem
                          key={`key-${item[coluna]}`}
                          value={item[coluna]}
                        >
                          {item[coluna]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : coluna === "Periodo de Início" ? (
                  // Ajuste para a margin do provider
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    fullWidth
                  >
                    <DatePicker
                      label={coluna}
                      id="Periodo de Início"
                      name="Periodo de Início"
                      placeholder="Periodo de Início"
                      slotProps={{ textField: { fullWidth: true } }}
                      format="DD/MM/YYYY"
                      views={["day", "month", "year"]}
                      textField={params => (
                        <TextField
                          {...params}
                          fullWidth
                        />
                      )}
                      onChange={date => setFiltros({ ...filtros, [coluna]: date })}
                    />
                  </LocalizationProvider>
                ) : (
                  <TextField
                    label={coluna}
                    variant="filled"
                    fullWidth
                    value={filtros[coluna]}
                    onChange={e => setFiltros({ ...filtros, [coluna]: e.target.value })}
                  />
                )}
              </Grid>
            ))}

          <Grid
            item
            container
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end", gap: "10px 10px" }}
          >
            <Grid
              item
              md={2}
              sm={6}
              sx={{ alignSelf: "flex-end" }}
            >
              <Button
                size="large"
                type="submit"
                variant="outlined"
                fullWidth
              >
                CANCELAR
              </Button>
            </Grid>
            <Grid
              item
              md={2}
              sm={6}
              sx={{ alignSelf: "flex-end" }}
            >
              <Button
                size="large"
                type="submit"
                startIcon={<SearchIcon />}
                variant="contained"
                onClick={aplicarFiltros}
                fullWidth
              >
                PESQUISAR
              </Button>
            </Grid>
          </Grid>

          <Grid
            item
            md={12}
            sx={{ mt: "16px" }}
          >
            <DefaultTable
              columns={headerTabela}
              rows={dadosFiltrados}
              actionButtons={getTabelaActions}
            ></DefaultTable>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default PageVisualizarTrilhaCurso;
