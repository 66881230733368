import React from "react";

import PropTypes from "prop-types";

import TermoDeUso from "../../assets/texto/termoDeUso";
import CustomModal from "./CustomModal";

const TermoDeUsoModal = ({ showModal, handleClose }) => {
  const modalTitle = "Termo de Uso";
  const modalContent = <TermoDeUso TermoDeUso></TermoDeUso>;

  const modalButtons = [{ label: "Concordar", onClick: handleClose }];

  return (
    <CustomModal
      showModal={showModal}
      handleClose={handleClose}
      title={modalTitle}
      content={modalContent}
      buttons={modalButtons}
    />
  );
};

TermoDeUsoModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default TermoDeUsoModal;
