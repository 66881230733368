import React from "react";

import { CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

const LoadingComponent = ({ isLoading, fullScreen }) => {
  const theme = useTheme();

  return (
    //Caso seja fullscreen
    (isLoading && fullScreen && (
      <div
        style={{
          zIndex: "3",
          width: "100vw",
          height: "100vh",
          backgroundColor: theme.palette.text.inverter,
          opacity: "0.8",
          position: "fixed",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          top: "0px",
          right: "0px",
        }}
      >
        <CircularProgress size={80} />
        <a style={{ color: theme.palette.primary.main, fontFamily: "Rawline Bold", fontSize: "24px" }}>Carregando...</a>
      </div>
    )) ||
    //Caso NAO seja fullScreen
    (isLoading && !fullScreen && (
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress size={80} />
        <a style={{ color: theme.palette.primary.main, fontFamily: "Rawline Bold", fontSize: "24px" }}>Carregando...</a>
      </div>
    ))
  );
};

LoadingComponent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
};

export default LoadingComponent;
