import * as Yup from "yup";

import optionsService from "../../../services/options.service.js";
import { formatSite, validarCNPJ, validarCPF } from "../utils.js";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Forneça um e-mail válido").required("E-mail é obrigatório"),
  cnpj: Yup.string()
    .required("CNPJ é obrigatório")
    .test("Tamanho-Valido-CNPJ", "O CNPJ deve ter 14 dígitos", cnpj => cnpj.replace(/\D/g, "").length == 14)
    .test("Validar-CNPJ", "O CNPJ informado não é válido", async function (cnpj) {
      if (validarCNPJ(cnpj)) {
        return await optionsService.verificarCNPJ(cnpj).catch(() => {
          console.error("Erro ao obter CNPJ");
          return false;
        });
      }
      return false;
    }),
  razaoSocial: Yup.string().required("Razão social é obrigatório"),
  cep: Yup.string()
    .required("CEP é obrigatório")
    .test("minCEP", "O CEP deve ter 8 dígitos", cep => cep.replace(/\D/g, "").length == 8)
    .test("CEP-Encontrado", "O CEP não foi encontrado", async function (cep) {
      if (cep.replace(/\D/g, "").length == 8) {
        return await optionsService.verificarCEP(cep);
      }
      return true;
    }),
  endereco: Yup.string().required("Endereço é obrigatório"),
  bairro: Yup.string().required("Bairro é obrigatório"),
  uf: Yup.string().required("UF é obrigatório"),
  cidade: Yup.string().required("Município é obrigatório"),
  telefone: Yup.string()
    .test("Tamanho-Valido-Telefone", "Insira um telefone válido", telefone => {
      const tamanho = telefone.replace(/\D/g, "").length;
      if (tamanho == 10 || tamanho == 11) {
        return true;
      }
      return false;
    })
    .required("Telefone é obrigatório"),
  site: Yup.string()
    .test("url", "O site deve ter um formato válido", function (site) {
      if (!site) return true; // Se o campo estiver vazio, a validação passa
      return Yup.string().url().isValidSync(site);
    })
    .test("Site-Ativo", "O site informado não está ativo", async function (site) {
      if (!site || !Yup.string().url().isValidSync(site)) {
        return true; // Se o campo estiver vazio ou a URL for inválida, a validação passa
      }
      return await optionsService.verificarSiteAtivo(site).catch(erro => console.error(erro));
    }),
  redesSociais: Yup.array().of(
    Yup.string().test("url", "A rede social necessita ter um formato de site válido (ex: https://www.minharede.com)", function (site) {
      if (!site) return true; // Se o campo estiver vazio, a validação passa
      return Yup.string().url().isValidSync(site);
    }),
  ),
  nomeRepresentante: Yup.string().required("Nome do representante é obrigatório"),
  cpfRepresentante: Yup.string()
    .required("CPF do representante é obrigatório")
    .test("Tamanho-Valido-CPF", "O CPF deve ter 11 dígitos", value => value.replace(/\D/g, "").length == 11)
    .test("Validar-CPF", "O CPF informado não é válido", value => validarCPF(value)),
  telefoneRepresentante: Yup.string()
    .test("Tamanho-Valido-TelefoneRepresentante", "Insira um telefone válido", telefone => {
      const tamanho = telefone.replace(/\D/g, "").length;
      if (tamanho == 10 || tamanho == 11) {
        return true;
      }
      return false;
    })
    .required("Telefone do representante é obrigatório"),
  ufRepresentante: Yup.string().required("UF do representante é obrigatório"),
  cidadeRepresentante: Yup.string().required("Município do representante é obrigatório"),
  nomePontoFocal: Yup.string().required("Nome do Ponto Focal é obrigatório"),
  emailPontoFocal: Yup.string().email("Forneça um email válido").required("Email do Ponto Focal é obrigatório"),
  telefonePontoFocal: Yup.string()
    .test("Tamanho-Valido-TelefonePontoFocal", "Insira um telefone válido", telefone => {
      const tamanho = telefone.replace(/\D/g, "").length;
      if (tamanho == 10 || tamanho == 11) {
        return true;
      }
      return false;
    })
    .required("Telefone do Ponto Focal é obrigatório"),
  areaAtuacao: Yup.string().required("Área de atuação do parceiro é obrigatório"),
  naturezaJuridica: Yup.string().required("Natureza jurídica é obrigatório"),
  tipo: Yup.string().required("Tipo é obrigatório").max(100, "Tipo deve ter no máximo 100 caracteres"),
  porte: Yup.string().required("Porte é obrigatório").max(100, "Porte deve ter no máximo 100 caracteres"),
  toggleCienteNormas: Yup.boolean().oneOf([true], "Você precisa concordar com as normas"),
  toggleCienteGratuito: Yup.boolean().oneOf([true], "Você precisa informar que está ciente da condição"),
});

const isURLValid = value => {
  // Função para verificar se o valor é uma URL válida
  try {
    Yup.string().url().validateSync(value);
    return true;
  } catch (error) {
    return false;
  }
};

const avisoSiteValido = (errors, valor) => {
  // Função para verificar se o site é válido e se não há erros e mostrar uma mensagem válida
  if (isURLValid(formatSite(valor)) && !errors.site && valor.trim() !== "") {
    return "Site válido";
  }
  return "";
};

const handleKeyDown = event => {
  // Impedir que o formularário seja enviado ao pressionar a tecla Enter
  if (event.key === "Enter") {
    event.preventDefault();
  }
};

const FormInscricaoParceiroUtils = {
  validationSchema,
  avisoSiteValido,
  handleKeyDown,
};

export default FormInscricaoParceiroUtils;
