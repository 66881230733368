import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import FormEditarPerfilCidadao from "../../components/formularios/CidadaoEditarPefil";
import TitlePage from "../../components/title-page/TitlePage";

const PageEditarPerfil = () => {
  const handleSubmit = async values => {
    alert(JSON.stringify(values));
  };

  const linksBreadCrumb = [{ href: "/", text: "Cidadão" }];

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        currentPage="Editar perfil"
        links={linksBreadCrumb}
      />

      <TitlePage title={"Editar perfil"} />

      <FormEditarPerfilCidadao handleSubmit={handleSubmit}></FormEditarPerfilCidadao>
    </Container>
  );
};

export default PageEditarPerfil;
