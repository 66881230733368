import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ParceiroMobilizacaoListarCursosRegiaoCidadao from "../../components/formularios/ParceiroMobilizacaoListarCursosRegiaoCidadao";
import TitlePage from "../../components/title-page/TitlePage";

const PageMobilizacaoListarCursosRegiaoCidadao = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/", text: "Página Inicial" },
          { href: "/parceiro", text: "Parceiro" },
          { href: "/parceiro/cursos", text: "Cursos" },
        ]}
        currentPage={"Listar Cursos na Região do Cidadão"}
      />

      <TitlePage title={"Listar cursos para região do cidadão"} />

      <ParceiroMobilizacaoListarCursosRegiaoCidadao />
    </Container>
  );
};

export default PageMobilizacaoListarCursosRegiaoCidadao;
